// Drop handler function to get all files and folders

export default async function readAllEntries(
  dataTransferItemList: DataTransferItemList,
  folderCallback: (folder: FileSystemDirectoryEntry) => any,
  fileCallBack: (file: FileSystemFileEntry) => any,
  readDirCallback,
) {
  let fileEntries: FileSystemEntry[] = [];
  let directoryEntries: FileSystemDirectoryEntry[] = [];
  // Use BFS to traverse entire directory/file structure
  let queue: Array<FileSystemEntry | FileSystemDirectoryEntry> = [];
  // Unfortunately dataTransferItemList is not iterable i.e. no forEach
  for (let i = 0; i < dataTransferItemList.length; i++) {
    const dir = dataTransferItemList[i].webkitGetAsEntry();
    if (dir) {
      readDirCallback([dir]);
      queue.push(dir);
    }
  }
  while (queue.length > 0) {
    let entry = queue.shift();
    if (entry.isFile) {
      const fileEntry = entry as FileSystemFileEntry;
      fileCallBack(fileEntry);
      fileEntries.push(fileEntry);
    } else if (entry.isDirectory) {
      const directoryEntry = entry as FileSystemDirectoryEntry;
      folderCallback(directoryEntry);
      directoryEntries.push(directoryEntry);
      let reader = directoryEntry.createReader();
      // eslint-disable-next-line no-await-in-loop
      const all = await readAllDirectoryEntries(reader);
      readDirCallback(all);
      queue.push(...all);
    }
  }
  return {
    fileEntries,
    directoryEntries,
  };
}

// Get all the entries (files or sub-directories) in a directory by calling readEntries until it returns empty array
async function readAllDirectoryEntries(directoryReader: FileSystemDirectoryReader) {
  let entries: Array<FileSystemFileEntry | FileSystemDirectoryEntry> = [];
  let readEntries = await readEntriesPromise(directoryReader) as any[];
  while (readEntries && readEntries.length > 0) {
    entries.push(...readEntries);
    // eslint-disable-next-line no-await-in-loop
    readEntries = await readEntriesPromise(directoryReader) as any[];
  }
  return entries;
}

// Wrap readEntries in a promise to make working with readEntries easier
// eslint-disable-next-line consistent-return
async function readEntriesPromise(
  directoryReader: FileSystemDirectoryReader,
): Promise<Array<FileSystemFileEntry | FileSystemDirectoryEntry>> {
  try {
    return await new Promise((resolve, reject) => {
      // @ts-ignore
      directoryReader.readEntries(resolve, reject);
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}
