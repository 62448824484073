import EventEmitter from 'events';
import UsersUploadAdapter
  from '@/dataroom/infrastructure/api/FileUploadDispatcher/uploadAdapters/UsersUploadAdapter';
import { UploadProcessing, IUploadStrategy } from '@dealroadshow/file-uploader';
import IFileStream from '@dealroadshow/file-uploader/src/models/IFileStream';

export default class UsersUploadStrategy implements IUploadStrategy {
  constructor(protected readonly uploadAdapter: UsersUploadAdapter) {
  }

  async createUpload(
    fileStream: IFileStream,
    fileSize: number,
    fileName: string,
    options: {
      dataroomId: number,
      abortController: AbortController,
    },
  ) {
    const eventEmitter = new EventEmitter();
    const uploadAdapter = this.createAdapter(this.uploadAdapter, eventEmitter);

    uploadAdapter.upload(fileStream, fileSize, {
      fileName,
      ...options,
    });

    return new UploadProcessing(
      '', // We don't have uuid in our uploadAdapter
      fileSize,
      options,
      uploadAdapter,
      eventEmitter,
    );
  }

  protected createAdapter = (adapter, eventEmitter: EventEmitter) => {
    const clone = Object.assign(Object.create(Object.getPrototypeOf(adapter)), adapter);
    clone.setEventEmitter(eventEmitter);
    return clone;
  };
}
