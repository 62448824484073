import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import FilesystemRepository from '@/dataroom/infrastructure/repository/FilesystemRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentFolderContext } from '@/dataroom/application/CurrentFolderContext';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';

const useIndexLimits = () => {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();
  const { currentFolder } = useCurrentFolderContext();
  const [isFetching, setIsFetching] = useState(false);
  const [maxFileIndex, setMaxFileIndex] = useState<number>(null);
  const [maxFolderIndex, setMaxFolderIndex] = useState<number>(null);

  const getIndexLimits = async () => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        folderId: currentFolder.id,
      };

      const response = await container.get<FilesystemRepository>(FilesystemRepository).getIndexLimits(payload);

      setMaxFileIndex(response.maxFilesIndexValue);
      setMaxFolderIndex(response.maxFoldersIndexValue);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
      throw error;
    } finally {
      setIsFetching(false);
    }
  };

  const resetIndexLimits = () => {
    setMaxFileIndex(null);
    setMaxFolderIndex(null);
  };

  return {
    isFetching,
    maxFileIndex,
    maxFolderIndex,
    getIndexLimits,
    resetIndexLimits,
  };
};

export default useIndexLimits;
