import React from 'react';
import logoDealVDR from '@/dataroom/ui/assets/dealvdrLogoText.svg';
import logo17g5 from '@/dataroom/ui/assets/17g5LogoText.svg';
import logoInvestorSet from '@/dataroom/ui/assets/investorSetLogoText.svg';
import logoVeriSend from '@/dataroom/ui/assets/veriSendLogoText.svg';
import logoResearchRoom from '@/dataroom/ui/assets/researchRoomLogoText.svg';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';
import config from '@/dataroom/application/config/config';
import styles from '@/dataroom/ui/components/Dataroom/Sidebar/Footer/footer.scss';

const logoMapping = {
  [config.tenant.tenantDealvdr.code]: logoDealVDR,
  [config.tenant.tenant17g5.code]: logo17g5,
  [config.tenant.tenantInvestorSet.code]: logoInvestorSet,
  [config.tenant.tenantVeriSend.code]: logoVeriSend,
  [config.tenant.tenantResearchRoom.code]: logoResearchRoom,
};

function Logo() {
  const { tenant } = useDataroomTenantContext();

  return (
    <a
      href={ dmPortalUrl.getUrl(`/${ tenant }`) }
      className={ styles.rightAlignedIcon }
      data-test="logoButton"
    >
      <img src={ logoMapping[tenant] } alt={ tenant } />
    </a>
  );
}

export default Logo;
