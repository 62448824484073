import React, { createContext, useContext, useState } from 'react';
import Dispatcher from '@dealroadshow/json-rpc-dispatcher';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useDIContext } from '@/Framework/DI/DIContext';
import FolderRepository from '@/dataroom/infrastructure/repository/FolderRepository';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';

const useFolderInfo = () => {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();
  const [isFetching, setIsFetching] = useState(false);
  const [folderInfo, setFolderInfo] = useState(null);

  const getFolderInfo = async (folderId: number) => {
    setIsFetching(true);

    try {
      const folderRepository = container.get<FolderRepository>(FolderRepository);
      const response = await folderRepository.getInfo({
        folderId,
        dataroomId: dataroom.id,
      });

      setFolderInfo(response);
    } catch (error) {
      !Dispatcher.isAbortError(error) && container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  const resetFolderInfo = () => setFolderInfo(null);

  return {
    isFetching,
    folderInfo,
    getFolderInfo,
    resetFolderInfo,
  };
};

type TFolderInfoContext = ReturnType<typeof useFolderInfo>;

export const FolderInfoContext = createContext<TFolderInfoContext>(null);

export function useFolderInfoContext() {
  const context = useContext(FolderInfoContext);
  if (!context) {
    throw new Error('useFolderInfoContext must be used within a FolderInfoContext');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function FolderInfoContextProvider({ children } : IProps) {
  return (
    <FolderInfoContext.Provider value={ useFolderInfo() }>
      { children }
    </FolderInfoContext.Provider>
  );
}

export default FolderInfoContextProvider;
