export default class UploadError extends Error {
  message;

  displayMessage;

  constructor(message) {
    super(message);
    this.message = message;
    this.displayMessage = message;
  }
}
