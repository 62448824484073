import React, { useState, createContext, useContext } from 'react';
import { DndContext, useSensor, useSensors, MouseSensor, pointerWithin } from '@dnd-kit/core';
import DragOverlayComponent from '@/dataroom/ui/common/DragOverlayComponent';
import { canSelectedDrop } from '@/dataroom/domain/filesystemPermissions';
import DragAndDropMoveModal from '@/dataroom/ui/common/DataroomExplorer/Modals/DragAndDropModal';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';

interface IDragData {
  items: Array<IFilesystemListItem | IFolderTree>,
  destination: IFilesystemListItem | IFolderTree,
}

function useDragNDrop() {
  const [isDragModalVisible, setIsDragModalVisible] = useState<boolean>(false);
  const [dragData, setDragData] = useState<IDragData | null>(null);

  const handleDragStart = () => {
    setIsDragModalVisible(false);
    setDragData(null);
  };
  const handleDragEnd = ({ over, active }) => {
    if (over) {
      const items = active.data.current;
      const destination = over.data.current;
      if (canSelectedDrop(items, destination)) {
        setIsDragModalVisible(true);
        setDragData({ items, destination });
      }
    }
  };

  return {
    isDragModalVisible,
    setIsDragModalVisible,
    dragData,
    handleDragStart,
    handleDragEnd,
  };
}

type TDnDContext = ReturnType<typeof useDragNDrop>;

export const DragNDropContext = createContext<TDnDContext>(null);

export function useDragNDropContext() {
  const context = useContext(DragNDropContext);
  if (!context) {
    throw new Error('useDialInsContext must be used within a DialInsContextProvider');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function shouldHandleEvent(element: HTMLElement | null) {
  let currentElement = element;

  while (currentElement) {
    if (currentElement.dataset && currentElement.dataset.noDnd) {
      return false;
    }
    currentElement = currentElement.parentElement;
  }

  return true;
}

class CustomSensor extends MouseSensor {
  static activators = [
    {
      eventName: 'onMouseDown' as const,
      handler: ({ nativeEvent: event }) => {
        return shouldHandleEvent(event.target);
      },
    },
  ];
}

function DndContextProvider({ children } : IProps) {
  const mouseSensor = useSensor(CustomSensor, {
    activationConstraint: {
      distance: 5,
    },
  });

  const sensors = useSensors(
    mouseSensor,
  );

  const value = useDragNDrop();

  return (
    <DragNDropContext.Provider value={ value }>
      <DndContext
        sensors={ sensors }
        onDragStart={ value.handleDragStart }
        onDragEnd={ value.handleDragEnd }
        collisionDetection={ pointerWithin }
      >
        { children }
        <DragOverlayComponent />
        { value.isDragModalVisible ? (
          <DragAndDropMoveModal
            filesystemItems={ value.dragData?.items }
            closeModal={ () => value.setIsDragModalVisible(false) }
            destinationFolder={ value.dragData?.destination }
          />
        ) : null }

      </DndContext>
    </DragNDropContext.Provider>
  );
}

export default DndContextProvider;
