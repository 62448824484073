import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import { EnabledIndexingKeys } from '../../constants';
import styles from './dropdown.scss';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Dropdown from '@dealroadshow/uikit/core/components/Menu/Dropdown';
import IconArroeLeftBold from '@dealroadshow/uikit/core/components/Icon/IconArrowLeftBold';

interface IProps {
  handleDownload: (truncationMode?: string) => void,
  handleHide: () => void,
  handleShow: () => void,
  isOneFileSelected: boolean,
  isDisabled: boolean,
  isDropdownOpen: boolean,
}

interface IDownloadItem {
  text: string,
  dataTest: string,
  onClick: () => void,
  isDisabled?: boolean,
}

export interface DropdownRef {
  hide: () => void,
  dropdown: HTMLDivElement,
}

const DropdownComponent = (
  {
    handleDownload,
    handleHide,
    handleShow,
    isOneFileSelected,
    isDisabled,
    isDropdownOpen,
  }: IProps) => {
  const dropdownRef = useRef<DropdownRef>(null);

  const downloadItems: IDownloadItem[] = [
    {
      text: 'Download Index + Names',
      dataTest: 'downloadButtonIndexNames',
      onClick: () => handleDownload(),
    },
    {
      text: 'Download Index Only',
      dataTest: 'downloadButtonIndexOnly',
      onClick: () => handleDownload(EnabledIndexingKeys.IndexingEnabledDeleteAllNames),
      isDisabled: isOneFileSelected,
    },
    {
      text: 'Download Names Only',
      dataTest: 'downloadButtonNamesOnly',
      onClick: () => handleDownload(EnabledIndexingKeys.IndexingEnabledByIndex),
      isDisabled: isOneFileSelected,
    },
  ];

  const handleClickOutside = (event) => {
    if (!dropdownRef.current?.dropdown.contains(event.target)) {
      dropdownRef.current?.hide();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <Dropdown
      ref={ dropdownRef }
      data-test="downloadButtonDropdown"
      usePortal={ false }
      onClose={ handleHide }
      onOpen={ handleShow }
      className={ cn({ [styles.isDisabled]: isDisabled }, styles.dropdown) }
      disabled={ isDisabled }
      active={ isDropdownOpen }
      trigger={ (
        <div className={ styles.iconWrapper }>
          <IconArroeLeftBold
            className={ cn({ [styles.isOpen]: isDropdownOpen }, styles.icon) }
          />
        </div>
      ) }
      triggerClassName={ styles.dropdownTrigger }
      contentClassName={ styles.content }
    >
      { downloadItems.map(({
        isDisabled,
        text,
        dataTest,
        onClick,
      }) => (
        <Button
          type="button"
          variant={ ButtonVariantType.text }
          className={ cn({ [styles.isDisabled]: isDisabled }, styles.dropdownItem) }
          onClick={ onClick }
          title={ text }
          key={ text }
          dataTest={ dataTest }
          disabled={ isDisabled }
        />
      )) }
    </Dropdown>
  );
};

export default DropdownComponent;
