import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import Input from '@dealroadshow/uikit/core/components/Input';
import FormError from '@dealroadshow/uikit/core/components/Form/FormError';
import IconRefresh from '@dealroadshow/uikit/core/components/Icon/IconRefresh';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import styles from './twoFactorAuthenticationForm.scss';

interface IProps {
  isFetching: boolean,
  error: string,
  checkCode: (value: any, callback: () => void) => void,
  sendCode: (deliveryMethod: string) => void,
  reset: () => void,
  successCallback: () => void,
  deliveryMethod: string,
  formContactClassName?: string,
  resendButtonClassName?: string,
}

const TwoFactorAuthenticationForm = ({
  isFetching,
  error,
  checkCode,
  sendCode,
  reset,
  successCallback,
  deliveryMethod,
  formContactClassName,
  resendButtonClassName,
}: IProps) => {
  const codeInput = useRef(null);
  const [code, setCode] = useState('');

  useEffect(() => {
    if (!isFetching) {
      codeInput?.current.focus();
    }
  }, [isFetching]);

  useEffect(() => {
    setCode('');
  }, [deliveryMethod]);

  const onSuccessCallback = () => {
    setCode('');
    successCallback();
  };

  const onChange = ({ target: { value } }) => {
    setCode(value);

    if (error) {
      reset();
    }

    if (value.length === 6) {
      checkCode(value, onSuccessCallback);
    }
  };

  const onResendClick = () => {
    sendCode(deliveryMethod);
  };

  return (
    <div className={ styles.formWrapper }>
      <div className={ styles.inputWrapper }>
        { /* @ts-ignore */ }
        <Input
          inputClassName={ cn(styles.formInput, {
            [styles.formInputError]: error,
          }) }
          ref={ codeInput }
          value={ code }
          onChange={ onChange }
          maxLength={ 6 }
          disabled={ isFetching }
          isNarrow
          dataTest="twoFactorFormInput"

        />
        { error && error.length && (
          <FormError
            className={ styles.formError }
            error={ error }
            touched
          />
        ) }
      </div>
      <div className={ styles.resendWrapper }>
        <div
          className={ cn(styles.resendButton, resendButtonClassName) }
          onClick={ onResendClick }
        >
          <span>
            Resend code
          </span>
          <IconRefresh className={ styles.formIcon } />
        </div>
      </div>
      <p className={ cn(styles.formContact, formContactClassName) }>
        Please
        <a href={ finsightWebUrl.getContactUsUrl() } target="_blank">&nbsp;contact&nbsp;</a>
        our support team if you have trouble receiving your code.
      </p>
    </div>
  );
};

export default TwoFactorAuthenticationForm;
