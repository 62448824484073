import React from 'react';
import { useCheckBoxArrayContext } from './CheckboxArrayContext';

const CheckAll = (props) => {
  const { CheckAll, values, setValues } = useCheckBoxArrayContext();
  return (
    <CheckAll
      { ...props }
      allValues={ values }
      change={ setValues }
    />
  );
};

export default CheckAll;
