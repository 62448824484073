import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '../../application/DI/Rpc/HttpDispatcher';
import Country from '@/dealroadshow/domain/vo/Country';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import CountryRepositoryI from '@/dealroadshow/infrastructure/repository/country/CountryRepositoryI';

@Dependencies(RpcDispatcher)
class PhysicalDeliveryRepository implements CountryRepositoryI {
  constructor(private readonly rpc: typeof RpcDispatcher) {
  }

  requestCopy = async (
    payload: {
      token: string,
      dataroomName: string,
      firstName: string,
      lastName: string,
      phoneNumber: string,
      corporateEmail: string,
      companyName: string,
      addressLine1: string,
      copyCount: number,
      country: string,
      state: string,
      city: string,
      zipCode: string,
      additionalNotes?: string,
    },
  ): Promise<void> => {
    const request = new Request('physical_delivery.request_copy', payload);
    await this.rpc().call<RpcSuccess>(request);
  };

  getCountries = async (
    payload: {
      token: string,
    },
  ): Promise<Array<Country>> => {
    const request = new Request('physical_delivery.country_list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload as any as Promise<Array<Country>>;
  };

  hasAccess = async (
    payload: {
      dataroomName: string,
      token: string,
    },
  ): Promise<{ hasAccess: boolean }> => {
    const request = new Request('physical_delivery.has_access', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getDataroomDisplayName = async (
    payload: {
      token: string,
    },
  ): Promise<{ displayName: string }> => {
    const request = new Request('physical_delivery.dataroom_display_name', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getDataroomSettings = async (
    payload: {
      token: string,
    },
  ): Promise<{ docState: string, requestPhysicalCopy: boolean }> => {
    const request = new Request('physical_delivery.dataroom_settings', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };
}

export default PhysicalDeliveryRepository;
