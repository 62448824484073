import { useState } from 'react';

import AskQuestionModal from './AskQuestionModal';

export default function useAskQuestionModal() {
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const showAskQuestionModal = (file) => {
    setIsQuestionModalVisible(true);
    setSelectedFile(file);
  };

  const hideAskQuestionModal = () => {
    setIsQuestionModalVisible(false);
    setSelectedFile(null);
  };

  return {
    AskQuestionModal,
    selectedFile,
    isQuestionModalVisible,
    showAskQuestionModal,
    hideAskQuestionModal,
  };
}
