import { Component } from 'react';
import Div100 from 'react-div-100vh';

interface IProps {
  children: React.ReactNode,
  id?: string,
  className?: string,
}

class Div100vh extends Component<IProps> {
  // eslint-disable-next-line react/sort-comp
  private divRef: any;

  constructor(props: IProps) {
    super(props);

    this.divRef = null;
  }

  componentDidMount() {
    window.addEventListener('orientationchange', this.updateStyleOnRotationEnd);
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.updateStyleOnRotationEnd);
  }

  /**
   * Fixes bug when on iOS device rotation default libs resize happens before rotation animation end
   */
  updateStyleOnRotationEnd = () => {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    setTimeout(this.divRef.updateStyle, 420);
  };

  render() {
    return (
      <Div100
        { ...this.props }
        ref={ (ref) => { this.divRef = ref; } }
      >
        { this.props.children }
      </Div100>
    );
  }
}

export default Div100vh;
