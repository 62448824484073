import { useState, useRef } from 'react';
import Percentage from '@/dataroom/application/FileUploader/Percentage';

const limit = 100;
const RERENDER_DELAY = 300;
const getCurrentTime = () => new Date().getTime();

export default function useProgress(triggerUploadingHealthCheck) {
  const [, updateState] = useState({});
  const lastUpdateTime = useRef(getCurrentTime());
  const forceUpdate = () => {
    triggerUploadingHealthCheck();
    updateState({});
    lastUpdateTime.current = getCurrentTime();
  };

  const progressRef = useRef(0);
  const currentShowingPercentage = useRef(0);
  const totalDifficulty = useRef(0);
  const increaseTotalDifficulty = (amount: number) => {
    const needUpdate = totalDifficulty.current === 0 && amount !== 0;
    totalDifficulty.current += amount;
    if (needUpdate) {
      forceUpdate();
    }
  };

  const reset = () => {
    totalDifficulty.current = 0;
    progressRef.current = 0;
    currentShowingPercentage.current = 0;
  };

  const forceComplete = () => {
    makeProgress(totalDifficulty.current - progressRef.current);
  };

  const makeProgress = (progressAmount = 1, update = false) => {
    const percentage = new Percentage(
      totalDifficulty.current,
      progressRef.current + progressAmount,
    );

    const percentageCalculated = percentage.calculatePercentage();

    if (percentageCalculated > limit) {
      forceComplete();
      return;
    }
    progressRef.current += progressAmount;

    const currentRounded = ~~percentageCalculated;
    if (currentRounded - currentShowingPercentage.current >= 1) {
      currentShowingPercentage.current = currentRounded;
      forceUpdate();
    } else if (update && (getCurrentTime() - lastUpdateTime.current > RERENDER_DELAY)) {
      forceUpdate();
    }
  };

  const percentage = new Percentage();
  percentage.total = totalDifficulty.current;
  percentage.part = progressRef.current;

  return {
    makeProgress,
    reset,
    increaseTotalDifficulty,
    percentage,
    forceComplete,
  };
}
