import cn from 'classnames';
import noop from 'lodash/noop';
import Link from '@/Framework/Router/ReactRouter/Link';
import { emailRegexp } from '@/Framework/UI/Organisms/FinalForm/validators/user/validateEmail';
import IconExternalLink from '@dealroadshow/uikit/core/components/Icon/IconExternalLink';
import styles from './footerLink.scss';

interface IProps {
  path: string,
  children: React.ReactNode,
  className?: string,
  shouldOpenInNewTab?: boolean,
  isOpenInNewTabIcon?: boolean,
  onClick?: () => void,
}

const FooterLink = ({
  shouldOpenInNewTab = false,
  isOpenInNewTabIcon = true,
  onClick = noop,
  ...otherProps
}: IProps) => {
  const props = { shouldOpenInNewTab, isOpenInNewTabIcon, onClick, ...otherProps };
  const parsedPath = new URLSearchParams(props.path);
  const isLocalLink = (
    !props.shouldOpenInNewTab && emailRegexp.test(props.path) &&
    (!parsedPath.get('hostname') || parsedPath.get('hostname') === window.location.hostname)
  );
  const LinkComponent = isLocalLink ? Link : 'a';

  return (
    <LinkComponent
      { ...({ [isLocalLink ? 'to' : 'href']: props.path }) }
      target={ props.shouldOpenInNewTab ? '_blank' : '_self' }
      rel={ props.shouldOpenInNewTab ? 'noopener' : '' }
      className={ cn(styles.link, props.className) }
      onClick={ props.onClick }
      suppressHydrationWarning
    >
      { props.children }
      { (props.shouldOpenInNewTab && props.isOpenInNewTabIcon) && (
        <IconExternalLink className={ styles.linkInNewTabIcon } />
      ) }
    </LinkComponent>
  );
};

export default FooterLink;
