import { Dependencies } from 'constitute';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';
import Request from '@/Framework/api/Rpc/Request';
import SocketClientFactory from '@/dataroom/application/DI/Socket/Client';

@Dependencies(SocketClientFactory)
export default class TimeTrackingRepository {
  constructor(
    private socketClient: typeof SocketClientFactory,
    private trackerSubscribe,
  ) {}

  subscribeToTimeTracking = async (
    payload: {
      dataroomId: number,
      userId: number,
    },
  ): Promise<void> => {
    const { dataroomId, userId } = payload;
    const subscribeReq = new Request('dataroom.time_tracking.listen', { dataroomId, userId });

    this.trackerSubscribe = await this.socketClient().subscribe(subscribeReq);
  };

  sendSocketPing = async (
    payload: {
      dataroomId: number,
      userId: number,
    },
  ): Promise<void> => {
    const { dataroomId, userId } = payload;
    const pingPayload = new Request('dataroom.websocket.ping', { dataroomId, userId });
    await this.socketClient().rpc.call(pingPayload);
  };

  unsubscribeToTimeTracking = (): void => {
    if (this.trackerSubscribe instanceof Subscription) {
      this.trackerSubscribe.cancel();
    }
  };
}
