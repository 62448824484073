import { Dependencies } from 'constitute';
import { RpcSuccess, Notification } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { v4 as uuid } from 'uuid';
import SocketClientFactory from '@/dataroom/application/DI/Socket/Client';

@Dependencies(JsonRpcDispatcherFactory, SocketClientFactory)
class LinkRepository {
  constructor(private readonly rpc: typeof JsonRpcDispatcherFactory, private socketClient: typeof SocketClientFactory) {
  }

  create = async (payload: {
    dataroomId: number,
    parentFolderId: number,
    name: string,
    href: string,
  }): Promise<void> => {
    const request = new Request('dataroom.filesystem.link.create', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  edit = async (
    payload: {
      dataroomId: number,
      linkId: number,
      name: string,
      href: string,
      resolving?: string,
      customPermissionGroups: {
        id: number,
        permission: string,
      }[],
      onFinish: () => void,
      onError: (error: { code: number }) => void,
    },
  ): Promise<void> => {
    const requestUuid = uuid();
    const { onFinish, onError, ...requestPayload } = payload;

    const subscribeReq = new Request('dataroom.filesystem_element_permission_groups.update.listen', {
      uuid: requestUuid,
    });

    const subscription = await this.socketClient().subscribe(subscribeReq);

    subscription
      .on('filesystem_element_permission_groups.update.completed', () => {
        subscription.cancel();
        onFinish();
      })
      .on('filesystem_element_permission_groups.update.error', (notification: Notification) => {
        subscription.cancel();
        onError(notification.params.payload || {});
      });

    const request = new Request('dataroom.filesystem.link.edit', {
      filesystemElementSessionUuid: requestUuid,
      ...requestPayload,
    });
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  /**
   * When opening a link in a new window, an empty query for analytics occurs.
   */
  preview = async (
    payload: {
      dataroomId: number,
      linkId: number,
    },
  ): Promise<void> => {
    const request = new Request('dataroom.filesystem.link.preview', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };
}

export default LinkRepository;
