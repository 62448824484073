import React from 'react';
import cn from 'classnames';
import ActionButton from '@/Framework/UI/Atoms/Button/ActionButton';
import PreviewButtonHandler from './PreviewButtonHandler';
import { SidebarMode } from '@/Framework/UI/Organisms/DocumentViewer/SidebarMode';
import { variantTypes } from '@/Framework/UI/Atoms/Button/ActionButton/constants';
import hoverActionsStyles from '@/Framework/UI/Molecules/Tables/HoverActions/hoverActions.scss';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import styles from './previewButton.scss';
import IconDisplay from '@dealroadshow/uikit/core/components/Icon/IconDisplay';
import { IconComponentType } from '@dealroadshow/uikit/core/components/Icon';

interface IProps {
  item: IFilesystemListItem,
  className?: string,
  variant?: string,
  dataTest: string,
  title?: string,
  icon?: IconComponentType,
  mode?: SidebarMode,
}

const PreviewButton = ({
  item,
  className,
  variant = variantTypes.icon,
  dataTest,
  icon = IconDisplay,
  title,
  mode,
}: IProps) => (
  <PreviewButtonHandler item={ item } mode={ mode }>
    { ({ onClick }) => (
      <ActionButton
        className={ className }
        buttonClassName={ cn(styles.previewButton, {
          [hoverActionsStyles.textActionItem]: variant === variantTypes.text,
        }) }
        title={ title || `Preview${ item.previewable || variant === variantTypes.text ? '' : ' Not Available' }` }
        variant={ variant }
        icon={ icon }
        isDisabled={ !item.previewable }
        onClick={ onClick }
        dataTest={ dataTest }
      />
    ) }
  </PreviewButtonHandler>
);

export default PreviewButton;
