import { useState } from 'react';
import CreateFolderModal from './CreateFolderModal';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';

export default function useCreateFolderModal() {
  const [parentFolder, setParentFolder] = useState<IFolderTree>(null);

  const showCreateFolderModal = (parentFolder: IFolderTree) => {
    setParentFolder(parentFolder);
  };

  const hideCreateFolderModal = () => {
    setParentFolder(null);
  };

  return {
    CreateFolderModal,
    parentFolder,
    showCreateFolderModal,
    hideCreateFolderModal,
  };
}
