import { useState } from 'react';
import CopyModal from './CopyModal';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';

export default function useCopyModal() {
  const { copy } = useFilesystemContext();
  const [filesystemCopyItems, setFilesystemCopyItems] = useState([]);

  const showCopyModal = (copyItems) => {
    copy.reset();
    setFilesystemCopyItems(Array.isArray(copyItems) ? copyItems : [copyItems]);
  };

  const hideCopyModal = () => {
    setFilesystemCopyItems([]);
  };

  return {
    CopyModal,
    filesystemCopyItems,
    showCopyModal,
    hideCopyModal,
  };
}
