import { useState } from 'react';

import EditQuestionAlertsModal from './EditQuestionAlertsModal';

export default function useEditQuestionAlertsModal() {
  const [isVisible, setIsVisible] = useState(false);

  function showEditQuestionAlertsModal() {
    setIsVisible(true);
  }

  function hideEditQuestionAlertsModal() {
    setIsVisible(false);
  }

  return {
    isVisible,
    EditQuestionAlertsModal,
    showEditQuestionAlertsModal,
    hideEditQuestionAlertsModal,
  };
}
