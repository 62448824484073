import getDataroomBasedUrl from '@/dataroom/infrastructure/getDataroomBasedUrl';
import { Area } from '@/dataroom/domain/vo/Area';
import IconNonDownloaded from '@dealroadshow/uikit/core/components/Icon/IconNonDownloaded';
import IconNotDisplay from '@dealroadshow/uikit/core/components/Icon/IconNotDisplay';
import IconCalendar from '@dealroadshow/uikit/core/components/Icon/IconCalendar';
import IconStar from '@dealroadshow/uikit/core/components/Icon/IconStar';
import IconBookmark from '@dealroadshow/uikit/core/components/Icon/IconBookmark';

export const quickFilterItems = (
  dataroomName: string,
  pathname: string,
  canUserAccessPrimary: boolean,
  canUserAccessStaging: boolean,
  isDateModifiedEnabled: boolean,
) => [
  {
    key: 'bookmarks',
    title: 'Bookmarks',
    path: getDataroomBasedUrl(
      dataroomName,
      `view/bookmarks${ canUserAccessPrimary && canUserAccessStaging ? `/${ Area.Primary }` : '' }`,
    ),
    isActive: pathname.startsWith(getDataroomBasedUrl(dataroomName, 'view/bookmarks')),
    icon: IconBookmark,
  },
  ...(isDateModifiedEnabled ? [{
    key: 'new-since-last-login',
    title: 'New since last login',
    path: getDataroomBasedUrl(
      dataroomName,
      `view/new-since-last-login${ canUserAccessPrimary && canUserAccessStaging ? `/${ Area.Primary }` : '' }`,
    ),
    isActive: pathname.startsWith(getDataroomBasedUrl(dataroomName, 'view/new-since-last-login')),
    icon: IconStar,
  },
    {
      key: 'date-range',
      title: 'Date range',
      path: getDataroomBasedUrl(
        dataroomName,
        `view/date-range${ canUserAccessPrimary && canUserAccessStaging ? `/${ Area.Primary }` : '' }`,
      ),
      isActive: pathname.startsWith(getDataroomBasedUrl(dataroomName, 'view/date-range')),
      icon: IconCalendar,
    }] : []),
  {
    key: 'unread-files',
    title: 'Unread files',
    path: getDataroomBasedUrl(
      dataroomName,
      `view/unread-files${ canUserAccessPrimary && canUserAccessStaging ? `/${ Area.Primary }` : '' }`,
    ),
    isActive: pathname.startsWith(getDataroomBasedUrl(dataroomName, 'view/unread-files')),
    icon: IconNotDisplay,
  },
  {
    key: 'view-only-files',
    title: 'View only files',
    path: getDataroomBasedUrl(
      dataroomName,
      `view/view-only-files${ canUserAccessPrimary && canUserAccessStaging ? `/${ Area.Primary }` : '' }`,
    ),
    isActive: pathname.startsWith(getDataroomBasedUrl(dataroomName, 'view/view-only-files')),
    icon: IconNonDownloaded,
  },
];
