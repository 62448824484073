import React from 'react';
import cn from 'classnames';
import styles from './footer.scss';
import Logo from './Logo';
import Home from './buttons/Home';
import SupportChat from './buttons/SupportChat';
import Support from './buttons/Support';
import Logout from './buttons/Logout';

interface IProps {
  isCollapsed: boolean,
}

const Footer = ({ isCollapsed }: IProps) => (
  <div className={ cn(styles.footerWrp, {
    [styles.isCollapsed]: isCollapsed,
  }) }
  >
    <div
      className={ styles.footer }
      data-test="sidebarFooter"
    >
      <div data-test="footerActions">
        <Home />
        <SupportChat />
        <Support />
        <Logout />
      </div>
      <Logo />
    </div>
  </div>
);

export default Footer;
