export const constants = {
  unpin: {
    title: 'Unpin Item(s)',
    description: {
      withFolders:
        'Please choose an option below for unpinning items inside selected folders. ' +
        'Once unpinned, the items will be reverted to their default positions, determined by the data room indexing method in settings. ' +
        'Selected items that were already unpinned will remain unchanged. ' +
        'Depending on number of items, this operation may take a few minutes to complete.',
      withoutFolders:
        'Once unpinned, the items will be reverted to their default positions, determined by the data room indexing method in settings. ' +
        'Selected items that were already unpinned will remain unchanged. ' +
        'Depending on number of items, this operation may take a few minutes to complete.',
    },
    options: {
      selectedAndAll: 'Unpin selected and all items inside selected folders',
      onlySelected: 'Unpin only selected items',
    },
    buttons: {
      confirm: 'Unpin',
      cancel: 'Cancel',
    },
  },
  pin: {
    title: 'Pin Item(s)',
    description: {
      withFolders:
        'Please choose an option below for pinning items inside selected folders. ' +
        'Selected items that were already pinned will remain unchanged. ' +
        'Depending on number of items, this operation may take a few minutes to complete.',
      withoutFolders:
        'Depending on number of items this operation may take a few minutes to complete. ' +
        'Selected items that were already pinned will remain unchanged.',
    },
    options: {
      selectedAndAll: 'Pin selected and all items inside selected folders',
      onlySelected: 'Pin only selected items',
    },
    buttons: {
      confirm: 'Pin',
      cancel: 'Cancel',
    },
  },
};
