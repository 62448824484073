export const groupedStylesConfig = {
  control: (base, state) => (
    {
      ...base,
      height: 'auto',
      minHeight: 36,
      maxHeight: 85,
      overflow: 'auto',
      background: '#fffff',
      borderWidth: 1,
      zIndex: 0,
      borderRadius: state.menuIsOpen ? '3px 3px 0 0' : 3,
      borderColor: state.isFocused ? 'rgb(140,140,140)' : 'rgb(218,218,218)',
      boxShadow: state.isFocused ? '0 1px 5px rgba(0, 0, 0, 0.1)' : null,
      '&:hover': {
        borderColor: '#8c8c8c',
      },
    }),
  multiValueLabel: (base, { isDisabled }) => ({
    ...base,
    lineHeight: '16px',
    color: isDisabled ? '#8c8c8c' : '#616161',
    fontSize: '12px',
    padding: '3px 8px',
    paddingLeft: '8px',
    maxWidth: '100%',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    marginTop: 'calc(50% + 2px)',
    padding: 3,
    marginRight: 11,
    border: !state.isFocused ? 'solid #c4c4c4' : 'solid #8c8c8c',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    width: 8,
    height: 8,
    transition: 'all .2s ease',
    transform: !state.selectProps.menuIsOpen ? 'rotate(45deg)' : 'rotate(-135deg)',
    alignSelf: 'flex-start',
  }),
};
