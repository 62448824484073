import { useState } from 'react';
import MoveModal from './MoveModal';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';

export default function useMoveModal() {
  const { move } = useFilesystemContext();
  const [filesystemMoveItems, setFilesystemMoveItems] = useState([]);

  const showMoveModal = (moveItems) => {
    move.reset();
    setFilesystemMoveItems(Array.isArray(moveItems) ? moveItems : [moveItems]);
  };

  const hideMoveModal = () => {
    setFilesystemMoveItems([]);
  };

  return {
    MoveModal,
    filesystemMoveItems,
    showMoveModal,
    hideMoveModal,
  };
}
