import VirtualDataTable from './VirtualDataTable';
import { TVirtualBody, TSyncVirtualBody, IColumn, IRowData } from './interfaces';

export default VirtualDataTable;

export type {
  TVirtualBody,
  TSyncVirtualBody,
  IColumn,
  IRowData,
};
