import React from 'react';
import DeleteModalForm from '../DeleteModalForm';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';

const DeleteFileSystemItemsForm = (props) => {
  const { remove: { isFetching, remove } } = useFilesystemContext();
  return (
    <DeleteModalForm
      { ...props }
      isFetching={ isFetching }
      onSubmit={ remove }
    />
  );
};

export default DeleteFileSystemItemsForm;
