import React, { createContext, useContext, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';

const useAccessRestricted = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataroomName, setDataroomName] = useState('');

  const showAccessRestrictedModal = (dataroomName?: string) => {
    setIsModalVisible(true);
    !!dataroomName && setDataroomName(dataroomName);
  };

  const hideAccessRestrictedModal = () => {
    setIsModalVisible(false);
  };

  return {
    dataroomName,
    isModalVisible,
    showAccessRestrictedModal,
    hideAccessRestrictedModal,
  };
};

export type AccessRestrictedContextType = ReturnType<typeof useAccessRestricted>;

export const AccessRestrictedContext = createContext<AccessRestrictedContextType>(null);

export const useAccessRestrictedContext = () => {
  const context = useContext(AccessRestrictedContext);
  if (!context) {
    throw new Error('useAccessRestrictedContext must be used within a AccessRestrictedContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const AccessRestrictedContextProvider = ({ children }: IProps) => {
  const contextValue = useAccessRestricted();
  const { container } = useDIContext();

  container.bindContextValue(AccessRestrictedContext, contextValue);

  return (
    <AccessRestrictedContext.Provider value={ contextValue }>
      { children }
    </AccessRestrictedContext.Provider>
  );
};

export default AccessRestrictedContextProvider;
