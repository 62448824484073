import { allItems, defaultCheckboxGroupName, disabledIds } from '@/Framework/UI/Molecules/CheckBoxArray/constants';
import { permissionGroups } from '@/dataroom/ui/common/PermissionGroups/constants';

const {
  manage: { name: manage },
  viewAndDownload: { name: viewAndDownload },
  viewOnly: { name: viewOnly },
  noAccess: { name: noAccess },
} = permissionGroups;

export const getPermissionGroupsInitialValues = (permissions) => permissions.reduce(
  (checkboxValues, item) => {
    checkboxValues[allItems].push(item.key);
    if (item.isDisabled) {
      checkboxValues[disabledIds].push(item.key);
    }
    if (item.permissionGroup === manage) {
      checkboxValues[manage].push(item.key);
    } else if (item.permissionGroup === viewAndDownload) {
      checkboxValues[viewAndDownload].push(item.key);
    } else if (item.permissionGroup === viewOnly) {
      checkboxValues[viewOnly].push(item.key);
    } else if (item.permissionGroup === noAccess || item.permissionGroup === 'list_only') {
      checkboxValues[noAccess].push(item.key);
    } else {
      throw new Error('Invalid permission');
    }
    return checkboxValues;
  },
  {
    [manage]: [],
    [viewAndDownload]: [],
    [viewOnly]: [],
    [noAccess]: [],
    [allItems]: [],
    [defaultCheckboxGroupName]: [],
    [disabledIds]: [],
  },
);
