import { Tenant } from '@/Framework/Tenant/vo/types/Tenant';
import appendNestedCallbackUrl from '@/Framework/url/helpers/appendNestedCallbackUrl';
import usersUrl from '@/users/infrastructure/usersUrl';
import logoutTargets from './logoutTargets';

export default function getLogoutTarget({
  tenant,
  callbackUrl,
  isAutoLogout = false,
  errorCode,
  errorData,
  showToast,
}: {
  tenant: Tenant,
  callbackUrl: string,
  isAutoLogout?: boolean,
  errorCode?: number,
  errorData?: any,
  showToast?: boolean,
}): string {
  let target: string;

  // eslint-disable-next-line no-restricted-syntax
  for (let logoutTarget of logoutTargets) {
    if (logoutTarget.pattern.test(callbackUrl)) {
      target = logoutTarget.getTarget(isAutoLogout);
      break;
    }
  }

  if (!target) {
    target = usersUrl.getLoginUrl();
  }

  const targetWithCallback = callbackUrl ? appendNestedCallbackUrl(target, callbackUrl) : target;
  const targetParsed = new URL(targetWithCallback);
  const callbackUrlParsed = new URL(callbackUrl);

  if (tenant || callbackUrlParsed.searchParams.get('tenant')) {
    targetParsed.searchParams.set('tenant', tenant || callbackUrlParsed.searchParams.get('tenant'));
  }
  if (errorCode) {
    // eslint-disable-next-line camelcase
    targetParsed.searchParams.set('error_code', errorCode.toString());
  }
  if (errorData) {
    // eslint-disable-next-line camelcase
    targetParsed.searchParams.set('error_data', encodeURIComponent(JSON.stringify(errorData)));
  }
  if (showToast) {
    // eslint-disable-next-line camelcase
    // @ts-ignore
    targetParsed.searchParams.set('show_toast', 1);
  }

  return targetParsed.href;
}

global.getLogoutTarget = getLogoutTarget;
