import React from 'react';
import QuestionModal from '@/dataroom/ui/common/Questions/QuestionModal';
import QuestionModalContext, { useQuestionModalContext } from '@/dataroom/application/questions/QuestionModalContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import getDataroomBasedUrl from '@/dataroom/infrastructure/getDataroomBasedUrl';

const AskQuestionModal = (props) => {
  const { create, files, categories, questionLimits } = useQuestionModalContext();
  const { currentUser } = useCurrentUserContext();
  const { dataroom: { name } } = useDataroomContext();

  const baseUrl = getDataroomBasedUrl(name, 'questions');

  return (
    <QuestionModal
      currentUser={ currentUser }
      create={ create }
      files={ files }
      categories={ categories }
      baseUrl={ baseUrl }
      questionLimits={ questionLimits }
      { ...props }
    />
  );
};

export default (props) => (
  <QuestionModalContext>
    <AskQuestionModal { ...props } />
  </QuestionModalContext>
);
