import React from 'react';
import Child from './Child';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { IFilesystemItem } from '@/dataroom/domain/vo/filesystem/FilesystemItem';

interface IProps {
  isRoot: boolean,
  padding?: number,
  tree: IFolderTree[],
  openNodes: number[],
  focusNodeId: number,
  focusNodeCallback: React.Dispatch<React.SetStateAction<HTMLDivElement>>,
  depth: number,
  data: {
    destinationFolder: IFilesystemItem,
    setDestinationFolder: (v: IFilesystemItem) => void,
    filesystemItems: IFilesystemItem[],
  },
  NodeComponent: React.ElementType,
}

const Children = ({
  tree,
  isRoot,
  depth,
  NodeComponent,
  openNodes,
  focusNodeId,
  focusNodeCallback,
  data,
}: IProps) => tree.map((item, index) => (
  <Child
    focusNodeId={ focusNodeId }
    focusNodeCallback={ focusNodeCallback }
    key={ item.id }
    openNodes={ openNodes }
    index={ index }
    isLast={ index === tree.length - 1 }
    item={ item }
    isRoot={ isRoot }
    depth={ depth }
    data={ data }
    NodeComponent={ NodeComponent }
  />
));

export default Children;
