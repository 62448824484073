import React from 'react';
import cn from 'classnames';
import isFunction from 'lodash/isFunction';
import { Modal as BaseModal, ResponsiveModal as BaseResponsiveModal } from '@dealroadshow/uikit/core/components/Modal';

import styles from './modal.scss';

interface IProps {
  isBaseModal?: boolean,
  className?: string,
  children: React.ReactNode | ((footerRefCallback: any) => React.ReactElement),
  footer?: React.ReactNode,
  usePortal?: boolean,
  isVisible?: boolean,
  isLoading?: boolean,
  onCloseClicked?: () => void,
  showOverlay?: boolean,
  onOverlayClicked?: () => void,
  title?: React.ReactNode,
  closeOnEsc?: boolean,
  showCloseButton?: boolean,
  afterClose?: () => void,
  afterOpen?: () => void,
  hideOnOverlayClicked?: boolean,
  modalTitleClassName?: string,
  modalBodyClassName?: string,
  dataTest: string,
  handleCloseClick?: () => void,
  onContextMenu?: (e?: React.MouseEvent<HTMLDivElement>) => void,
  footerRefCallback?: () => void,
}

const Modal = (props: IProps) => {
  const ModalComponent = props.isBaseModal ? BaseModal : BaseResponsiveModal;

  if (isFunction(props.children)) {
    return (
      <BaseResponsiveModal
        { ...props }
        className={ cn(props.className, styles.modal) }
      >
        { props.children }
      </BaseResponsiveModal>
    );
  }

  return (
    // @ts-ignore
    <ModalComponent
      { ...props }
      className={ cn(props.className, styles.modal) }
    >
      { props.children }
    </ModalComponent>
  );
};

export default Modal;
