import React, { createContext, useContext, useState } from 'react';
import { useDataroomContext } from './DataroomContext';
import { useDIContext } from '@/Framework/DI/DIContext';
import BookmarksRepository from '../infrastructure/repository/BookmarksRepository';
import DataroomErrorHandler from './ErrorHandler';
import { isFolder } from '../domain/filesystem';
import { IFilesystemItem } from '../domain/vo/filesystem/FilesystemItem';
import { Area } from '@/dataroom/domain/vo/Area';

const useBookmarking = () => {
  const { container } = useDIContext();

  const [isFetching, setIsFetching] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [folderIds, setFolderIds] = useState([]);
  const [fileIds, setFileIds] = useState([]);

  const { dataroom } = useDataroomContext();

  /**
   * Gets bookmarks listing.
   */
  const getBookmarks = async (isStaging: boolean) => {
    setIsFetching(true);

    try {
      const bookmarksRepository = container.get<BookmarksRepository>(BookmarksRepository);

      const response = await bookmarksRepository.getBookmarks({
        dataroomId: dataroom.id,
        filesystemArea: isStaging ? Area.Staging : Area.Primary,
      });

      setFolderIds(response.folderIds);
      setFileIds(response.fileIds);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  /**
   * Checks whether file is already bookmarked.
   */
  const isFileBookmarked = (fileId: number) => fileIds.includes(fileId);

  /**
   * Checks whether folder is already bookmarked.
   */
  const isFolderBookmarked = (folderId: number) => folderIds.includes(folderId);

  /**
   * Adds file to Bookmarks
   */
  const addFileToBookmarks = async (fileId: number) => {
    setIsUpdating(true);
    try {
      setFileIds(fileIds.concat([fileId]));

      const bookmarksRepository = container.get<BookmarksRepository>(BookmarksRepository);

      await bookmarksRepository.addFileToBookmarks({
        dataroomId: dataroom.id,
        fileId,
      });
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  /**
   * Adds folder to Bookmarks
   */
  const addFolderToBookmarks = async (folderId: number) => {
    setIsUpdating(true);
    try {
      setFolderIds(folderIds.concat([folderId]));

      const bookmarksRepository = container.get<BookmarksRepository>(BookmarksRepository);

      await bookmarksRepository.addFolderToBookmarks({
        dataroomId: dataroom.id,
        folderId,
      });
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  /**
   * Removes file from Bookmarks
   */
  const removeFileFromBookmarks = async (fileId: number) => {
    setIsUpdating(true);
    try {
      setFileIds(fileIds.filter((id) => id !== fileId));

      const bookmarksRepository = container.get<BookmarksRepository>(BookmarksRepository);

      await bookmarksRepository.removeFileFromBookmarks({
        dataroomId: dataroom.id,
        fileId,
      });
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  /**
   * Removes folder from Bookmarks
   */
  const removeFolderFromBookmarks = async (folderId: number) => {
    setIsUpdating(true);
    try {
      setFolderIds(folderIds.filter((id) => id !== folderId));

      const bookmarksRepository = container.get<BookmarksRepository>(BookmarksRepository);

      await bookmarksRepository.removeFolderFromBookmarks({
        dataroomId: dataroom.id,
        folderId,
      });
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const isBookmarked = (
      item: IFilesystemItem,
  ) => (isFolder(item) ? isFolderBookmarked(item.id) : isFileBookmarked(item.id));

  const addToBookmarks = (
      item: IFilesystemItem,
  ) => (isFolder(item) ? addFolderToBookmarks(item.id) : addFileToBookmarks(item.id));

  const removeFromBookmarks = (
      item: IFilesystemItem,
  ) => (isFolder(item) ? removeFolderFromBookmarks(item.id) : removeFileFromBookmarks(item.id));

  return {
    isBookmarked,
    isFetching,
    isUpdating,
    getBookmarks,
    addToBookmarks,
    removeFromBookmarks,
  };
};

type TBookmarkingContext = ReturnType<typeof useBookmarking>;

export const BookmarkingContext = createContext<TBookmarkingContext>(null);

export function useBookmarkingContext() {
  const context = useContext(BookmarkingContext);
  if (!context) {
    throw new Error('useBookmarkingContext must be used within a BookmarkingContext');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function BookmarkingContextProvider({ children } : IProps) {
  return (
    <BookmarkingContext.Provider value={ useBookmarking() }>
      { children }
    </BookmarkingContext.Provider>
  );
}

export default BookmarkingContextProvider;
