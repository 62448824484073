import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';

@Dependencies(JsonRpcDispatcherFactory)
class BookmarksRepository {
  constructor(private readonly rpc: typeof JsonRpcDispatcherFactory) {
  }

  getBookmarks = async (
    payload: {
      dataroomId: number,
      filesystemArea: string,

    },
  ): Promise<{ folderIds: number[], fileIds: number[] }> => {
    const request = new Request('dataroom.filesystem.bookmark.keys', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  addFileToBookmarks = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<void> => {
    const request = new Request('dataroom.bookmarks.file.add', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  addFolderToBookmarks = async (
    payload: {
      dataroomId: number,
      folderId: number,
    },
  ): Promise<void> => {
    const request = new Request('dataroom.bookmarks.folder.add', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  removeFileFromBookmarks = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<void> => {
    const request = new Request('dataroom.bookmarks.file.remove', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  removeFolderFromBookmarks = async (
    payload: {
      dataroomId: number,
      folderId: number,
    },
  ): Promise<void> => {
    const request = new Request('dataroom.bookmarks.folder.remove', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };
}

export default BookmarksRepository;
