import React, { useEffect, useMemo, useState, useRef } from 'react';
import FolderTreeComponent from '@/dataroom/ui/components/Dataroom/components/FolderTree';
import Node from './Node';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { IFilesystemItem } from '@/dataroom/domain/vo/filesystem/FilesystemItem';
import styles from './folderTree.scss';

const NODE_HEIGHT = 31;

interface IProps {
  folderTree: IFolderTree,
  filesystemItems: IFilesystemItem[],
  destinationFolder: IFilesystemItem,
  openNodes: number[],
  focusNodeId: number,
  setDestinationFolder: (item: IFilesystemItem) => void,
}

const FolderTree = ({
  folderTree,
  filesystemItems,
  destinationFolder,
  openNodes = [],
  focusNodeId,
  setDestinationFolder,
}: IProps) => {
  const folderTreeRef = useRef<HTMLDivElement>(null);
  const [focusNode, setFocusNode] = useState<HTMLDivElement>(null);
  const containerTreeHeight = (folderTree.children.length + 1) * NODE_HEIGHT;

  useEffect(() => {
    if (focusNode && folderTreeRef.current) {
      setTimeout(() => {
        folderTreeRef.current.scrollTo({
          top: focusNode.getBoundingClientRect().top -
            folderTreeRef.current.getBoundingClientRect().top,
          left: 0,
        });
      }, 0); // Timeout for delayed nodes render
    }
  }, [focusNode]);

  return (
    <div
      ref={ folderTreeRef }
      className={ styles.folderTree }
      style={
        { ...((containerTreeHeight < 250) && { minHeight: containerTreeHeight }) }
      }
    >
      { useMemo(() => (
        <FolderTreeComponent
          folderTree={ folderTree }
          NodeComponent={ Node }
          openNodes={ openNodes }
          focusNodeId={ focusNodeId }
          focusNodeCallback={ setFocusNode }
          data={ {
            destinationFolder,
            setDestinationFolder,
            filesystemItems,
          } }
        />
      ), [folderTree, destinationFolder, setDestinationFolder, filesystemItems]) }
    </div>
  );
};

export default FolderTree;
