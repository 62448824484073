import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';

export default abstract class AbstractDataroomRepository {
  protected constructor(protected rpc: typeof JsonRpcDispatcherFactory) {
  }

  protected async abortableCall(request: Request) {
    const rpc = this.rpc();
    rpc.abort(request.method);
    const response = await rpc.call<RpcSuccess>(request, { tags: request.method });
    return response.getResult().payload;
  }
}
