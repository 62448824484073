import React from 'react';
import { Area } from '@/dataroom/domain/vo/Area';

export interface IChildrenCallback {
  (args: {
    className: string,
    breadcrumbs: React.ReactElement,
    toolbar: React.ReactElement,
    content: React.ReactElement,
  }): React.ReactElement,
}

export enum Role {
  Manage = 'manage',
  Compare = 'compare',
  QnA = 'q&a',
}

export interface ITab {
  path: string,
  role: Role,
  area: Area,
  title: string,
  isDisabled: boolean,
  isVisible: boolean,
}
