import { Dependencies } from 'constitute';
import { RpcSuccess, Notification } from '@dealroadshow/json-rpc-dispatcher';
import { SortOrder } from '@dealroadshow/uikit/core/components/Table/DataTable/interfaces/SortOrder';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { FileIndexShiftMethod } from '@/dataroom/domain/vo/filesystem/FileIndexShiftMethod';
import { IFilePermissionGroup } from '@/dataroom/domain/vo/filesystem/FilePermissionGroup';
import { IFileInfo } from '@/dataroom/domain/vo/filesystem/FileInfo';
import { v4 as uuid } from 'uuid';
import SocketClientFactory from '@/dataroom/application/DI/Socket/Client';

@Dependencies(JsonRpcDispatcherFactory, SocketClientFactory)
class FileRepository {
  constructor(
    private readonly rpc: typeof JsonRpcDispatcherFactory,
    private socketClient: typeof SocketClientFactory,
  ) {
  }

  edit = async (
    payload: {
      dataroomId: number,
      fileId: number,
      fileName: string,
      customPermissionGroups: {
        id: number,
        permission: string,
      }[],
      onFinish: () => void,
      onError: (error: { code: number }) => void,
    },
  ): Promise<void> => {
    const requestUuid = uuid();
    const {
      onFinish,
      onError,
      ...requestPayload
    } = payload;

    const subscribeReq = new Request('dataroom.filesystem_element_permission_groups.update.listen', {
      uuid: requestUuid,
    });

    const subscription = await this.socketClient()
      .subscribe(subscribeReq);

    subscription
      .on('filesystem_element_permission_groups.update.completed', () => {
        subscription.cancel();
        onFinish();
      })
      .on('filesystem_element_permission_groups.update.error', (notification: Notification) => {
        subscription.cancel();
        onError(notification.params.payload || {});
      });

    const request = new Request('dataroom.filesystem.file.edit', {
      filesystemElementSessionUuid: requestUuid,
      ...requestPayload,
    });
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult();
  };

  getCustomEditPermissions = async (payload: {
    dataroomId: number,
    fileId: number,
  }): Promise<IFilePermissionGroup[]> => {
    const request = new Request('dataroom.filesystem.file.custom_permissions', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getAllEditPermissions = async (payload: {
    dataroomId: number,
    fileId: number,
  }): Promise<IFilePermissionGroup[]> => {
    const request = new Request('dataroom.filesystem.file.all_permissions', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  changeIndex = async (payload: {
    dataroomId: number,
    fileId: number,
    index: number,
    overrideMethod?: FileIndexShiftMethod,
  }): Promise<void> => {
    const request = new Request('dataroom.filesystem.file.change_index', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult();
  };

  getNameWithCustomIndex = async (payload: {
    dataroomId: number,
    fileId: number,
    index: number,
  }): Promise<{
    name: string,
    exist: boolean,
  }> => {
    const request = new Request('dataroom.filesystem.file.custom_exists', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  unpin = async (payload: {
    dataroomId: number,
    fileId: number,
  }): Promise<void> => {
    const request = new Request('dataroom.filesystem.file.unpin', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult();
  };

  getFilesListing = async (
    payload: {
      dataroomId: number,
      folderIds: string[],
      page: number,
      perPage: number,
      sortBy: string,
      sortOrder: SortOrder,
      filesystemArea: string,
    },
  ): Promise<{
    collection: IFilesystemListItem[],
    totalCount: number,
  }> => {
    const request = new Request('dataroom.filesystem.file.list', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  preview = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<{ viewingSessionId: number, stampText: string }> => {
    const request = new Request('dataroom.filesystem.file.preview', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  checkPreviewAvailability = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<{ text: string }> => {
    const request = new Request('dataroom.filesystem.file.preview.check_availability', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult();
  };

  getPreviewWatermark = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<{ text: string }> => {
    const request = new Request('dataroom.filesystem.file.preview.get_stamp', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getNameInFolder = async (
    payload: {
      dataroomId: number,
      fileId: number,
      folderId: number,
    },
  ): Promise<{
    name: string,
  }> => {
    const request = new Request('dataroom.filesystem.file.get_name_in_folder', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getInfo = async (
    payload: {
      dataroomId: number,
      fileId: number,
    },
  ): Promise<IFileInfo> => {
    const request = new Request('dataroom.filesystem.file.get_info', payload);
    const response = await this.rpc()
      .call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default FileRepository;
