import { Dependencies } from 'constitute';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { IDownloadsListItem } from '@/dataroom/domain/vo/collection/DownloadPageListItem';
import SocketClientFactory from '@/dataroom/application/DI/Socket/Client';

interface IResponse {
  collection: IDownloadsListItem[],
  page: number,
  perPage: number,
  totalCount: number,
}

@Dependencies(JsonRpcDispatcherFactory, SocketClientFactory)
export default class ArchiveRepository {
  constructor(
    private readonly rpc: typeof JsonRpcDispatcherFactory,
    private socketClient: typeof SocketClientFactory,
    private downloadsSubscribe,
  ) {}

  getArchivesList = async (payload: {
    dataroomId: number,
    search: string,
    sortBy: string,
    sortOrder: string,
    page: number,
    perPage: number,
  }): Promise<IResponse> => {
    const request = new Request('dataroom.archive.list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getPendingArchivesCount = async (
    payload: {
      dataroomId: number,
    },
  ): Promise<{ count: number }> => {
    const request = new Request('dataroom.archive.pending_count', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  private getSubscription = async (
    dataroomId: number,
    userId: number,
  ): Promise<Subscription> => {
    if (!this.downloadsSubscribe) {
      const subscribeReq = new Request('dataroom.download.archive.list.listen', { dataroomId, userId });

      this.downloadsSubscribe = await this.socketClient().subscribe(subscribeReq);
    }

    return this.downloadsSubscribe;
  };

  subscribeToArchivesStatus = async (
    payload: {
      dataroomId: number,
      userId: number,
      callback: (archive?: {
        status: string,
        hash: string,
        expireAt: string,
      }) => void,
    },
  ): Promise<void> => {
    const { dataroomId, userId, callback } = payload;

    const subscription = await this.getSubscription(dataroomId, userId);

    subscription
      .on('archive.list.status', ({ params: { payload } }) => {
        callback(payload);
      });
  };
}
