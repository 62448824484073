import { useState } from 'react';
import CreateLinkModal from './CreateLinkModal';

export default function useCreateLinkModal() {
  const [isVisible, setIsVisible] = useState(false);

  const showCreateLinkModal = () => setIsVisible(true);
  const hideCreateLinkModal = () => setIsVisible(false);

  return {
    CreateLinkModal,
    isVisible,
    showCreateLinkModal,
    hideCreateLinkModal,
  };
}
