import { IValidationError } from './interfaces/ValidationError';

const forbiddenChars = /[/\\*|<>"?:]/;
// ensure string is in utf8's Basic Multilingual Plane (BMP)
// @see https://dev.mysql.com/doc/mysql-g11n-excerpt/5.7/en/charset-unicode.html
// @ts-ignore
const outOfBmpRange = /[^\u{0}-\u{ffff}]/u; // eslint-disable-line no-control-regex
const dotStart = /^\./;
const forbiddenFileNames = /^(aux|nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i;
const dotEnd = /(\.)$/i;

interface IParams {
  value: string,
  fieldName: string,
  fieldCaption: string,
}

export const validateFilesystemItemName = (params: IParams): IValidationError => {
  const isInvalidChars = forbiddenChars.test(params.value);
  const isOutOfBmpRange = outOfBmpRange.test(params.value);
  const isDotStart = dotStart.test(params.value);
  const isForbiddenFileNames = forbiddenFileNames.test(params.value);
  const isDotEnd = dotEnd.test(params.value);

  if (isInvalidChars || isOutOfBmpRange || isDotStart || isDotEnd) {
    return { [params.fieldName]: `The following characters are not permitted: / \\ * | " < > ?${ (isDotStart || isDotEnd) ? ' .' : '' } :` };
  }

  if (isForbiddenFileNames) {
    return { [params.fieldName]: 'Names that reference System Actions or Devices are invalid. Please choose another name.' };
  }

  return null;
};
