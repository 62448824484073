import React from 'react';
import cn from 'classnames';
import { CheckAll } from '@/Framework/UI/Molecules/CheckBoxArray';
import { permissionGroupsClassNames } from '../constants';
import styles from './permissionGroupHeaderLabel.scss';
import { IconComponentType } from '@dealroadshow/uikit/core/components/Icon';

interface IProps {
  permissionGroup: {
    name: string,
    label: string,
    icon: IconComponentType,
  },
  name: string,
  reversedArrays?: string[],
  isUnCheckEnable?: boolean,
  isCheckEnable?: boolean,
}

const PermissionGroupHeaderLabel = (props: IProps) => {
  const {
    permissionGroup,
    ...otherProps
  } = props;

  const { icon: Icon } = permissionGroup;

  return (
    <CheckAll
      checkboxGroupName={ otherProps.name }
      { ...otherProps }
      className={ styles.headerWrp }
      label={ (
        <>
          <Icon
            className={ cn(styles.headerIcon, permissionGroupsClassNames[permissionGroup.name]) }
          />
          <span className={ styles.headerLabel }>
            { permissionGroup.label }
          </span>
        </>
      ) }
      labelClassName={ cn(
        styles.headerLabelWrp,
        permissionGroupsClassNames[permissionGroup.name],
      ) }
      controlClassName={ styles.headerControl }
      dataTest={ `${ permissionGroup.name }CheckAll` }
    />
  );
};

export default PermissionGroupHeaderLabel;
