/**
 * Appends a `callbackUrl` query parameter to a given `link`.
 * If the `link` already contains a `callbackUrl`, the new `callbackUrl` is nested recursively.
 *
 * @param {string} link - The base URL to which the `callbackUrl` will be appended.
 * @param {string} callbackUrl - The callback URL to be added or nested.
 * @returns {string} - The updated URL with the nested `callbackUrl`.
 *
 * @example
 * // Simple case with no existing callbackUrl
 * const link = "https://first.link";
 * const callback = "https://second.link";
 * console.log(appendNestedCallbackUrl(link, callback));
 * // Output: "https://first.link?callbackUrl=https%3A%2F%2Fsecond.link"
 *
 * @example
 * // Case with an existing callbackUrl
 * const link = "https://first.link/api?callbackUrl=https%3A%2F%2Fsecond.link";
 * const callback = "https://third.link";
 * console.log(appendNestedCallbackUrl(link, callback));
 * // Output: "https://first.link/api?callbackUrl=https%3A%2F%2Fsecond.link%2F%3FcallbackUrl%3Dhttps%253A%252F%252Fthird.link"
 *
 */
const appendNestedCallbackUrl = (link: string, callbackUrl: string): string => {
  const url = new URL(link);
  const existingCallbackUrl = url.searchParams.get('callbackUrl');

  if (existingCallbackUrl) {
    const updatedCallbackUrl = appendNestedCallbackUrl(existingCallbackUrl, callbackUrl);
    url.searchParams.set('callbackUrl', updatedCallbackUrl);
  } else {
    url.searchParams.set('callbackUrl', callbackUrl);
  }

  return url.toString();
};

export default appendNestedCallbackUrl;
