import React, { createContext, useContext } from 'react';
import { useDataroomContext } from './DataroomContext';
import LoggerRepository from '@/dataroom/infrastructure/repository/LoggerRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';

function useLogger() {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const logLogin = async () => {
    const loggerRepository = container.get<LoggerRepository>(LoggerRepository);
    const payload = { dataroomId: dataroom.id };

    try {
      await loggerRepository.logLogin(payload);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    }
  };

  return { logLogin };
}
type TLoggerContext = ReturnType<typeof useLogger>;

export const LoggerContext = createContext<TLoggerContext>(null);

export function useLoggerContext() {
  const context = useContext(LoggerContext);
  if (!context) {
    throw new Error('useLoggerContext must be used within a LoggerContext');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function LoggerContextProvider({ children } : IProps) {
  return (
    <LoggerContext.Provider value={ useLogger() }>
      { children }
    </LoggerContext.Provider>
  );
}

export default LoggerContextProvider;
