export const conflictResolving = {
  keepBoth: 'keepBoth',
  skip: 'skip',
  overwrite: 'overwrite',
};

export const conflictTypes = {
  copy: 'Copying',
  move: 'Moving',
  restore: 'Restoring',
};
