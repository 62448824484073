import React, { useState, createContext, useContext } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { NotificationManager } from '@/Framework/Notification';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useDataroomExplorerContext } from '@/dataroom/ui/common/DataroomExplorer/DataroomExplorerContext';
import { useFolderTreeContext } from '@/dataroom/application/folderTree/FolderTreeContext';
import { isFolder } from '@/dataroom/domain/filesystem';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import FilesystemRepository from '@/dataroom/infrastructure/repository/FilesystemRepository';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler/DataroomErrorHandler';
import messages from '@/Framework/Message/messages';

const BulkPinningContext = createContext(null);

const useBulkPinning = () => {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();
  const { updateCollection: updateDataroomExplorerCollection } = useDataroomExplorerContext();
  const { updateFolderTree } = useFolderTreeContext();

  const [isFetching, setIsFetching] = useState(false);

  const pinItems = async ({ items, isRecursive: recursive }: {
    items: (IFilesystemListItem | IFolderTree)[],
    isRecursive: boolean,
    // eslint-disable-next-line no-async-promise-executor
  }) => new Promise(async (resolve, reject) => {
    setIsFetching(true);
    try {
      const { foldersPayload, filesPayload } = items.reduce(
        (acc, item) => {
          if (isFolder(item)) {
            acc.foldersPayload.push({ id: item.id });
          } else {
            acc.filesPayload.push({ id: item.id });
          }
          return acc;
        },
        { foldersPayload: [] as { id: number }[], filesPayload: [] as { id: number }[] },
      );

      const filesystemRepository = container.get<FilesystemRepository>(FilesystemRepository);

      await filesystemRepository.pin({
        dataroomId: dataroom.id,
        folders: foldersPayload,
        files: filesPayload,
        isRecursive: recursive,
        onFinish: () => {
          resolve(true);
          setIsFetching(false);
          NotificationManager.success(messages.DATAROOM_ITEMS_PIN_SUCCESS);
          updateDataroomExplorerCollection();
          const folderIds = foldersPayload.map((folder) => folder.id);
          updateFolderTree(...folderIds);
        },
        onError: (error) => {
          reject(error);
          setIsFetching(false);
          container.get(DataroomErrorHandler).handleError(error);
        },
      });
    } catch (error) {
      reject(error);
      container.get(DataroomErrorHandler).handleError(error);
    }
  });

  const unpinItems = async ({ items, isRecursive: recursive }: {
    items: (IFilesystemListItem | IFolderTree)[],
    isRecursive: boolean,
    // eslint-disable-next-line no-async-promise-executor
  }) => new Promise(async (resolve, reject) => {
    setIsFetching(true);
    try {
      const { foldersPayload, filesPayload } = items.reduce(
        (acc, item) => {
          if (isFolder(item)) {
            acc.foldersPayload.push({ id: item.id });
          } else {
            acc.filesPayload.push({ id: item.id });
          }
          return acc;
        },
        { foldersPayload: [] as { id: number }[], filesPayload: [] as { id: number }[] },
      );

      const filesystemRepository = container.get<FilesystemRepository>(FilesystemRepository);

      await filesystemRepository.unpin({
        dataroomId: dataroom.id,
        folders: foldersPayload,
        files: filesPayload,
        isRecursive: recursive,
        onFinish: () => {
          resolve(true);
          setIsFetching(false);
          NotificationManager.success(messages.DATAROOM_ITEMS_UNPIN_SUCCESS);
          updateDataroomExplorerCollection();
          const folderIds = foldersPayload.map((folder) => folder.id);
          updateFolderTree(...folderIds);
        },
        onError: (error) => {
          reject(error);
          setIsFetching(false);
          container.get(DataroomErrorHandler).handleError(error);
        },
      });
    } catch (error) {
      reject(error);
      container.get(DataroomErrorHandler).handleError(error);
    }
  });

  return {
    isFetching,
    pinItems,
    unpinItems,
  };
};

export const useBulkPinningContext = () => {
  const context = useContext(BulkPinningContext);
  if (!context) {
    throw new Error('useBulkPinningContext must be used within a BulkPinningContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

export const BulkPinningContextProvider = ({ children }: IProps) => (
  <BulkPinningContext.Provider value={ useBulkPinning() }>
    { children }
  </BulkPinningContext.Provider>
);

export default BulkPinningContextProvider;
