import getDataroomBasedUrl from '@/dataroom/infrastructure/getDataroomBasedUrl';
import IconAnalytics from '@dealroadshow/uikit/core/components/Icon/IconAnalytics';
import IconChat from '@dealroadshow/uikit/core/components/Icon/IconChat';
import IconCheckCircle from '@dealroadshow/uikit/core/components/Icon/IconCheckCircle';

export default (dataroom, isQuestionManager) => {
  const { name: dataroomName } = dataroom;
  const questionsPath = getDataroomBasedUrl(dataroomName, 'questions');

  return {
    qaSnapshot: {
      key: 'qa-snapshot',
      title: 'Q&A Snapshot',
      path: `${ questionsPath }/snapshot`,
      icon: IconAnalytics,
      isVisible: isQuestionManager,
      isCountLabelFetching: false,
      count: 0,
    },
    openQuestions: {
      key: 'open-questions',
      title: 'Open Questions',
      path: `${ questionsPath }/open-questions`,
      icon: IconChat,
      isVisible: true,
      isCountLabelFetching: false,
      count: 0,
    },
    resolvedQuestions: {
      key: 'resolved-questions',
      title: 'Resolved Questions',
      path: `${ questionsPath }/resolved-questions`,
      icon: IconCheckCircle,
      isVisible: true,
      isCountLabelFetching: false,
      count: 0,
    },
  };
};
