import React, { useState, createContext, useContext } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import DataroomEsmaRepository from '@/dataroom/infrastructure/repository/DataroomEsmaRepository';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { IEsmaEntitiesCount } from '@/dataroom/domain/vo/types/EsmaEntitiesCount';

const initialEsmaEntitiesCount = {
  total: 0,
  regulatoryEntityAccounts: 0,
  unassignedEntityAccounts: 0,
  contacts: 0,
};

const useEsma = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [esmaEntitiesCount, setEsmaEntitiesCount] = useState<IEsmaEntitiesCount>(initialEsmaEntitiesCount);
  const [esmaAccountList, setEsmaAccountList] = useState<string[]>([]);
  const esmaLimit = 150;

  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const assignEsmaAccount = async (payload) => {
    try {
      setIsFetching(true);
      const dataroomEsmaRepository = container.get<DataroomEsmaRepository>(DataroomEsmaRepository);
      await dataroomEsmaRepository.assignEsmaAccount(payload);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      container.get(DataroomErrorHandler).handleError(error);
    }
  };

  const selfAssignEsmaAccount = async (payload) => {
    try {
      setIsFetching(true);
      const dataroomEsmaRepository = container.get<DataroomEsmaRepository>(DataroomEsmaRepository);
      await dataroomEsmaRepository.selfAssignEsmaAccount(payload);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      container.get(DataroomErrorHandler).handleError(error);
    }
  };

  const getEsmaEntitiesCount = async () => {
    try {
      setIsFetching(true);
      const dataroomEsmaRepository = container.get<DataroomEsmaRepository>(DataroomEsmaRepository);
      const esmaEntitiesCount = await dataroomEsmaRepository.getEsmaEntitiesCount({ dataroomId: dataroom.id });
      setEsmaEntitiesCount(esmaEntitiesCount);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      container.get(DataroomErrorHandler).handleError(error);
    }
  };

  const getEsmaAccountList = async () => {
    try {
      setIsFetching(true);
      const dataroomEsmaRepository = container.get<DataroomEsmaRepository>(DataroomEsmaRepository);
      const esmaAccountList = await dataroomEsmaRepository.getEsmaAccountList({ dataroomId: dataroom.id });
      setIsFetching(false);
      setEsmaAccountList(Object.values(esmaAccountList));
    } catch (error) {
      setIsFetching(false);
      container.get(DataroomErrorHandler).handleError(error);
    }
  };

  return {
    isFetching,
    esmaLimit,
    esmaEntitiesCount,
    esmaAccountList,
    getEsmaEntitiesCount,
    getEsmaAccountList,
    assignEsmaAccount,
    selfAssignEsmaAccount,
  };
};

type EsmaContextType = ReturnType<typeof useEsma>;

export const EsmaContext = createContext<EsmaContextType>(null);

export function useEsmaContext() {
  const context = useContext(EsmaContext);
  if (!context) {
    throw new Error('useEsmaContext must be used within a EsmaContextProvider');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function EsmaContextProvider({ children }: IProps) {
  return (
    <EsmaContext.Provider value={ useEsma() }>
      { children }
    </EsmaContext.Provider>
  );
}

export default EsmaContextProvider;
