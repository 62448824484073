import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import AbstractDataroomRepository from '@/dataroom/infrastructure/repository/AbstractDataroomRepository';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { IEmailUploadingFolderItem } from '@/dataroom/domain/vo/collection/EmailUploadingFolderItem';

@Dependencies(JsonRpcDispatcherFactory)
class EmailUploadingRepository extends AbstractDataroomRepository {
  constructor(protected rpc: typeof JsonRpcDispatcherFactory) {
    super(rpc);
  }

  getFolderListing = async (payload: {
    dataroomId: number,
    folderId: number,
  }): Promise<{
    collection: Array<IEmailUploadingFolderItem & {
      childrenCounts: {
        folders: number,
      },
    }>,
  }> => {
    const request = new Request('dataroom.email_upload.folder_list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getFolderEmailListing = async (payload: {
    dataroomId: number,
  }): Promise<{
    collection: IEmailUploadingFolderItem[],
  }> => {
    const request = new Request('dataroom.email_upload.only_email_folders_list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getFolderEmail = async (payload: {
    dataroomId: number,
    folderId: number,
  }): Promise<IEmailUploadingFolderItem> => this.abortableCall(new Request('dataroom.email_upload.folder_email', payload));

  generateEmail = async (payload: {
    dataroomId: number,
    folderId: number,
  }): Promise<string> => {
    const request = new Request('dataroom.email_upload.generate_email', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload.email;
  };

  checkExistEmail = async (payload: {
    dataroomId: number,
    folderId: number,
    email: string,
  }): Promise<{
    exist: boolean,
  }> => {
    const request = new Request('dataroom.email_upload.exist_email', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload.exist;
  };
}

export default EmailUploadingRepository;
