import React, { useMemo } from 'react';
import cn from 'classnames';
import { useDraggable, useDroppable, UseDraggableArguments, UseDroppableArguments } from '@dnd-kit/core';
import { useCurrentFolderContext } from '@/dataroom/application/CurrentFolderContext';

const DnDWrapper = (
  Component: React.ElementType,
  dragSettingsCallback: (props) => UseDraggableArguments,
  dropSettingsCallback: (props) => UseDroppableArguments,
  dropAvailableCallback: (props, selectedItems) => boolean,
) => (props) => {
  // eslint-disable-next-line
  const { currentFolder } = useCurrentFolderContext();

  const {
    setNodeRef: setDraggableNodeRef,
    attributes: dragListeners,
    listeners: dragAttributes,
    isDragging,
    active, // eslint-disable-next-line
  } = useDraggable(dragSettingsCallback(props));

  const {
    setNodeRef: setDroppableNodeRef,
    isOver, // eslint-disable-next-line
  } = useDroppable(dropSettingsCallback({ ...props, currentFolder }));

  const setNodeRef = (e) => {
    setDraggableNodeRef(e);
    setDroppableNodeRef(e);
  };
  const selectedItems = active ? active.data.current : [];
  const isDropAvailable = dropAvailableCallback
    ? dropAvailableCallback({ ...props, currentFolder }, selectedItems)
    : true;
  // eslint-disable-next-line
  return useMemo(() => (
    <Component
      { ...props }
      ref={ setNodeRef }
      isOver={ isOver }
      isDragging={ isDragging }
      isDropAvailable={ isDropAvailable }
      dragListeners={ dragListeners }
      dragAttributes={ dragAttributes }
      className={ cn(props.className, { 'is-dragging': active }) }
    />
    ), [props, isOver, isDragging, isDropAvailable, active]);
};

export default DnDWrapper;
