import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { IDealIns } from '@/dataroom/domain/vo/DealIns';
import { withCache } from '@/Framework/withCache';

@Dependencies(JsonRpcDispatcherFactory)
class DialInsRepository {
  constructor(private readonly rpc: typeof JsonRpcDispatcherFactory) {
  }

  @withCache
  async getDialIns (): Promise<IDealIns[]> {
    const request = new Request('dataroom.get_dial_ins');
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().collection;
  }
}

export default DialInsRepository;
