import React, { createContext, useContext } from 'react';
import StagingFolderTreeContext, { useStagingFolderTreeContext } from '@/dataroom/application/folderTree/StagingFolderTreeContext';
import PrimaryFolderTreeContext, { usePrimaryFolderTreeContext } from '@/dataroom/application/folderTree/PrimaryFolderTreeContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { getNodeItemById } from '@/dataroom/domain/filesystem';

const useFolderTree = () => {
  const { canUserAccessStaging } = useCurrentUserContext();

  const {
    getStagingFolderTree,
    stagingFolderTree,
  } = useStagingFolderTreeContext();

  const {
    getPrimaryFolderTree,
  } = usePrimaryFolderTreeContext();

  const isStagingFolder = (id: number) => (
    canUserAccessStaging && stagingFolderTree && !!getNodeItemById(stagingFolderTree, id)
  );

  const updateFolderTree = async (...changedFoldersIds: number[]) => {
    let isUpdateForStagingTreeNeeded = false;
    let isUpdateForPrimaryTreeNeeded = false;
    let i = 0;

    while (i < changedFoldersIds.length) {
      const folderId = changedFoldersIds[i];

      if (isStagingFolder(folderId)) {
        isUpdateForStagingTreeNeeded = true;
      } else {
        isUpdateForPrimaryTreeNeeded = true;
      }

      if (isUpdateForStagingTreeNeeded && isUpdateForPrimaryTreeNeeded) {
        break;
      }
      i++;
    }

    if (isUpdateForPrimaryTreeNeeded && !isUpdateForStagingTreeNeeded) {
      return getPrimaryFolderTree();
    }

    if (isUpdateForStagingTreeNeeded && !isUpdateForPrimaryTreeNeeded) {
      return getStagingFolderTree();
    }

    return Promise.all([getPrimaryFolderTree(), getStagingFolderTree()]);
  };

  return {
    updateFolderTree,
    isStagingFolder,
  };
};

type FolderTreeContextType = ReturnType<typeof useFolderTree>;

export const FolderTreeContext = createContext<FolderTreeContextType>(null);

export const useFolderTreeContext = () => {
  const context = useContext(FolderTreeContext);
  if (!context) {
    throw new Error('useFolderTreeContext must be used within a FolderTreeContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const FolderTreeContextProvider = ({ children }: IProps) => (
  <FolderTreeContext.Provider value={ useFolderTree() }>
    { children }
  </FolderTreeContext.Provider>
);

export default ({ children }: IProps) => (
  <StagingFolderTreeContext>
    <PrimaryFolderTreeContext>
      <FolderTreeContextProvider>
        { children }
      </FolderTreeContextProvider>
    </PrimaryFolderTreeContext>
  </StagingFolderTreeContext>
);
