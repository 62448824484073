import React from 'react';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { Tenant as TenantType } from '@/Framework/Tenant/vo/types/Tenant';

interface IProps {
  isScheduleDemoModalVisible: boolean,
  showScheduleDemoModal: () => void,
  hideScheduleDemoModal: () => void,
  scheduleDemoModal: React.ElementType,
  tenant: TenantType,
  buttonComponent?: React.ElementType,
  title?: string,
  className?: string,
  variantType?: keyof typeof ButtonVariantType,
}

const BookDemoButton = ({
  isScheduleDemoModalVisible,
  showScheduleDemoModal,
  hideScheduleDemoModal,
  scheduleDemoModal: ScheduleDemoModal,
  tenant,
  buttonComponent: ButtonComponent,
  title = 'Book Demo',
  className,
  variantType = ButtonVariantType.action as keyof typeof ButtonVariantType,
}: IProps) => {
  const button = ButtonComponent ? (
    <ButtonComponent
      className={ className }
      onClick={ showScheduleDemoModal }
    >
      { title }
    </ButtonComponent>
  ) : (
    <Button
      variant={ variantType }
      className={ className }
      onClick={ showScheduleDemoModal }
      type="button"
      title={ title }
      dataTest="bookDemoButton"
    />
  );

  return (
    <>
      { button }
      { isScheduleDemoModalVisible && (
        <ScheduleDemoModal
          tenant={ tenant }
          closeModal={ hideScheduleDemoModal }
        />
      ) }
    </>
  );
};

export default BookDemoButton;
