import styles from './filesystemIcon.scss';
import IconVideo from '@dealroadshow/uikit/core/components/Icon/IconVideo';
import IconAudio from '@dealroadshow/uikit/core/components/Icon/IconAudio';
import IconFileJpg from '@dealroadshow/uikit/core/components/Icon/IconFileJpg';
import IconFileDoc from '@dealroadshow/uikit/core/components/Icon/IconFileDoc';
import IconFileXls from '@dealroadshow/uikit/core/components/Icon/IconFileXls';
import IconFilePpt from '@dealroadshow/uikit/core/components/Icon/IconFilePpt';
import IconFileArchive from '@dealroadshow/uikit/core/components/Icon/IconFileArchive';
import IconLock from '@dealroadshow/uikit/core/components/Icon/IconLock';
import IconFolder from '@dealroadshow/uikit/core/components/Icon/IconFolder';
import IconFolderOpen from '@dealroadshow/uikit/core/components/Icon/IconFolderOpen';
import IconFilePdf from '@dealroadshow/uikit/core/components/Icon/IconFilePdf';
import IconFilePng from '@dealroadshow/uikit/core/components/Icon/IconFilePng';
import IconFileTiff from '@dealroadshow/uikit/core/components/Icon/IconFileTiff';
import IconImage from '@dealroadshow/uikit/core/components/Icon/IconImage';
import IconFileCsv from '@dealroadshow/uikit/core/components/Icon/IconFileCsv';
import IconFileCdi from '@dealroadshow/uikit/core/components/Icon/IconFileCdi';
import IconFileXml from '@dealroadshow/uikit/core/components/Icon/IconFileXml';
import IconFileTxt from '@dealroadshow/uikit/core/components/Icon/IconFileTxt';
import IconDocument from '@dealroadshow/uikit/core/components/Icon/IconDocument';
import IconLink from '@dealroadshow/uikit/core/components/Icon/IconLink';
import { IconComponentType } from '@dealroadshow/uikit/core/components/Icon';

const jpegImage: {
  icon: IconComponentType,
  className: string,
} = {
  icon: IconFileJpg,
  className: styles.jpeg,
};

const audio: {
  icon: IconComponentType,
  className: string,
} = {
  icon: IconAudio,
  className: styles.audio,
};

const video: {
  icon: IconComponentType,
  className: string,
} = {
  icon: IconVideo,
  className: styles.video,
};

const document: {
  icon: IconComponentType,
  className: string,
} = {
  icon: IconFileDoc,
  className: styles.doc,
};

const excel: {
  icon: IconComponentType,
  className: string,
} = {
  icon: IconFileXls,
  className: styles.xls,
};

const presentation: {
  icon: IconComponentType,
  className: string,
} = {
  icon: IconFilePpt,
  className: styles.ppt,
};

const archive: {
  icon: IconComponentType,
  className: string,
} = {
  icon: IconFileArchive,
  className: styles.archive,
};

const rootStagingFolder: {
  icon: IconComponentType,
  className: string,
} = {
  icon: IconLock,
  className: styles.stagingFolder,
};

export const iconMapping: {
  [key: string]: {
    icon: IconComponentType,
    className: string,
  },
} = {
  folderOpen: {
    icon: IconFolderOpen,
    className: styles.folder,
  },
  folderClosed: {
    icon: IconFolder,
    className: styles.folder,
  },
  pdf: {
    icon: IconFilePdf,
    className: styles.pdf,
  },
  png: {
    icon: IconFilePng,
    className: styles.png,
  },
  jpg: jpegImage,
  jpeg: jpegImage,
  tiff: {
    icon: IconFileTiff,
    className: styles.tiff,
  },
  ico: {
    icon: IconImage,
    className: styles.image,
  },
  wav: audio,
  mp3: audio,
  mov: video,
  mpeg: video,
  mp4: video,
  avi: video,
  doc: document,
  docx: document,
  xls: excel,
  xlsx: excel,
  csv: {
    icon: IconFileCsv,
    className: styles.csv,
  },
  cdi: {
    icon: IconFileCdi,
    className: styles.cdi,
  },
  ppt: presentation,
  pptx: presentation,
  txt: {
    icon: IconFileTxt,
    className: styles.txt,
  },
  xml: {
    icon: IconFileXml,
    className: styles.xml,
  },
  zip: archive,
  rar: archive,
  gz: archive,
  tar: archive,
  url: {
    icon: IconLink,
    className: styles.url,
  },
};

export const stagingIconMapping: {
  [key: string]: {
    icon: IconComponentType,
    className: string,
  },
} = {
  folderOpen: {
    icon: IconFolderOpen,
    className: styles.stagingFolder,
  },
  folderClosed: {
    icon: IconFolder,
    className: styles.stagingFolder,
  },
};

export const rootStagingIconMapping: {
  [key: string]: {
    icon: IconComponentType,
    className: string,
  },
} = {
  folderOpen: rootStagingFolder,
  folderClosed: rootStagingFolder,
};

export const defaultIcon: {
  icon: IconComponentType,
  className: string,
} = {
  icon: IconDocument,
  className: styles.file,
};
