import React from 'react';
import cn from 'classnames';

import {
  iconMapping,
  stagingIconMapping,
  rootStagingIconMapping,
} from '@/dataroom/ui/common/FilesystemIcon/constants';

interface IProps {
  className?: string,
  isStaging?: boolean,
  isOpen?: boolean,
  isRoot?: boolean,
}

const FolderIcon = (
  {
    className: folderClassName,
    isOpen = false,
    isStaging = false,
    isRoot = false,
  }: IProps,
) => {
  const stagingMapping = isRoot ? rootStagingIconMapping : stagingIconMapping;
  const mapping = isStaging ? stagingMapping : iconMapping;
  const {
    icon: Icon,
    className,
  } = isOpen ? mapping.folderOpen : mapping.folderClosed;

  return (
    <span className={ cn(className, folderClassName) }>
      <Icon />
    </span>
  );
};

export default FolderIcon;
