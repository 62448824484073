import { IValidationError } from './interfaces/ValidationError';

export interface IParams {
  value: string | number,
  fieldName: string,
  fieldCaption?: string,
  message?: string,
  isShortErrorText?: boolean,
  isPlural?: boolean,
}

export const validateRequiredField = (params: IParams): IValidationError => {
  const message =
    params.message ||
    (params.isShortErrorText
      ? 'Required'
      : `${ params.fieldCaption || 'Field' } ${ params.isPlural && params.fieldCaption ? 'are' : 'is' } required`);
  if (!params.value) {
    return {
      [params.fieldName]: message,
    };
  }
  return null;
};
