import { useRef } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import UploaderHealthCheckRepository from '@/dataroom/infrastructure/repository/UploaderHealthCheckRepository';
import { NotificationManager } from '@/Framework/Notification';
import { getMessage, getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';

const uploadAttemptsLimit = 4;
const uploadAttemptsTimeLimit = 30000;

const selectFilesAttemptsLimit = 4;
const selectFilesAttemptsTimeLimit = 30000;

const uploadingProgressStuckTimeLimit = 15 * 60 * 1000;

const useUploaderHealthCheck = () => {
  const { container } = useDIContext();
  const uploadAttempts = useRef<number[]>([]);
  const selectFilesAttempts = useRef<number[]>([]);
  const uploadingHealthCheckIsActive = useRef<boolean>(false);
  const uploadingProgressStuckTimeoutRef = useRef<ReturnType<typeof setTimeout>>(null);
  const lastUploaderProgressUpdated = useRef<number>(null);

  const { dataroom } = useDataroomContext();

  const triggerUploadAttempt = () => {
    triggerAttempt.bind(this, uploadAttempts, uploadAttemptsLimit, uploadAttemptsTimeLimit)();
  };

  const triggerSelectFilesAttempt = () => {
    triggerAttempt.bind(this, selectFilesAttempts, selectFilesAttemptsLimit, selectFilesAttemptsTimeLimit)();
  };

  const triggerAttempt = (attemptsListRef, attemptsLimit, attemptsTimeLimit) => {
    const currentTimestamp = Date.now();

    attemptsListRef.current.push(currentTimestamp);

    while (
      attemptsListRef.current.length &&
      currentTimestamp - attemptsListRef.current[0] > attemptsTimeLimit
    ) {
      attemptsListRef.current.shift();
    }

    if (attemptsListRef.current.length >= attemptsLimit) {
      attemptsListRef.current = [];
      showNotification();
    }
  };

  const triggerUploadingHealthCheck = () => {
    if (!uploadingHealthCheckIsActive.current) {
      return;
    }

    !uploadingProgressStuckTimeoutRef.current && startTimeout();
    lastUploaderProgressUpdated.current = Date.now();
  };

  const startTimeout = () => {
    const currentTimestamp = Date.now();
    const timeout = lastUploaderProgressUpdated.current
      ? uploadingProgressStuckTimeLimit - (currentTimestamp - lastUploaderProgressUpdated.current)
      : uploadingProgressStuckTimeLimit;

    uploadingProgressStuckTimeoutRef.current = setTimeout(() => {
      const currentTimestamp = Date.now();

      uploadingProgressStuckTimeoutRef.current = null;

      if (currentTimestamp - lastUploaderProgressUpdated.current >= uploadingProgressStuckTimeLimit) {
        showNotification();
        sendNotificationToSupport();
        stopUploadingHealthCheck();
      } else {
        startTimeout();
      }
    }, timeout);
  };

  const resetUploadAttempts = () => {
    uploadAttempts.current = [];
  };

  const resetSelectFilesAttempts = () => {
    selectFilesAttempts.current = [];
  };

  const startUploadingHealthCheck = () => {
    uploadingHealthCheckIsActive.current = true;
  };

  const stopUploadingHealthCheck = () => {
    uploadingHealthCheckIsActive.current = false;
    lastUploaderProgressUpdated.current = null;
    clearTimeout(uploadingProgressStuckTimeoutRef.current);
    uploadingProgressStuckTimeoutRef.current = null;
  };

  const showNotification = () => {
    NotificationManager.info(getMessage(messageCodes.DATAROOM_UPLOAD_ISSUES));
  };

  const sendNotificationToSupport = () => {
    try {
      container.get<UploaderHealthCheckRepository>(UploaderHealthCheckRepository).sendTroublesNotification({
        dataroomId: dataroom.id,
      });
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  return {
    triggerUploadAttempt,
    triggerSelectFilesAttempt,
    resetUploadAttempts,
    resetSelectFilesAttempts,
    startUploadingHealthCheck,
    stopUploadingHealthCheck,
    triggerUploadingHealthCheck,
  };
};

export default useUploaderHealthCheck;
