import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { ResponsiveModal as Modal } from '@dealroadshow/uikit/core/components/Modal';
import { filesystemItemType } from '@/dataroom/domain/filesystem';
import EditFolderForm from './EditFolderForm';
import EditFileForm from './EditFileForm';
import EditLinkForm from './EditLinkForm';
import { modalTitle } from './constants';
import styles from './editModal.scss';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';
import * as managePermissions from '@/dataroom/domain/managePermissions';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { canManageSettingsLite } from '@/dataroom/domain/managePermissions';

interface IProps {
  item: IFilesystemListItem,
  closeModal: () => void,
  isPermissionsVisible?: boolean,
}

const EditModal = (
  {
    item,
    closeModal,
    isPermissionsVisible = false,
  }: IProps) => {
  const [isPermissionsSectionVisible, setIsPermissionsSectionVisible] = useState(false);
  const {
    edit: {
      isFetching,
      editFolder,
      editFile,
      editLink,
    },
  } = useFilesystemContext();
  const { currentUser } = useCurrentUserContext();

  const isUserAdminLite = canManageSettingsLite(currentUser);
  const userHasSystemManageAccess = managePermissions.canManageSettings(currentUser) || isUserAdminLite;

  useEffect(() => {
    if (!item) {
      setIsPermissionsSectionVisible(false);
    } else {
      setIsPermissionsSectionVisible(isPermissionsVisible);
    }
  }, [item]);

  const handleShowPermissionsSection = () => {
    setIsPermissionsSectionVisible(true);
  };

  const getEditForm = (footerRefCallback, type) => {
    switch (type) {
      case filesystemItemType.FOLDER:
        return (
          <EditFolderForm
            isFetching={ isFetching }
            item={ item }
            closeModal={ closeModal }
            onSubmit={ editFolder }
            initialValues={ {
              folderId: item.id,
              folderName: item.name,
            } }
            isPermissionsSectionVisible={ isPermissionsSectionVisible }
            isUserAdminLite={ isUserAdminLite }
            userHasSystemManageAccess={ userHasSystemManageAccess }
            onShowPermissionsSection={ handleShowPermissionsSection }
            name="folderFormEdit"
            footerRefCallback={ footerRefCallback }
          />
        );
      case filesystemItemType.FILE:
        return (
          <EditFileForm
            isFetching={ isFetching }
            item={ item }
            closeModal={ closeModal }
            onSubmit={ editFile }
            initialValues={ {
              fileId: item.id,
              fileName: item.name.replace(new RegExp(`.${ item.extension }$`), ''),
              fileExtension: item.extension,
            } }
            isPermissionsSectionVisible={ isPermissionsSectionVisible }
            isUserAdminLite={ isUserAdminLite }
            userHasSystemManageAccess={ userHasSystemManageAccess }
            onShowPermissionsSection={ handleShowPermissionsSection }
            name="fileFormEdit"
            footerRefCallback={ footerRefCallback }
          />
        );
      case filesystemItemType.URL:
        return (
          <EditLinkForm
            isFetching={ isFetching }
            item={ item }
            closeModal={ closeModal }
            onSubmit={ editLink }
            initialValues={ {
              linkId: item.id,
              name: item.name,
              href: item.href,
            } }
            isPermissionsSectionVisible={ isPermissionsSectionVisible }
            isUserAdminLite={ isUserAdminLite }
            userHasSystemManageAccess={ userHasSystemManageAccess }
            onShowPermissionsSection={ handleShowPermissionsSection }
            name="linkFormEdit"
            footerRefCallback={ footerRefCallback }
          />
        );
      default:
        return null;
    }
  };

  if (!item) {
    return null;
  }

  return (
    <Modal
      title={ modalTitle[item.type] }
      isVisible
      className={ cn(styles.editModal, styles[`${ item.type }Modal`]) }
      onCloseClicked={ closeModal }
      dataTest={ `${ item.type }EditModal` }
    >
      { (footerRefCallback) => getEditForm(footerRefCallback, item.type) }
    </Modal>
  );
};

export default EditModal;
