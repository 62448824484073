import { useState } from 'react';
import UploaderModal from './UploaderModal';

export default function useUploaderModal(clearUploaderData: () => any) {
  const [isVisibleUploaderModal, setIsVisibleUploaderModal] = useState(false);

  function showUploaderModal() {
    setIsVisibleUploaderModal(true);
  }

  function hideUploaderModal() {
    setIsVisibleUploaderModal(false);
    clearUploaderData();
  }

  return {
    isVisibleUploaderModal,
    UploaderModal,
    showUploaderModal,
    hideUploaderModal,
  };
}
