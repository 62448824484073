import { FileWithPath } from 'file-selector';
import trimChar from '@/Framework/dataHelpers/string/trimChar';

// TODO move validators to separate file
export const isMacOsFile = (entry: FileSystemFileEntry | FileSystemDirectoryEntry) => entry.name === '.DS_Store';
export const isNotMacOsFile = (entry: FileSystemFileEntry | FileSystemDirectoryEntry) => !isMacOsFile(entry);

/**
 * @param {String} name - File or Folder Name
 * @return {Boolean}
 */
export const isValidName = (name: string) => {
  const forbiddenChars = /[/\\*|<>"?:]/;
  const dotStart = /^\./;
  const forbiddenFileNames = /^(aux|nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i;
  const dotEnd = /(\.)$/i;
  return !(forbiddenChars.test(name) ||
      dotStart.test(name) ||
      dotEnd.test(name) ||
      forbiddenFileNames.test(name));
};

const getFileExtension = (fileName) => fileName.split('.').pop();

export const isValidExtension = (allowedFileTypes, fileName) => allowedFileTypes.includes(
  getFileExtension(fileName).toLowerCase(),
);

export const isValidEntry = (entry) => getPathArrayFromEntry(entry).every(isValidName);

export const isValidPathWithoutName = (path) => removeNameFromPathWithName(getPathArrayFromString(path))
  .every(isValidName);

const getPathArrayFromString = (path) => {
  const trimPath = trimChar(path, '/');
  return trimPath.split('/');
};

export const getPathArrayFromEntry = (entry: FileSystemEntry | FileWithPath) => {
  let path: string;
  if (entry instanceof File) {
    const fileWithPath = entry as FileWithPath;
    path = fileWithPath.path;
  } else {
    const directory = entry as FileSystemEntry;
    path = directory.fullPath;
  }

  return getPathArrayFromString(path);
};

export const removeNameFromPathWithName = (path: string[]) => {
  path.length -= 1;
  return path;
};

export const hasFolders = (items: DataTransferItemList) => {
  // TODO after updated TS version let's us method 'some' instead of 'for'
  for (let i = 0; i < items.length; i++) {
    if (items[i].webkitGetAsEntry()?.isDirectory) {
      return true;
    }
  }
  return false;
};
