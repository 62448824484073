import { IValidationError } from './interfaces/ValidationError';

interface IParams {
  value: number,
  maxAmount: number,
  minAmount: number,
  fieldName: string,
  fieldCaption: string,
}

export const validateNumberMaxMinAmount = (params: IParams): IValidationError => {
  let errors = {};
  if (params.value < params.minAmount) {
    errors[params.fieldName] = `${ params.fieldCaption || 'Field' } may not be less than ${ params.minAmount }`;
  }

  if (params.value > params.maxAmount) {
    errors[params.fieldName] = `${ params.fieldCaption || 'Field' } may not be more than ${ params.maxAmount }`;
  }

  return errors;
};
