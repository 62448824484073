import React, { createContext, MutableRefObject, RefObject, useCallback, useContext, useRef } from 'react';
import { ListOnItemsRenderedProps } from 'react-window';
import { getItemStyle } from './overrides';
import { defaultRowHeight } from './constants';
import { IVirtualListProps, TVirtualBody } from './interfaces';

const useVirtualList = ({
  bodyHeight,
  overscanCount,
  backwardOverscan = true,
}: IVirtualListProps) => {
  const sizeMap = useRef<{ [key: number]: number }>({});

  const setSize = useCallback((
    virtualListRef: React.RefObject<TVirtualBody>,
    index: number,
    size: number,
  ) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    virtualListRef.current?.resetAfterIndex(index);
  }, []);

  const getSize = (index: number) => sizeMap.current[index] || defaultRowHeight;

  const overscan = overscanCount || 2 * Math.round(
    (bodyHeight || window?.innerHeight || 0) / defaultRowHeight,
  );

  const style = {
    ...(bodyHeight ? { maxHeight: bodyHeight } : {}),
    overflow: bodyHeight ? 'auto' : 'visible',
  };

  const handleRenderItems = (
    innerRef: MutableRefObject<HTMLDivElement>,
    listRef: RefObject<TVirtualBody> | MutableRefObject<any>,
    props: ListOnItemsRenderedProps & {
      onItemsRendered?: ((props: ListOnItemsRenderedProps) => void) | undefined,
    },
  ) => {
    const updatePosition = (top: string | number) => {
      const tableContainer = innerRef.current?.children?.[0] as HTMLElement;
      if (tableContainer) {
        tableContainer.style.top = `${ top }px`;
      }
    };
    const { top } = getItemStyle(listRef, props.overscanStartIndex) || { top: 0 };
    updatePosition(top);
    props.onItemsRendered && props.onItemsRendered(props);
  };

  return {
    setSize,
    getSize,
    overscan,
    backwardOverscan,
    style,
    handleRenderItems,
  };
};

type VirtualListContextType = ReturnType<typeof useVirtualList>;

export const VirtualListContext = createContext<VirtualListContextType>(undefined);

export const useVirtualListContext = () => {
  const context = useContext(VirtualListContext);
  if (!context) {
    throw new Error('useVirtualListContext must be used within a VirtualListContextProvider');
  }
  return context;
};

interface IProps extends IVirtualListProps {
  children: React.ReactNode,
}

const VirtualListContextProvider = ({ children, ...props }: IProps) => (
  <VirtualListContext.Provider value={ useVirtualList(props) }>
    { children }
  </VirtualListContext.Provider>
);

export default VirtualListContextProvider;
