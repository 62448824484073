import { Method } from 'constitute';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import { DataroomTenantContext, IDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import Container from '@/Framework/DI/Container';
import { UploadDispatcher, SinglepartUploadStrategy, DataroomUploadAdapter } from '@dealroadshow/file-uploader';

class FactoryBuilder {
  container: Container;

  constructor(container) {
    this.container = container;
  }

  getFactory() {
    return (tenantCode: DataroomTenant = null) => {
      const tenant = tenantCode || this.container.get<IDataroomTenantContext>(DataroomTenantContext).tenant;

      const dataroomUploadAdapter = new DataroomUploadAdapter({ url: dataroomUrl(tenant).getUploadFileUrl() });

      return new UploadDispatcher(new SinglepartUploadStrategy(dataroomUploadAdapter));
    };
  }
}

export default new Method(
  (container: Container) => {
    const factoryBuilder = new FactoryBuilder(container);
    return factoryBuilder.getFactory();
  },
  [Container],
) as ReturnType<InstanceType<typeof FactoryBuilder>['getFactory']>;
