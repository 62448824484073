import React from 'react';
import byteConverter from '@/Framework/dataHelpers/formatters/byteConverter';
import styles from './size.scss';

interface IProps {
  value: number,
}

const Size = ({ value }: IProps) => (
  <div className={ styles.sizeColumnWrp }>
    { value ? byteConverter(value) : '-' }
  </div>
);

export default Size;
