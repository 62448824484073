import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import FolderRepository from '@/dataroom/infrastructure/repository/FolderRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { IFolderPermissionGroup } from '@/dataroom/domain/vo/filesystem/FolderPermissionGroup';

const useCustomPermissions = () => {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState(false);
  const [collection, setCollection] = useState<IFolderPermissionGroup[]>([]);

  const { dataroom } = useDataroomContext();

  const getPermissions = async (folderId: number) => {
    setIsFetching(true);

    try {
      const response = await container.get<FolderRepository>(FolderRepository).getCustomPermissions({
        dataroomId: dataroom.id,
        folderId,
      });

      setCollection(response);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    collection,
    getPermissions,
  };
};

export default useCustomPermissions;
