// @ts-nocheck
import cn from 'classnames';
import React, { Component, createRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import Highlighter from 'react-highlight-words';
import { components as ReactSelectComponents, createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import FormError from '@dealroadshow/uikit/core/components/Form/FormError';
import FormField from '@dealroadshow/uikit/core/components/Form/FormField';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Label from '@dealroadshow/uikit/core/components/Input/Label';
import { customStyles, customStylesSlim, selectTheme } from '@/Framework/UI/Molecules/Form/Select/selectConfig';
import PortalWrp, { PortalId } from '@/Framework/UI/Templates/PortalWrp';
import getOffset from '@/Framework/dom/getOffset';
import styles from '@/Framework/UI/Molecules/Form/Select/select.scss';

interface IProps extends Partial<Props<any, boolean, GroupBase<any>>> {
  name?: string,
  placeholder?: string,
  noOptionsMessage?: () => string,
  filterOption?: (option: FilterOptionOption<any>, inputValue: string) => boolean,
  loadOptions?: Function,
  getOptionLabel?: Function,
  getOptionValue?: Function,
  formatOptionLabel?: Function,
  label?: string | React.ReactNode,
  value?: any,
  formFieldClassName?: string,
  className?: string,
  optionClassName?: string,
  selectedOptionClassName?: string,
  selectClassName?: string,
  isNarrow?: boolean,
  usePortal?: boolean,
  supportHighlighting?: boolean,
  creatable?: boolean,
  bodyElement?: HTMLElement,
  meta?: {
    dirty?: boolean,
    touched?: boolean,
    error?: string,
    submitError?: string,
    dirtySinceLastSubmit?: boolean,
  },
  components?: SelectComponentsConfig<any, boolean, any>,
  options?: OptionsOrGroups<any, any>,
  simpleValue?: boolean,
  isSlim?: boolean,
  isLoading?: boolean,
  isMulti?: boolean,
  disabled?: boolean,
  autoload?: boolean,
  cache?: boolean,
  searchable?: boolean,
  clearable?: boolean,
  backspaceRemovesValue?: boolean,
  onChange: (value: OnChangeValue<any, boolean>, meta?: ActionMeta<any>) => void,
  inputValue?: string,
  onInputChange?: Function,
  onInputFocus?: Function,
  onMenuOpen?: Function,
  onBlur?: Function,
  dataTest: string,
  maxMenuHeight?: number,
  menuPlacement?: MenuPlacement,
  selectStyles?: Props<any, boolean, GroupBase<any>>['styles'],
  multiValueClassName?: string,
}

const defaultProps: IProps = {
  className: '',
  components: {},
  supportHighlighting: true,
  clearable: true,
  backspaceRemovesValue: true,
  maxMenuHeight: 200,
};

/**
 * @deprecated This component is DEPRECATED. Use ui/shared/components/Form/Select
 * @param {Object} props
 * @return {Node}
 */
class ReactAsyncSelect extends Component<IProps> {
  constructor(props) {
    super(props);

    this.inputSearchValue = null;
    this.selectWrapperRef = createRef();
    this.getMenuRenderer = this.getMenuRenderer.bind(this);
  }

  getMenuOuterStyle = () => {
    const offset = getOffset(this.selectWrapperRef.current, this.props.bodyElement);
    const style = {
      position: 'fixed',
      margin: '30px 0 0 0',
      width: offset.width,
      left: offset.left,
      top: offset.top,
    };

    if (this.props.bodyElement && (offset.top + 230 > this.props.bodyElement.offsetHeight)) {
      style.top = offset.top - 200;
      style.margin = '0 0 30px';
    }

    return style;
  };

  getDataTest = (label) => {
    const { dataTest } = this.props;
    const dataLabel = label && typeof label === 'string' && label.toLowerCase().replace(/\W+/g, '_');
    return dataLabel ? `${ dataTest }_${ dataLabel }` : dataTest;
  };

  // TODO: must be replaced with a component from ui/shared/components/Select/components
  getValueRenderer = (props) => {
    const { children, ...otherProps } = props;
    if (this.props.components?.SingleValue) {
      const { SingleValue } = this.props.components;
      return (
        <div
          data-test={ this.getDataTest('selected') }
          style={ props.getStyles('singleValue', props) }
        >
          <SingleValue { ...otherProps }>{ children }</SingleValue>
        </div>
      );
    }
    return (
      <div
        data-test={ this.getDataTest('selected') }
        style={ props.getStyles('singleValue', props) }
      >
        <ReactSelectComponents.SingleValue { ...otherProps }>{ children }</ReactSelectComponents.SingleValue>
      </div>
    );
  };

  // TODO: must be replaced with a component from ui/shared/components/Select/components
  getMultiValueRenderer = ({ children, ...props }) => {
    if (this.props.components?.MultiValue) {
      const { MultiValue } = this.props.components;
      return (
        <div data-test={ this.getDataTest('selected') }>
          <MultiValue { ...props }>{ children }</MultiValue>
        </div>
      );
    }
    return (
      <div data-test={ this.getDataTest('selected') }>
        <ReactSelectComponents.MultiValue { ...props }>{ children }</ReactSelectComponents.MultiValue>
      </div>
    );
  };

  // TODO: must be replaced with a component from ui/shared/components/Select/components
  valueContainer = (props) => {
    const { children, ...otherProps } = props;
    const { components = {}, isLoading, isSlim } = this.props;
    let cut = 0;
    if (isLoading) cut += 21;
    if (!components.DropdownIndicator) cut += 20;
    const width = `calc(100% - ${ cut }px)`;
    const lineHeight = isSlim ? '16px' : '26px';

    const style = {
      ...props.getStyles('valueContainer', props),
      padding: 0,
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      width,
      lineHeight,
    };

    if (this.props.components?.ValueContainer) {
      const { ValueContainer } = this.props.components;
      return (
        <div data-test={ this.getDataTest('value_container') } style={ style }>
          <ValueContainer { ...props }>{ children }</ValueContainer>
        </div>
      );
    }
    return (
      <div
        data-test={ this.getDataTest('value_container') }
        style={ style }
      >
        <ReactSelectComponents.ValueContainer { ...otherProps }>{ children }</ReactSelectComponents.ValueContainer>
      </div>
    );
  };

  onInputChange = (value) => {
    this.inputSearchValue = value;
  };

  formatOptionLabel = ({ label }) => {
    const dataTestAttr = this.getDataTest('option');
    return (
      <span data-test={ dataTestAttr } className="Select-menu-list-option">
        <Highlighter
          autoEscape
          searchWords={ [this.inputSearchValue] }
          textToHighlight={ label?.toString() }
          highlightClassName="matchingText"
        />
      </span>
    );
  };

  // TODO: must be replaced with a component from ui/shared/components/Select/components
  getMenuRenderer = (params) => {
    const dataTestAttr = this.getDataTest('options');
    if (this.props.usePortal) {
      return (
        <PortalWrp portalId={ PortalId.PORTAL_OVERLAY_ID }>
          <div className={ styles.selectPortalEncapsulation }>
            <div
              className="Select-menu-outer"
              style={ this.getMenuOuterStyle() }
            >
              <div className="Select-menu">
                <span data-test={ dataTestAttr }>
                  <ReactSelectComponents.Menu { ...params }>
                    { params.children }
                  </ReactSelectComponents.Menu>
                </span>
              </div>
            </div>
          </div>
        </PortalWrp>
      );
    }

    return (
      <div data-test={ dataTestAttr } className="Select-menu-wrapper">
        <ReactSelectComponents.Menu { ...params }>
          { params.children }
        </ReactSelectComponents.Menu>
      </div>
    );
  };

  // TODO: must be replaced with a component from ui/shared/components/Select/components
  getOptionRenderer = (option) => {
    const { isSelected } = option;
    const { children, ...props } = option;
    const dataTestAttr = this.getDataTest('option');
    if (this.props.components?.Option) {
      const { Option } = this.props.components;
      return (
        <span
          data-test={ dataTestAttr }
          className={ cn('Select-menu-list-option', {
            'is-selected': isSelected,
          }) }
        >
          <Option { ...props }>{ children }</Option>
        </span>
      );
    }
    return (
      <span data-test={ dataTestAttr } className={ cn('Select-menu-list-option', { 'is-selected': isSelected }) }>
        <ReactSelectComponents.Option { ...option } />
      </span>
    );
  };

  onChangeHandler = (value) => {
    if (this.props.simpleValue) {
      this.props.onChange(value?.value || null);
    } else this.props.onChange(value);
  };

  // TODO: must be replaced with a component from ui/shared/components/Select/components
  getInput = (props) => (
    <ReactSelectComponents.Input
      { ...props }
      name={ this.props.name }
      onFocus={ (e) => {
        if (typeof this.props.onInputFocus === 'function') {
          this.props.onInputFocus(e);
        }

        props.onFocus(e);
      } }
      data-lpignore
    />
);

  render() {
    const {
      className,
      isNarrow,
      selectClassName,
      name,
      clearable,
      disabled,
      components,
      value: inputVal,
      simpleValue,
      isMulti,
      searchable,
      onChange,
      dataTest,
      isSlim,
      label,
      formFieldClassName,
      ...otherProps
    } = this.props;
    const isError = this.props.meta && this.props.meta.touched && this.props.meta.error;
    // TODO need refactoring in the future. This is not good implementation.
    let value = isMulti ? inputVal : otherProps.options?.filter(({ value }) => {
      if (typeof inputVal === 'object') {
        return value === inputVal?.value;
      }
      return value === inputVal;
    });
    if (isEmpty(value) && !isEmpty(inputVal)) {
      value = inputVal;
    }
    return (
      <FormField
        isNarrow={ isNarrow }
        isValidationFeedback={ !!isError }
        className={ formFieldClassName }
        data-test={ dataTest }
      >
        <div
          className={ cn(styles.selectEncapsulation, className) }
          ref={ this.selectWrapperRef }
        >
          { label && (
            <Label>{ label }</Label>
          ) }
          { !this.props.creatable && (
            <AsyncSelect
              className={ cn(['Select', selectClassName, { isError }]) }
              filterOption={ createFilter({ ignoreCase: true, stringify: (option) => option.label.toString() }) }
              onInputChange={ this.onInputChange }
              formatOptionLabel={ this.props.supportHighlighting && this.formatOptionLabel }
              isClearable={ clearable }
              isDisabled={ disabled }
              theme={ selectTheme }
              styles={ isSlim ? customStylesSlim : customStyles }
              onChange={ this.onChangeHandler }
              value={ value }
              isMulti={ isMulti }
              components={ {
                ...components,
                Menu: this.getMenuRenderer,
                Option: this.getOptionRenderer,
                Input: this.getInput,
                ValueContainer: this.valueContainer,
                SingleValue: this.getValueRenderer,
                MultiValue: this.getMultiValueRenderer,
                LoadingIndicator: () => <Spinner className={ styles.selectSpinner } />,
              } }
              { ...otherProps }
            />
          ) }
          { this.props.creatable && (
            <CreatableSelect
              className={ cn(['Select', selectClassName, { isError }]) }
              filterOption={ createFilter({ ignoreCase: true, stringify: (option) => option.label.toString() }) }
              isClearable={ clearable }
              isDisabled={ disabled }
              onInputChange={ this.onInputChange }
              isSearchable={ searchable }
              formatOptionLabel={ this.props.supportHighlighting && this.formatOptionLabel }
              theme={ selectTheme }
              styles={ isSlim ? customStylesSlim : customStyles }
              onChange={ this.onChangeHandler }
              value={ value }
              isMulti={ isMulti }
              components={ {
                ...components,
                Menu: this.getMenuRenderer,
                Option: this.getOptionRenderer,
                Input: this.getInput,
                ValueContainer: this.valueContainer,
                SingleValue: this.getValueRenderer,
                MultiValue: this.getMultiValueRenderer,
                LoadingIndicator: () => <Spinner className={ styles.selectSpinner } />,
              } }
              { ...otherProps }
            />
          ) }
          <FormError { ...this.props.meta } />
        </div>
      </FormField>
    );
  }
}

ReactAsyncSelect.defaultProps = defaultProps;

export default ReactAsyncSelect;
