import React, { useState } from 'react';
import noop from 'lodash/noop';
import Input from '@/Framework/UI/Molecules/Form/FinalFormInput';
import { IFinalFormFieldInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';

interface IProps extends IFinalFormFieldInputComponentProps {
  dataTest: string,
  min?: number,
  max?: number,
  isInteger?: boolean,
  change?: (string, number) => void,
}

const FinalFormInputNumber = (
  {
    dataTest,
    input,
    min = 0,
    max = Infinity,
    isInteger = true,
    change = noop,
    ...otherProps
  }: IProps,
) => {
  const { value, name } = input;
  const [inputValue, setInputValue] = useState(value);

  const changeValue = (formValue) => {
    setInputValue(formValue);
    change(name, formValue);
  };

  const handleChange = (e) => {
    let currentValue = e.target.value;
    if (currentValue.length > 0 && isInteger) {
      currentValue = Math.trunc(currentValue);
    }
    changeValue(currentValue);
  };

  const handleBlur = () => {
    if (!inputValue || inputValue < min) {
      changeValue(min);
    } else if (inputValue > max) {
      changeValue(max);
    }
  };

  return (
    <Input
      dataTest={ dataTest }
      min={ min }
      max={ max }
      name={ name }
      // @ts-ignore
      input={ { value: inputValue } }
      onChange={ handleChange }
      onBlur={ handleBlur }
      type="number"
      { ...otherProps }
    />
  );
};

export default FinalFormInputNumber;
