import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import QuestionRepository from '@/dataroom/infrastructure/repository/questions/QuestionRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { NotificationManager } from '@/Framework/Notification';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { ICreateQuestion } from '@/dataroom/domain/questions/vo/CreateQuestion';
import { IFile } from '@/dataroom/domain/questions/vo/File';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';

export default function useCreateQuestion() {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const { dataroom } = useDataroomContext();
  const { getCurrentUser } = useCurrentUserContext();

  /**
   * Creates new question.
   */
  const createQuestions = async (questions: ICreateQuestion[], selectedFile: IFile, isAllLimitsReached: boolean) => {
    setIsFetching(true);
    setIsError(false);

    try {
      const payload = {
        dataroomId: dataroom.id,
        questions: questions.map((questionItem) => ({
          ...questionItem,
          questionText: questionItem.questionText.trim(),
        })),
        ...(selectedFile ? {
          reference: {
            [`${ selectedFile.type }Id`]: selectedFile.id,
          },
        } : {}),
      };

      const questionRepository = container.get<QuestionRepository>(QuestionRepository);

      await questionRepository.createQuestions(payload);

      NotificationManager.success(
        getMessage(messageCodes.QNA_QUESTIONS_CREATE_SUCCESS, {
          questionsLabel: questions.length > 1 ? `${ questions.length } questions have` : 'question has',
        }),
      );
    } catch (error) {
      // TODO: @stanislav-bilov @vladimirlomonos Review implementation after research in FIN-9123.
      const { name } = (('getData' in error) && error.getData()) || {};

      if (name) {
        setIsError(true);
      } else {
        container.get(DataroomErrorHandler).handleError(error);
      }
    } finally {
      setIsFetching(false);
      isAllLimitsReached && await getCurrentUser();
    }
  };

  return {
    isError,
    isFetching,
    createQuestions,
  };
}
