import React, { useCallback, useEffect, useState } from 'react';
import { ResponsiveModal as Modal } from '@dealroadshow/uikit/core/components/Modal';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { CheckboxInput } from '@dealroadshow/uikit/core/components/Checkbox';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { canManageSettings, canManageSettingsLite } from '@/dataroom/domain/managePermissions';
import { AsyncCreatableSelect } from '@/Framework/UI/Organisms/FinalForm/Fields/Select';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import { TFinalFormRenderProp } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import { useEsmaContext } from '@/dataroom/application/EsmaContext';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import CompaniesRepository from '@/users/infrastructure/repository/CompaniesRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import validate from './validator';

import styles from './esmaSelfAssign.scss';

interface IProps {
  children: React.ReactNode,
}

const EsmaSelfAssign = (props: IProps) => {
  const [isVisibleEsmaModal, setVisibleEsmaModal] = useState<boolean>(true);
  const { tenant } = useDataroomTenantContext();
  const { dataroom } = useDataroomContext();
  const { currentUser } = useCurrentUserContext();
  const isUserCanManageSettings = canManageSettings(currentUser);
  const isUserAdminLite = canManageSettingsLite(currentUser);
  const {
    isFetching,
    selfAssignEsmaAccount,
  } = useEsmaContext();

  const { container } = useDIContext();
  const companiesRepository = container.get(CompaniesRepository);

  const loadOptions = (text: string) => {
    if (text.length > 2) {
      return companiesRepository.list({
        verified: true,
        query: text,
        limit: 50,
      })
        .then(({ collection }) => collection.map((item) => (
          {
            label: item.name,
            value: item.name,
          }
        )));
    }
    return Promise.resolve([]);
  };

  const formatCreateLabel = (label: string) => {
    if (!label) return null;

    return (
      <div data-test="createEsmaAccount">{ `Create "${ label }"` }</div>
    );
  };

  useEffect(() => {
    setVisibleEsmaModal(
      !isUserAdminLite
      && !isUserCanManageSettings
      && dataroom.isEsmaCompliant
      && !currentUser.esmaAccount);
  }, [isUserCanManageSettings]);

  const assignAccount = ({ esmaAccount }) => {
    selfAssignEsmaAccount({
      dataroomId: dataroom.id,
      esmaAccount: esmaAccount.value,
    })
      .then(() => {
        setVisibleEsmaModal(false);
      });
  };

  const cancelAssign = () => {
    window.location.href = dmPortalUrl.getUrl(`/${ tenant }`);
  };

  const renderForm: TFinalFormRenderProp = useCallback(({
    invalid,
  }, {
    Field,
  }) => (
    <div>
      <span className={ styles.modalText }>
        In order to access this data room, please select the relevant entity account under which you are accessing this
        transaction (this must be the entity which employs you). Note: once chosen, you will not be able to edit this
        (only editable by Admins)
      </span>
      <Field
        name="esmaAccount"
        component={ AsyncCreatableSelect }
        placeholder="Type your company name"
        loadOptions={ loadOptions }
        formFieldClassName={ styles.esmaAccount }
        formatCreateLabel={ formatCreateLabel }
        dataTest="esmaAccountListSelect"
        isSupportHighlighting={ false }
        isValidateOnChange
      />
      <div className={ styles.confirm }>
        <Field
          name="confirm"
          type="checkbox"
          component={ CheckboxInput }
          label="I confirm that the selected entity name above accurately reflects my employing entity. I understand
          that not providing the correct entity name may have regulatory and/or legal implications."
          labelClassName={ styles.confirmLabel }
          controlClassName={ styles.confirmControls }
          dataTest="confirmCheckbox"
        />
      </div>
      <div>
        <Button
          variant={ ButtonVariantType.action }
          type="submit"
          title="Confirm & Proceed"
          dataTest="esmaSelfAssignModalAgreeButton"
          disabled={ invalid }
        />
        <Button
          variant={ ButtonVariantType.text }
          onClick={ cancelAssign }
          title="Cancel"
          dataTest="esmaSelfAssignModalCancelButton"
        />
      </div>
      <Spinner
        isVisible={ isFetching }
        overlay
      />
    </div>
  ), [isFetching]);

  return (
    <>
      { isVisibleEsmaModal && (
        <Modal
          className={ styles.esmaModal }
          isVisible
          title="Please verify your entity"
          showCloseButton={ false }
          closeOnEsc={ false }
          dataTest="esmaSelfAssignModal"
        >
          <FinalForm
            name="getEsmaAccountForm"
            dataTest="getEsmaAccountForm"
            onSubmit={ assignAccount }
            validate={ validate }
            render={ renderForm }
          />
        </Modal>
      ) }
      {
        !isVisibleEsmaModal
          ? props.children
          : <Spinner className={ styles.spinner } />
      }
    </>
  );
};
export default EsmaSelfAssign;
