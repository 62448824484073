import React, { useState, useMemo } from 'react';
import cn from 'classnames';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { isCopyMoveAvailable } from '@/dataroom/domain/filesystemPermissions';
import { isFileIndexingEnabled } from '@/dataroom/domain/filesystem';
import { Area } from '@/dataroom/domain/vo/Area';
import FolderIcon from '@/dataroom/ui/common/FilesystemIcon/FolderIcon';
import ToggleIcon from '@/dataroom/ui/common/ToggleIcon';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import folderTreeNodeStyles from '@/dataroom/ui/components/Dataroom/components/FolderTree/Node/node.scss';
import nodeStyles from './node.scss';

const NODE_OFFSET = 28;

interface IProps {
  item: IFilesystemListItem,
  depth: number,
  isRoot: boolean,
  isOpen: boolean,
  hasChildren: boolean,
  isCopyAvailable: boolean,
  data: any,
  dataTest: string,
}

const Node = (props) => {
  const {
    depth,
    isRoot,
    isOpen: isNodeOpen,
    item,
    hasChildren,
    data,
    dataTest,
  }: IProps = props;

  const area = item.isStaging ? Area.Staging : Area.Primary;

  const { destinationFolder, setDestinationFolder, filesystemItems } = data;

  const isSelected = destinationFolder?.id === item.id;
  const isCopyAvailable = isCopyMoveAvailable(filesystemItems, item);
  const [isOpen, setIsOpen] = useState(isRoot || isNodeOpen);
  const { dataroom } = useDataroomContext();
  const { canUserAccessStaging } = useCurrentUserContext();

  return useMemo(() => {
    const toggleOpen = () => {
      setIsOpen((prevState) => !prevState);
    };

    const hasToggle = hasChildren && !isRoot;

    const nodeOffset = NODE_OFFSET * (depth - 1);

    const nodeItemTitle = item.fileIndexFull && isFileIndexingEnabled(dataroom, area)
      ? `${ item.fileIndexFull } ${ item.name }`
      : item.name;

    return (
      <div
        className={ cn(nodeStyles.nodeItem, 'folder-tree-node', {
          [folderTreeNodeStyles.isOpen]: isOpen,
          [nodeStyles.rootNodeItem]: isRoot && !canUserAccessStaging,
          [nodeStyles.isSelected]: isSelected,
          [nodeStyles.isSelectable]: isCopyAvailable,
          [nodeStyles.isDisabled]: !isCopyAvailable,
          'folder-tree-node-selected': isSelected,
        }) }
        data-test={ dataTest }
      >
        { hasToggle && (
          <ToggleIcon
            isOpen={ isOpen }
            onClick={ toggleOpen }
            className={ nodeStyles.toggleIcon }
            style={ {
              left: nodeOffset,
            } }
          />
        ) }
        <div
          onClick={ () => {
            if (isCopyAvailable) {
              setDestinationFolder(item);
            }
          } }
        >
          <div
            style={ {
              paddingLeft: nodeOffset,
            } }
          >
            <FolderIcon
              isOpen={ isOpen }
              className={ nodeStyles.nodeFolderIcon }
              isStaging={ item.isStaging }
            />
            <span className={ cn(nodeStyles.nodeItemTitle) }>
              { nodeItemTitle }
            </span>
          </div>
        </div>
      </div>
    );
  }, [hasChildren, isRoot, depth, isOpen, isSelected, isCopyAvailable, item, setDestinationFolder]);
};

export default Node;
