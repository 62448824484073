import { IFileStream, IUploadAdapter, UploadEvent } from '@dealroadshow/file-uploader';
import EventEmitter from 'events';

export default class UsersUploadAdapter implements IUploadAdapter {
  private url: string;

  private eventEmitter?: EventEmitter;

  constructor(params: { url: string }) {
    this.url = params.url;
  }

  private getFileContentBufferArray(readStream: IFileStream) {
    const chunks: BlobPart[] = [];

    return new Promise<BlobPart[]>((resolve, reject) => {
      readStream
        .on('data', (chunk) => {
          chunks.push(chunk);
        })
        .on('end', () => {
          resolve(chunks);
        })
        .on('error', (e) => {
          reject(e);
        });

      readStream.resume();
    });
  }

  async upload(
    fileStream: IFileStream,
    fileSize: number,
    options: {
      fileName: string,
      dataroomId: number,
    },
  ) {
    fileStream.pause();

    const bufferArray = await this.getFileContentBufferArray(fileStream);

    let reqParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'x-binary',
        'X-Dataroom-Id': options.dataroomId.toString(),
        'X-File-Name': options.fileName,
        'X-File-Size': fileSize.toString(),
      },
      body: new Blob(bufferArray),
      credentials: 'include',
    } as RequestInit;

    const result = await fetch(`${ this.url }`, reqParams)
      .then((data) => data.json());

    this.eventEmitter.emit(UploadEvent.uploadDone, result);

    return result;
  }

  /**
   * Pause stream reading
   */
  pause() {
  }

  /**
   * Resume stream reading
   */
  resume() {
  }

  /**
   * Cancel uploading
   * Not sure that this method is ever called,
   * as upload is async and it's not possible to cancel it via this method
   * it was created for compatibility with IUploadAdapter interface
   */
  cancel() {
    this.eventEmitter.emit(UploadEvent.cancel, {
      status: 'CANCELLED',
    });
  }

  /**
   *
   * @param eventEmitter
   */
  setEventEmitter(eventEmitter) {
    this.eventEmitter = eventEmitter;
  }
}
