import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import Textarea from '@dealroadshow/uikit/core/components/Textarea';
import PhoneInput from '@dealroadshow/uikit/core/components/Input/PhoneInput';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import validator from './validator';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Input from '@/Framework/UI/Molecules/Form/FinalFormInput';
import InputNumber from '@/Framework/UI/Molecules/Form/FinalFormInputNumber';
import TextField from '@/Framework/UI/Organisms/EnhancedTagging/Field/TextField';
import FinalFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/FinalFormSelect';
import { usePhysicalDeliveryContext } from '@/dataroom/application/PhysicalDeliveryContext';
import TermsOfUse from '@/Framework/UI/Atoms/TermsOfUse';
import ReCaptcha from '@/Framework/UI/Molecules/Form/ReCaptcha';
import { IDataroomListItem } from '@/dataroom/domain/vo/collection/DataroomListItem';
import { IDataroom } from '@/dataroom/domain/vo/Dataroom';
import { IUser } from '@/users/domain/vo/User';
import { TFinalFormRenderProp } from '@/Framework/UI/Organisms/FinalForm/interfaces';

import headerStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import styles from './physicalCopyForm.scss';
import IconFolder from '@dealroadshow/uikit/core/components/Icon/IconFolder';

interface IProps {
  dataroom?: IDataroomListItem | IDataroom,
  onClose?: () => void,
  currentUser?: IUser,
  setFormData?: (object) => void,
  dataroomName?: string,
  hasAccess?: boolean,
  token?: string,
  isFetching?: boolean,
  isExample?: boolean,
}

const PhysicalCopyForm = (
  {
    dataroom,
    onClose,
    currentUser,
    setFormData = noop,
    token,
    dataroomName,
    hasAccess = true,
    isFetching: isFormFetching = false,
    isExample,
  }: IProps,
) => {
  const {
    sendRequestCopy,
    isRequestCopyFetching,
    isCountriesFetching,
    isDataroomDisplayNameFetching,
    getCountriesList,
    countriesCollection,
    dataroomDisplayName,
  } = usePhysicalDeliveryContext();

  const isFromDataroom = !!dataroom;
  const initialValues = {
    firstName: currentUser?.firstName,
    lastName: currentUser?.lastName,
    phoneNumber: currentUser?.workPhone,
    corporateEmail: currentUser?.email,
    companyName: currentUser?.company.name,
    copyCount: 1,
  };
  const handleFormSubmit = (formData) => {
    sendRequestCopy(formData, dataroom?.name || dataroomName, token, isFromDataroom && !isExample)
      .then(onClose)
      .then(() => setFormData(formData));
  };

  const isFetching = isCountriesFetching || isRequestCopyFetching || isDataroomDisplayNameFetching || isFormFetching;

  const countriesOptions = countriesCollection.map((option) => ({
    value: option.name,
    label: option.name,
  }));

  useEffect(() => {
    hasAccess && getCountriesList(token);
  }, [hasAccess]);

  const validate = useCallback(
    (values) => validator(values, isFromDataroom),
    [isFromDataroom],
  );

  const renderForm: TFinalFormRenderProp = useCallback(({
    submitFailed,
    invalid,
    form,
  }, {
    Field,
  }) => (
    <>
      <div className={ styles.formSection }>
        <Field
          label="first name"
          name="firstName"
          component={ Input }
          dataTest="firstName"
        />
        <Field
          label="last name"
          name="lastName"
          component={ Input }
          dataTest="lastName"
        />
      </div>
      <div className={ styles.formSection }>
        <Field
          label="phone number"
          component={ PhoneInput }
          name="phoneNumber"
          dataTest="phoneNumber"
          isFinalForm
        />
        <Field
          label="corporate email"
          name="corporateEmail"
          component={ Input }
          dataTest="corporateEmail"
        />
      </div>
      <div className={ styles.formSection }>
        <div className={ styles.bigInput }>
          <TextField
            label="company name"
            name="companyName"
            component={ Input }
            dataTest="companyName"
            fieldComponent={ Field }
          />
        </div>
        <Field
          label="# of copies"
          name="copyCount"
          component={ InputNumber }
          dataTest="copyCount"
          min={ 1 }
          max={ 99 }
          change={ form.change }
        />
      </div>
      <TextField
        label="address line 1"
        name="addressLine1"
        component={ Input }
        dataTest="addressLine1"
        fieldComponent={ Field }
      />
      <TextField
        label="address line 2 (optional)"
        name="addressLine2"
        component={ Input }
        dataTest="addressLine2"
        fieldComponent={ Field }
      />
      <TextField
        label="address line 3 (optional)"
        name="addressLine3"
        component={ Input }
        dataTest="addressLine3"
        fieldComponent={ Field }
      />
      <div className={ styles.formSection }>
        <Field
          label="Country"
          name="country"
          placeholder="Select country"
          // @ts-ignore
          component={ FinalFormSelect }
          simpleValue
          asterisk
          options={ countriesOptions }
          dataTest="country"
        />
        <TextField
          label="state (optional)"
          name="state"
          component={ Input }
          dataTest="state"
          fieldComponent={ Field }
        />
      </div>
      <div className={ styles.formSection }>
        <div className={ styles.bigInput }>
          <TextField
            label="city"
            name="city"
            component={ Input }
            dataTest="city"
            fieldComponent={ Field }
          />
        </div>
        <TextField
          label="zip code"
          name="zipCode"
          component={ Input }
          dataTest="zipCode"
          fieldComponent={ Field }
        />
      </div>
      <Field
        label="special instruction"
        name="additionalNotes"
        dataTest="additionalNotes"
        rows={ 8 }
        component={ Textarea }
        maxLength={ 500 }
        isEnabledCountdownMessage
      />
      { !isFromDataroom && (
        <div className={ styles.recaptchaWrapper }>
          <Field
            name="recaptchaToken"
            component={ ReCaptcha }
          />
        </div>
      ) }
      <div>
        <Button
          variant={ ButtonVariantType.action }
          type="submit"
          title="Submit"
          dataTest="Submit"
          disabled={ (submitFailed && invalid) || isFetching }
          className={ styles.button }
        />
        {
          isFromDataroom && (
            <Button
              onClick={ onClose }
              variant={ ButtonVariantType.text }
              title="Cancel"
              dataTest="Cancel"
            />
          )
        }
      </div>
      {
        !isFromDataroom && (<TermsOfUse className={ styles.termsOfUse } />)
      }
      <Spinner
        isVisible={ isFetching }
        overlay
      />
    </>
  ), [isFetching, countriesCollection]);

  return (
    <>
      <div className={ cn(styles.title, headerStyles.isH2) }>Request a Physical Copy</div>
      <div className={ styles.textSection }>
        Please complete the delivery details below to request a physical copy of this document. Orders are processed
        within 3 business days and are sent via priority mail.
      </div>
      <div className={ styles.subTitleSection }>
        <IconFolder
          className={ styles.folderIcon }
        />
        <div className={ styles.subTitle }>
          { dataroom?.displayName || dataroomDisplayName }
        </div>
      </div>
      <Spinner
        overlay
        isVisible={ false }
      />
      <FinalForm
        dataTest="physicalCopyForm"
        name="physicalCopyForm"
        onSubmit={ handleFormSubmit }
        render={ renderForm }
        className={ cn(styles.form) }
        validate={ validate }
        initialValues={ initialValues }
      />
    </>
  );
};

export default PhysicalCopyForm;
