import { useState } from 'react';
import ChangeIndexModal from './ChangeIndexModal';

export default function useChangeIndexModal() {
  const [changeIndexItem, setChangeIndexItem] = useState(null);

  const showChangeIndexModal = (item) => {
    setChangeIndexItem(item);
  };

  const hideChangeIndexModal = () => {
    setChangeIndexItem(null);
  };

  return {
    ChangeIndexModal,
    changeIndexItem,
    showChangeIndexModal,
    hideChangeIndexModal,
  };
}
