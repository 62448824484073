import React from 'react';
import FolderIcon from './FolderIcon';
import FileIcon from './FileIcon';
import LinkIcon from './LinkIcon';
import { filesystemItemType } from '@/dataroom/domain/filesystem';
import { FilesystemItemType } from '@/dataroom/domain/vo/types/FilesystemItemType';

interface IProps {
  type: FilesystemItemType,
  isStaging?: boolean,
  extension: string,
  isOpen?: boolean,
  className?: string,
}

const FilesystemIcon = (props: IProps) => {
  switch (props.type) {
    case filesystemItemType.FOLDER:
      return (
        <FolderIcon
          isOpen={ props.isOpen }
          isStaging={ props.isStaging }
          className={ props.className }
        />
      );
    case filesystemItemType.URL:
      return (
        <LinkIcon
          className={ props.className }
        />
      );
    case filesystemItemType.FILE:
    default:
      return (
        <FileIcon
          extension={ props.extension }
          className={ props.className }
        />
      );
  }
};

export default FilesystemIcon;
