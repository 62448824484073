import React, { createContext, useContext, useState } from 'react';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import * as managePermissions from '@/dataroom/domain/managePermissions';
import UserRepository from '@/dataroom/infrastructure/repository/UserRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { IUser } from '@/dataroom/domain/vo/User';

const useCurrentUser = () => {
  const { container } = useDIContext();
  const { logout } = useSessionContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IUser>(null);
  const [canUserAccessStaging, setCanUserAccessStaging] = useState<boolean>(false);
  const [canUserAccessPrimary, setCanUserAccessPrimary] = useState<boolean>(false);
  const [canUserManageSettings, setCanUserManageSettings] = useState<boolean>(false);

  async function getCurrentUser() {
    setIsFetching(true);

    try {
      const payload = { dataroomId: dataroom.id };
      const userRepository = container.get<UserRepository>(UserRepository);
      const currentUser = await userRepository.getCurrent(payload);

      const canUserAccessStaging = managePermissions.canUserAccessStaging(currentUser);
      const canUserAccessPrimary = managePermissions.canUserAccessPrimary(currentUser);
      const canUserManageSettings = managePermissions.canManageSettings(currentUser);

      if (!canUserAccessPrimary && (!canUserAccessStaging || !dataroom.stagingEnabled)) {
        logout({ error: true });
        return;
      }

      setCanUserAccessStaging(canUserAccessStaging);
      setCanUserAccessPrimary(canUserAccessPrimary);
      setCanUserManageSettings(canUserManageSettings);
      setCurrentUser(currentUser);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  }

  return {
    isFetching,
    currentUser,
    getCurrentUser,
    canUserAccessStaging: !!dataroom?.stagingEnabled && canUserAccessStaging,
    canUserAccessPrimary,
    canUserViewUploadedByColumn: !!dataroom?.uploadedByEnabled && canUserManageSettings,
  };
};

type CurrentUserContextType = ReturnType<typeof useCurrentUser>;

export const CurrentUserContext = createContext<CurrentUserContextType>(null);

export const useCurrentUserContext = () => {
  const context = useContext(CurrentUserContext);
  if (!context) {
    throw new Error('useCurrentUserContext must be used within a CurrentUserContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const CurrentUserContextProvider = ({ children }: IProps) => (
  <CurrentUserContext.Provider value={ useCurrentUser() }>
    { children }
  </CurrentUserContext.Provider>
);

export default CurrentUserContextProvider;
