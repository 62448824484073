import React, { useRef } from 'react';
import { DataroomURL } from '@/dataroom/infrastructure/dataroomUrl';
import Popover, { PopoverRef } from '@dealroadshow/uikit/core/components/Popover';
import styles from './troubleFeedback.scss';
import IconClose from '@dealroadshow/uikit/core/components/Icon/IconClose';
import IconQuestion from '@dealroadshow/uikit/core/components/Icon/IconQuestion';

const contactUsLink = (<a href={ DataroomURL.getContactUsUrl() } target="_blank">contact us</a>);

const TroubleFeedback = () => {
  const popoverRef = useRef<PopoverRef>(null);

  const handlePopoverClose = () => {
    popoverRef.current.hide();
  };

  const popover = (
    <div className={ styles.troubleFeedbackPopoverContent }>
      <IconClose
        className={ styles.popoverCloseButton }
        onClick={ handlePopoverClose }
      />
      We are committed to providing you world class service and support.
      If you are experiencing upload issues, please { contactUsLink }
    </div>
  );

  return (
    <div className={ styles.troubleFeedbackContainer }>
      <span className={ styles.troubleFeedbackTitle }>
        Having trouble?
      </span>
      <Popover
        ref={ popoverRef }
        wrapperClassName={ styles.toggleIconWrapper }
        contentClassName={ styles.troubleFeedbackPopoverContentWrapper }
        placement="bottom"
        content={ popover }
        minPadding={ 0 }
        isDarkTheme
        usePortal={ false }
        renderOnlyVisible
      >
        <IconQuestion
          className={ styles.toggleIcon }
        />
      </Popover>
    </div>
  );
};

export default TroubleFeedback;
