import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

const useIsMobile = (isMobileChecker: () => boolean) => {
  const [isMobile, setIsMobile] = useState(isMobileChecker());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileChecker());
    };
    window.addEventListener('resize', throttle(handleResize, 100));
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
