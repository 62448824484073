import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import DisclaimerContext, { useDisclaimerContext } from '@/dataroom/application/DisclaimerContext';
import { canManageSettings } from '@/dataroom/domain/managePermissions';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useLoggerContext } from '@/dataroom/application/LoggerContext';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { ResponsiveModal as Modal } from '@dealroadshow/uikit/core/components/Modal';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import styles from './disclaimer.scss';

interface IProps {
  children?: React.ReactNode,
}

const Disclaimer = (props: IProps) => {
  const { dataroom } = useDataroomContext();
  const { currentUser } = useCurrentUserContext();
  const { logLogin } = useLoggerContext();
  const {
    isDisclaimerAccepted,
    acceptDisclaimer,
    rejectDisclaimer,
  } = useDisclaimerContext();

  const [isVisibleDisclaimerModal, setVisibleDisclaimerModal] = useState(true);

  useEffect(() => {
    const isModalVisible = canManageSettings(currentUser)
      ? false : !isDisclaimerAccepted();

    if (!isModalVisible || !dataroom.disclaimer) {
      logLogin();
    }

    setVisibleDisclaimerModal(isModalVisible && !!dataroom.disclaimer);
  }, [dataroom.id, currentUser.id]);

  const handleAcceptDisclaimer = () => {
    setVisibleDisclaimerModal(false);
    acceptDisclaimer()
      .then(logLogin);
  };

  const disclaimerButtons = ((
    <>
      <Button
        variant={ ButtonVariantType.action }
        onClick={ handleAcceptDisclaimer }
        title="I Agree"
        dataTest="disclaimerModalAgreeButton"
      />
      <Button
        variant={ ButtonVariantType.text }
        onClick={ rejectDisclaimer }
        title="Cancel"
        dataTest="disclaimerModalCancelButton"
      />
    </>
  ));

  return (
    <>
      { isVisibleDisclaimerModal && (
        <Modal
          className={ styles.disclaimerModal }
          isVisible
          title={ dataroom.displayName }
          showCloseButton={ false }
          closeOnEsc={ false }
          footer={ disclaimerButtons }
          onCloseClicked={ rejectDisclaimer }
          dataTest="disclaimerModal"
        >
          <div className={ cn(styles.content, { [styles.copyDisable]: !dataroom.disclaimerCopyingEnabled }) }>
            <b className={ styles.hint }>
              Please read the Disclaimer below and
              click <span className={ styles.noNewLineString }>"I Agree"</span> to continue
            </b>
            <br />
            <br />
            <div dangerouslySetInnerHTML={ {
              __html: dataroom.disclaimer.replace(/(?:\r\n|\r|\n){2}/g, '<br /><br />'),
            } }
            />
          </div>
        </Modal>
      ) }
      {
        !isVisibleDisclaimerModal ? props.children : <Spinner />
      }
    </>
  );
};

export default (props) => (
  <DisclaimerContext>
    <Disclaimer { ...props } />
  </DisclaimerContext>
);
