import React, { forwardRef, SyntheticEvent } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { UrlObject } from 'url';
import styles from './nextLink.scss';

interface IProps {
  title?: string,
  dataTest?: string,
  className?: string,
  isActive?: boolean,
  isExactActive?: boolean,
  activeClassName?: string,
  to: string | UrlObject,
  target?: string,
  children?: React.ReactNode,
  onClick?: (e: any) => void,
  onMouseEnter?: (e: any) => void,
  as?: string,
  ariaCurrent?: 'page',
  disabled?: boolean,
  passHref?: boolean,
  shallow?: boolean,
  id?: string,
}

const NextLink = forwardRef<HTMLAnchorElement, IProps>(({
  dataTest,
  title,
  className,
  to,
  children,
  activeClassName,
  isActive,
  isExactActive,
  as,
  ariaCurrent,
  onClick,
  disabled,
  passHref,
  shallow,
  onMouseEnter,
  target,
  id,
  ...other
}, ref) => {
  const { asPath } = useRouter();
  const toPathname = typeof to === 'string' ? to : to.pathname;
  const isLinkActive = isActive || (isExactActive ? asPath === toPathname : asPath.includes(toPathname));

  const onHandleClick = (e: SyntheticEvent) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    onClick?.(e);
  };

  return (
    <Link
      href={ to }
      as={ as }
      passHref={ passHref }
      shallow={ shallow }
      onClick={ onHandleClick }
      onMouseEnter={ onMouseEnter }
      target={ target }
      aria-disabled={ disabled }
      title={ title }
      data-test={ dataTest }
      aria-current={ ariaCurrent }
      className={ cn(className, {
        [activeClassName]: isLinkActive,
        [styles.isDisabled]: disabled,
       }) }
      tabIndex={ disabled ? -1 : undefined }
      ref={ ref }
      id={ id }
      { ...other }
    >
      { children }
    </Link>
  );
});

export default NextLink;
