import React, { useMemo } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Children from '@/dataroom/ui/components/Dataroom/components/FolderTree/Children';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { IFilesystemItem } from '@/dataroom/domain/vo/filesystem/FilesystemItem';
import styles from './folderTree.scss';

interface IProps {
  folderTree: IFolderTree,
  className?: string,
  openNodes?: number[],
  data?: {
    destinationFolder: IFilesystemItem,
    setDestinationFolder: (v: IFilesystemItem) => void,
    filesystemItems: IFilesystemItem[],
  },
  NodeComponent: React.ElementType,
  focusNodeId?: number,
  focusNodeCallback?: React.Dispatch<React.SetStateAction<HTMLDivElement>>,
}

const FolderTree = ({
  folderTree,
  openNodes,
  className,
  NodeComponent,
  data,
  focusNodeId,
  focusNodeCallback,
}: IProps) => useMemo(() => (
  <div className={ cn(styles.folderTreeWrp, className) }>
    { !isEmpty(folderTree) && (
      // @ts-ignore
      <Children
        tree={ [folderTree] }
        openNodes={ openNodes }
        focusNodeId={ focusNodeId }
        focusNodeCallback={ focusNodeCallback }
        depth={ 1 }
        NodeComponent={ NodeComponent }
        data={ data }
        isRoot
      />
    ) }
  </div>
), [
  folderTree,
  NodeComponent,
  className,
  data,
  openNodes,
]);

export default FolderTree;
