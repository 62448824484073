import { useState } from 'react';
import Dispatcher from '@dealroadshow/json-rpc-dispatcher';
import { IFile } from '@/dataroom/domain/questions/vo/File';
import { useDIContext } from '@/Framework/DI/DIContext';
import QuestionRepository from '@/dataroom/infrastructure/repository/questions/QuestionRepository';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';

export default function useFiles() {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [filesCollection, setFilesCollection] = useState<IFile[]>([]);

  const { dataroom } = useDataroomContext();

  const searchFiles = async (search: string) => {
    setIsFetching(true);

    const payload = {
      search,
      dataroomId: dataroom.id,
      page: 1,
      perPage: 25,
    };

    try {
      const questionRepository = container.get<QuestionRepository>(QuestionRepository);
      const response = await questionRepository.getFiles(payload);
      setFilesCollection(response.collection);
      setIsFetching(false);

      return response.collection;
    } catch (error) {
      !Dispatcher.isAbortError(error) && container.get(DataroomErrorHandler).handleError(error);
      setIsFetching(false);

      return [];
    }
  };

  return {
    isFetching,
    filesCollection,
    searchFiles,
  };
}
