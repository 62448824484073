import React from 'react';
import cn from 'classnames';
import Textarea from '@dealroadshow/uikit/core/components/Textarea';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import FinalFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/FinalFormSelect';
import QuestionPriority from '@/dataroom/ui/common/Questions/QuestionPriority';
import Tooltip from '@/dataroom/ui/common/Questions/QuestionsExplorer/QuestionThread/ThreadTooltip';

import { ICategory } from '@/dataroom/domain/questions/vo/Category';
import { IQuestionPriorities } from '@/dataroom/domain/questions/vo/QuestionPriorities';
import { IFinalFormApi } from '@/Framework/UI/Organisms/FinalForm/interfaces';

import styles from './questionFields.scss';
import IconClose from '@dealroadshow/uikit/core/components/Icon/IconClose';
import IconAdd from '@dealroadshow/uikit/core/components/Icon/IconAdd';

interface IProps {
  fields: any,
  fieldComponent: IFinalFormApi['Field'],
  categoriesCollection: ICategory[],
  activePriority?: string,
  priorityRemain?: IQuestionPriorities,
  isAllLimitsReached?: boolean,
  initialPriority?: string,
  isMaxQuantity?: boolean,
}

const MAX_QUESTIONS_COUNT = 5;

const QuestionFields = (
  {
    fields,
    fieldComponent: Field,
    categoriesCollection,
    activePriority,
    priorityRemain,
    isAllLimitsReached,
    initialPriority,
    isMaxQuantity,
  }: IProps,
) => {
  const handleFieldRemove = (index) => {
    if (fields.length > 1) {
      fields.remove(index);
    }
  };

  const categoryOptions = categoriesCollection.map((option) => ({
    value: option.id,
    label: option.name,
  }));

  const questionFields = (
    <div className={ styles.inputsWrp }>
      { fields.map((field, index) => (
        <fieldset
          className={ styles.fieldset }
          key={ field }
        >
          <legend>Question { index + 1 }:</legend>
          <div className={ styles.headerWrapper }>
            <Field
              name={ `${ field }.categoryId` }
              placeholder="Choose Category"
              formFieldClassName={ styles.categoryFormField }
              className={ styles.categoryWrapper }
              // @ts-ignore
              component={ FinalFormSelect }
              options={ categoryOptions }
              clearable={ false }
              dataTest="questionCategorySelect"
              asterisk
              simpleValue
            />
            <div className={ styles.priorityWrapper }>
              <Field
                name={ `${ field }.priority` }
                component={ QuestionPriority }
                fieldClassName={ styles.priorityFormField }
                priorityRemain={ priorityRemain }
                tooltipContent="Question limit for this priority has been reached"
                isMaxQuantity={ isMaxQuantity }
              />
            </div>
          </div>
          <div className={ styles.questionDescriptionWrapper }>
            <Field
              name={ `${ field }.questionText` }
              placeholder="Type your question here..."
              component={ Textarea }
              rows={ 6 }
              className={ styles.textarea }
              dataTest="questionTextTextarea"
            />
          </div>
          { fields.length > 1 && (
            <Button
              variant={ ButtonVariantType.text }
              onClick={ () => handleFieldRemove(index) }
              className={ styles.deleteButton }
              dataTest="deleteQuestionBtn"
            >
              <IconClose
                className={ styles.deleteButtonIcon }
              />
            </Button>
          ) }
        </fieldset>
      )) }
    </div>
  );

  return (
    <>
      { questionFields }
      { fields.length < MAX_QUESTIONS_COUNT && (
        <Tooltip
          content="The question limit has been reached"
          disabled={ !isAllLimitsReached }
        >
          <Button
            variant={ ButtonVariantType.text }
            className={ styles.addMoreButton }
            disabled={ isAllLimitsReached }
            onClick={ () => fields.push({
              priority: activePriority || initialPriority,
              questionText: '',
              categoryId: '',
            }) }
            dataTest="addAnotherQuestion"
          >
            <IconAdd
              className={ cn(
                styles.addMoreIcon,
                { [styles.disabled]: isAllLimitsReached },
              ) }
              isDisabled={ isAllLimitsReached }
            />
            Add Another Question
          </Button>
        </Tooltip>
      ) }
    </>
  );
};

export default QuestionFields;
