import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';

@Dependencies(JsonRpcDispatcherFactory)
class LoggerRepository {
  constructor(private readonly rpc: typeof JsonRpcDispatcherFactory) {
  }

  logLogin = async (
    payload: {
      dataroomId: number,
    },
  ): Promise<void> => {
    const request = new Request('dataroom.log.login', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };
}

export default LoggerRepository;
