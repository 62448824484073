import React from 'react';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Input from '@dealroadshow/uikit/core/components/Input';
import validate from './validator';
import styles from './folderInputs.scss';
import IconClose from '@dealroadshow/uikit/core/components/Icon/IconClose';
import IconAdd from '@dealroadshow/uikit/core/components/Icon/IconAdd';

interface IProps {
  fields: any,
  fieldComponent: React.ElementType,
}

const FolderInputs = ({
  fields,
  fieldComponent: Field,
}: IProps) => {
  const handleFieldRemove = (index) => {
    fields.remove(index);
  };

  const folderInputs = (
    <ul className={ styles.inputsWrp }>
      { fields.map((field, index) => {
        const key = `${ field }-${ index }`;
        return (
          <li
            key={ key }
            className={ styles.inputWrp }
            data-test={ `listInput[${ index }]` }
          >
            <Field
              name={ `${ field }.name` }
              isClearable
              placeholder={ `New folder ${ index + 1 } name` }
              validate={ validate }
              component={ Input }
              dataTest={ `${ field }NameInput` }
              autoFocus
            />
            { !!index && (
              <Button
                variant={ ButtonVariantType.text }
                onClick={ () => handleFieldRemove(index) }
                className={ styles.removeButton }
                dataTest={ `fieldRemoveButton[${ index }]` }
              >
                <IconClose
                  className={ styles.removeButtonIcon }
                />
              </Button>
            ) }
          </li>
        );
      }) }
    </ul>
  );

  return (
    <>
      { folderInputs }
      <Button
        variant={ ButtonVariantType.text }
        className={ styles.addMoreButton }
        onClick={ () => fields.push({ name: '' }) }
        dataTest="addOneMoreButton"
      >
        <IconAdd
          className={ styles.addMoreIcon }
        />
        Add Another Folder
      </Button>
    </>
  );
};

export default FolderInputs;
