import React, { useEffect } from 'react';
import cn from 'classnames';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { Area } from '@/dataroom/domain/vo/Area';
import { getAreaTitles } from '@/dataroom/domain/areas';
import { Tab, TabList } from '@dealroadshow/uikit/core/components/Tabs';
import toArray from '@/Framework/dataHelpers/enum/toArray';
import tabsStyles from '@dealroadshow/uikit/core/styles/tabs.scss';
import styles from './areaTabs.scss';

interface IProps {
  className?: string,
  activeArea: Area,
  disabledAreas?: (string | number | symbol)[],
  onAreaChange: (tab: Area) => void,
}

const AreaTabs = (
  {
    className,
    activeArea,
    disabledAreas = [],
    onAreaChange,
  }: IProps,
) => {
  const { tenant } = useDataroomTenantContext();

  const handleAreaChange = (area) => {
    if (!disabledAreas.includes(area)) {
      onAreaChange(area);
    }
  };

  useEffect(() => {
    if (disabledAreas.includes(activeArea)) {
      const areas = toArray(Area)
        .filter((area) => !disabledAreas.includes(area)) as Array<Area>;

      if (areas.length > 0) {
        onAreaChange(areas[0]);
      } else {
        onAreaChange(Area.Staging);
      }
    }
  }, [disabledAreas]);

  return (
    <TabList className={ cn(styles.tabsWrp, className) }>
      { toArray(Area)
        .map((area) => (
          <Tab
            key={ area.toString() }
            tabFor={ area }
            className={ cn(tabsStyles.tab) }
            activeClassName={ tabsStyles.isTabActive }
            disabledClassName={ tabsStyles.isTabDisabled }
            selected={ area === activeArea }
            name={ `${ area.toString() }Tab` }
            onClick={ () => handleAreaChange(area) }
            disabled={ disabledAreas.includes(area) }
          >
            { getAreaTitles(tenant)[area] }
          </Tab>
        )) }
    </TabList>
  );
};

export default AreaTabs;
