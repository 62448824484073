import { isFile, isFolder, isRoot, isUrl } from '@/dataroom/domain/filesystem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IQuickFiltersItem } from './vo/collection/QuickFiltersItem';
import { hasQuestionAccess } from '@/dataroom/domain/questionPermissions';

export const permissionType = {
  manage: 'manage',
  viewAndDownload: 'view_and_download',
  viewOnly: 'view_only',
  noAccess: 'no_access',
  listOnly: 'list_only',
};

export const systemFolders = {
  emailUpload: 'Email Communications',
  audioUpload: 'Phone Recordings',
};

export const isManageable = (item) => (item?.mostOpenPermission === permissionType.manage);
// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
const isViewableAndDownloadable = (item: IFolderTree | IFilesystemListItem) => (
  item.mostOpenPermission === permissionType.viewAndDownload
);
export const isNoAccess = (item: IFolderTree | IFilesystemListItem) => (
  item.mostOpenPermission === permissionType.noAccess
);
export const isListOnly = (item: IFolderTree | IFilesystemListItem) => (
  item.mostOpenPermission === permissionType.listOnly
);
export const isViewOnly = (item: IFolderTree | IFilesystemListItem | IQuickFiltersItem) => (
  item.mostOpenPermission === permissionType.viewOnly
);
export const canManage = (item: IFolderTree | IFilesystemListItem) => (
  item && isFolder(item) ? item.canManage : isManageable(item)
);
// @ts-ignore
export const isReadOnly = (item: IFolderTree | IFilesystemListItem) => (!!item.isReadonly || item.readonly === 1);
const isReadOnlySelected = (selected: Array<IFilesystemListItem>) => selected.some(isReadOnly);
const isManageableSelected = (selected: Array<IFilesystemListItem>) => selected.every(canManage);

const canManageDestination = (
  selected: Array<IFilesystemListItem> | IFilesystemListItem,
  destination: IFilesystemListItem,
) => {
  const isFolderSelected = Array.isArray(selected) ? selected.some(isFolder) : isFolder(selected);

  return isFolderSelected ? canManage(destination) : isManageable(destination);
};

const isSystemFolder = (item) => (
  item &&
  isFolder(item) &&
  Object.values(systemFolders)
    .includes(item.name) &&
  isReadOnly(item)
);

/**
 * Checks whether item can be copied or moved to destination.
 * Copying/Moving is not available if destination is a parent of item or destination equals to item.
 * This is helper function.
 * @param {Object} item
 * @param {Object} destination
 * @return {Boolean}
 */
const isCopyMoveAvailableForItemHierarchy = (item, destination) => {
  if (destination.parent && !isCopyMoveAvailableForItemHierarchy(item, destination.parent)) {
    return false;
  }

  return isFolder(item) ? destination.id !== item.id : true;
};

/**
 * Checks whether collection of items can be copied/moved to destination.
 * Also checks for destination system folder and checks whether destination not equals to item parent folder.
 * @param {Array} items
 * @param {Object} destination
 * @return {Boolean}
 */
export const isCopyMoveAvailable = (items, destination) => items.every(
  (item) => (
    !isSystemFolder(destination) &&
    canManageDestination(items, destination) &&
    isCopyMoveAvailableForItemHierarchy(item, destination) &&
    destination.id !== item.parentFolderId
  ),
);

/**
 * Checks recursively if there are at least one destination is available.
 * @param {{ children: Array.<Object>, destination: Object }}
 * @param {Array.<Object>} items
 * @return {Boolean}
 */
const isAvailableDestinations = ({
  children,
  ...destination
}, items) => {
  if (isCopyMoveAvailable(items, destination)) {
    return true;
  }

  for (let i = 0; i < children.length; i++) {
    if (isAvailableDestinations(children[i], items)) {
      return true;
    }
  }

  return false;
};

// @ts-ignore
export const canPreview = (item: IFolderTree | IFilesystemListItem) => item && item.previewable;

export const canCopyAndMove = (
  item: IFolderTree | IFilesystemListItem = null,
  trees: IFolderTree[],
  selected: IFilesystemListItem[],
) => {
  if (selected.length) {
    return !isReadOnlySelected(selected) &&
      isManageableSelected(selected) &&
      trees.some((tree) => tree && isAvailableDestinations(tree, selected));
  }

  return (
    item &&
    // @ts-ignore
    !isReadOnly(item) &&
    !isRoot(item) &&
    canManage(item) &&
    trees.some((tree) => tree && isAvailableDestinations(tree, [item]))
  );
};

export const canSelectedDrop = (selected, destination) => (
  !isSystemFolder(destination)
  && isFolder(destination)
  && canManageDestination(selected, destination)
  && !!selected
  && selected.some((item) => !isSystemFolder(item) && canManage(item))
  && selected.every((item) => (
    !isRoot(item)
    && isCopyMoveAvailableForItemHierarchy(item, destination)
    && (item.parentFolderId !== destination.id)
    && ((item.id !== destination.id) || (item.type !== destination.type))
  ))
);

export const canDownload = (item, userCanBulkDownload, isPreparingForArchive, selectedLength) => {
  if (!item) {
    return false;
  }

  const isBulkDownload = isFolder(item) || selectedLength > 1;
  const canBulkDownload = (item) => !isViewOnly(item) && userCanBulkDownload && !isPreparingForArchive;

  return isBulkDownload ? canBulkDownload(item) : !isViewOnly(item);
};

export const isDownloadButtonDisabled = (
  selected, userCanBulkDownload, isPreparingForArchive, collectionLength = 1,
) => (
  userCanBulkDownload ? (
    !collectionLength
  ) : (
    selected.length !== 1 || !canDownload(selected[0], userCanBulkDownload, isPreparingForArchive, 1)
  ));

export const canUpload = (parentFolder) => (
  isManageable(parentFolder)
);

export const canCreateFolder = (parentFolder) => (
  parentFolder && isFolder(parentFolder) && canManage(parentFolder)
);

export const canCreateLink = (parentFolder) => (
  isManageable(parentFolder)
);

export const canAskAQuestion = (item, selectedLength, isQnaModuleEnabled, user) => (
  selectedLength <= 1 &&
  item &&
  !item.isStaging &&
  !isUrl(item) &&
  isQnaModuleEnabled &&
  hasQuestionAccess(user)
);

export const canChangeIndex = (item, selected, isDataroomFileIndexingEnabled, userHasSystemManageAccess) => {
  if (!item) {
    if (selected.length > 1) {
      return false;
    }
    const [first] = selected;
    item = first;
  }

  return (
    item &&
    isDataroomFileIndexingEnabled &&
    canManage(item) &&
    userHasSystemManageAccess &&
    !isViewOnly(item) &&
    !isListOnly(item) &&
    !isRoot(item)
  );
};

export const canEdit = (item, selectedLength) => (
  selectedLength <= 1 &&
  item &&
  !isReadOnly(item) &&
  canManage(item)
);

export const canDisable = (items, userHasSystemManageAccess) => (
  items.length > 0 && items.every(isSystemFolder) && userHasSystemManageAccess
);

export const canDelete = (item, selected: IFilesystemListItem[] = []) => {
  if (selected?.length > 0) {
    return (
      !isReadOnlySelected(selected) &&
      isManageableSelected(selected)
    );
  }

  return (
    item &&
    !isReadOnly(item) &&
    canManage(item)
  );
};

export const canRedact = (item, selectedLength) => (
  selectedLength <= 1 &&
  item &&
  isFile(item) &&
  item.extension === 'pdf'
);

export const isRedacted = (item) => (
  item &&
  item.isRedacted
);
