import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { IEsmaEntitiesCount } from '@/dataroom/domain/vo/types/EsmaEntitiesCount';

@Dependencies(JsonRpcDispatcherFactory)
class DataroomEsmaRepository {
  protected constructor(protected rpc: typeof JsonRpcDispatcherFactory) {
  }

  assignEsmaAccount = async (
    payload: { dataroomId: number, userId: number, esmaAccount: string },
  ): Promise<null> => {
    const request = new Request('dataroom.users.assign_esma_account', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  selfAssignEsmaAccount = async (
    payload: { dataroomId: number, esmaAccount: string },
  ): Promise<null> => {
    const request = new Request('dataroom.users.current.assign_esma_account', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  getEsmaAccountList = async (payload: { dataroomId: number }): Promise<string[]> => {
    const request = new Request('dataroom.esma.get_account_list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getEsmaEntitiesCount = async (payload: { dataroomId: number }): Promise<IEsmaEntitiesCount> => {
    const request = new Request('dataroom.esma.get_entities_count', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default DataroomEsmaRepository;
