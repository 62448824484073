import React from 'react';
import cn from 'classnames';
import footerPattern from './assets/footerPattern.svg';
import styles from './footerPattern.scss';

interface IProps {
  className?: string,
}

const FooterPattern = ({ className }: IProps) => (
  <div className={ cn(styles.footerPattern, className) }>
    <img src={ footerPattern } />
  </div>
);

export default FooterPattern;
