import React, { useState, useEffect } from 'react';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { TUserAccess } from '@/users/domain/vo/TUserAccess';
import isDefined from '@/Framework/dataHelpers/isDefined';

interface IProps extends Partial<{
  permissions: TUserAccess,
  onInit: (() => Promise<boolean> | void) | Array<() => Promise<boolean> | void>,
  preloader: React.ReactElement,
  fallback: React.ReactElement,
}> {
  children: React.ReactElement,
}

/**
 * [!]
 * Pay attention that using of this middleware will block server side rendering until we get rid of getContainer usage.
 * Once we get rid of getContainer function, we can control rendering of parts of our platform with permissions.
 * The example you can find on UserProfile component in case that it is not critical for SSR.
 * TODO: After removing getContainer we should remove this comment.
 */
const CheckAccessPermissions = ({
  permissions,
  onInit,
  preloader = null,
  fallback = null,
  children,
}: IProps) => {
  const [shouldRender, setShouldRender] = useState(!isDefined(onInit));
  const [hasError, setHasError] = useState(false);
  const { initialized, satisfiesCurrentAccessPermissions } = useSessionContext();

  const handleOnInit = async () => {
    try {
      await Promise.all(Array.isArray(onInit)
        ? onInit.map((callback) => callback())
        : [onInit ? onInit() : Promise.resolve()],
      );

      setShouldRender(true);
    } catch {
      setHasError(true);
    }
  };

  useEffect(() => {
    initialized && handleOnInit();
  }, [initialized]);

  if (!initialized || !shouldRender) {
    return preloader;
  }

  return (satisfiesCurrentAccessPermissions(permissions) && !hasError) ? children : fallback;
};

export default CheckAccessPermissions;
