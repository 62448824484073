import SyntheticEventEmitter from '@/Framework/dataHelpers/event/SyntheticEventEmitter';

export const SIDEBAR_RESIZE_SYNTHETIC_EVENT = 'sidebarResizeSyntheticEvent';

const emitSidebarResizeEvent = (isAutoResizing: boolean = false) => {
  SyntheticEventEmitter.emit<{ isAutoResizing: typeof isAutoResizing }>(
    SIDEBAR_RESIZE_SYNTHETIC_EVENT,
    { detail: { isAutoResizing } },
  );
};

export default emitSidebarResizeEvent;
