import React from 'react';
import cn from 'classnames';
import FilesystemIcon from '@/dataroom/ui/common/FilesystemIcon';
import { IFile } from '@/dataroom/domain/questions/vo/File';

import styles from './questionTypeInfo.scss';

interface IProps {
  selectedFile: IFile,
  openEditMode: () => void,
  removeFile: () => void,
}

const QuestionTypeInfo = ({ selectedFile, openEditMode, removeFile }: IProps) => {
  const buttonText = selectedFile ? 'Ask General Question' : 'Select file/folder';
  const infoText = selectedFile ? 'Ask question(s) about the file/folder' : 'Add general question(s)';

  const handleClick = () => {
    selectedFile ? removeFile() : openEditMode();
  };

  return (
    <>
      <div className={ cn(styles.infoWrapper, { [styles.withFile]: !!selectedFile }) }>
        <div className={ cn({ [styles.typeInfoWithFile]: !!selectedFile }) }>
          { infoText } or { ' ' }
          <span
            className={ styles.linkBtn }
            onClick={ handleClick }
            data-test="changeQuestionTypeButton"
          >
            { buttonText }
          </span>
        </div>
      </div>
      { selectedFile && (
        <div className={ styles.fileWrapper }>
          <FilesystemIcon
            extension={ selectedFile.extension }
            type={ selectedFile.type }
            className={ styles.filesystemIcon }
          />
          { selectedFile.name }
        </div>
      ) }
    </>
  );
};

export default QuestionTypeInfo;
