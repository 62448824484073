import cn from 'classnames';
import React from 'react';
import Children from '@/dataroom/ui/components/Dataroom/components/FolderTree/Children';
import { useDelayedRender } from './useDelayedRender';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { IFilesystemItem } from '@/dataroom/domain/vo/filesystem/FilesystemItem';
import styles from './Node/node.scss';

const CHILD_RENDER_DELAY_COEF = 100;

interface IProps {
  isRoot: boolean,
  openNodes: number[],
  item: IFolderTree,
  depth: number,
  NodeComponent: React.ElementType,
  index: number,
  data: {
    destinationFolder: IFilesystemItem,
    setDestinationFolder: (v: IFilesystemItem) => void,
    filesystemItems: IFilesystemItem[],
  },
  isLast: boolean,
  focusNodeId: number,
  focusNodeCallback: React.Dispatch<React.SetStateAction<HTMLDivElement>>,
}

const Child = ({
  item,
  openNodes,
  isRoot,
  depth,
  NodeComponent,
  index,
  isLast,
  focusNodeId,
  data,
  focusNodeCallback,
}: IProps) => {
  const isOpen = openNodes?.includes(item.id);

  const { delayedRender, removeDelay } = useDelayedRender(
    depth === 1 || isOpen ? 0 : depth * index * CHILD_RENDER_DELAY_COEF,
  );

  return (
    <div
      ref={ focusNodeId === item.id ? focusNodeCallback : null }
      onClick={ removeDelay }
      key={ item.id }
      className={ cn(styles.nodeItemWrp, {
        [styles.isRoot]: isRoot,
      }) }
    >
      { /* @ts-ignore */ }
      <NodeComponent
        item={ item }
        depth={ depth }
        isRoot={ isRoot }
        isOpen={ isOpen }
        hasChildren={ !!item.children.length }
        isLast={ isLast }
        data={ data }
        dataTest={ `${ item.name }[${ item.id }]` }
      />
      <div className={ cn(styles.childrenWrp) }>
        { delayedRender(
          // @ts-ignore
          <Children
            focusNodeId={ focusNodeId }
            focusNodeCallback={ focusNodeCallback }
            tree={ item.children }
            openNodes={ openNodes }
            isRoot={ false }
            depth={ depth + 1 }
            NodeComponent={ NodeComponent }
            data={ data }
          />,
        ) }
      </div>
    </div>
  );
};

export default Child;
