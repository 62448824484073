import React from 'react';
import cn from 'classnames';
import ContactSupport from '@/Framework/UI/Molecules/ContactSupport';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import footerStyles from '@/dataroom/ui/components/Dataroom/Sidebar/Footer/footer.scss';
import styles from './supportChat.scss';
import IconOperator from '@dealroadshow/uikit/core/components/Icon/IconOperator';

function SupportChat() {
  const action = (
    <span className={ cn(styles.supportChatIcon, footerStyles.leftAlignedAction) }>
      <IconOperator />
    </span>
  );

  return (
    <div className={ footerStyles.leftAlignedIcon }>
      <ContactSupport>
        <Tooltip
          content="Live Tech Support"
          placement="top"
        >
          { action }
        </Tooltip>
      </ContactSupport>
    </div>
  );
}

export default SupportChat;
