import React from 'react';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { parseDateString } from '@/Framework/DateTime/parseDateString';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';
import { IFileUploadEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/FileUploadEmailAlerts';
import { IStagingFileUploadEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/StagingFileUploadEmailAlerts';
import { IDigestEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/DigestEmailAlerts';
import { IAccessRequestEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/AccessRequestEmailAlerts';
import { IQuestionsEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/QuestionsEmailAlerts';

export const convertTime = (time: string, abbr: string) => (
  time && `${ formatInTimeZone(parseDateString(time, 'UTC', 'H:mm:ss'), null, 'ha') }${ abbr ? ` ${ abbr }` : '' }`
);

export const convertArrayTime = (timeArray: string[], abbr: string) => timeArray?.map((time) => convertTime(time, abbr)).join(', ');

const getEmailAlerts = ({ alertSettings, isShort = false, timezone }: {
  alertSettings?: IFileUploadEmailAlerts |
    IStagingFileUploadEmailAlerts |
    IDigestEmailAlerts |
    IAccessRequestEmailAlerts |
    IQuestionsEmailAlerts,
  isShort?: boolean,
  timezone?: ITimezone,
}) => {
  const { daily, weekly } = alertSettings || {};

  return [
    {
      enabled: daily?.enabled,
      label: `${ isShort ? 'Dly' : 'Daily at' } ${
        Array.isArray(daily?.time) ?
          convertArrayTime(daily?.time, timezone?.abbr) :
          convertTime(daily?.time, timezone?.abbr)
      }`,
    },
    {
      enabled: weekly?.enabled,
      label: isShort ?
        `Wkly ${ convertTime(weekly?.time, timezone?.abbr) }` :
        `Weekly on ${ weekly?.day } at ${ convertTime(weekly?.time, timezone?.abbr) }`,
    },
  ];
};

const DigestEmailAlertsText = ({ alertSettings, isShort = false, timezone }: {
  alertSettings?: IDigestEmailAlerts,
  isShort?: boolean,
  timezone?: ITimezone,
}) => (
  <>{ getEmailAlerts({ alertSettings, isShort, timezone })
      .filter((item) => item.enabled)
      .map((item) => item.label)
      .join(', ') || 'None' }
  </>
);

const EmailAlertsText = ({ alertSettings, isShort = false, timezone }: {
  alertSettings?: IFileUploadEmailAlerts |
    IStagingFileUploadEmailAlerts |
    IAccessRequestEmailAlerts |
    IQuestionsEmailAlerts,
  isShort?: boolean,
  timezone?: ITimezone,
}) => {
  const { realtime } = alertSettings || {};

  return (
    <>
      { [
        {
          enabled: realtime?.enabled,
          label: isShort ? 'RT' : 'Real-time',
        },
        ...getEmailAlerts({ alertSettings, isShort, timezone }),
      ]
      .filter((item) => item.enabled)
      .map((item) => item.label)
      .join(', ') || 'None' }
    </>
  );
};

export { DigestEmailAlertsText, EmailAlertsText };
