import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDataroomContext } from './DataroomContext';
import { useCurrentUserContext } from './CurrentUserContext';
import { usePrimaryFolderTreeContext } from './folderTree/PrimaryFolderTreeContext';
import { useStagingFolderTreeContext } from './folderTree/StagingFolderTreeContext';
import useDataroomNavigation from './useDataroomNavigation';
import { getNodeItemById } from '../domain/filesystem';
import { IFolderTree } from '../domain/vo/filesystem/FolderTree';

const useCurrentFolder = () => {
  const [isStaging, setIsStaging] = useState<boolean>(false);
  const [currentFolderId, setCurrentFolderId] = useState<number>(null);
  const [currentFolder, setCurrentFolder] = useState<IFolderTree & { childrenCount: number }>(null);

  const { canUserAccessStaging, canUserAccessPrimary } = useCurrentUserContext();
  const { primaryFolderTree } = usePrimaryFolderTreeContext();
  const { stagingFolderTree } = useStagingFolderTreeContext();
  const { dataroom } = useDataroomContext();
  const { goToRootFolder } = useDataroomNavigation();

  const findStagingFolder = (id: number) => (
    stagingFolderTree ? getNodeItemById(stagingFolderTree, id) : null
  );

  const findPrimaryFolder = (id: number) => (
    primaryFolderTree ? getNodeItemById(primaryFolderTree, id) : null
  );

  // Current Folder should be updated if id has been changed or folder tree has been updated
  useEffect(() => {
    if (currentFolderId || Number.isNaN(currentFolderId)) {
      const currentFolder = isStaging ? findStagingFolder(currentFolderId) : findPrimaryFolder(currentFolderId);

      if (currentFolder) {
        setCurrentFolder({
          ...currentFolder,
          childrenCount: currentFolder.children.length,
        });
      } else if (isStaging) {
        // go to staging root folder if it is available, otherwise go to primary root folder
        setCurrentFolder(null);
        goToRootFolder(dataroom, canUserAccessStaging);
      } else {
        // go to primary root folder if it is available, otherwise go to staging root folder
        setCurrentFolder(null);
        goToRootFolder(dataroom, !canUserAccessPrimary);
      }
    } else {
      setCurrentFolder(null);
    }
  }, [currentFolderId, primaryFolderTree, stagingFolderTree]);

  const changeCurrentFolder = (folderId: number, isStaging: boolean) => {
    setIsStaging(isStaging);
    setCurrentFolderId(folderId);
  };

  const resetCurrentFolder = () => {
    setIsStaging(false);
    setCurrentFolderId(null);
  };

  return {
    changeCurrentFolder,
    resetCurrentFolder,
    currentFolder,
    currentFolderId,
  };
};

type CurrentFolderContextType = ReturnType<typeof useCurrentFolder>;

export const CurrentFolderContext = createContext<CurrentFolderContextType>(null);

export const useCurrentFolderContext = () => {
  const context = useContext(CurrentFolderContext);
  if (!context) {
    throw new Error('useCurrentFolderContext must be used within a CurrentFolderContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const CurrentFolderContextProvider = ({ children }: IProps) => (
  <CurrentFolderContext.Provider value={ useCurrentFolder() }>
    { children }
  </CurrentFolderContext.Provider>
);

export default CurrentFolderContextProvider;
