import getFileText from './fileText';
import getFolderText from './folderText';

const title = (foldersNumber, currentFolder, allFiles, isUploaded) => {
  if (isUploaded) {
    return 'Success! Your files were uploaded';
  }

  const fileText = getFileText(allFiles);
  const folderText = getFolderText(foldersNumber);
  const hasFolders = !!foldersNumber;

  if (allFiles.length && hasFolders) {
    return `Upload ${ fileText } and ${ folderText } to ${ currentFolder.name }`;
  }
  if (allFiles.length && !hasFolders) {
    return `Upload ${ fileText } to ${ currentFolder.name }`;
  }
  if (!allFiles.length && hasFolders) {
    return `Upload ${ folderText } to ${ currentFolder.name }`;
  }
  return `Upload to ${ currentFolder.name }`;
};

export default title;
