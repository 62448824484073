import { RefObject } from 'react';

export interface IRowRect {
  rect: DOMRect,
  selectedText: string,
}

export interface IRectProps {
  startElement: HTMLElement,
  startOffset: number,
  endOffset: number,
  selectedText: string,
}

export interface IPointProps {
  shape: IShape,
  scale: number,
  startPageRect: DOMRect,
  selectedText: string[],
  pageIndex: number,
}

export interface IPoint {
  x: number,
  y: number,
  x1: number,
  y1: number,
  pageIndex: number,
}

export enum RedactionType {
  Area = 'area',
  Text = 'text',
  Search = 'search',
}

export enum RedactionStructureType {
  Entry = 'entry',
  Redaction = 'redaction',
}

export enum RedactionAction {
  Add = 'add',
  Delete = 'delete',
  Restore = 'restore',
  Applied = 'applied',
  None = 'none',
}

export enum RedactionState {
  Applied,
  Pending,
}

export interface IOptions {
  isAreaPending?: boolean,
  isInitialized?: boolean,
  isSearch?: boolean,
}

export interface IPropsRedactionTool {
  isEnabled: boolean,
  canvasRef: RefObject<HTMLCanvasElement>,
  scale: number,
  handleInsertShapes: (shapes: IShape[], options?: IOptions) => void,
  handleUpdateShape: (id: string, oldControlPoints: IShape<RedactionType.Area>['controlPoints']) => void,
}

export interface RedactionFrame {
  id: string,
  topLeftX: number,
  topLeftY: number,
  bottomRightX: number,
  bottomRightY: number,
  pageIndex: number,
}

export interface IEntry {
  id: string,
  redactionId: string,
  type: RedactionType,
  label: string,
  frames: RedactionFrame[],
  action: RedactionAction,
}

export interface IRedaction {
  id: string,
  label: string,
  type: RedactionType,
  entries: IEntry[],
}

export interface IRedactionListItem {
  label: string,
  count?: number,
  id: string,
  redactionId: string,
  action: RedactionAction,
  state: RedactionState,
}

export type ISerializedShape = ReturnType<IShape['serialize']>;

interface IShapeActionItem {
  type?: RedactionType,
  id: string,
  action?: RedactionAction,
  label: string,
  redactionId?: string,
  state?: RedactionState | string,
  controlPoints?: (IPoint | Partial<IPoint>)[],
}

export interface IShapesAction {
  shapes: IShapeActionItem[],
  structureType: RedactionStructureType,
  action: RedactionAction,
}

export interface IShape<T extends RedactionType = RedactionType> {
  id: string, // Identification for entry
  redactionId: string, // Identification for redaction
  ctx: { [pageIndex: number]: CanvasRenderingContext2D }, // Canvas context for drawing.
  type: RedactionType, // Type of redaction tool. It is used on serialization.
  label: string, // Label for the shape.
  isActive: boolean, // Whether current shape is selected.
  isToolActive: boolean, // Whether current tool is selected.
  isDragging?: boolean, // Whether current shape is in dragging state.
  isHovered: boolean, // Whether current shape is hovered.
  activeColor: string, // Color of active shape.
  strokeColor: string, // Color of strokes on canvas for current shape.
  strokeWidth: number, // Size of strokes on canvas for current shape.
  defaultColor: string,
  deleteColor: string,
  state: RedactionState, // State of the shape.
  fillColor: string, // Color of filling background on canvas for current shape.
  requiredControlPoints: number, // Required amount of control point to build a shape.
  meta: any, // Meta information for the shape.
  controlPoints: ControlPointsType<T>, // Array of control points.
  addControlPoint: AddControlPointType<T>, // Adds control point to the array of control points.
  controlPointSize?: number, // Size of control point. Will be rendered on canvas when the shape is active.
  deviation: number, // Permissible deviation when choosing the shape on canvas.
  diameterCloseButton: number, // Diameter of close button. Will be rendered on canvas when the shape is hovered.
  bindContext: (canvas: HTMLCanvasElement, pageIndex: number) => void, // Binds canvas context.
  move?: (deltaX: number, deltaY: number) => void, // Move the shape.
  resize?: (controlPointIndex: number, x: number, y: number) => void, // Changing position of specific control point.
  draw: DrawType<T>, // Draw the shape
  drawControlPoints?: () => void, // Draw control points when the shape is active.
  drawDeleteButton: () => void, // Draw delete button when the shape is active.
  drawRestoreButton: () => void, // Draw restore button when the shape is deleted.
  isShapeArea: (x: number, y: number, pageIndex: number) => boolean, // Whether passed coordinates is on the shape area.
  isDeleteButtonArea: (
    x: number,
    y: number,
    pageIndex?: number,
  ) => boolean, // Whether passed coordinates is on the delete button area.
  getHandleIndex?: (x: number, y: number) => number | null, // Gets handle index by coordinates according deviation.
  isFinished?: () => boolean, // Whether the shape has all required control points to render.
  setFillColor: (color: string) => void,
  setIsHovered: (value: boolean) => void,
  setMetadata: (meta: any) => void,
  setId: (id: string) => void, // Set id for the shape.
  setToolActive: (value: boolean) => void,
  setIsActive: (value: boolean) => void,
  setIsDragging?: (value: boolean) => void,
  setLabel: (label: string) => void,
  action: RedactionAction,
  setAction: (action: RedactionAction) => void,
  setRedactionId: (redactionId: string) => void,
  setState: (state: RedactionState) => void,
  serialize: () => {
    type: IShape['type'],
    id: IShape['id'],
    action: IShape['action'],
    label: IShape['label'],
    redactionId: IShape['redactionId'],
    state: IShape['state'],
  }, // Serialize shape.
}

type ControlPointsType<T> = T extends RedactionType.Area ? Partial<IPoint>[] : IPoint[];

type AddControlPointType<T> = T extends RedactionType.Area
  ? (controlPoint: Partial<IPoint>) => void
  : (controlPoint: IPoint) => void;

type DrawType<T> = T extends RedactionType.Area
  ? (nextPoint?: Partial<IPoint>) => void
  : () => void;
