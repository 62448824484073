import React from 'react';
import NavigationItem from '@/dataroom/ui/components/Dataroom/Sidebar/Navigation/NavigationItem';
import getDataroomBasedUrl from '@/dataroom/infrastructure/getDataroomBasedUrl';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import IconDownload from '@dealroadshow/uikit/core/components/Icon/IconDownload';

const Downloads = ({ className }) => {
  const { dataroom } = useDataroomContext();

  return (
    <NavigationItem
      path={ getDataroomBasedUrl(dataroom.name, 'downloads') }
      icon={ IconDownload }
      title="Downloads"
      className={ className }
    />
  );
};

export default Downloads;
