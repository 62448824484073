import React from 'react';
import cn from 'classnames';

import styles from './inlineFieldWrp.scss';

interface IProps {
  name?: string,
  label: string,
  placeholder?: string,
  className?: string,
  formLabelClassName: string,
  formFieldClassName: string,
  children: (payload: { name: string, placeholder: string }) => React.ReactNode,
}

const InlineFieldWrp = (props: IProps) => (
  <div className={ cn(styles.inlineFieldWrp, props.className) }>
    <label
      className={ cn(styles.formLabel, props.formLabelClassName) }
      htmlFor={ props.name }
    >
      { props.label }:
    </label>
    <div className={ cn(styles.formField, props.formFieldClassName) }>
      {
        props.children({
          name: props.name,
          placeholder: props.placeholder,
        })
      }
    </div>
  </div>
);

export default InlineFieldWrp;
