import React, { createContext, useContext, useState } from 'react';

const useSidebarTab = () => {
  const [activeSidebarTab, setActiveSidebarTab] = useState(null);
  return {
    activeSidebarTab,
    changeSidebarTab: setActiveSidebarTab,
  };
};

export function useSidebarTabContext() {
  const context = useContext(SidebarTabContext);
  if (!context) {
    throw new Error('useSidebarTabContext must be used within a SidebarTabContextProvider');
  }
  return context;
}

type TSidebarTabContext = ReturnType<typeof useSidebarTab>;

export const SidebarTabContext = createContext<TSidebarTabContext>(null);

interface IProps {
  children: React.ReactNode,
}

function SidebarTabContextProvider({ children }: IProps) {
  return (
    <SidebarTabContext.Provider value={ useSidebarTab() }>
      { children }
    </SidebarTabContext.Provider>
  );
}

export default SidebarTabContextProvider;
