import React, { ChangeEvent } from 'react';
import {
  managersRealTimeOptions,
  submittersRealTimeOptions,
} from '@/dataroom/ui/common/EmailAlerts/QuestionAlerts/RealTime/options';
import CheckboxField from '../../common/CheckboxField';
import FormError from '@dealroadshow/uikit/core/components/Form/FormError';
import { IQuestionsRealtimeNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/QuestionsEmailAlerts';
import styles from './realTime.scss';

interface IProps<T> {
  isQuestionManager: boolean,
  values: T,
  realtimeError: string,
  handleChange: (event: ChangeEvent<{
    name: keyof T,
    checked: T[keyof T],
  }>) => void,
}

const RealTime = <T extends IQuestionsRealtimeNotificationSettingsPayload>({
  isQuestionManager,
  values,
  handleChange,
  realtimeError,
}: IProps<T>) => {
  const realTimeOptions = isQuestionManager ? managersRealTimeOptions : submittersRealTimeOptions;

  return (
    <div className={ styles.wrapper }>
      { realTimeOptions.map(({
        name,
        label,
        dataTest,
      }) => (
        <CheckboxField
          key={ name }
          name={ name }
          dataTest={ dataTest }
          label={ label }
          value={ values[name] }
          className={ styles.checkbox }
          onChange={ handleChange }
        />
      )) }
      <FormError error={ realtimeError } touched />
    </div>
  );
};

export default RealTime;
