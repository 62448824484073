import cn from 'classnames';
import React from 'react';
import { iconMapping } from '@/dataroom/ui/common/FilesystemIcon/constants';

interface IProps {
  className: string,
}

const LinkIcon = (props: IProps) => {
  const {
    icon: Icon,
    className,
  } = iconMapping.url;

  return (
    <span className={ cn(className, props.className) }>
      <Icon data-test="linkIcon" />
    </span>
  );
};

export default LinkIcon;
