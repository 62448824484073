import React, { createContext, useContext, useRef, useState } from 'react';
import remove from 'lodash/remove';
import ExportTimeoutModal from '@/dataroom/ui/common/ExportToExcel/ExportTimeoutModal';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import ExportRepository from '@/dataroom/infrastructure/repository/ExportRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import { messageCodes } from '@/Framework/Message/messages';
import { NotificationManager } from '@/Framework/Notification';
import getMessage from '@/Framework/Message/getMessage';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import ExportToExcel from './models/ExportToExcel';

const useExportToExcel = () => {
  const { container } = useDIContext();
  const runningExportsRef = useRef<Array<ExportToExcel>>([]);
  const setRunningExports = (exports: Array<ExportToExcel>) => {
    runningExportsRef.current = exports;
  };

  const [isOversizeModalVisible, setIsOversizeModalVisible] = useState(false);

  const addRunningExport = (excelExport: ExportToExcel) => {
    setRunningExports([...runningExportsRef.current, excelExport]);
    excelExport.timeout = setTimeout(() => {
      excelExport.onTimeout();
      setRunningExports(remove(runningExportsRef.current, excelExport));
      setIsOversizeModalVisible(true);
    }, oversizeTimeout);
  };

  const removeExportByUuid = (uuid: string) => {
    const findByUuid = (e) => e.uuid === uuid;
    const excelExport = runningExportsRef.current.find(findByUuid);
    if (excelExport) {
      clearTimeout(excelExport.timeout);
    }
  };

  const { dataroom } = useDataroomContext();
  const [isExportCreating, setIsExportCreating] = useState(false);

  const requestExportToExcel = async (exportName, payload, onFinishCallback) => {
    setIsExportCreating(true);

    const timer = setTimeout(() => {
      setIsExportCreating(false);
      NotificationManager.success(getMessage('DATAROOM_CREATING_EXCEL_MESSAGE'));
    }, 5000);

    const onFinish = (uuid: string) => {
      setIsExportCreating(false);
      removeExportByUuid(uuid);
      clearTimeout(timer);
      onFinishCallback && onFinishCallback();
    };

    const onError = (uuid: string) => {
      setIsExportCreating(false);
      onFinish(uuid);
      NotificationManager.error(getMessage(messageCodes.GENERAL_ERROR));
    };

    try {
      const exportRepository = container.get<ExportRepository>(ExportRepository);
      const excelExport = await exportRepository.requestExportToExcel({
        ...payload,
        dataroomId: dataroom.id,
        exportName,
        onFinish,
        onError,
      });

      addRunningExport(excelExport);
    } catch (error) {
      setIsExportCreating(false);
      container.get(DataroomErrorHandler).handleError(error);
      clearTimeout(timer);
      onFinishCallback && onFinishCallback();
    }
  };

  return {
    isExportCreating,
    requestExportToExcel,
    isOversizeModalVisible,
    setIsOversizeModalVisible,
  };
};
type TuseExportToExcel = ReturnType<typeof useExportToExcel>;

type TExportToExcelContext = Omit<TuseExportToExcel, 'isOversizeModalVisible' | 'setIsOversizeModalVisible'>;

export const ExportToExcelContext = createContext<TExportToExcelContext>(null);

const oversizeTimeout = 120 * 1000;

export function useExportToExcelContext() {
  const context = useContext(ExportToExcelContext);
  if (!context) {
    throw new Error('useExportToExcelContext must be used within a ExportToExcelContextProvider');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function ExportToExcelContextProvider({ children } : IProps) {
  const { isOversizeModalVisible, setIsOversizeModalVisible, ...exportToExelContextValue } = useExportToExcel();

  return (
    <ExportToExcelContext.Provider value={ exportToExelContextValue }>
      { isOversizeModalVisible && (
        <ExportTimeoutModal closeModal={ () => { setIsOversizeModalVisible(false); } } />
        ) }
      { children }
    </ExportToExcelContext.Provider>
  );
}

export default ExportToExcelContextProvider;
