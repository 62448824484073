export default class ExportToExcel {
  timeout: any;

  onTimeout: any;

  constructor(
      public dataroomId: number,
      public uuid: string,
      public hash: string,
       onTimeout: (any: any)=> any,
      ) {
    this.onTimeout = () => onTimeout(this);
    this.timeout = null;
  }
}
