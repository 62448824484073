import { useState } from 'react';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import BulkPinningModal from '@/dataroom/ui/common/DataroomExplorer/Modals/BulkPinUnpinModal/BulkPinningModal/BulkPinningModal';
import { useBulkPinningContext } from '@/dataroom/application/BulkPinningContext';
import { BulkPinningAction } from '@/dataroom/domain/vo/filesystem/BulkPinning';

export default function useBulkPinningModal() {
  const [modalState, setModalState] = useState<{
    isVisible: boolean,
    mode: BulkPinningAction.PIN | BulkPinningAction.UNPIN | null,
    items:(IFilesystemListItem | IFolderTree)[],
  }>({
    isVisible: false,
    mode: null,
    items: [],
  });

  const { pinning, unpinning } = useBulkPinningContext();

  const showBulkPinningModal = (items: (IFilesystemListItem | IFolderTree)[], mode: BulkPinningAction) => {
    setModalState({ isVisible: true, mode, items });
  };

  const hideBulkPinningModal = () => {
    setModalState({ isVisible: false, mode: null, items: [] });
  };

  const handleBulkAction = async (dataroomId: number) => {
    if (BulkPinningAction.PIN) {
      await pinning(dataroomId);
    } else if (BulkPinningAction.UNPIN) {
      await unpinning(dataroomId);
    }
    hideBulkPinningModal();
  };

  return {
    BulkPinningModal,
    modalState,
    showBulkPinningModal,
    hideBulkPinningModal,
    handleBulkAction,
  };
}
