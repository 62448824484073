import React from 'react';
import styles
  from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderProgress/uploaderProgressBar.scss';

interface IProps {
  children: React.ReactNode,
}

const ProgressBarWrp = ({ children }: IProps) => (<div className={ styles.progressBarWrp }>{ children }</div>);

export default ProgressBarWrp;
