import config from '@/Framework/config';
import buildUrl from '@/Framework/url/helpers/buildUrl';
import base from '@/Framework/url/baseUrl';
import usersUrl from '@/users/infrastructure/usersUrl';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';

export default {
  /**
   * Get base users url
   */
  getUrl(...uris: (string | string[])[]): string {
    return buildUrl(config.protocol, `${ config.drsSubdomain }.${ config.globalDomain }`, ...uris);
  },

  /**
   * Get propagate url
   */
  getPropagateUrl(): string {
    return base.getRestUrl('/propagate');
  },

  /**
   * Get propagate with callback url as parameter
   */
  getPropagateCallbackUrl(callbackUrl: string): string {
    return `${ this.getPropagateUrl() }?callbackUrl=${ encodeURIComponent(callbackUrl) }`;
  },

  /**
   * Get legacy url
   */
  getLegacyUrl(...uris: (string | string[])[]): string {
    return buildUrl(config.protocol, config.drsLegacyDomain, ...uris);
  },

  /**
   * Get base files url
   */
  getBaseFilesUrl(...uris: (string | string[])[]): string {
    return base.getApiUrl('dealroadshow/files', ...uris);
  },

  /**
   * Get file url
   */
  getFileUrl(...uris: (string | string[])[]): string {
    return this.getBaseFilesUrl(...uris);
  },

  /**
   * Get login url
   */
  getLoginUrl(): string {
    return usersUrl.getLoginUrl(
      this.getPropagateCallbackUrl(dmPortalUrl.getUrl('/roadshow')),
      config.tenant.dealroadshow.code,
    );
  },

  /**
   * Get register url
   */
  getRegisterUrl(): string {
    return usersUrl.getRegisterUrl(
      this.getPropagateCallbackUrl(dmPortalUrl.getUrl('/roadshow')),
      config.tenant.dealroadshow.code,
    );
  },
};
