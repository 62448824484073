import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import FileUploadDispatcherFactory from '@/dataroom/application/DI/FileUpload/FileUploadDispatcherFactory';
import MultipartUploadDispatcherFactory
  from '@/dataroom/application/DI/FileUpload/MultipartFileUploadDispatcher';
import FileWithUploadAndPath
  from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/FileWithUploadAndPath';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import Logger from '@/Framework/browser/log/Logger';

@Dependencies(RpcDispatcher, FileUploadDispatcherFactory, MultipartUploadDispatcherFactory)
class FileUploadRepository {
  constructor(
    private readonly rpc: typeof RpcDispatcher,
    private readonly uploadDispatcherFactory: typeof FileUploadDispatcherFactory,
    private readonly multipartUploadDispatcherFactory: typeof MultipartUploadDispatcherFactory,
  ) {
  }

  initBigFileUpload = async (
    dataroomId: number,
    file: FileWithUploadAndPath,
    chunkSize: number,
    options: { abortController: AbortController },
  ) => this.multipartUploadDispatcherFactory()
      .upload(
        file,
        {
          type: 'DEALVDR_FILE',
          fileSize: file.size,
          chunkSize,
          dataroomId,
          file,
          ...options,
        });

  uploadSmallFile = async (
    dataroomId: number,
    file: FileWithUploadAndPath,
    chunkSize: number,
    options: { abortController: AbortController },
  ) => this.uploadDispatcherFactory()
    .upload(
      file,
      {
        type: 'DEALVDR_FILE',
        fileSize: file.size,
        chunkSize,
        dataroomId,
        file,
        ...options,
      });

  notifyProhibitedExtensions = async (
    payload: {
      dataroomId: number,
      parentFolderId: number,
      prohibitedExtensionsFiles: string[],
    },
  ): Promise<void> => {
    try {
      const request = new Request('upload.prohibited_extension_notify', payload);
      await this.rpc()
        .call<RpcSuccess>(request);
    } catch (e) {
      Logger.error(e, 'notify prohibited extensions');
    }
  };
}

export default FileUploadRepository;
