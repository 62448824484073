import React, { createContext, useContext, useState } from 'react';
import noop from 'lodash/noop';

const useDataroomExplorer = () => {
  const [isPristine, setIsPristine] = useState<boolean>(true);
  const [updateCollection, setUpdateCollectionState] = useState<() => void>(noop);
  const [reset, setResetState] = useState<() => void>(noop);
  const [softReset, setSoftResetState] = useState<() => void>(noop);

  const setUpdateCollection = (updateCollection: () => void) => {
    setUpdateCollectionState(() => updateCollection);
  };

  const setReset = (reset: () => void) => {
    setResetState(() => reset);
  };

  const setSoftReset = (softReset: () => void) => {
    setSoftResetState(() => softReset);
  };

  const resetDataroomExplorer = () => {
    setIsPristine(true);
    setUpdateCollectionState(() => noop);
    setResetState(() => noop);
    setSoftResetState(() => noop);
  };

  return {
    isPristine,
    setIsPristine,
    updateCollection,
    setUpdateCollection,
    reset,
    setReset,
    softReset,
    setSoftReset,
    resetDataroomExplorer,
  };
};

type DataroomExplorerContextType = ReturnType<typeof useDataroomExplorer>;

export const DataroomExplorerContext = createContext<DataroomExplorerContextType>(null);

export const useDataroomExplorerContext = () => {
  const context = useContext(DataroomExplorerContext);
  if (!context) {
    throw new Error('useDataroomExplorerContext must be used within a DataroomExplorerContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactElement,
}

const DataroomExplorerContextProvider = ({ children }: IProps) => (
  <DataroomExplorerContext.Provider value={ useDataroomExplorer() }>
    { children }
  </DataroomExplorerContext.Provider>
);

export default DataroomExplorerContextProvider;
