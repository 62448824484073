import React, { createContext, useContext } from 'react';

import useCreateQuestion from '@/dataroom/application/questions/useCreateQuestion';
import useFiles from '@/dataroom/application/questions/useFiles';
import useCategories from '@/dataroom/application/questions/categories/useCategories';
import useQuestionLimits from '@/dataroom/application/questions/useQuestionLimits';

const useQuestionModal = () => ({
  create: useCreateQuestion(),
  files: useFiles(),
  categories: useCategories(),
  questionLimits: useQuestionLimits(),
});

type QuestionModalContextType = ReturnType<typeof useQuestionModal>;

export const QuestionModalContext = createContext<QuestionModalContextType>(null);

export function useQuestionModalContext() {
  const context = useContext(QuestionModalContext);
  if (!context) {
    throw new Error('useQuestionModalContext must be used within a QuestionModalContextProvider');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

const QuestionModalContextProvider = ({ children }: IProps) => (
  <QuestionModalContext.Provider value={ useQuestionModal() }>
    { children }
  </QuestionModalContext.Provider>
);

export default QuestionModalContextProvider;
