import { Method } from 'constitute';
import {
  DataroomMultipartUploadAdapter, UploadDispatcher, DataroomMultipartUploadStrategy,
} from '@dealroadshow/file-uploader';
import dataroomUrlFactory from '@/dataroom/infrastructure/dataroomUrl';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import { IDataroomTenantContext, DataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import Container from '@/Framework/DI/Container';
import ProcessingAdapter from '@/dataroom/application/DI/FileUpload/SocketProcessingAdapter';

class FactoryBuilder {
  container: Container;

  constructor(container) {
    this.container = container;
  }

  getFactory() {
    return (tenantCode: DataroomTenant = null) => {
      const tenant = tenantCode || this.container.get<IDataroomTenantContext>(DataroomTenantContext).tenant;

      const dataroomUrl = dataroomUrlFactory(tenant);

      const multipartUploadAdapter = new DataroomMultipartUploadAdapter({
        url: dataroomUrl.getUploadFileUrl(),
      });

      const processingAdapterFactory = this.container.get<typeof ProcessingAdapter>(ProcessingAdapter);
      const processingAdapter = processingAdapterFactory(tenant);

      return new UploadDispatcher(new DataroomMultipartUploadStrategy(multipartUploadAdapter, processingAdapter));
    };
  }
}

export default new Method(
  (container: Container) => {
    const factoryBuilder = new FactoryBuilder(container);
    return factoryBuilder.getFactory();
  },
  [Container],
) as ReturnType<InstanceType<typeof FactoryBuilder>['getFactory']>;
