export const HOURS_OPTIONS = [
  {
    label: '1AM',
    value: '01:00:00',
  },
  {
    label: '2AM',
    value: '02:00:00',
  },
  {
    label: '3AM',
    value: '03:00:00',
  },
  {
    label: '4AM',
    value: '04:00:00',
  },
  {
    label: '5AM',
    value: '05:00:00',
  },
  {
    label: '6AM',
    value: '06:00:00',
  },
  {
    label: '7AM',
    value: '07:00:00',
  },
  {
    label: '8AM',
    value: '08:00:00',
  },
  {
    label: '9AM',
    value: '09:00:00',
  },
  {
    label: '10AM',
    value: '10:00:00',
  },
  {
    label: '11AM',
    value: '11:00:00',
  },
  {
    label: '12PM',
    value: '12:00:00',
  },
  {
    label: '1PM',
    value: '13:00:00',
  },
  {
    label: '2PM',
    value: '14:00:00',
  },
  {
    label: '3PM',
    value: '15:00:00',
  },
  {
    label: '4PM',
    value: '16:00:00',
  },
  {
    label: '5PM',
    value: '17:00:00',
  },
  {
    label: '6PM',
    value: '18:00:00',
  },
  {
    label: '7PM',
    value: '19:00:00',
  },
  {
    label: '8PM',
    value: '20:00:00',
  },
  {
    label: '9PM',
    value: '21:00:00',
  },
  {
    label: '10PM',
    value: '22:00:00',
  },
  {
    label: '11PM',
    value: '23:00:00',
  },
  {
    label: '12AM',
    value: '00:00:00',
  },
];
export const DAY_OF_WEEK_LIST = [
  { value: 'Sunday', label: 'Sunday' },
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
];
