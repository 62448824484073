import React, { useState, createContext, useContext } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import DialInsRepository from '@/dataroom/infrastructure/repository/DialInsRepository';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { IDealIns } from '@/dataroom/domain/vo/DealIns';

const useDialIns = () => {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [collection, setCollection] = useState<IDealIns[]>([]);

  /**
   * Get collection
   */
  async function getDialIns() {
    if (collection.length) return;
    setIsFetching(true);
    try {
      const dialInsRepository = container.get<DialInsRepository>(DialInsRepository);
      const response = await dialInsRepository.getDialIns();

      setCollection(response);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  }

  return {
    isFetching,
    collection,
    getDialIns,
  };
};

type DialInsContextType = ReturnType<typeof useDialIns>;

export const DialInsContext = createContext<DialInsContextType>(null);

export function useDialInsContext() {
  const context = useContext(DialInsContext);
  if (!context) {
    throw new Error('useDialInsContext must be used within a DialInsContextProvider');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function DialInsContextProvider({ children }: IProps) {
  return (
    <DialInsContext.Provider value={ useDialIns() }>
      { children }
    </DialInsContext.Provider>
  );
}

export default DialInsContextProvider;
