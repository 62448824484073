import React, { createContext, useContext } from 'react';
import useRemove from '@/dataroom/application/redaction/useRemove';
import useApply from '@/dataroom/application/redaction/useApply';
import useListing from '@/dataroom/application/redaction/useListing';
import useSave from '@/dataroom/application/redaction/useSave';
import useSearch from '@/dataroom/application/redaction/useSearch';

const useDataroomRedaction = () => {
  const remove = useRemove();
  const apply = useApply();
  const listing = useListing();
  const search = useSearch();

  const { isFetching: isRemoveFetching } = remove;
  const { isFetching: isApplyFetching } = apply;
  const { isFetching: isGetFetching } = listing;
  const { isFetching: isSearchFetching } = search;

  return {
    remove,
    apply,
    listing,
    search,
    save: useSave(),
    isFetching: isRemoveFetching || isApplyFetching || isGetFetching || isSearchFetching,
  };
};

type DataroomRedactionContextType = ReturnType<typeof useDataroomRedaction>;

export const DataroomRedactionContext = createContext<DataroomRedactionContextType>(null);

export function useDataroomRedactionContext() {
  const context = useContext(DataroomRedactionContext);
  if (!context) {
    throw new Error('useDataroomRedactionContext must be used within a DataroomRedactionContextProvider');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

const DataroomRedactionContextProvider = ({ children }: IProps) => (
  <DataroomRedactionContext.Provider value={ useDataroomRedaction() }>
    { children }
  </DataroomRedactionContext.Provider>
);

export default DataroomRedactionContextProvider;
