/**
 * @param {Number} byte
 * @return {String}
 */
export default function byteConverter(byte) {
  const SCALES = ['GB', 'MB', 'KB'];
  const SCALE_TO_SIZE = {
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
  };
  // eslint-disable-next-line radix
  let value = parseInt(byte, 10);
  let scale;
  let scaleSize;
  let size;

  if (!byte) {
    return '-';
  }

  for (let i = 0; i < SCALES.length; i++) {
    scale = SCALES[i];
    scaleSize = SCALE_TO_SIZE[scale];
    if (value >= scaleSize) {
      size = (Math.round(10 * (value / scaleSize)) / 10);
      return `${ size.toFixed(1) } ${ scale }`;
    }
  }

  return `${ value } B`;
}
