import React from 'react';
import cn from 'classnames';
import styles from './alert.scss';

interface IProps {
  className?: string,
  children: React.ReactNode,
}

const Alert = ({ children, className }: IProps) => (
  <div className={ cn(styles.notification, className) }>{ children }</div>
);

export default Alert;
