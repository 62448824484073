import React from 'react';
import cn from 'classnames';

import Radio, { RadioGroup } from '@dealroadshow/uikit/core/components/Radio';
import Tooltip from '@/dataroom/ui/common/Questions/QuestionsExplorer/QuestionThread/ThreadTooltip';
import { IQuestionPriorities } from '@/dataroom/domain/questions/vo/QuestionPriorities';

import { questionPriority } from './constants';

import styles from './questionPriority.scss';

interface IProps {
  fieldClassName?: string,
  input: {
    value: string,
  },
  priorityRemain?: IQuestionPriorities,
  tooltipContent?: string,
  isMaxQuantity?: boolean,
}

const priority = [
  questionPriority.low,
  questionPriority.medium,
  questionPriority.high,
];

const QuestionPriority = ({
  input,
  fieldClassName,
  tooltipContent,
  priorityRemain,
  isMaxQuantity,
}: IProps) => (
  <RadioGroup
    { ...input }
    selectedValue={ input.value }
    fieldClassName={ fieldClassName }
    className={ styles.radioGroupContainer }
  >
    {
      priority.map((priority) => {
        const isNoLimits = !isMaxQuantity && priorityRemain && !priorityRemain[priority.name];
        const checked = input.value === priority.name;

        return (
          <Tooltip
            key={ priority.name }
            content={ tooltipContent }
            disabled={ !tooltipContent || !isNoLimits || checked }
            maxWidth="165px"
            containerClassName={ styles.tooltipContainer }
          >
            <Radio
              key={ priority.name }
              value={ priority.name }
              label={ priority.label }
              disabled={ isNoLimits }
              className={ styles.radioItemWrp }
              radioLabelClassName={ cn(styles.radioItem, {
                [styles.low]: priority.name === questionPriority.low.name,
                [styles.medium]: priority.name === questionPriority.medium.name,
                [styles.high]: priority.name === questionPriority.high.name,
              }) }
              dataTest={ `${ priority.name }Radio` }
            />
          </Tooltip>
        );
      })
    }
  </RadioGroup>
);

export default QuestionPriority;
