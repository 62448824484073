import React from 'react';
import cn from 'classnames';

import SelectFile from '@/dataroom/ui/common/Questions/SelectFile';
import QuestionTypeInfo from './QuestionTypeInfo';

import { IFile } from '@/dataroom/domain/questions/vo/File';
import { IFileAction } from '@/dataroom/domain/questions/vo/questionAction';

import styles from './questionType.scss';

interface IProps {
  files: IFileAction,
  selectedFile: IFile,
  setSelectedFile: (file: IFile) => void,
  isFileEditMode: boolean,
  setIsFileEditMode: (v: boolean) => void,
}

const QuestionType = ({ files, selectedFile, setSelectedFile, isFileEditMode, setIsFileEditMode }: IProps) => {
  const handleSubmitFile = (file: IFile) => {
    setSelectedFile(file);
    setIsFileEditMode(false);
  };

  return (
    <div className={ cn(styles.wrapper, { [styles.editMode]: isFileEditMode }) }>
      { isFileEditMode ? (
        <SelectFile
          files={ files }
          handleCancel={ () => setIsFileEditMode(false) }
          handleSubmitFile={ handleSubmitFile }
        />
      ) : (
        <QuestionTypeInfo
          selectedFile={ selectedFile }
          openEditMode={ () => setIsFileEditMode(true) }
          removeFile={ () => setSelectedFile(null) }
        />
      ) }
    </div>
  );
};

export default QuestionType;
