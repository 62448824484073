import React from 'react';
import QuestionTabs from '@/dataroom/ui/components/Dataroom/Sidebar/Questions/QuestionTabs';
import QuestionActions from '@/dataroom/ui/components/Dataroom/Sidebar/Questions/QuestionActions';

import styles from './questions.scss';

const Questions = () => (
  <div className={ styles.questionWrp }>
    <div>
      <QuestionTabs />
    </div>
    <div className={ styles.bottomActions }>
      <QuestionActions />
    </div>
  </div>
);

export default Questions;
