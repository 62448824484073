import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import { Area } from '@/dataroom/domain/vo/Area';
import { Action } from '@/dataroom/domain/vo/filesystem/Action';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';

const defaultConfirmation = `I understand that any permission group with access to these files and folders will
  continue to have access after this action is performed.`;

const defaultPermissionsConfirmation = `I understand that by making any changes, I will overwrite existing
  permissions for the destination folder with the permissions selected in the table above`;

const copyPrimaryToStagingConfirmation = (tenant: DataroomTenant) => `I understand that after this operation, access
  for the copied files and folders will be revoked for
  general ${ DataroomTenantConfig.fromCode(tenant).options.tenantLabelSm } users. `;

const copyStagingToPrimaryConfirmation = (tenant: DataroomTenant) => `I understand that after this operation, the files
  and folders will be copied into the primary ${ DataroomTenantConfig.fromCode(tenant).options.tenantLabelSm }, making
  them accessible to general ${ DataroomTenantConfig.fromCode(tenant).options.tenantLabelSm } users (with permissions
  of the destination folder).`;

const movePrimaryToPrimaryConfirmation = `I understand that any permission group with access to these files and folders
  will continue to have access after this action is performed.`;

const movePrimaryToStagingConfirmation = (tenant: DataroomTenant) => `I understand that after this operation, file and
  folder access will be revoked for general ${ DataroomTenantConfig.fromCode(tenant).options.tenantLabelSm } users.`;

const moveStagingToPrimaryConfirmation = (tenant: DataroomTenant) => `I understand that after this operation, the files
  and folders will be moved into the primary ${ DataroomTenantConfig.fromCode(tenant).options.tenantLabelSm }, making
  them accessible to general ${ DataroomTenantConfig.fromCode(tenant).options.tenantLabelSm } users (with permissions
  of the destination folder).`;

const moveStagingToStagingConfirmation = `I understand that any permission group with access to these files and
  folders will continue to have access after this operation.`;

export const getConfirmation = (
  tenant: DataroomTenant,
  actionName: Action,
  sourceArea: Area,
  activeArea: Area,
  isPermissionChangeVisible: boolean,
): string => {
  if (isPermissionChangeVisible) {
    return defaultPermissionsConfirmation;
  }

  const mapping = {
    [Action.Copy]: {
      [Area.Primary]: {
        [Area.Primary]: defaultConfirmation,
        [Area.Staging]: copyPrimaryToStagingConfirmation(tenant),
      },
      [Area.Staging]: {
        [Area.Primary]: copyStagingToPrimaryConfirmation(tenant),
        [Area.Staging]: defaultConfirmation,
      },
    },
    [Action.Move]: {
      [Area.Primary]: {
        [Area.Primary]: movePrimaryToPrimaryConfirmation,
        [Area.Staging]: movePrimaryToStagingConfirmation(tenant),
      },
      [Area.Staging]: {
        [Area.Primary]: moveStagingToPrimaryConfirmation(tenant),
        [Area.Staging]: moveStagingToStagingConfirmation,
      },
    },
  };

  return mapping[actionName][sourceArea][activeArea];
};
