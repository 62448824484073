import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import FolderRepository from '@/dataroom/infrastructure/repository/FolderRepository';
import FileRepository from '@/dataroom/infrastructure/repository/FileRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { IFolderPermissionGroup } from '@/dataroom/domain/vo/filesystem/FolderPermissionGroup';
import { IFilePermissionGroup } from '@/dataroom/domain/vo/filesystem/FilePermissionGroup';

const useAllEditPermissions = () => {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState(false);
  const [collection, setCollection] = useState<(IFolderPermissionGroup | IFilePermissionGroup)[]>([]);
  const [itemId, setItemId] = useState<number>(null);
  const [itemIsFolder, setItemIsFolder] = useState(false);

  const { dataroom } = useDataroomContext();

  const getPermissions = async (id: number, isFolder: boolean) => {
    setIsFetching(true);
    setItemId(id);
    setItemIsFolder(isFolder);

    try {
      const payload = { dataroomId: dataroom.id };

      const response = await (() => {
        if (isFolder) {
          return container.get<FolderRepository>(FolderRepository).getAllEditPermissions({
            ...payload,
            folderId: id,
          });
        }
        return container.get<FileRepository>(FileRepository).getAllEditPermissions({
          ...payload,
          fileId: id,
        });
      })();

      setCollection(response);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  const updatePermissions = async() => {
    itemId && await getPermissions(itemId, itemIsFolder);
  };

  return {
    isFetching,
    collection,
    getPermissions,
    updatePermissions,
  };
};

export default useAllEditPermissions;
