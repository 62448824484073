import React from 'react';
import cn from 'classnames';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { responsiveModalStyles } from '@dealroadshow/uikit/core/components/Modal';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import styles from './changeIndexConflictForm.scss';

interface IProps {
  item: IFilesystemListItem,
  conflictItemName: string,
  index: number,
  isFetching: boolean,
  closeModal: () => void,
  changeIndexOverrideAndShiftAll: (item: IFilesystemListItem | IFolderTree, index: number) => Promise<void>,
  changeIndexOverrideAndShiftNonPinned: (item: IFilesystemListItem | IFolderTree, index: number) => Promise<void>,
  footerRefCallback: () => void,
}

const ChangeIndexConflictForm = ({
  item,
  conflictItemName,
  index,
  isFetching,
  closeModal,
  changeIndexOverrideAndShiftAll,
  changeIndexOverrideAndShiftNonPinned,
  footerRefCallback,
}: IProps) => (
  <>
    <div className={ styles.contentWrp }>
      { isFetching && (<Spinner overlay />) }
      <div className={ styles.disclaimer }>
        <p>
          The index number you entered is already a Pinned Index Position.
          Index is currently pinned
          by <span className={ styles.disclaimerBold }>{ conflictItemName }</span>. You
          have the option to:
        </p>
        <p>
          <span className={ cn(styles.disclaimerBold, styles.disclaimerUnderline) }>
            1. Override & Shift All:
          </span> Override and replace the existing Pinned Index Position.
          The index number for all files or folders below this index number will shift by 1.
        </p>
        <p>
          <span className={ cn(styles.disclaimerBold, styles.disclaimerUnderline) }>
            2. Override & Shift Non-Pinned:
          </span> Override and replace the existing Pinned Index Position.
          All non-pinned files or folders will shift to the next available index position.
        </p>
      </div>
    </div>
    <div
      className={ cn(styles.buttonsWrp, responsiveModalStyles.modalFooter) }
      ref={ footerRefCallback }
    >
      <div className={ styles.actionButtons }>
        <div className={ styles.leftButtonWrp }>
          <Button
            variant={ ButtonVariantType.action }
            onClick={ () => changeIndexOverrideAndShiftAll(item, index).then(closeModal) }
            disabled={ isFetching }
            title="Override & Shift All"
            dataTest="overrideAndShiftAllButton"
          />
        </div>
        <Button
          variant={ ButtonVariantType.action }
          className={ styles.overrideShiftNonPinnedButton }
          onClick={ () => changeIndexOverrideAndShiftNonPinned(item, index).then(closeModal) }
          disabled={ isFetching }
          title="Override & Shift Non-Pinned"
          dataTest="overrideAndShiftNonPinnedButton"
        />
      </div>
      <Button
        variant={ ButtonVariantType.text }
        onClick={ closeModal }
        title="Cancel"
        dataTest="overrideAndShiftCancelButton"
      />
    </div>
  </>
);

export default ChangeIndexConflictForm;
