import ErrorCodeHelper from '@finsight/error-codes';
import UploadError from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/UploadError';
import messages from '@/Framework/Message/messages';

export default class ResponseError extends UploadError {
  code;

  constructor(payload) {
    super(payload.message);

    if (!payload.code) {
      this.displayMessage = 'Unknown error.';
    } else {
      this.code = payload.code;

      try {
        if (messages[ErrorCodeHelper.getNameByCode(this.code)]) {
          this.displayMessage = messages[ErrorCodeHelper.getNameByCode(this.code)];
        } else {
          // eslint-disable-next-line no-console
          console.error(`Impossible get message for name: ${ ErrorCodeHelper.getNameByCode(this.code) }`);
          this.displayMessage = this.message || messages.GENERAL_ERROR;
        }
      } catch (e) {
        // (AbortError) https://developer.mozilla.org/en-US/docs/Web/API/DOMException
        if (this.code !== 20 && this.name !== 'AbortError' && this.name !== 'ABORT_ERR') {
          // eslint-disable-next-line no-console
          console.error(`Impossible get error for code: ${ this.code }. Payload ${ JSON.stringify(payload) }`);
          this.displayMessage = this.message || 'Unknown error.';
        }
      }
    }
  }
}
