export const homeConfig = {
  features: {
    title: 'Your time is valuable, simplify 17g-5 compliance',
    description: `17g5.com is easy to setup and manage with all the functionality you need
              to get your files and users into and out of the data room as fast
              and securely as possible.`,
    collection: [
      [
        'Dedicated email uploading to any folder',
        '24x7 U.S. based phone & email support',
        'File & folder drag and drop uploading',
        'File & folder level permissioning',
        'File upload notifications by email',
        'GDPR compliant; SOC 2 Type II certified',
        'Instantly create new data rooms',
        'Keyword search within documents, across file types',
      ],
      [
        'Dedicated recorded audio uploading',
        'Mobile friendly',
        'One-click bulk downloading',
        'Search by file, folder and date uploaded',
        'View-only permissioning, no plug-ins required',
        'Watermarking',
        '"View data room as" user permission auditing',
      ],
    ],
    isExploreButtonExists: false,
  },
};
