import { IPermissionGroup } from '@/dataroom/domain/vo/permissionGroups/manage/PermissionGroup';
import { IUserItem, IUserItemPermissionGroup } from '@/dataroom/domain/vo/users/UserItem';
import { IUser, IUserPermissionGroup } from '@/dataroom/domain/vo/User';

export const isNoAccess = (user: IUser | IUserItem) => !!user.permissionGroups.filter(
  // @ts-ignore
  (permissionGroup) => permissionGroup.isNoAccess,
).length;

export const hasAccess = (user: IUser | IUserItem) => !isNoAccess(user);
export const hasNotBanned = (user: IUserItem) => !user.isBanned;

export const canManageSettings = (user: IUser | IUserItem) => user && hasAccess(user)
  && user.permissionGroups.some((permissionGroup) => (
    // TODO: Rewrite after naming on BE is fixed
    // On current user it is manage_settings, on users list it is isManageSettings
    permissionGroup.isManageSettings || permissionGroup.manage_settings
  ));

export const canManageSettingsLite = (user: IUser | IUserItem) => user && hasAccess(user)
  && user.permissionGroups.some((permissionGroup) => isPermissionGroupAdminLite(permissionGroup))
  && !user.permissionGroups.some((permissionGroup) => (
    permissionGroup.isManageSettings || permissionGroup.manage_settings
  ));

export const isDefaultAdmin = (user: IUser | IUserItem) => user && hasAccess(user)
  && user.permissionGroups.some((permissionGroup) => (
    permissionGroup.readonly &&
    (permissionGroup.isManageSettings || permissionGroup.manage_settings)
  ));

export const canAccessPrimary = (user: IUser | IUserItem) => user && hasAccess(user)
  && user.permissionGroups.some((permissionGroup) => !!permissionGroup.isAccessPrimary);

export const canAccessStaging = (user: IUser | IUserItem) => user && hasAccess(user)
  && user.permissionGroups.some((permissionGroup) => !!permissionGroup.isAccessStaging);
// @ts-ignore
export const canAccessQna = (user: IUser | IUserItem) => user && hasAccess(user) && user.canEditQnaAlerts;

export const canManageFiles = (user: IUser | IUserItem) => user && hasAccess(user)
  && user.permissionGroups.some((permissionGroup) => (
    permissionGroup.fixed_filesystem_permission === 'manage'
  ));

export const isGroupBelongToUser = (user: IUser, groupId: number):
  boolean => !!user.permissionGroups.some((group) => group.id === groupId);

export const canUserManageOwnSettings = (user: IUser): boolean => user?.mostOpenPermissions?.manage_own_settings;

export const canUserBulkDownload = (user: IUser): boolean => user.mostOpenPermissions.bulk_download;

export const canUserEditPermissionGroupLimits = (isEnabledQnaModule: boolean, user: IUser): boolean => (
  isEnabledQnaModule && user.mostOpenPermissions.manage_settings
);

export const canUserAccessStaging = (user: IUser): boolean => user.mostOpenPermissions.staging_access;

export const canUserAccessPrimary = (user: IUser): boolean => user.primary_access;

export const isPermissionGroupNoAccess = (group : IPermissionGroup): boolean => group?.isNoAccess;

export const isPermissionGroupDefaultViewer = (group: IPermissionGroup): boolean => group?.name === 'View & Download (All Files)';

export const isPermissionGroupAdminLite = (group: IUserPermissionGroup | IUserItemPermissionGroup): boolean => (
  // @ts-ignore
  group.isManageSettingsLite || group.manage_settings_lite
);

export const canManageOwnSettingsPermissionGroup = (group: IPermissionGroup): boolean => group.canManageOwnSettings;

export const filterNoAccessGroup = (group: IPermissionGroup): boolean => !group.isNoAccess;

export const filterManageGroup = (group: IPermissionGroup) => {
  // @ts-ignore
  return (group.isAdmin || group.isAdminLite || group.canManageFolders || group.isNoAccess);
};

export const getPermissionGroupIdsBelongsToUsers = (groups: IPermissionGroup[], users: IUserItem[]): number[] => {
  const hasUsersNoAccessGroup = users.some(isNoAccess);

  if (hasUsersNoAccessGroup) {
    return [];
  }

  return groups.filter((group) => (users.length ? users.every(
    (user) => user.permissionGroups.some(
      (userGroup) => userGroup.id === group.id,
    ),
  ) : false)).filter(filterNoAccessGroup).map((group) => group.id);
};

export const getIndeterminatePermissionGroupIdsForUsers = (groups: IPermissionGroup[],
                                                           users: IUserItem[]): number[] => {
  const hasUsersNoAccessGroup = users.some(isNoAccess);

  if (hasUsersNoAccessGroup) {
    return [];
  }

  return groups.filter((group) => {
    const usersWithGroup = users.filter(
      (user) => user.permissionGroups.some(
        (userGroup) => userGroup.id === group.id,
      ),
    );
    return usersWithGroup.length !== 0 && usersWithGroup.length !== users.length;
  }).filter(filterNoAccessGroup).map((group) => group.id);
};

export const getDisabledPermissionGroupIdsForUsers = (groups: IPermissionGroup[], users: IUserItem[],
                                                      currentUser: IUser) => {
  const hasUsersNoAccessGroup = users.some(isNoAccess);
  const isUserAdminLite = canManageSettingsLite(currentUser);

  if (hasUsersNoAccessGroup) {
    return groups.map((group) => group.id);
  }

  if (isUserAdminLite) {
    return groups.filter((group) => !isPermissionGroupDefaultViewer(group))
      .map((group) => group.id);
  }

  return groups.filter((group) => (
    users.some((user) => user.id === currentUser.id) &&
    canManageOwnSettingsPermissionGroup(group) &&
    isGroupBelongToUser(currentUser, group.id)
  )).filter(filterNoAccessGroup).map((group) => group.id);
};
