import React from 'react';
import cn from 'classnames';
import { EmailAlertsText, DigestEmailAlertsText } from '../EmailAlertsText';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';
import { IFileUploadEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/FileUploadEmailAlerts';
import { IStagingFileUploadEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/StagingFileUploadEmailAlerts';
import { IDigestEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/DigestEmailAlerts';
import { IAccessRequestEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/AccessRequestEmailAlerts';
import { IQuestionsEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/QuestionsEmailAlerts';
import styles from './emailAlertsTextTooltipContent.scss';

interface IProps {
  timezone: ITimezone,
  fileUploadAlerts: IFileUploadEmailAlerts,
  stagingFileUploadAlerts: IStagingFileUploadEmailAlerts,
  digestAlerts: IDigestEmailAlerts,
  accessRequestAlerts: IAccessRequestEmailAlerts,
  qnaAlerts: IQuestionsEmailAlerts,
}

const EmailAlertsTextTooltipContent = ({
  timezone,
  fileUploadAlerts,
  stagingFileUploadAlerts,
  digestAlerts,
  accessRequestAlerts,
  qnaAlerts,
}: IProps) => (
  <div className={ styles.tooltip }>
    <div className={ styles.row }>
      <div
        className={ cn(styles.title, {
          [styles.wide]: stagingFileUploadAlerts,
          [styles.extraWide]: qnaAlerts,
        }) }
      >
        File Upload:
      </div>
      <div className={ styles.text }>
        <EmailAlertsText
          alertSettings={ fileUploadAlerts }
          timezone={ timezone }
        />
      </div>
    </div>
    { digestAlerts && (
      <div className={ styles.row }>
        <div
          className={ cn(styles.title, {
            [styles.wide]: stagingFileUploadAlerts,
            [styles.extraWide]: qnaAlerts,
          }) }
        >
          Digest:
        </div>
        <div className={ styles.text }>
          <DigestEmailAlertsText
            alertSettings={ digestAlerts }
            timezone={ timezone }
          />
        </div>
      </div>
    ) }
    { stagingFileUploadAlerts && (
      <div className={ styles.row }>
        <div
          className={ cn(styles.title, {
            [styles.wide]: stagingFileUploadAlerts,
            [styles.extraWide]: qnaAlerts,
          }) }
        >
          Staging Upload:
        </div>
        <div className={ styles.text }>
          <EmailAlertsText
            alertSettings={ stagingFileUploadAlerts }
            timezone={ timezone }
          />
        </div>
      </div>
    ) }
    { accessRequestAlerts && (
      <div className={ styles.row }>
        <div
          className={ cn(styles.title, {
            [styles.wide]: stagingFileUploadAlerts,
            [styles.extraWide]: qnaAlerts,
          }) }
        >
          Request:
        </div>
        <div className={ styles.text }>
          <EmailAlertsText
            alertSettings={ accessRequestAlerts }
            timezone={ timezone }
          />
        </div>
      </div>
    ) }
    { qnaAlerts && (
      <div className={ styles.row }>
        <div
          className={ cn(styles.title, {
            [styles.wide]: stagingFileUploadAlerts,
            [styles.extraWide]: qnaAlerts,
          }) }
        >
          Q&A Alerts ({ qnaAlerts.allQuestions ? 'all questions' : 'your questions' }):
        </div>
        <div className={ styles.text }>
          <EmailAlertsText
            alertSettings={ qnaAlerts }
            timezone={ timezone }
          />
        </div>
      </div>
    ) }
  </div>
);

export default EmailAlertsTextTooltipContent;
