interface IParams {
  esmaEntitiesCount: number,
  esmaLimit: number,
  esmaAccountList: string[],
  currentAccount?: string,
}

export const fieldName = 'esmaAccount';

export default (esmaParams: IParams) => (values) => {
  if (
    esmaParams.esmaEntitiesCount > esmaParams.esmaLimit
    || (
      esmaParams.esmaLimit - esmaParams.esmaEntitiesCount < 1
      && esmaParams.currentAccount
      && esmaParams.esmaAccountList.every((account) => account !== values[fieldName]?.value)
    )
  ) {
    return { [fieldName]: 'Entity limit exceeded.' };
  }
  return null;
};
