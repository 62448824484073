import TenantConfig from '@/Framework/Tenant/TenantConfig';
import Logger from '@/Framework/browser/log/Logger';

const getSupportChatId = (): string => {
  const tenantConfig = TenantConfig.fromHostname();
  if (!tenantConfig) {
    return undefined;
  }
  if (!tenantConfig.supportChatId) {
    Logger.error(
      'Support chat ID for current tenant not found.',
      { tenantConfig },
    );
  }
  return tenantConfig.supportChatId;
};

export default getSupportChatId;
