import React, { useState } from 'react';
import { Button } from '@dealroadshow/uikit/core/components/Button';
import { Radio, RadioGroup } from '@dealroadshow/uikit/core/components/Radio';
import { Spinner } from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { Modal } from '@dealroadshow/uikit/core/components/Modal';
import styles from './bulkPinningModal.scss';
import { constants } from '../constants';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { useBulkPinningContext } from '@/dataroom/application/BulkPinningContext';
import { BulkPinningAction } from '@/dataroom/domain/vo/filesystem/BulkPinning';
import { isFolder } from '@/dataroom/domain/filesystem';

interface IProps {
  mode: BulkPinningAction,
  items: (IFilesystemListItem | IFolderTree)[],
  closeModal: () => void,
}

const BulkPinningModal = ({
  mode,
  items,
  closeModal,
}: IProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOption, setSelectedOption] = useState<boolean>(true);
  const { isFetching, pinItems, unpinItems } = useBulkPinningContext();
  const isPinMode = mode === BulkPinningAction.PIN;

  const hasOnlyFiles = items.every((item) => !isFolder(item));
  const hasFoldersAndFiles = items.some((item) => isFolder(item)) && !hasOnlyFiles;

  const { title, description, options, buttons } = isPinMode ? constants.pin : constants.unpin;

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      if (isPinMode) {
        await pinItems({
          items,
          isRecursive: selectedOption,
        });
      } else {
        await unpinItems({
          items,
          isRecursive: selectedOption,
        });
      }
      closeModal();
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRadioChange = (value: boolean) => {
    setSelectedOption(value);
  };

  return (
    <Modal
      title={ title }
      isVisible
      className={ styles.bulkPinningModal }
      onCloseClicked={ closeModal }
      dataTest="BulkPinningModal"
    >
      <div className={ styles.modalContent }>
        { hasOnlyFiles ? (
          <p className={ styles.description }>
            { description.withoutFolders }
          </p>
        ) : (
          <p className={ styles.description }>
            { description.withFolders }
          </p>
        ) }
      </div>
      { hasFoldersAndFiles && (
        <div className={ styles.modalActions }>
          <RadioGroup
            isNarrow
            selectedValue={ selectedOption }
            onChange={ handleRadioChange }
            className={ styles.switcherWrp }
            dataTest="customButtonTypeSwitchRadio"
          >
            <Radio
              label={ options.selectedAndAll }
              dataTest="dealFilesRadio"
              value
              className={ styles.switcherRadio }
            />
            <Radio
              label={ options.onlySelected }
              dataTest="externalLinkRadio"
              value={ false }
              className={ styles.switcherRadio }
            />
          </RadioGroup>
        </div>
      ) }
      <div className={ styles.modalActions }>
        <Button
          variant="action"
          onClick={ handleConfirm }
          dataTest="bulkPinningSubmit"
          disabled={ isFetching || isSubmitting }
        >
          { buttons.confirm }
        </Button>
        <Button
          variant="text"
          onClick={ closeModal }
          dataTest="bulkPinningCancel"
        >
          { buttons.cancel }
        </Button>
      </div>
      <Spinner overlay isVisible={ isFetching || isSubmitting } />
    </Modal>
  );
};

export default BulkPinningModal;
