import React from 'react';
import { TabLink as NextNavLink } from '@/Framework/Router/Next/Link';
import CountLabel from '@/dataroom/ui/common/CountLabel';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import getTabItems from './getTabItems';
import * as questionPermissions from '@/dataroom/domain/questionPermissions';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import styles from './questionTabs.scss';

const QuestionTabs = () => {
  const { currentUser } = useCurrentUserContext();
  const { dataroom } = useDataroomContext();

  const isQuestionManager = questionPermissions.isQuestionManager(currentUser);

  const questionItems = getTabItems(dataroom, isQuestionManager);
  const questionList = Object.values(questionItems)
    .filter((item) => item.isVisible);

  const getDataTestAttributes = (str) => {
    const strWithoutSpaces = str.replace(/\s/g, '');
    return strWithoutSpaces.charAt(0)
      .toLowerCase() + strWithoutSpaces.slice(1);
  };

  return (
    <>
      { questionList.map((item) => {
        const { icon: Icon } = item;

        return (
          <NextNavLink
            key={ item.key }
            to={ item.path }
            className={ styles.questionsItem }
            activeClassName={ styles.questionsItemActive }
            dataTest={ getDataTestAttributes(item.title) }
          >
            <Icon
              className={ styles.questionsItemIcon }
            />
            <span className={ styles.questionsItemText }>{ item.title }</span>
            <CountLabel
              className={ styles.countLabel }
              totalCount={ item.count }
              canShowCount={ !item.isCountLabelFetching && !!item.count }
            />
            { item.isCountLabelFetching && <Spinner className={ styles.countLabelSpinner } /> }
          </NextNavLink>
        );
      }) }
    </>
  );
};

export default QuestionTabs;
