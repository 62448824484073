import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import CategoryRepository from '@/dataroom/infrastructure/repository/questions/CategoryRepository';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { ICategory } from '@/dataroom/domain/questions/vo/Category';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';

export default function useCategories() {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [collection, setCollection] = useState<ICategory[]>([]);

  /**
   * Get collection
   */
  async function getCategoryList() {
    setIsFetching(true);

    const payload = {
      dataroomId: dataroom.id,
    };

    try {
      const categoryRepository = container.get<CategoryRepository>(CategoryRepository);
      const response = await categoryRepository.getCategoryListing(payload);

      setCollection(response.collection);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  }

  return {
    isFetching,
    collection,
    getCategoryList,
  };
}
