import React, { forwardRef } from 'react';
import cn from 'classnames';
import styles from './sectionContainer.scss';

interface IProps {
  children: React.ReactNode,
  size?: 'large' | 'middle' | 'small',
  className?: string,
  narrow?: boolean,
}

const SectionContainer = forwardRef(({ children, size = 'middle', className, narrow }: IProps, ref: React.Ref<HTMLDivElement>) => (
  <div
    ref={ ref }
    className={ cn(styles.container, className, {
      [styles.large]: size === 'large',
      [styles.small]: size === 'small',
      [styles.narrow]: narrow,
    }) }
  >
    { children }
  </div>
));

export default SectionContainer;
