import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import { withCache } from '@/Framework/withCache';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import CountryRepositoryI from '@/dealroadshow/infrastructure/repository/country/CountryRepositoryI';
import Country from '@/dealroadshow/domain/vo/Country';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';

@Dependencies(RpcDispatcher)
class CountryRepository implements CountryRepositoryI {
  constructor(private readonly rpc: typeof RpcDispatcher) {
  }

  @withCache
  async getCountries(): Promise<[Country?]> {
    let req = new Request('dm_portal.queries.get_country_list');

    let response = await this.rpc.call<RpcSuccess>(req);
    return response.getResult().payload;
  }
}

export default CountryRepository;
