import React, { useState } from 'react';
import cn from 'classnames';
import ActionButton from '@/Framework/UI/Atoms/Button/ActionButton';
import { variantTypes } from '@/Framework/UI/Atoms/Button/ActionButton/constants';
import { useExportToExcelContext } from '@/dataroom/application/ExportToExcelContext';
import { useFileUploaderContext } from '@/dataroom/application/FileUploader/FileUploaderContext';
import {
  convertUploadStatusesToSting,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/helpers/convertUploadTypes';
import styles from '@/Framework/UI/Atoms/Button/ExportToExcelButton/exportToExcelButton.scss';
import IconFileXls from '@dealroadshow/uikit/core/components/Icon/IconFileXls';
import { IconComponentType } from '@dealroadshow/uikit/core/components/Icon';

interface IProps {
  className?: string,
  exportName: string,
  isDisabled?: boolean,
  variant: string,
  label: string,
  icon: IconComponentType,
}

const ExportToExcelButton = ({
  exportName,
  className,
  isDisabled = false,
  variant,
  label,
  icon,
}: IProps) => {
  const { requestExportToExcel } = useExportToExcelContext();
  const { allFiles } = useFileUploaderContext();
  const [isExportCreating, setIsExportCreating] = useState(false);

  const exportFilesFormat = () => allFiles.map((file) => {
    const sizeInMB = parseFloat((file.size / (1024 * 1024)).toFixed(2));

    return {
      name: file.name,
      status: convertUploadStatusesToSting(file.upload.status),
      path: file.path,
      size: sizeInMB,
      errorMessage: file.upload?.errors[0]?.displayMessage || null,
    };
  });

  const handleExport = () => {
    const extraParams = { files: exportFilesFormat() };
    setIsExportCreating(true);

    requestExportToExcel(
      exportName,
      extraParams,
      () => {
        setIsExportCreating(false);
      },
    );
  };

  return (
    <ActionButton
      className={ cn(className, {
        [styles.iconButtonWrp]: variant === variantTypes.icon,
      }) }
      buttonClassName={ cn({
        [styles.iconButton]: variant === variantTypes.icon,
        [styles.isFetching]: isExportCreating,
        [styles.isDisabled]: isDisabled,
      }) }
      title="Export to Excel"
      isFetching={ isExportCreating }
      isDisabled={ isDisabled || isExportCreating }
      variant={ variant }
      onClick={ handleExport }
      dataTest="exportToExcelButton"
      label={ label }
      icon={ icon || IconFileXls }
    />
  );
};

export default ExportToExcelButton;
