import { IValidationError } from './interfaces/ValidationError';

interface IParams {
  value: string,
  fieldName: string,
  fieldCaption: string,
}

export const validateNumber = (params: IParams): IValidationError => {
  if (params.value && !/^[0-9]+$/i.test(params.value)) {
    return { [params.fieldName]: `${ params.fieldCaption || 'Field' } must be numeric` };
  }
  return null;
};
