import { fieldName } from '@/dataroom/ui/components/Settings/ManageUsers/Modals/EsmaModal/validator';
import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateRequiredFields } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredFields';

export default (values) => groupValidators(
  validateRequiredFields([
    { fieldName, fieldCaption: 'Regulatory Entity Account' },
    { fieldName: 'confirm', fieldCaption: 'Confirm' },
  ], values),
);
