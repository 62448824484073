import React, { useState, useEffect, ChangeEvent } from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import { getQuestionNotificationSummary } from '../helpers';
import { DAY_OF_WEEK_LIST, HOURS_OPTIONS } from '../common/constants';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import CheckboxField from '../common/CheckboxField';
import SelectField from '../common/SelectField';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';
import {
  IQuestionsNotificationSettingsPayload,
  IQuestionsRealtimeNotificationSettingsPayload,
} from '@/dataroom/domain/vo/emailAlerts/QuestionsEmailAlerts';
import RealTime from './RealTime';
import styles from './questionAlerts.scss';

interface IProps<T, R> {
  isFetching?: boolean,
  timezone?: ITimezone,
  initialValues?: T,
  initialRealtimeValues: R,
  name?: string,
  isDisabled?: boolean,
  onChange?: (values: T) => void,
  onRealtimeChange?: (values: R) => void,
  isQuestionManager: boolean,
  realtimeError: string,
  setRealtimeError: (errorMessage: string) => void,
}

const QuestionAlerts = <
  T extends IQuestionsNotificationSettingsPayload,
  R extends IQuestionsRealtimeNotificationSettingsPayload,
>({
  name = 'questionAlertsForm',
  initialValues,
  initialRealtimeValues,
  isFetching = false,
  isDisabled,
  timezone,
  onChange = noop,
  onRealtimeChange = noop,
  isQuestionManager,
  realtimeError,
  setRealtimeError,
}: IProps<T, R>) => {
  const [questionAlertsState, setQuestionAlertsState] = useState<T>(initialValues);
  const [realtimeAlertsState, setRealtimeAlertsState] = useState<R>(initialRealtimeValues);

  useEffect(() => {
    onChange(questionAlertsState);
  }, [questionAlertsState]);

  useEffect(() => {
    onRealtimeChange(realtimeAlertsState);
  }, [realtimeAlertsState]);

  useEffect(() => {
    setQuestionAlertsState(initialValues);
  }, [initialValues, isDisabled]);

  const validateRealtime = (realtimeAlerts: R) => {
    if (
      !realtimeAlerts.questionSubmitted &&
      !realtimeAlerts.replySubmitted &&
      !(isQuestionManager && (realtimeAlerts.noteSubmitted || realtimeAlerts.questionAssigned))
    ) {
      setRealtimeError('Select at least one item');
    } else {
      setRealtimeError('');
    }
  };

  useEffect(() => {
    if (questionAlertsState.isQuestionRealTime) {
      validateRealtime(realtimeAlertsState);
    } else {
      setRealtimeError('');
    }
  }, [questionAlertsState.isQuestionRealTime]);

  const handleCheckboxFieldChange = (
    event: ChangeEvent<{
      name: keyof T & `is${ string }`,
      checked: T[keyof T & `is${ string }`],
    }>,
  ) => {
    const {
      name: field,
      checked: value,
    } = event.target;

    const state = {
      ...questionAlertsState,
      [field]: value,
    };

    if (field === 'isActiveQuestionAlerts') {
      state.isActiveQuestionAlerts = true;
      state.isQuestionRealTime = false;
      state.isQuestionDaily = false;
      state.isQuestionWeekly = false;
    } else {
      state.isActiveQuestionAlerts = false;
    }

    if (
      !state.isActiveQuestionAlerts &&
      !state.isQuestionDaily &&
      !state.isQuestionWeekly &&
      !state.isQuestionRealTime
    ) {
      state.isActiveQuestionAlerts = true;
    }

    setQuestionAlertsState(state);
  };

  const handleRealtimeCheckboxChange = (
    event: ChangeEvent<{
      name: keyof R & `is${ string }`,
      checked: R[keyof R & `is${ string }`],
    }>,
  ) => {
    const {
      name: field,
      checked: value,
    } = event.target;

    const state = {
      ...realtimeAlertsState,
      [field]: value,
    };

    validateRealtime(state);

    setRealtimeAlertsState(state);
  };

  const handleSelectFieldChange = (
    name: keyof Omit<T, keyof T & `is${ string }`>,
    value: T[keyof Omit<T, keyof T & `is${ string }`>],
  ) => {
    if (!value) return;

    setQuestionAlertsState({
      ...questionAlertsState,
      [name]: value,
    });
  };

  return (
    <div data-test={ name }>
      <div className={ styles.itemAlerts }>
        <CheckboxField<T>
          name="isActiveQuestionAlerts"
          dataTest="noneCheckbox"
          label="None"
          disabled={ isDisabled }
          value={ questionAlertsState.isActiveQuestionAlerts }
          onChange={ handleCheckboxFieldChange }
        />
      </div>
      <div className={ styles.itemAlerts }>
        <CheckboxField<T>
          name="isQuestionRealTime"
          dataTest="realtimeCheckbox"
          label="Real-time"
          disabled={ isDisabled }
          value={ questionAlertsState.isQuestionRealTime }
          onChange={ handleCheckboxFieldChange }
        />
        { questionAlertsState.isQuestionRealTime && (
          <RealTime
            values={ realtimeAlertsState }
            handleChange={ handleRealtimeCheckboxChange }
            isQuestionManager={ isQuestionManager }
            realtimeError={ realtimeError }
          />
        ) }
      </div>
      <div className={ styles.itemAlerts }>
        <CheckboxField<T>
          name="isQuestionDaily"
          dataTest="dailyCheckbox"
          label="Daily summary"
          disabled={ isDisabled }
          value={ questionAlertsState.isQuestionDaily }
          onChange={ handleCheckboxFieldChange }
          className={ styles.dailyTimeCheckbox }
        />
        <div className={ cn(styles.inlineBlock, styles.wrpSelect) }>
          <SelectField<T>
            name="questionDailyTime"
            dataTest="questionDailyTimeSelect"
            selectClassName={ styles.timeSelect }
            options={ HOURS_OPTIONS }
            disabled={ !questionAlertsState.isQuestionDaily || isDisabled }
            value={ questionAlertsState.questionDailyTime }
            onChange={ handleSelectFieldChange }
          />
        </div>
      </div>
      <div className={ styles.itemAlerts }>
        <CheckboxField<T>
          name="isQuestionWeekly"
          dataTest="weeklyCheckbox"
          label="Weekly summary"
          disabled={ isDisabled }
          value={ questionAlertsState.isQuestionWeekly }
          onChange={ handleCheckboxFieldChange }
        />
        <div className={ cn(styles.inlineBlock, styles.wrpSelect) }>
          <SelectField<T>
            name="questionWeeklyTime"
            dataTest="questionWeeklyTimeSelect"
            selectClassName={ styles.timeSelect }
            options={ HOURS_OPTIONS }
            disabled={ !questionAlertsState.isQuestionWeekly || isDisabled }
            value={ questionAlertsState.questionWeeklyTime }
            onChange={ handleSelectFieldChange }
          />
        </div>
        <div
          className={ cn(
            styles.inlineBlock,
            styles.dayWeek,
          ) }
        >
          <SelectField<T>
            name="questionWeeklyDay"
            dataTest="questionWeeklyDaySelect"
            selectClassName={ styles.selectDaysOfWeek }
            options={ DAY_OF_WEEK_LIST }
            disabled={ !questionAlertsState.isQuestionWeekly || isDisabled }
            value={ questionAlertsState.questionWeeklyDay }
            onChange={ handleSelectFieldChange }
          />
        </div>
      </div>
      { timezone && (
        <div className={ styles.footer }>
          <span className={ styles.strong }>
            Summary:
          </span> { getQuestionNotificationSummary(questionAlertsState, timezone.abbr) }
        </div>
      ) }
      <Spinner
        overlay
        isVisible={ isFetching }
      />
    </div>
  );
};

export default QuestionAlerts;
