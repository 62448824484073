import React, { createContext, useContext, useState } from 'react';

const useQuestionExplorer = () => {
  const [isPristine, setIsPristine] = useState(true);
  const [getCollection, setGetCollectionState] = useState<() => void>(() => {});
  const [reset, setResetState] = useState(() => {});
  const [softReset, setSoftResetState] = useState(() => {});

  const setGetCollection = (getCollection) => {
    setGetCollectionState(() => getCollection);
  };
  const setReset = (reset) => {
    setResetState(() => reset);
  };
  const setSoftReset = (softReset) => {
    setSoftResetState(() => softReset);
  };

  return {
    isPristine,
    setIsPristine,
    getCollection,
    setGetCollection,
    reset,
    setReset,
    softReset,
    setSoftReset,
  };
};

type QuestionExplorerContextType = ReturnType<typeof useQuestionExplorer>;

export const QuestionsExplorerContext = createContext<QuestionExplorerContextType>(null);

export function useQuestionsExplorerContext() {
  const context = useContext(QuestionsExplorerContext);
  if (!context) {
    throw new Error('useQuestionsExplorerContext must be used within a QuestionsExplorerContext');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function QuestionsExplorerContextProvider({ children }: IProps) {
  return (
    <QuestionsExplorerContext.Provider value={ useQuestionExplorer() }>
      { children }
    </QuestionsExplorerContext.Provider>
  );
}

export default QuestionsExplorerContextProvider;
