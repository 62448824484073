import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import { IFileUploadEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/FileUploadEmailAlerts';
import { IAccessRequestItem } from '@/dataroom/domain/vo/accessRequests/AccessRequestItem';
import { IApprovePermissionGroupsItem } from '@/dataroom/domain/vo/accessRequests/ApprovePermissionGroupsItem';

@Dependencies(JsonRpcDispatcherFactory)
class AccessRequestsRepository {
  constructor(private readonly rpc: typeof JsonRpcDispatcherFactory) {
  }

  getAccessRequestsCount = async (
    payload: {
      dataroomId: number,
      status: string,
    },
  ): Promise<{ count: number }> => {
    const request = new Request('dataroom.restricted.access_request.count', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getRequests = async (
    payload: {
      dataroomId: number,
      status: string,
      search: string,
      sortBy: string,
      sortOrder: string,
      page: number,
      perPage: number,
    },
  ): Promise<IAccessRequestItem[]> => {
    const request = new Request('dataroom.restricted.access_request.list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getApprovePermissionGroups = async (
    payload: {
      dataroomId: number,
      sortBy: string,
      sortOrder: string,
      page: number,
      perPage: number,
    },
  ): Promise<IApprovePermissionGroupsItem[]> => {
    const request = new Request('dataroom.restricted.access_request.approve.permission_groups', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  approve = async (
    payload: {
      dataroomId: number,
      permissionGroupIds: number[],
      emails: string[],
      fileUploadAlerts: IFileUploadEmailAlerts,
      notification: {
        send: boolean,
        customMessage: string,
        customSubject: string,
        contactName?: string,
        contactEmail?: string,
      },
    },
  ): Promise<boolean> => {
    const request = new Request('dataroom.restricted.access_request.approve', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  getPendingRequestEmails = async (
    payload: {
      dataroomId: number,
    },
  ): Promise<{ collection: [{ email: string }] }> => {
    const request = new Request('dataroom.restricted.access_request.approve.emails', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  revert = async (
    payload: {
      dataroomId: number,
      accessRequests: number[],
    },
  ): Promise<boolean> => {
    const request = new Request('dataroom.restricted.revert', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  deny = async (
    payload: {
      dataroomId: number,
      accessRequests: number[],
      notification: {
        send: boolean,
        customMessage: string,
        customSubject: string,
        contactName?: string,
        contactEmail?: string,
      },
    },
  ): Promise<boolean> => {
    const request = new Request('dataroom.restricted.deny', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  addToWhitelist = async (
    payload: {
      dataroomId: number,
      accessRequests: number[],
    },
  ): Promise<IAccessRequestItem[]> => {
    const request = new Request('dataroom.restricted.access_request.whitelist_domain', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };
}

export default AccessRequestsRepository;
