import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { ICategory } from '@/dataroom/domain/questions/vo/Category';

@Dependencies(JsonRpcDispatcherFactory)
class CategoryRepository {
  constructor(private rpc: typeof JsonRpcDispatcherFactory) {
  }

  getCategoryListing = async (
    payload: { dataroomId: number },
  ): Promise<{ collection: ICategory[] }> => {
    const request = new Request('qna.category.list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  createCategory = async (
    payload: {
      dataroomId: number,
      name: string,
    },
  ): Promise<null> => {
    const request = new Request('qna.category.create', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  updateCategory = async (
    payload: {
      dataroomId: number,
      categoryId: string,
      name: string,
    },
  ): Promise<null> => {
    const request = new Request('qna.category.update', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  deleteCategory = async (
    payload: {
      dataroomId: number,
      categoryId: string,
    },
  ): Promise<void> => {
    const request = new Request('qna.category.delete', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };
}

export default CategoryRepository;
