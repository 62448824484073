import React from 'react';
import withDataroomGlobalProps from '@/dataroom/infrastructure/next/withDataroomGlobalProps';
import Layout from '@/dataroom/ui/components/Landing/17g5';
import HomeComponent from '@/dataroom/ui/components/Landing/17g5/Home';

export const getServerSideProps = withDataroomGlobalProps(() => ({ props: {} }));

const Home = () => (
  <Layout>
    <HomeComponent />
  </Layout>
);

export default Home;
