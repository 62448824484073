import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';
import { conflictTypes } from '@/dataroom/ui/common/DataroomExplorer/Modals/ConflictModal/constants';
import CopyMoveModal from '@/dataroom/ui/common/DataroomExplorer/Modals/CopyMoveModal/CopyMoveModal';
import { Action } from '@/dataroom/domain/vo/filesystem/Action';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';

interface IProps {
  items: IFolderTree[],
  closeModal: () => void,
}

const MoveModal = ({ items, closeModal }: IProps) => {
  const { move } = useFilesystemContext();

  if (isEmpty(items)) {
    return null;
  }

  return (
    <CopyMoveModal
      { ...move }
      filesystemItems={ items }
      title={
        items.length === 1
          ? `Move: "${ items[0].name }"`
          : `Move ${ items.length } Selected Items`
      }
      closeCopyMoveModal={ () => {
        closeModal();
        move.reset();
      } }
      actionName={ Action.Move }
      conflictType={ conflictTypes.move }
    />
  );
};

export default MoveModal;
