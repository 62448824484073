import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import AbstractDataroomRepository from '@/dataroom/infrastructure/repository/AbstractDataroomRepository';
import { IQuestion } from '@/dataroom/domain/questions/vo/Question';
import { ICreateQuestion } from '@/dataroom/domain/questions/vo/CreateQuestion';
import { IFile } from '@/dataroom/domain/questions/vo/File';
import { IManager } from '@/dataroom/domain/questions/vo/Manager';
import { ISubmitter } from '@/dataroom/domain/questions/vo/Submitter';
import { IPermissionGroup } from '@/dataroom/domain/questions/vo/PermissionGroup';
import { IThreadEvent } from '@/dataroom/domain/questions/vo/ThreadEvent';
import { IQuestionsSummary } from '@/dataroom/domain/questions/vo/QuestionsSummary';
import { ReferenceType } from '@/dataroom/domain/questions/vo/types/ReferenceType';

@Dependencies(JsonRpcDispatcherFactory)
class QuestionRepository extends AbstractDataroomRepository {
  constructor(protected rpc: typeof JsonRpcDispatcherFactory) {
    super(rpc);
  }

  getQuestionListing = async (
    payload: {
      dataroomId: number,
      sortBy: string,
      categoryId: string,
      search: string,
      page : number,
      perPage: number,
      status: string,
    },
  ): Promise<{
    collection: IQuestion[],
    totalCount: number,
  }> => {
    const request = new Request('qna.question.list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  /**
   * Gets question by id.
   */
  getQuestionInfo = async (payload: {
    dataroomId: number,
    questionId: string,
  }): Promise<IQuestion> => {
    const request = new Request('qna.question', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getSharedQuestionListing = async (
    payload: {
      dataroomId: number,
      sortBy: string,
      categoryId: string,
      search: string,
      page : number,
      perPage: number,
      isResolved: boolean,
    },
  ): Promise<{
    collection: IQuestion[],
    totalCount: number,
  }> => {
    const request = new Request('qna.question.shared_list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  createQuestions = async (
    payload: {
      dataroomId: number,
      questions: ICreateQuestion[],
    },
  ): Promise<void> => {
    const request = new Request('qna.question.create', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult();
  };

  updateQuestion = async (
    payload: {
      dataroomId: number,
      questionId: string,
      priority: string,
      categoryId: string,
    },
  ): Promise<void> => {
    const request = new Request('qna.question.update', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  assignQuestions = async (
    payload: {
      dataroomId: number,
      questionIds: string[],
      contactIds: number[],
    },
  ): Promise<void> => {
    const request = new Request('qna.question.assign', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  shareQuestion = async (
    payload: {
      dataroomId: number,
      questionId: string,
      contactIds: number[],
      text: string,
      permissionGroupIds: number[],
      references: { id: number, type: ReferenceType }[],
    },
  ): Promise<void> => {
    const request = new Request('qna.question.share', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  deleteQuestions = async (
    payload: {
      dataroomId: number,
      questionIds: string[],
    },
  ): Promise<void> => {
    const request = new Request('qna.question.delete', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  resolveQuestions = async (
    payload: {
      dataroomId: number,
      questionIds: string[],
    },
  ): Promise<void> => {
    const request = new Request('qna.question.resolve', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  reopenQuestions = async (
    payload: {
      dataroomId: number,
      questionIds: string[],
    },
  ): Promise<void> => {
    const request = new Request('qna.question.reopen', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  getFiles = async (
    payload: {
      dataroomId: number,
      search: string,
      page : number,
      perPage : number,
    },
  ): Promise<{ collection: IFile[] }> => this.abortableCall(new Request('qna.filesystem.search', payload));

  getManagers = async (
    payload: {
      dataroomId: number,
    },
  ): Promise<{ collection: IManager[] }> => {
    const request = new Request('qna.user.manager.list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getSubmitters = async (
    payload: {
      dataroomId: number,
      questionId: string,
    },
  ): Promise<{ collection: ISubmitter[] }> => {
    const request = new Request('qna.user.submitter.share_with_list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getPermissionGroups = async (
    payload: {
      dataroomId: number,
      questionId: string,
    },
  ): Promise<IPermissionGroup[]> => {
    const request = new Request('qna.question.share.permission_groups', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getThreadEvents = async (
    payload: {
      dataroomId: number,
      questionId: string,
    },
  ): Promise<IThreadEvent[]> => {
    const request = new Request('qna.question.thread_event.list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getQuestionsSummary = async (
    payload: {
      dataroomId: number,
      search: string,
    },
  ): Promise<IQuestionsSummary> => {
    const request = new Request('qna.snapshot.category.question.summary', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getUsersSharedWithByQuestion = async (
    payload: {
      dataroomId: number,
      questionId: string,
    },
  ): Promise<ISubmitter> => {
    const request = new Request('qna.question.users_question_shared_with', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getPGSharedWithByQuestion = async (
    payload: {
      dataroomId: number,
      questionId: string,
    },
  ): Promise<{ collection: IPermissionGroup[] }> => {
    const request = new Request('qna.question.share.shared_permission_groups', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getContactsSharedWithByPG = async (
    payload: {
      dataroomId: number,
      questionId: string,
      permissionGroupId: number,
    },
  ): Promise<ISubmitter[]> => {
    const request = new Request('qna.question.share.shared_permission_groups.contacts', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };
}

export default QuestionRepository;
