import React from 'react';
import cn from 'classnames';
import styles from './name.scss';
import FileWithUploadAndPath from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/FileWithUploadAndPath';

interface IProps {
  cellProps: {
    row: FileWithUploadAndPath,
  },
  className?: string,
}

const Name = ({ cellProps, className = '' }: IProps) => {
  const file = cellProps.row;
  let fileName;
  let extension;
  const name = file.name.split('.');

  if (name.length > 1) {
    fileName = file.name.slice(0, file.name.lastIndexOf('.'));
    extension = name[name.length - 1];
  } else {
    fileName = file.name;
    extension = '';
  }

  return (
    <div className={ styles.fileName }>
      <span className={ cn(className, styles.name) }>
        { fileName }
      </span>
      <span className={ styles.extension }>.{ extension }</span>
    </div>
  );
};

export default Name;
