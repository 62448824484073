import React from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import headersStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import styles from './uploadDone.scss';
import { useFileUploaderContext } from '@/dataroom/application/FileUploader/FileUploaderContext';
import { useCurrentFolderContext } from '@/dataroom/application/CurrentFolderContext';
import getFileText from '../../helpers/fileText';
import getFolderText from '../../helpers/folderText';
import {
  ExportToExcelButton,
  variantTypes as exportToExcelButtonVariantTypes,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderForm/ExportToExcelButton';
import IconDownload from '@dealroadshow/uikit/core/components/Icon/IconDownload';
import IconSuccessfullyUploaded from '@dealroadshow/uikit/core/components/Icon/IconSuccessfullyUploaded';

interface IProps {
  foldersNumber: number,
}

const UploadDoneSection = ({
  foldersNumber,
}: IProps) => {
  const {
    getSuccessFiles,
    allFiles,
  } = useFileUploaderContext();

  const { currentFolder } = useCurrentFolderContext();

  const successFiles = getSuccessFiles();

  const filesWithErrors = allFiles.filter((file) => file.upload.isFailure() || !isEmpty(file.upload.errors));

  const successFileText = getFileText(successFiles);
  const errorFileText = getFileText(filesWithErrors);
  const folderText = getFolderText(foldersNumber);

  return (
    <div
      className={ styles.uploadDoneWrapper }
      data-test="uploadDoneSection"
    >
      <IconSuccessfullyUploaded
        className={ styles.icon }
      />
      <div className={ cn(headersStyles.isH1, styles.title) }>
        Success!
      </div>
      <div className={ cn(styles.subtitle) }>
        <span className={ styles.textBold }>{ successFileText }</span> and{ ' ' }
        <span className={ styles.textBold }>{ folderText }</span>{ ' ' }
        were successfully uploaded to{ ' ' }
        <span className={ styles.textBold }>{ currentFolder.name }</span>.
      </div>
      { !!filesWithErrors.length && (
        <div className={ cn(styles.subtitle, styles.subtitleError) }>
          <span className={ styles.textBold }>{ errorFileText }</span> had errors and{ ' ' }
          { filesWithErrors.length > 1 ? 'were' : 'was' } not uploaded.
        </div>
      ) }
      <div className={ cn(styles.subtitle) }>
        Download the xls report for additional details.
      </div>
      <ExportToExcelButton
        exportName="file_upload"
        variant={ exportToExcelButtonVariantTypes.textWithIcon }
        label="Download XLS Report"
        icon={ IconDownload }
      />
    </div>
  );
};

export default UploadDoneSection;
