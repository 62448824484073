import React from 'react';
import cn from 'classnames';
import Head from 'next/head';
import baseStyles from '@/dataroom/ui/components/Landing/dealvdr/tenantDealvdrStyles.scss';
import BookDemoButton from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoModalLegacy/BookDemoButton';
import GetStartedButton from '@/dataroom/ui/components/Landing/common/GetStartedButton';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';
import Support from '@/Framework/UI/Pages/Landing/Sections/Support';
import Features from '@/dataroom/ui/components/Landing/Legacy/Sections/Features';
import LearnMore from '../LearnMore';
import FooterPattern from '@/Framework/UI/Pages/Landing/Sections/FooterPattern';
import { homeConfig } from './constants';
import introAnimation from './assets/introAnimation.gif';
// Patterns
import introPattern from '@/dataroom/ui/assets/introPattern.svg';
import supportStamp from './assets/supportStamp.svg';
// Styles
import commonHomeStyles from '@/dataroom/ui/components/Landing/common/homeSection.scss';
import styles from './home.scss';

const Home = () => (
  <div className={ styles.homeWrp }>
    <Head>
      <title>Secure Virtual Data Room | 17g5.com</title>
      <meta
        name="description"
        content={
          'Industry-leading data room that streamlines compliance with SEC Rule 17g5. ' +
          'Setup instantly; upload by email, drag & drop & recorded conference lines.'
        }
      />
    </Head>
    <div className={ cn(commonHomeStyles.intro, styles.intro) }>
      <div className={ commonHomeStyles.introContainer }>
        <div className={ commonHomeStyles.introContent }>
          <h1 className={ commonHomeStyles.introTitle }>Streamline your<br />17g-5 compliance</h1>

          <div className={ cn(commonHomeStyles.introDescription, styles.description) }>
            <p>
              <strong>17g5.com</strong> is a secure and cost-effective virtual data room
              for SEC Rule 17g-5 compliance. It’s used by hundreds of issuers
              in the US ABS market, all major underwriters and rating agencies
              and is backed by <strong>fanatical 24x7 support</strong>.
            </p>
            <p>Book a demo or get started now.</p>
          </div>

          <BookDemoButton />
          <GetStartedButton className={ cn(baseStyles.secondaryBtn, spacesStyles.mrn) } />
        </div>
        <div className={ commonHomeStyles.introAnimation }>
          <div className={ commonHomeStyles.introAnimationContainer }>
            <img src={ introAnimation } width={ 608 } height={ 460 } />
          </div>
        </div>
      </div>
      <div className={ commonHomeStyles.introPattern }>
        <img src={ introPattern } />
      </div>
    </div>
    <Features { ...homeConfig.features } />
    <div className={ cn(commonHomeStyles.support, styles.support) }>
      <Support />
      <div className={ commonHomeStyles.supportStamp }>
        <img src={ supportStamp } />
      </div>
    </div>
    <LearnMore />
    <FooterPattern />
  </div>
);

export default Home;
