import { useRef, useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Link as NextLink, TabLink as NextNavLink } from '@/Framework/Router/Next/Link';
import cn from 'classnames';
import throttle from 'lodash/throttle';
import HeaderBanner from '@/Framework/UI/Organisms/HeaderBanner';
import isDefined from '@/Framework/dataHelpers/isDefined';
import useElementSize from '@/Framework/hooks/useElementSize';
import { Tenant } from '@/Framework/Tenant/vo/types/Tenant';
import config from '@/Framework/config';

import hInteraction from '@dealroadshow/uikit/core/styles/helpers/interaction.scss';
import styles from './header.scss';
import IconLock from '@dealroadshow/uikit/core/components/Icon/IconLock';
import IconClose from '@dealroadshow/uikit/core/components/Icon/IconClose';
import IconHamburgerMenu from '@dealroadshow/uikit/core/components/Icon/IconHamburgerMenu';

interface IProps {
  tenant: Tenant,
  logo: string,
  menu?: {
    key: string,
    title: string,
    path: string,
  }[],
  button?: () => React.ReactNode,
  loginButton?: () => React.ReactNode,
  loginUrl?: string,
  registerUrl?: string,
  isNext?: boolean,
  animatedHeader?: boolean,
}

/**
 * Usage: Dealroadshow, Evercall
 *
 * @param {Object} props
 */
const Header = ({
  menu: propMenu = [],
  button = null,
  isNext = false,
  ...otherProps
}: IProps) => {
  const props = {
    menu: propMenu,
    button,
    isNext,
    ...otherProps,
  };
  /**
   * @type {Component}
   */
  const HeaderLink = props.isNext ? NextLink : Link;
  /**
   * @type {Component}
   */
  const HeaderNavLink = props.isNext ? NextNavLink : NavLink;

  const headerRef = useRef(null);
  const lastScrollPos = useRef(0);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const { height: headerHeight } = useElementSize('landingHeader');

  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [isMobileMenuOpened, setMobileMenuOpened] = useState(false);

  const isDealroadshow = props.tenant === config.tenant.dealroadshow.code;
  const isEvercall = props.tenant === config.tenant.tenantEvercall.code;

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);
    window.addEventListener('resize', onResize);

    return () => {
      document.removeEventListener('scroll', handleScroll, true);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  // Hide or show the menu.
  const handleScroll = throttle(() => {
    setIsHeaderFixed(window.scrollY > 0);
    // Fix for throttled scroll on redirect
    if (!headerRef.current) {
      return;
    }

    const currentScroll = document.documentElement.scrollTop;
    const isSticky = currentScroll > headerRef.current.clientHeight;

    if (isSticky) {
      if (currentScroll > 0 && lastScrollPos.current <= currentScroll) {
        lastScrollPos.current = currentScroll;
        setIsHeaderVisible(false);
      } else {
        lastScrollPos.current = currentScroll;
        setIsHeaderVisible(true);
      }
    } else {
      setIsHeaderVisible(true);
    }
  }, 100);

  const onResize = throttle(() => {
    handleMobileMenuToggle(false);
  }, 100);

  const handleMobileMenuToggle = (toggle?: boolean) => {
    toggle = isDefined(toggle) ? toggle : !isMobileMenuOpened;
    setMobileMenuOpened(toggle);
    // IE11 Fix
    if (toggle) {
      document.body.classList.add(hInteraction.nonScrollable);
    } else {
      document.body.classList.remove(hInteraction.nonScrollable);
    }
  };

  const handleMobileMenuLink = () => {
    if (isMobileMenuOpened) {
      handleMobileMenuToggle(false);
    }
  };

  const renderLoginButton = () => {
    const {
      loginButton,
      loginUrl,
    } = props;

    if (loginButton) {
      return loginButton();
    }

    if (loginUrl) {
      return (
        <a
          suppressHydrationWarning
          href={ loginUrl }
          className={ cn(styles.menuLink, styles.menuIconLink, styles.menuLoginButton) }
          data-test="headerLogIn"
        >
          <IconLock
            className={ styles.menuLoginIcon }
          />
          Log In
        </a>
      );
    }

    return null;
  };

  const renderRegisterButton = () => (props.registerUrl ? (
    <a
      suppressHydrationWarning
      href={ props.registerUrl }
      className={ styles.menuLink }
      data-test="headerRegister"
    >
      Register
    </a>
  ) : null);

  const renderHeaderButton = () => {
    const { button } = props;

    return button ? (
      <span
        className={ styles.headerBtn }
        onClick={ () => handleMobileMenuToggle(false) }
      >
        { button && button() }
      </span>
    ) : null;
  };

  const renderHeaderActions = (): React.ReactElement => (
    <>
      { !isEvercall && renderLoginButton() }
      { !isEvercall && !isDealroadshow && renderRegisterButton() }
      { renderHeaderButton() }
    </>
  );

  const renderMobileHeaderActions = (): React.ReactElement => (
    <>
      { !isEvercall && !isDealroadshow && renderRegisterButton() }
      { renderHeaderButton() }
    </>
  );

  const renderMenu = (menu: { [key: string]: any }[]): React.ReactElement[] => menu.map((menuItem) => (
    <HeaderNavLink
      key={ menuItem.key }
      to={ menuItem.path }
      title={ menuItem.title }
      className={ styles.menuLink }
      activeClassName={ styles.isMenuLinkActive }
      isExactActive
      { ...(menuItem?.isActive && { isActive: menuItem.isActive() }) }
      onClick={ handleMobileMenuLink }
    >
      { menuItem.title }
    </HeaderNavLink>
  ));

  const headerCls = cn(`tenant-${ props.tenant }`, {
    [styles.animatedHeader]: props.animatedHeader,
    [styles.isHeaderHidden]: !isHeaderVisible,
  });

  const menuCls = cn(styles.menu, {
    [styles.isMobileMenu]: isMobileMenuOpened,
  });

  const { menu } = props;
  const sortedMenu = [...menu].sort((prev, next) => (prev.title > next.title ? 1 : -1));

  return (
    <>
      { isMobileMenuOpened && (
        <div
          className={ styles.headerOverlay }
          onClick={ () => handleMobileMenuToggle(false) }
        />
      ) }
      { isHeaderFixed && <div style={ { height: headerHeight } } /> }
      <div
        className={ cn(styles.header, {
          [styles.headerFixed]: isHeaderFixed,
        }) }
        id="landingHeader"
      >
        <HeaderBanner isSticky={ false } />
        <div className={ headerCls } ref={ headerRef }>
          <div className={ styles.headerWrp }>
            <div className={ styles.headerContainer }>
              <div>
                <div
                  className={ cn(styles.burger, {
                    [styles.tenantDealroadshow]: props.tenant === config.tenant.dealroadshow.code,
                  }) }
                  onClick={ () => handleMobileMenuToggle() }
                >
                  { !isMobileMenuOpened ? <IconHamburgerMenu /> : <IconClose /> }
                </div>
                <div className={ styles.logo }>
                  <HeaderLink
                    to="/"
                    onClick={ () => handleMobileMenuToggle(false) }
                  >
                    <img
                      alt="Logo"
                      src={ props.logo }
                    />
                  </HeaderLink>
                </div>
                <div className={ menuCls }>
                  <div className={ styles.headerMenu }>
                    { renderMenu(menu) }
                  </div>
                  <div className={ styles.mobileHeaderMenu }>
                    { renderMenu(sortedMenu) }
                  </div>
                  <div className={ styles.mobileHeaderUserActions }>
                    { renderMobileHeaderActions() }
                  </div>
                </div>
              </div>
              <div className={ styles.headerActions }>
                <div className={ styles.headerUserActions }>
                  { renderHeaderActions() }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
