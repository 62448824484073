import { FileWithPath } from 'file-selector';
import trimChar from '@/Framework/dataHelpers/string/trimChar';
import Upload from './Upload';
import Folder, { serializeReducer } from './Folder';
import { getPathArrayFromEntry, isValidName, removeNameFromPathWithName } from '../helpers';
import { WithChildren } from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/WithChildren';

export default class FileSystemStructure {
  children: Array<Folder | Upload> = [];

  addEntry(entry: FileSystemDirectoryEntry | FileWithPath) {
    const pathArray = getPathArrayFromEntry(entry);
    if (entry instanceof File) {
      removeNameFromPathWithName(pathArray);
    }

    let level = 0;
    let currentExisting: WithChildren = this;
    let currentPathPart = pathArray[level];

    while (currentPathPart) {
      let name = trimChar(currentPathPart, '/');

      let found = currentExisting.children.find((child) => child instanceof Folder && child.name === name) as Folder;
      if (found) {
        currentExisting = found;
      } else {
        let folder = new Folder(currentPathPart, currentExisting);
        currentExisting.children.push(folder);
        currentExisting = folder;
      }

      level++;
      currentPathPart = pathArray[level];
    }

    // is last level and is file
    // @ts-ignore
    if (!entry.isDirectory) {
      let upload = new Upload(entry as FileWithPath, currentExisting);

      //  (if the user adds a file or folder with the same name into the same folder path,
      //  it will not duplicate but instead replace the original with the latest version uploaded).
      FileSystemStructure.removeFileFromArray(currentExisting, entry);

      currentExisting.children.push(upload);
    }
  }

  static removeFileFromArray(item, file) {
    item.children = item.children.filter((child) => child.children || (child instanceof Upload && child.file !== file));
  }

  /**
   * @param callback {function}
   */
  forEachFolder(callback) {
    const forEachChild = (item, callback) => {
      if (item instanceof Upload) {
        return;
      }
      callback(item);
      item.children.forEach((child) => {
        forEachChild(child, callback);
      });
    };
    for (let i = 0; i < this.children.length; i++) {
      let child = this.children[i];
      forEachChild(child, callback);
    }
  }

  /**
   * @param file {FileSystemEntry|File}
   */
  removeFile(file) {
    // @ts-ignore
    this.children = this.children.filter((child) => child.children || (child instanceof Upload && child.file !== file));
    this.forEachFolder((item) => FileSystemStructure.removeFileFromArray(item, file));
  }

  countFolders() {
    let count = 0;
    this.forEachFolder(() => count++);
    return count;
  }

  countValidFolders() {
    let count = 0;
    const isFolderValid = (folder) => (
      folder?.parent?.name
        ? isValidName(folder.name) && isFolderValid(folder?.parent)
        : isValidName(folder.name));

    this.forEachFolder((folder) => isFolderValid(folder) && count++);
    return count;
  }

  serialize() {
    return this.children.reduce(serializeReducer, []);
  }
}
