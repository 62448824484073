import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateRequiredFields } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredFields';
import { validateLink } from '@/Framework/UI/Organisms/FinalForm/validators/validateLink';
import { validateFilesystemItemName } from '@/Framework/UI/Organisms/FinalForm/validators/validateFilesystemItemName';

const fieldsList = [
  { fieldName: 'name', fieldCaption: 'Display text' },
  { fieldName: 'href', fieldCaption: 'URL' },
];

export default (values) => groupValidators(
  validateRequiredFields(
    fieldsList,
    {
      name: values.name?.trim(),
      href: values.href?.trim(),
    },
  ),
  validateMaxLength({
    value: values.name,
    length: 235,
    fieldName: 'name',
    fieldCaption: 'Display text',
  }),
  validateLink({
    value: values.href,
    fieldName: 'href',
    fieldCaption: 'URL',
  }),
  validateFilesystemItemName({
    value: values.name,
    fieldName: 'name',
    fieldCaption: 'Display text',
  }),
);
