import { Method } from 'constitute';
import { isDataroom } from '@/dataroom/domain/config';
import { DataroomProcessingAdapter } from '@dealroadshow/file-uploader';
import SocketClient from '../Socket/Client';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import Container from '@/Framework/DI/Container';

class FactoryBuilder {
  container: Container;

  constructor(container) {
    this.container = container;
  }

  getFactory() {
    return (tenantCode: DataroomTenant) => {
      if (!tenantCode) {
        throw 'Tenant is required to perform dataroom api requests';
      }
      if (!isDataroom(tenantCode)) {
        throw `Only dataroom tenants allowed. ${ tenantCode } provided`;
      }

      const socketClientFactory = this.container.get<typeof SocketClient>(SocketClient);
      const socketClient = socketClientFactory(tenantCode);
      return new DataroomProcessingAdapter(socketClient);
    };
  }
}

export default new Method(
  (container: Container) => {
    const factoryBuilder = new FactoryBuilder(container);
    return factoryBuilder.getFactory();
  },
  [Container],
) as ReturnType<InstanceType<typeof FactoryBuilder>['getFactory']>;
