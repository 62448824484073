import { useState, useMemo } from 'react';
import isBoolean from 'lodash/isBoolean';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useDIContext } from '@/Framework/DI/DIContext';
import QuestionsLimitsRepository from '@/dataroom/infrastructure/repository/questions/QuestionsLimitsRepository';
import { ICreateQuestion } from '@/dataroom/domain/questions/vo/CreateQuestion';
import { Priority } from '@/dataroom/domain/questions/vo/types/Priority';
import { IQuestionPriorities } from '@/dataroom/domain/questions/vo/QuestionPriorities';

enum LimitOption {
  Low = 'lowPriorityRemain',
  Medium = 'mediumPriorityRemain',
  High = 'highPriorityRemain',
  MaxQuantity = 'maxQuantityRemain',
}

interface IUpdateLimitsProps {
  questions: ICreateQuestion[],
}

const initialLimitsState = {
  isNoLimit: true,
  [LimitOption.MaxQuantity]: 0,
  [LimitOption.Low]: 0,
  [LimitOption.Medium]: 0,
  [LimitOption.High]: 0,
};

const limitType = {
  [Priority.Low]: LimitOption.Low,
  [Priority.Medium]: LimitOption.Medium,
  [Priority.High]: LimitOption.High,
};

const initialPriorityValues = {
  [Priority.Low]: 0,
  [Priority.Medium]: 0,
  [Priority.High]: 0,
};

export default function useQuestionLimits() {
  const { dataroom } = useDataroomContext();
  const [isFetching, setIsFetching] = useState(false);
  const [limits, setLimits] = useState(initialLimitsState);
  const [initialLimits, setInitialLimits] = useState(initialLimitsState);
  const [priorityRemain, setPriorityRemain] = useState(initialPriorityValues);
  const [activePriority, setActivePriority] = useState('');
  const [initialPriority, setInitialPriority] = useState(Priority.Low);
  const [isMaxQuantity, setIsMaxQuantity] = useState(false);

  const { container } = useDIContext();

  const updatePriorities = (priorityValues: IQuestionPriorities, isInitialized?: boolean) => {
    const availablePriority = Object.keys(priorityValues).find((value: Priority) => priorityValues[value]);

    isInitialized && !!availablePriority
      ? setInitialPriority(availablePriority as Priority)
      : setPriorityRemain(priorityValues);

    setActivePriority(availablePriority);
  };

  const updateLimitsRemaining = ({ questions }: IUpdateLimitsProps) => {
    if (limits.isNoLimit) return;

    const usedLimits = {
      [LimitOption.Low]: 0,
      [LimitOption.Medium]: 0,
      [LimitOption.High]: 0,
      [LimitOption.MaxQuantity]: 0,
    };

    questions.forEach(({ priority }) => {
      const limit = limitType[priority];
      usedLimits[limit] = Number(usedLimits[limit]) + 1;
      usedLimits.maxQuantityRemain = Number(usedLimits.maxQuantityRemain) + 1;
    });

    const leftLimits = Object.entries(initialLimits).reduce((obj, [key, value]) => {
      if (isBoolean(value)) {
        return { ...obj, [key]: value };
      }

      const result = value - usedLimits[key];
      const limit = result <= 0 ? 0 : result;

      return { ...obj, [key]: limit };
    }, { ...initialLimitsState });

    setLimits(leftLimits);

    const {
      lowPriorityRemain: low,
      mediumPriorityRemain: medium,
      highPriorityRemain: high,
    } = leftLimits;

    if (!leftLimits[LimitOption.MaxQuantity]) updatePriorities({ low, medium, high });
  };

  const isAllLimitsReached = useMemo(() => !limits.isNoLimit &&
    Object.values(limits).every((limit) => limit === 0 || isBoolean(limit),
  ), [limits]);

  async function getQuestionLimits() {
    setIsFetching(true);

    const payload = {
      dataroomId: dataroom.id,
    };

    try {
      const questionsLimitsRemainingRepository = container.get<QuestionsLimitsRepository>(
        QuestionsLimitsRepository,
      );
      const response = await questionsLimitsRemainingRepository.getQuestionsLimitsRemaining(payload);

      setLimits(response);
      setInitialLimits(response);

      const {
        lowPriorityRemain: low,
        mediumPriorityRemain: medium,
        highPriorityRemain: high,
        maxQuantityRemain,
        isNoLimit,
      } = response;

      setIsMaxQuantity(!!maxQuantityRemain);

      if (!isNoLimit && !maxQuantityRemain) updatePriorities({ low, medium, high }, true);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  }

  return {
    isFetching,
    limits,
    isAllLimitsReached,
    activePriority,
    priorityRemain,
    initialPriority,
    getQuestionLimits,
    updateLimitsRemaining,
    isMaxQuantity,
  };
}
