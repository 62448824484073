import React from 'react';
import cn from 'classnames';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useSidebarTabContext } from '@/dataroom/application/SidebarTabContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useDialInsContext } from '@/dataroom/application/DialInsContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { useEmailUploadingFolderContext } from '@/dataroom/application/EmailUploadingFolderContext';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { SidebarTab } from '@/dataroom/ui/components/Dataroom/Sidebar/constants';
import { DialInPopover } from '@/Framework/UI/Organisms/FinalForm/Fields/DialInPopover';
import styles from './dialInsSection.scss';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';

interface IProps {
  className: string,
  usePortal?: boolean,
  isHidden?: boolean,
}

const DialInsSection = ({
  className,
  usePortal = false,
  isHidden,
}: IProps) => {
  const { dataroom } = useDataroomContext();
  const { tenant } = useDataroomTenantContext();
  const { activeSidebarTab } = useSidebarTabContext();
  const {
    currentUser,
    canUserAccessPrimary,
  } = useCurrentUserContext();
  const {
    folderName,
    folderEmail,
    isFetching,
  } = useEmailUploadingFolderContext();
  const {
    conferencePhoneNumber,
    options: { hasViewIntDialIns },
  } = DataroomTenantConfig.fromCode(tenant);

  const { isAudioFolderManageable } = currentUser;
  const {
    emailUploadingEnabled,
    audioUploading,
    conferenceCode,
  } = dataroom;

  const {
    isFetching: isDialInsFetching,
    collection: dialInsCollection,
    getDialIns,
  } = useDialInsContext();

  const isDataroomTab = activeSidebarTab === SidebarTab.Dataroom;
  const isEmailUploadingEnabled = emailUploadingEnabled && folderEmail;
  const hasAccess = (isAudioFolderManageable || isEmailUploadingEnabled) && canUserAccessPrimary;

  const collection = dialInsCollection.map((item) => ({
    countryName: item.name,
    countryCode: item.code,
    phoneNumber: item.phone,
  }));

  const popover = (
    <DialInPopover
      collection={ collection }
      isFetching={ isDialInsFetching }
      getDialInPhones={ getDialIns }
      wrapperClassName={ styles.dialInsWrapper }
      usePortal={ usePortal }
    >
      <span className={ styles.dialInsText }>View Int'l Dial-Ins</span>
    </DialInPopover>
  );

  const emailUploadingTemplate = isEmailUploadingEnabled && (
    <span>
      To upload files directly to { folderName },
      bcc: <a href={ `mailto:${ folderEmail }` } target="_blank">{ folderEmail }</a>.
    </span>
  );

  const audioUploadingTemplate = !!audioUploading && (
    <span>
      To record and upload a phone call,
      dial: { conferencePhoneNumber } and enter
      code: { conferenceCode }# { hasViewIntDialIns && popover }
    </span>
  );

  return (
    isDataroomTab && !isHidden && hasAccess ? (
      <div
        className={ cn(styles.dialInsSection, className, {
          [styles.dialInsSectionVisible]: isEmailUploadingEnabled || audioUploading,
        }) }
      >
        <Spinner
          isVisible={ isFetching }
          isAbsolute
          overlay
          overlayClassName={ styles.overlay }
          className={ styles.spinner }
          size="small"
        />
        { emailUploadingTemplate }
        { audioUploadingTemplate }
      </div>
    ) : null
  );
};

export default DialInsSection;
