import React, { useState } from 'react';
import PhysicalCopyModal from './PhysicalCopyModal';
import { IDataroomListItem } from '@/dataroom/domain/vo/collection/DataroomListItem';
import { IDataroom } from '@/dataroom/domain/vo/Dataroom';

export default function usePhysicalCopyModal() {
  const [dataroomItem, setDataroomItem] = useState<IDataroomListItem | IDataroom>(null);

  const showPhysicalCopyModal = (item: IDataroomListItem | IDataroom) => {
    setDataroomItem(item);
  };

  const hidePhysicalCopyModal = () => {
    setDataroomItem(null);
  };

  const Modal = (props) => (props.dataroom ? <PhysicalCopyModal { ...props } /> : null);

  return {
    Modal,
    dataroomItem,
    showPhysicalCopyModal,
    hidePhysicalCopyModal,
  };
}
