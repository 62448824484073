import { useState } from 'react';
import EditModal from '@/dataroom/ui/common/DataroomExplorer/Modals/EditModal';

export default function useEditModal() {
  const [filesystemEditItem, setFilesystemEditItem] = useState(null);

  const showEditModal = (item) => {
    setFilesystemEditItem(item);
  };

  const hideEditModal = () => {
    setFilesystemEditItem(null);
  };

  return {
    EditModal,
    filesystemEditItem,
    showEditModal,
    hideEditModal,
  };
}
