import { Dependencies } from 'constitute';
import { errorCodeHelper } from '@finsight/error-codes';
import SessionRepository from '@/users/infrastructure/repository/SessionRepository';
import UserPermission from '@/users/domain/UserPermission';
import { TUserAccess } from '@/users/domain/vo/TUserAccess';
import TenantConfig from '@/Framework/Tenant/TenantConfig';
import config from '@/Framework/config';
import getLogoutTarget from '@/users/application/Session/getLogoutTarget';
import ViewSsidService from '@/users/application/Session/ViewSsidService';
import ViewerIdService from '@/users/application/Session/ViewerIdService';
import DeviceInfoService from '@/users/application/Session/DeviceInfoService';
import equal from 'fast-deep-equal';
import Logger from '@/Framework/browser/log/Logger';
import { ISession } from '@/users/domain/session/vo/Session';
import { IUser } from '@/users/domain/vo/User';
import { InitSessionState } from '@/users/application/Session/serverSide/InitSessionState';
import ClientReqData from '@/Framework/Router/Next/headers/ClientReqData';
import { Tenant } from '@/Framework/Tenant/vo/types/Tenant';

interface IState {
  initialized: boolean,
  currentSession?: ISession,
  currentSessionFetching: boolean,
  currentUser?: IUser,
  currentUserFetching: boolean,
  twoFactorRequired: boolean,
  isZeroToleranceAccess: boolean,
  accessPermissions: TUserAccess,
  handleUpdate: Function,
}

interface IInitParams {
  ssid?: string,
  currentUser?: IUser,
  currentSession?: ISession,
  fingerprint?: string,
  handleUpdate?: Function,
}

interface ILogoutParams {
  redirect?: boolean,
  error?: any | boolean,
  autoLogout?: boolean,
  callbackUrl?: string,
}

@Dependencies(ViewSsidService, ViewerIdService, DeviceInfoService, SessionRepository, InitSessionState, ClientReqData)
class SessionService {
  protected ssid: string;

  protected state: IState;

  protected _currentHost = null;

  constructor(
    protected viewSsidService: ViewSsidService,
    protected viewerIdService: ViewerIdService,
    protected deviceInfoService: DeviceInfoService,
    protected sessionRepo: SessionRepository,
    protected initSession: InitSessionState,
    protected clientReqData: ClientReqData,
  ) {
    const { currentHost } = this.clientReqData;

    this.state = new Proxy({
      ...initSession,
      currentSessionFetching: false,
      currentUserFetching: false,
      twoFactorRequired: false,
      isZeroToleranceAccess: false,
      accessPermissions: UserPermission.publicAccess,
      handleUpdate: () => {},
    }, {
      set(target: IState, property, value) {
        if (!equal(target[property], value)) {
          target[property] = value;
          target.handleUpdate(target);
        }
        return true;
      },
    });

    this._currentHost = currentHost;
    this.ssid = this.state?.currentSession?.ssid;

    (async () => {
      this.deviceInfoService.init({ fingerprint: await this.getFingerprint() });
    })();
  }

  init = async ({
      ssid,
      fingerprint,
      currentSession,
      currentUser,
      handleUpdate = () => {},
    }: IInitParams,
  ) => {
    this.ssid = ssid;
    this.deviceInfoService.init({ fingerprint });
    this.state.handleUpdate = handleUpdate;
    const [session, user] = await Promise.all(
      currentSession?.ssid
        ? [
          Promise.resolve(this.state.currentSession = currentSession),
          Promise.resolve(this.state.currentUser = currentUser),
        ]
        : [
          this.getCurrentSession({ clearCache: true }),
          this.getCurrentUser({ clearCache: true }),
        ],
    );

    this.state.initialized = true;
    this.ssid = this.state.currentSession?.ssid;

    return {
      currentSession: session,
      currentUser: user,
      currentHost: this._currentHost,
      initialized: this.state.initialized,
    };
  };

  get initialized() {
    return this.state.initialized;
  }

  get currentSession() {
    return this.state.currentSession;
  }

  get currentUser() {
    return this.state.currentUser;
  }

  get currentHost() {
    return this._currentHost;
  }

  get accessPermissions() {
    return this.state.accessPermissions;
  }

  getCurrentSession = async ({ clearCache = false } = {}) => {
    try {
      if (clearCache) {
        this.state.currentSessionFetching = true;
      }
      // @ts-ignore
      this.state.currentSession = await this.sessionRepo.getCurrentSession(this.ssid, {
        clearCache,
        cacheError: true,
      });
      this.ssid = this.state?.currentSession?.ssid;
    } catch (e) {
      this.state.currentSession = null;
    }
    this.state.currentSessionFetching = false;

    return this.state.currentSession;
  };

  get currentSessionFetching() {
    return this.state.currentSessionFetching;
  }

  handleUpdate = (handleUpdate) => {
    this.state.handleUpdate = handleUpdate;
  };

  isTwoFactorAuthenticationRequired = () => this.state.twoFactorRequired;

  isZeroToleranceAccess = () => this.state.isZeroToleranceAccess;

  async prolongSession({ tenant }) {
    try {
      this.state.currentSession = await this.sessionRepo.prolongSession({ tenant }) as ISession;
    } catch (e) {
      await this.checkSession({
        logoutParams: { autoLogout: true, error: true },
        clearCache: true,
      }).finally(() => {});
    }

    return this.state.currentSession;
  }

  getCurrentUser = async ({ clearCache = false } = {}) => {
    try {
      this.state.currentUserFetching = true;
      // @ts-ignore
      this.state.currentUser = await this.sessionRepo.getCurrentUser(this.ssid, { clearCache, cacheError: true });
    } catch (e) {
      this.state.currentUser = null;
    }
    this.state.currentUserFetching = false;

    return this.state.currentUser;
  };

  get currentUserFetching() {
    return this.state.currentUserFetching;
  }

  switchToPublicAccess = () => {
    this.state.accessPermissions = UserPermission.publicAccess;

    return this.checkSession();
  };

  switchToInvestorAccess = (checkSessionPayload = {}) => {
    this.state.accessPermissions = UserPermission.investorAccess;

    return this.checkSession(checkSessionPayload);
  };

  switchToManagerAccess = () => {
    this.state.accessPermissions = UserPermission.managerAccess;

    return this.checkSession();
  };

  /**
   * We all love our viewer. It was flagman ship project. We tested react on it. And as any flagman, its build fast
   * while it was exploring seas. So now, when we trying to update our codebase, and while we cannot refactor it
   * I'm leaving this nice zero tolerance mode here.
   *
   * Enabled ONLY for viewer, it will log out user on any of RPC or HTTP error we consider as access error. Without
   * taking care off permissions set to this context or whatever.
   *
   * You can see logic that checks if we have access error here in interceptors/ directory.
   * All logic related to this param executed before any further session checks
   *
   * TODO: Rewrite viewer one day
   *
   * @return {Promise<boolean>}
   */
  switchToLegacyZeroToleranceAccess = async () => {
    this.state.isZeroToleranceAccess = true;
    await this.checkSession();

    return 'WARNING: This mode is for VIEWER only. Use another mode and be aware\n' +
           'In brightest day, in blackest night,\n' +
           'No evil shall escape my sight!\n' +
           'Let those who worship evil\'s might\n' +
           'Beware my power, Green Lantern\'s light!\n' +
           '\n' +
           '— Hal Jordan/many current Lanterns';
  };

  satisfiesAccessPermissions = async (
    permissions: TUserAccess = this.state.accessPermissions,
    clearCache = false,
  ) => {
    const session = await this.getCurrentSession({ clearCache });
    const user = await this.getCurrentUser({ clearCache });

    const satisfiesPermissions = UserPermission.satisfiesAccessPermissions({
      ...permissions,
      session,
      user,
    });

    // 2FA is required only for pages with Authenticated access permission.
    if (permissions.authenticated) {
      this.setIsTwoFactorAuthenticationRequired(UserPermission.isTwoFactorAuthenticationRequired(session, user));
    }

    return satisfiesPermissions;
  };

  /**
   * Checks currentUser and currentSession permissions from state.
   */
  satisfiesCurrentAccessPermissions = (
    permissions: TUserAccess = this.state.accessPermissions,
  ) => UserPermission.satisfiesAccessPermissions({
    ...permissions,
    session: this.state.currentSession,
    user: this.state.currentUser,
  });

  /**
   * Will check session and perform all logout related stuff for you in case if session is not valid
   * Will use params first and fall back to context setup.
   * Use params only if you developing widget or so, prefer global configuration in router
   */
  checkSession = async (params?: Partial<{
    accessPermissions: TUserAccess,
    logoutParams: ILogoutParams,
    logoutHandler: Function,
    clearCache: boolean,
  }>) => {
    const { accessPermissions, logoutParams, logoutHandler, clearCache = false } = params || {};

    const satisfiesPermissions = await this.satisfiesAccessPermissions(
      accessPermissions || this.state.accessPermissions,
      clearCache,
    );

    if (!satisfiesPermissions) {
      if (logoutHandler) {
        return logoutHandler(logoutParams);
      }
      await this.logout(logoutParams);
      Logger.info('Not satisfies access permissions');
    }

    return true;
  };

  logout = async ({
    redirect = this.accessPermissions.authorised,
    error = false,
    autoLogout = false,
    callbackUrl = window.location.href,
  }: ILogoutParams = {}) => {
    try {
      await this.sessionRepo.invalidateSession();
    } catch (errorResponse) {
      // We move further to redirect in any case
    }

    this.ssid = null;
    this.state.currentSession = null;
    this.state.currentUser = null;

    let currentTenantCode: Tenant;
    try {
      currentTenantCode = TenantConfig.fromHostname(new URL(callbackUrl).hostname).code;
    } catch (e) { /* */ }

    const currentUrl = `${ window.location.protocol }//${ window.location.host }${ window.location.pathname }`
      .replace(/\/$/, '');
    if (
      redirect &&
      // This will protect us from loop on login pages
      config.session.loginEndpoints.every((endpoint) => currentUrl !== endpoint)
    ) {
      let errorCode: number;
      if (error && typeof error === 'boolean') {
        errorCode = errorCodeHelper.getCodeByName('SESSION_SECURITY_LOG_OUT');
      }
      if (error && error === Object(error)) {
        errorCode = error?.errorCodeName
          ? errorCodeHelper.getCodeByName(error?.errorCodeName)
          : errorCodeHelper.getCodeByName('SESSION_SECURITY_LOG_OUT');
      }
      return window.location.replace(getLogoutTarget({
        errorCode,
        errorData: error?.data,
        showToast: !!error?.toast,
        callbackUrl,
        tenant: currentTenantCode,
        isAutoLogout: autoLogout,
      }));
    }

    return true;
  };

  invalidateSession = () => {
    return this.sessionRepo.invalidateSession().catch(() => { /* */ });
  };

  setIsTwoFactorAuthenticationRequired = (required: boolean) => {
    this.state.twoFactorRequired = required;
    return this;
  };

  setSsidCookie = (ssid: string) => {
    this.ssid = ssid;
    return this.sessionRepo.setSsidCookie(ssid);
  };

  getViewSsid = this.viewSsidService.getViewSsid;

  bindViewSsid = this.viewSsidService.bindViewSsid;

  clearViewSsid = this.viewSsidService.clearViewSsid;

  getViewerId = this.viewerIdService.getViewerId;

  getFingerprint = this.deviceInfoService.getFingerprint;

  getUserAgent = this.deviceInfoService.getUserAgent;
}

export { SessionService };
