import React, { createContext, useContext, useState } from 'react';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useDIContext } from '@/Framework/DI/DIContext';
import AccessRequestsRepository from '@/dataroom/infrastructure/repository/AccessRequestsRepository';
import { AccessRequestType } from '@/dataroom/domain/vo/accessRequests/AccessRequestType';

const useAccessRequestsCount = () => {
  const { container } = useDIContext();
  const [
    isAccessRequestsCountFetching,
    setIsAccessRequestsCountFetching,
  ] = useState<{ [k in AccessRequestType]?: boolean; }>({});

  const [
    accessRequestsCount,
    setAccessRequestsCount,
  ] = useState<{ [k in AccessRequestType]?: number; }>({});

  const { dataroom } = useDataroomContext();

  const getAccessRequestsCount = async (type: AccessRequestType) => {
    setIsAccessRequestsCountFetching((prevIsAccessRequestsCountFetching) => ({
      ...prevIsAccessRequestsCountFetching,
      [type]: true,
    }));

    const accessRequestsRepository = container.get<AccessRequestsRepository>(AccessRequestsRepository);

    const response = await accessRequestsRepository.getAccessRequestsCount({
      dataroomId: dataroom.id,
      status: `access_${ type }`,
    });

    setAccessRequestsCount((prevAccessRequestsCount) => ({
      ...prevAccessRequestsCount,
      [type]: response.count,
    }));

    setIsAccessRequestsCountFetching((prevIsAccessRequestsCountFetching) => ({
      ...prevIsAccessRequestsCountFetching,
      [type]: false,
    }));
  };

  const resetAccessRequestsCount = (type: AccessRequestType) => {
    setIsAccessRequestsCountFetching((prevIsAccessRequestsCountFetching) => ({
      ...prevIsAccessRequestsCountFetching,
      [type]: false,
    }));

    setAccessRequestsCount((prevAccessRequestsCount) => ({
      ...prevAccessRequestsCount,
      [type]: 0,
    }));
  };

  return {
    isAccessRequestsCountFetching,
    accessRequestsCount,
    getAccessRequestsCount,
    resetAccessRequestsCount,
  };
};

type AccessRequestsCountContextType = ReturnType<typeof useAccessRequestsCount>;

export const AccessRequestsCountContext = createContext<AccessRequestsCountContextType>(null);

export function useAccessRequestsCountContext() {
  const context = useContext(AccessRequestsCountContext);
  if (!context) {
    throw new Error('useAccessRequestsCountContext must be used within a AccessRequestsCountContext');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function AccessRequestsCountContextProvider({ children }: IProps) {
  return (
    <AccessRequestsCountContext.Provider value={ useAccessRequestsCount() }>
      { children }
    </AccessRequestsCountContext.Provider>
  );
}

export default AccessRequestsCountContextProvider;
