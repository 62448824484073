import React, { createContext, useContext, useState, useEffect } from 'react';
import Dispatcher from '@dealroadshow/json-rpc-dispatcher';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useCurrentFolderContext } from '@/dataroom/application/CurrentFolderContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { useSidebarTabContext } from '@/dataroom/application/SidebarTabContext';
import EmailUploadingRepository from '@/dataroom/infrastructure/repository/EmailUploadingRepository';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { systemFolders, isManageable } from '@/dataroom/domain/filesystemPermissions';
import { SidebarTab } from '@/dataroom/ui/components/Dataroom/Sidebar/constants';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';

const useEmailUploadingFolder = () => {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string>(null);
  const [folderEmail, setFolderEmail] = useState<string>(null);

  const { tenant } = useDataroomTenantContext();
  const { dataroom } = useDataroomContext();
  const { currentUser, canUserAccessPrimary } = useCurrentUserContext();
  const { currentFolder } = useCurrentFolderContext();
  const { activeSidebarTab } = useSidebarTabContext();
  const { hostname } = DataroomTenantConfig.fromCode(tenant);
  const defaultEmail = dataroom && dataroom.baseEmailUploadingFolderEnabled && currentUser?.isEmailFolderManageable
    ? `${ dataroom.emailPrefix }@${ hostname }`
    : null;

  const getCurrentFolderEmail = async () => {
    setIsFetching(true);

    if (!currentFolder.parentFolderId) {
      // Root folder email uploading
      setFolderName(
        dataroom.rootFolderEmail && !currentFolder.isStaging ? currentFolder.name : systemFolders.emailUpload,
      );
      setFolderEmail(
        dataroom.rootFolderEmail && !currentFolder.isStaging ? dataroom.rootFolderEmail : defaultEmail,
      );
      setIsFetching(false);
      return;
    }

    const payload = {
      dataroomId: dataroom.id,
      folderId: currentFolder.id,
    };

    try {
      const emailUploadingRepository = container.get<EmailUploadingRepository>(EmailUploadingRepository);
      const response = await emailUploadingRepository.getFolderEmail(payload);
      setFolderName(response.folderEmail ? currentFolder.name : systemFolders.emailUpload);
      setFolderEmail(response.folderEmail || defaultEmail);
    } catch (error) {
      !Dispatcher.isAbortError(error) && container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (!canUserAccessPrimary) {
      return;
    }

    if (
      currentFolder &&
      isManageable(currentFolder) &&
      dataroom.emailUploadingEnabled &&
      activeSidebarTab !== SidebarTab.Settings
    ) {
      getCurrentFolderEmail();
    } else {
      setFolderName(systemFolders.emailUpload);
      setFolderEmail(defaultEmail);
    }
  }, [dataroom, defaultEmail, currentFolder?.id, canUserAccessPrimary]);

  return {
    isFetching,
    folderName,
    folderEmail,
  };
};

type EmailUploadingFolderContextType = ReturnType<typeof useEmailUploadingFolder>;

export const EmailUploadingFolderContext = createContext<EmailUploadingFolderContextType>(null);

export const useEmailUploadingFolderContext = () => {
  const context = useContext(EmailUploadingFolderContext);
  if (!context) {
    throw new Error('useEmailUploadingFolderContext must be used within a EmailUploadingFolderContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const EmailUploadingFolderContextProvider = ({ children }: IProps) => (
  <EmailUploadingFolderContext.Provider value={ useEmailUploadingFolder() }>
    { children }
  </EmailUploadingFolderContext.Provider>
);

export default EmailUploadingFolderContextProvider;
