import { useFolderTreeContext } from '@/dataroom/application/folderTree/FolderTreeContext';
import { useDIContext } from '@/Framework/DI/DIContext';
import FilesystemRepository from '@/dataroom/infrastructure/repository/FilesystemRepository';
import { messageCodes } from '@/Framework/Message/messages';
import useActionWithConflict from '@/dataroom/application/filesystem/filesystemActions/useActionWithConflict';
import { isFolder, prepareCopyMoveItems } from '@/dataroom/domain/filesystem';

const useMove = () => {
  const { container } = useDIContext();
  const { updateFolderTree } = useFolderTreeContext();

  const actionWithConflict = useActionWithConflict(
    container.get<FilesystemRepository>(FilesystemRepository).move,
    prepareCopyMoveItems,
    messageCodes.DATAROOM_MOVE_SUCCESS,
    messageCodes.DATAROOM_MOVE_ERROR,
  );

  return {
    ...actionWithConflict,
    fetch: (args: Parameters<typeof actionWithConflict.fetch>[0]) => actionWithConflict.fetch({
      ...args,
      successCallback: () => {
        const targetFolder = args.destinationFolder;
        const filesystemItemsFolderId = args.filesystemItems.find((item) => isFolder(item))?.id;
        updateFolderTree(filesystemItemsFolderId, targetFolder.id);
        args.successCallback && args.successCallback();
      },
      fetchBySockets: true,
    }),
  };
};

export default useMove;
