import cn from 'classnames';
import React from 'react';
import { LAYOUT_CONTENT_WRP_ID } from './constants';
import styles from './layoutContentWrp.scss';

interface IProps {
  children: React.ReactNode,
  className?: string,
}

function LayoutContentWrp(props: IProps) {
  return (
    <div
      id={ LAYOUT_CONTENT_WRP_ID }
      className={ cn(styles.layoutContentWrp, props.className) }
    >
      <div className={ styles.layoutContent }>
        { props.children }
      </div>
    </div>
  );
}

export default LayoutContentWrp;
