import React from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import styles from './toggleIcon.scss';
import IconCollapse from '@dealroadshow/uikit/core/components/Icon/IconCollapse';
import IconExpand from '@dealroadshow/uikit/core/components/Icon/IconExpand';

interface IProps {
  isOpen?: boolean,
  onClick?: () => void,
  className?: string,
  style?: object,
}

const ToggleIcon = (
  {
    isOpen,
    onClick = noop,
    className,
    style,
  }: IProps,
) => {
  const IconComponent = isOpen ? IconCollapse : IconExpand;

  return (
    <IconComponent
      className={ cn(styles.toggleIcon, className) }
      onClick={ onClick }
      style={ style }
      data-test="toggleIconButton"
    />
  );
};

export default ToggleIcon;
