import { useState } from 'react';
import DisableModal from './DisableModal';

export default function useDisableModal() {
  const [disableItems, setDisableItems] = useState([]);

  const showDisableModal = (items) => {
    setDisableItems(Array.isArray(items) ? items : [items]);
  };

  const hideDisableModal = () => {
    setDisableItems([]);
  };

  return {
    DisableModal,
    disableItems,
    showDisableModal,
    hideDisableModal,
  };
}
