import { useFolderTreeContext } from '@/dataroom/application/folderTree/FolderTreeContext';
import FilesystemRepository from '@/dataroom/infrastructure/repository/FilesystemRepository';
import { messageCodes } from '@/Framework/Message/messages';
import useActionWithConflict from '@/dataroom/application/filesystem/filesystemActions/useActionWithConflict';
import { prepareCopyMoveItems, filesystemItemType } from '@/dataroom/domain/filesystem';
import { useDIContext } from '@/Framework/DI/DIContext';

const useCopy = () => {
  const { container } = useDIContext();
  const { updateFolderTree } = useFolderTreeContext();

  const actionWithConflict = useActionWithConflict(
    container.get<FilesystemRepository>(FilesystemRepository).copy,
    prepareCopyMoveItems,
    messageCodes.DATAROOM_COPY_SUCCESS,
    messageCodes.DATAROOM_COPY_ERROR,
  );

  return {
    ...actionWithConflict,
    fetch: (args: Parameters<typeof actionWithConflict.fetch>[0]) => actionWithConflict.fetch({
      ...args,
      successCallback: () => {
        const targetFolder = args.destinationFolder;
        const sourceFolders = args.filesystemItems.filter(({ type }) => type === filesystemItemType.FOLDER);
        sourceFolders.length && updateFolderTree(targetFolder.id);
        args.successCallback && args.successCallback();
      },
      fetchBySockets: true,
    }),
  };
};

export default useCopy;
