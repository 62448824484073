export enum EnabledIndexingKeys {
  IndexingEnabledByFolderFileName = 'IndexingEnabledByFolderFileName',
  IndexingEnabledByIndex = 'IndexingEnabledByIndex',
  IndexingEnabledDeleteAllNames = 'IndexingEnabledDeleteAllNames',
  None = 'None',
}

export enum DisabledIndexingKeys {
  IndexingDisabledByFolderFileName = 'IndexingDisabledByFolderFileName',
  None = 'None',
}

export const enabledIndexingItems = [
  {
    key: EnabledIndexingKeys.IndexingEnabledByFolderFileName,
    label: 'Yes, truncate the longest file / folder names but keep index number as-is',
    dataTest: 'byFolderNameRadioButton',
  },
  {
    key: EnabledIndexingKeys.IndexingEnabledByIndex,
    label: 'Yes, keep file / folder names as-is but remove index numbers',
    dataTest: 'byIndexRadioButton',
  },
  {
    key: EnabledIndexingKeys.IndexingEnabledDeleteAllNames,
    label: 'Yes, keep file / folder index numbers as-is but remove names',
    dataTest: 'byIndexDeleteRadioButton',
  },
  {
    key: EnabledIndexingKeys.None,
    label: 'No, keep file / folder names and index numbers as-is',
    dataTest: 'noneRadioButton',
  },
];

export const disabledIndexingItems = [
  {
    key: DisabledIndexingKeys.IndexingDisabledByFolderFileName,
    label: 'Yes, truncate the longest file / folder names',
    dataTest: 'disabledRadioButton',
  },
  {
    key: DisabledIndexingKeys.None,
    label: 'No, keep file / folder names as-is',
    dataTest: 'noneRadioButton',
  },
];
