import React from 'react';
import cn from 'classnames';
import { DragOverlay, useDndContext } from '@dnd-kit/core';
import FilesystemIcon from '@/dataroom/ui/common/FilesystemIcon';
import { isFile, isFolder } from '@/dataroom/domain/filesystem';
import { canSelectedDrop } from '@/dataroom/domain/filesystemPermissions';
import { pluralize, snapCenterToCursor } from './helper';
import styles from './dragOverlayComponent.scss';

const overlayAnimation = {
  duration: 300,
  easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
};

const ExplorerItem = ({ item }) => (
  <div className={ styles.explorerItem }>
    <FilesystemIcon
      isStaging={ item.isStaging }
      extension={ item.extension }
      type={ item.type }
      className={ styles.filesystemIcon }
    />
    <span>{ item.name }</span>
  </div>
);

const DragOverlayComponent = () => {
  const { over, active } = useDndContext();
  const items = active ? active.data.current : [];
  const isCursorDisable = !over || !canSelectedDrop(items, over?.data.current);
  const overlayStyles = cn(styles.overlayContainer, {
    [styles.notAllowed]: isCursorDisable,
    [styles.overlayDetails]: items.length < 10,
  });
  const counterStyles = cn(styles.counter, {
    [styles.counterAbsolute]: items.length < 10,
  });

  const content = () => {
    if (items.length === 1) {
      const item = items[0];

      return (<ExplorerItem item={ item } />);
    }

    if (items.length < 10) {
      return (
        <>
          <div className={ counterStyles }>{ items.length }</div>
          { items.map((item) => (<ExplorerItem key={ item.id } item={ item } />)) }
        </>
      );
    }

    const folderCount = items.filter(isFolder).length;
    const fileCount = items.filter(isFile).length;

    return (
      <>
        <div className={ counterStyles }>{ items.length }</div>
        { `Moving ${ pluralize(folderCount, 'folder') }, ${ pluralize(fileCount, 'file') }` }
      </>

    );
  };

  if (!items.length) {
    return null;
  }

  return (
    <DragOverlay
      modifiers={ [snapCenterToCursor] }
      dropAnimation={ overlayAnimation }
    >
      <div className={ overlayStyles }>
        { content() }
      </div>
    </DragOverlay>
  );
};

export default DragOverlayComponent;
