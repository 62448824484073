import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import UploaderForm from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderForm';
import UploadDoneSection
  from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderForm/UploadDoneSection';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Clearfix from '@dealroadshow/uikit/core/components/Clearfix';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import styles from './uploaderModal.scss';
import { useFileUploaderContext } from '@/dataroom/application/FileUploader/FileUploaderContext';
import { useCurrentFolderContext } from '@/dataroom/application/CurrentFolderContext';
import * as tabs from './constants';
import ChangePermissions from '@/dataroom/ui/common/DataroomExplorer/Modals/ChangePermissions';
import title from './helpers/title';
import { DataroomURL } from '@/dataroom/infrastructure/dataroomUrl';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import * as managePermissions from '@/dataroom/domain/managePermissions';
import { canManageSettingsLite } from '@/dataroom/domain/managePermissions';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import IconLock from '@dealroadshow/uikit/core/components/Icon/IconLock';
import IconInfo from '@dealroadshow/uikit/core/components/Icon/IconInfo';

interface IProps {
  openFileDialog: () => void,
}

const UploaderModal = ({ openFileDialog }: IProps) => {
  const [isPermissionChangeVisible, setIsPermissionChangeVisible] = useState(false);
  const { currentFolder } = useCurrentFolderContext();
  const { currentUser } = useCurrentUserContext();
  const { tenant } = useDataroomTenantContext();

  const isUserAdminLite = canManageSettingsLite(currentUser);
  const userHasSystemManageAccess = managePermissions.canManageSettings(currentUser) || isUserAdminLite;
  const { isPermissionGroupsCreationEnabled } = DataroomTenantConfig.fromCode(tenant).options;

  const [activeTab, setActiveTab] = useState(tabs.ALL);
  const {
    uploadAllFiles,
    uploadProcessedFiles,
    isUploadErrors,
    isUploadCompleteWithNoErrors,
    isAllowedFileTypesFetching,
    allFiles,
    fileSystemStructure,
    isUploading,
    isRetryUploading,
    isUploadOnlyFoldersDone,
    isAddingChildren,
    isAddedChildren,
    retryAll,
    showCanceledModal,
    hideUploaderModal,
    isUploadComplete,
    isSomeFileSuccess,
    setPermissionGroups: setPermissions,
    isProcessing,
    isVisibleCancelUploadModal,
    CancelUploadModal,
    hideCanceledModal,
    stopUploadingHealthCheck,
    clearUploaderData,
  } = useFileUploaderContext();

  const foldersNumber = fileSystemStructure.countFolders();
  const validFoldersNumber = fileSystemStructure.countValidFolders();
  const hasFolders = !!foldersNumber;

  const [isVisibleModal, setIsVisibleModal] = useState(true);

  const handleCanceledModal = () => {
    setIsVisibleModal(true);
    hideCanceledModal();
  };

  const handlerCanceledUpload = () => {
    hideCanceledModal();
    hideUploaderModal();
  };

  const handleCloseClick = () => {
    if (isAddingChildren) {
      return;
    }

    if ((allFiles.length || hasFolders) && !isAddedChildren) {
      setIsVisibleModal(false);
      showCanceledModal();
    } else {
      hideUploaderModal();
    }
  };

  const handleRetryAll = () => {
    setActiveTab(tabs.ALL);
    retryAll();
  };

  const handleSavePermissionsAndUpload = () => {
    setIsPermissionChangeVisible(false);
    uploadAllFiles();
  };

  const isUploadedComplete = isUploadComplete();
  const isUploadedWithErrors = isUploadErrors();
  const isUploadedWithNoErrors = isUploadCompleteWithNoErrors();
  const isUploadedSomeFileSuccess = isSomeFileSuccess();
  const isRetryUploadingFiles = isRetryUploading();

  useEffect(() => {
    // If the last step of uploading has started and user is on Cancel Confirmation Screen we need to get him back
    // to Uploader modal, because for now there is no chance to Cancel uploading on this step. (FIN-16142)
    isAddingChildren && isVisibleCancelUploadModal && handleCanceledModal();
  }, [isAddingChildren]);

  useEffect(() => {
    isUploadedComplete && stopUploadingHealthCheck();
  }, [isUploadedComplete]);

  if (!currentFolder) {
    return null;
  }

  const footer = (
    <>
      {
        !isUploading && !isPermissionChangeVisible &&
        !isUploadedComplete && !isUploadOnlyFoldersDone && !isAddingChildren
        && (
          <Button
            type="submit"
            variant={ ButtonVariantType.action }
            onClick={ uploadAllFiles }
            className={ styles.submitButton }
            disabled={ (!allFiles.length && !hasFolders) || isProcessing }
            dataTest="submitUploadButton"
          >
            Upload
          </Button>
        )
      }
      { ((isUploading && !!allFiles.length && !isUploadedComplete) || isAddingChildren) && (
        <Button
          type="submit"
          variant={ ButtonVariantType.action }
          className={ styles.submitButton }
          disabled
          dataTest="submitUploadDisabledButton"
        >
          Uploading...
        </Button>
      ) }
      { isUploadedComplete && !isAddedChildren && isUploadedSomeFileSuccess && !isAddingChildren && (
        <Button
          type="submit"
          onClick={ uploadProcessedFiles }
          variant={ ButtonVariantType.action }
          className={ styles.submitButton }
          disabled={ isAddingChildren }
          dataTest="uploadProcessedFilesButton"
        >
          Upload Processed Files
        </Button>
      ) }
      {
        (isUploadedComplete || isRetryUploadingFiles) &&
        isUploadedWithErrors &&
        !isAddingChildren && (
          <Button
            variant={ ButtonVariantType.warning }
            onClick={ handleRetryAll }
            className={ styles.cancelButton }
            disabled={ isAddingChildren || isRetryUploadingFiles }
            dataTest="retryAllButton"
          >
            Retry All
          </Button>
        )
      }
      { isPermissionChangeVisible && (
        <Button
          type="submit"
          onClick={ handleSavePermissionsAndUpload }
          variant={ ButtonVariantType.action }
          disabled={ (!allFiles.length && !hasFolders) || isProcessing }
          className={ styles.submitButton }
          title="Save Permissions & Upload"
          dataTest="savePermissionsAndUploadButton"
        />
      ) }
      { isAddedChildren && (
        <>
          <Button
            variant={ ButtonVariantType.action }
            onClick={ handleCloseClick }
            dataTest="addedChildrenDoneButton"
          >
            Done
          </Button>
          <Button
            variant={ ButtonVariantType.outline }
            onClick={ clearUploaderData }
            dataTest="uploadMoreFilesButton"
          >
            Upload More Files
          </Button>
        </>
      ) }
      { !isAddedChildren && (
        <Button
          variant={ ButtonVariantType.text }
          onClick={ handleCloseClick }
          className={ styles.cancelButton }
          dataTest="cancelUploadButton"
          disabled={ isAddingChildren }
        >
          Cancel
        </Button>
      ) }
      { isPermissionGroupsCreationEnabled &&
        userHasSystemManageAccess &&
        !isPermissionChangeVisible &&
        !isAddedChildren && (
          <Tooltip
            containerClassName={ styles.tooltipContainer }
            content={ 'Not available based on your permissions' }
            placement="top"
            disabled={ !isUserAdminLite }
          >
            <Button
              variant={ ButtonVariantType.text }
              className={ styles.changePermissionsButton }
              onClick={ () => setIsPermissionChangeVisible(true) }
              disabled={ isProcessing || isUploading || isAddingChildren || isUserAdminLite }
              dataTest="uploaderModalChangePermissionsButton"
            >
              <IconLock
                className={ styles.changePermissionsIcon }
              />
              <span className={ styles.changePermissionsButtonText }>Change Permissions</span>
            </Button>
          </Tooltip>
        ) }
      <Clearfix />
      { isVisibleCancelUploadModal && (
        <CancelUploadModal
          hideCanceledModal={ handleCanceledModal }
          canceledUpload={ handlerCanceledUpload }
        />
      ) }
    </>
  );
  const contact = (
    <a
      target="_blank"
      href={ DataroomURL.getContactUsUrl() }
      data-test="contactButton"
    >
      contact
    </a>
  );
  return (
    <Modal
      className={ styles.uploaderModal }
      isVisible={ isVisibleModal }
      title={ title(foldersNumber, currentFolder, allFiles, isAddedChildren) }
      modalBodyClassName={ cn(styles.modalBody, { [styles.disabledFolders]: !currentFolder.canManage }) }
      footer={ footer }
      handleCloseClick={ handleCloseClick }
      dataTest="uploadModal"
    >
      { !currentFolder.canManage && (
        <div className={ styles.infoMessage }>
          <IconInfo
            className={ styles.infoIcon }
          />
          <span>Due to your permissions, you may only upload files, not folders.</span>
        </div>
      ) }
      { isAddedChildren ? (
        <UploadDoneSection
          foldersNumber={ validFoldersNumber }
        />
      ) : (
        <>
          <UploaderForm
            openFileDialog={ openFileDialog }
            activeTab={ activeTab }
            setActiveTab={ setActiveTab }
            isUploadedWithErrors={ isUploadedWithErrors }
            isUploadedWithNoErrors={ isUploadedWithNoErrors }
            isUploadOnlyFoldersDone={ isUploadOnlyFoldersDone }
            isUploadComplete={ isUploadedComplete }
            isPermissionChangeVisible={ isPermissionChangeVisible }
          />
        </>
      ) }
      { userHasSystemManageAccess && isPermissionChangeVisible && (
        <ChangePermissions
          folderId={ currentFolder.id }
          onChange={ setPermissions }
          className={ styles.permissionsWrp }
        />
      ) }
      { (isRetryUploadingFiles || isUploadedComplete) && !isAddedChildren && isUploadedWithErrors && (
        <div className={ styles.successWithErrorMessage }>
          Some files failed to process for upload. Please Retry or, to ignore these and continue with upload, choose
          "Upload Processed Files". If these errors persist, please { contact } our 24x7 Fanatical Support.
        </div>
      ) }
      { isAllowedFileTypesFetching && <Spinner overlay /> }
    </Modal>
  );
};

export default UploaderModal;
