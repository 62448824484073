export const ALL = 'All';
export const ERRORS = 'Errors';
export const NAME = 'name';
export const PATH = 'path';
export const STATUS = 'status';

export default [
  ALL,
  ERRORS,
  NAME,
  PATH,
  STATUS,
];
