/**
 * Returns overflowed array.
 * Example: getOverflowedArray([1, 2, 3, 4]) = [1, 2, ..., 4]
 * @param {Array} input
 * @param {String=} placeholder
 * @return {Array}
 */
const getOverflowedArray = (input: any[], placeholder: string = '...'): any[] => (
  input.length > 3
    ? input.slice(0, 2).concat([placeholder, input[input.length - 1]])
    : input
);

export default getOverflowedArray;
