import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import ArchiveRepository from '@/dataroom/infrastructure/repository/ArchiveRepository';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';

const useDownloadArchiveStatus = () => {
  const [archiveCount, setArchiveCount] = useState(0);
  const [isVisibleCount, setIsVisibleCount] = useState(true);

  const { container } = useDIContext();
  const { currentUser } = useCurrentUserContext();
  const { dataroom } = useDataroomContext();

  const archiveRepository = container.get<ArchiveRepository>(ArchiveRepository);

  const getArchivesCount = async () => {
    try {
      const { count } = await archiveRepository.getPendingArchivesCount({ dataroomId: dataroom.id });
      setArchiveCount(count);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    }
  };

  const incrementArchiveCount = () => {
    setArchiveCount((prevState) => prevState + 1);
    setIsVisibleCount(true);
  };

  useEffect(() => {
    if (dataroom && currentUser) {
      archiveRepository.subscribeToArchivesStatus({
        dataroomId: dataroom.id,
        userId: currentUser.id,
        callback: getArchivesCount,
      }).then(getArchivesCount);
    }
  }, [dataroom?.id, currentUser?.id]);

  return {
    isVisibleCount,
    archiveCount,
    setIsVisibleCount,
    incrementArchiveCount,
  };
};

type DownloadArchiveStatusContextType = ReturnType<typeof useDownloadArchiveStatus>;

export const DownloadArchiveStatusContext = createContext<DownloadArchiveStatusContextType>(null);

export const useDownloadArchiveStatusContext = () => {
  const context = useContext(DownloadArchiveStatusContext);

  if (!context) {
    throw new Error('useDownloadArchiveStatusContext must be used within a DownloadArchiveStatusContextProvider');
  }

  return context;
};

interface IProps {
  children: React.ReactElement,
}

const DownloadArchiveStatusContextProvider = ({ children }: IProps) => (
  <DownloadArchiveStatusContext.Provider value={ useDownloadArchiveStatus() }>
    { children }
  </DownloadArchiveStatusContext.Provider>
);

export default DownloadArchiveStatusContextProvider;
