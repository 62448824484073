import { NextRouter, useRouter } from 'next/router';

const useNextRouter = (): NextRouter => {
  const { push, replace, ...other } = useRouter();

  return {
    push: (path, asPath, options) => push(path, asPath, {
      shallow: true,
      ...options,
    }),
    replace: (path, asPath, options) => replace(path, asPath, {
      shallow: true,
      ...options,
    }),
    ...other,
  };
};

export default useNextRouter;
