import React, { createContext, useContext, useEffect } from 'react';
import noop from 'lodash/noop';
import useCheckboxArray from '@/Framework/UI/Molecules/CheckBoxArray/useCheckboxArray';
import ShiftContext from './ShiftContext';
import { IValues } from '@/Framework/UI/Molecules/CheckBoxArray/constants';

interface IProps {
  initialValues: IValues | {},
  children?: React.ReactNode,
  onChange?: (values) => void,
}

type TCheckboxArray = ReturnType<typeof useCheckboxArray>;

export const CheckboxArrayContext = createContext<TCheckboxArray>(null);

export function useCheckBoxArrayContext<T>() {
  const context = useContext<Omit<TCheckboxArray, 'selected'> & { selected: T[] }>(CheckboxArrayContext as any);
  if (!context) {
    throw new Error('useCheckBoxArrayContext must be used within a CheckboxArrayContextProvider');
  }
  return context;
}

function CheckboxArrayContextProvider({ children, initialValues, onChange = noop }: IProps) {
  const contextValue = useCheckboxArray(initialValues);

  useEffect(() => {
    onChange(contextValue.values);
  }, [JSON.stringify(contextValue.values)]);

  return (
    <CheckboxArrayContext.Provider value={ contextValue }>
      <ShiftContext>
        { children }
      </ShiftContext>
    </CheckboxArrayContext.Provider>
  );
}

export default CheckboxArrayContextProvider;
