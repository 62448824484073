import React, { useState } from 'react';
import cn from 'classnames';
import { useDndContext } from '@dnd-kit/core';
import { usePrimaryFolderTreeContext } from '@/dataroom/application/folderTree/PrimaryFolderTreeContext';
import { useStagingFolderTreeContext } from '@/dataroom/application/folderTree/StagingFolderTreeContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { useDnDFileIndexesContext } from '@/dataroom/application/DnDFileIndexesContext';
import QuickFilters from './QuickFilters';
import styles from './navigation.scss';
import SideBarFolderTree from '@/dataroom/ui/components/Dataroom/Sidebar/SidebarFolderTree';
import FieIndexItem from '@/dataroom/ui/components/Dataroom/Sidebar/Navigation/FieIndexItem';
import RedactionItem from '@/dataroom/ui/components/Dataroom/Sidebar/Navigation/RedactionItem';
import Search from '@/dataroom/ui/components/Dataroom/Sidebar/Navigation/Search';
import Downloads from '@/dataroom/ui/components/Dataroom/Sidebar/Navigation/Downloads';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import startsWith from 'lodash/startsWith';
import getDataroomBasedUrl from '@/dataroom/infrastructure/getDataroomBasedUrl';
import useRouter from '@/Framework/hooks/useNextRouter';
import { isDefaultAdmin } from '@/dataroom/domain/managePermissions';

const NavigationContentContainer = ({ children }) => {
  const { active } = useDndContext();

  return (
    <div className={ cn(styles.navigationContent, { [styles.isDragging]: active }) }>
      { children }
    </div>
  );
};

const Navigation = () => {
  const { dataroom } = useDataroomContext();
  const { asPath } = useRouter();
  const pathname = asPath.split('?')[0];
  const isSearchPage = startsWith(pathname, getDataroomBasedUrl(dataroom.name, 'search'));

  const [isSearchOpened, setIsSearchOpened] = useState(isSearchPage);

  const { isPrimaryFetching, primaryFolderTree } = usePrimaryFolderTreeContext();
  const { isStagingFetching, stagingFolderTree } = useStagingFolderTreeContext();
  const { canUserAccessStaging, canUserAccessPrimary, currentUser } = useCurrentUserContext();
  const { isDnDFileIndexesActive } = useDnDFileIndexesContext();
  const isUserDefaultAdmin = isDefaultAdmin(currentUser);

  return (
    <>
      <div className={ styles.searchWrp }>
        <Search
          isOpened={ isSearchOpened }
          setOpened={ setIsSearchOpened }
          isDisabled={ isDnDFileIndexesActive }
        />
      </div>
      <div className={ styles.mainSectionContainer }>
        <div className={ cn(styles.overflowContainer, {
            [styles.withSmallSearch]: isSearchOpened && !canUserAccessStaging && !dataroom.docSearchEnabled,
            [styles.withMediumSearch]: isSearchOpened && !canUserAccessStaging && dataroom.docSearchEnabled,
            [styles.withLargeSearch]: isSearchOpened && canUserAccessStaging && !dataroom.docSearchEnabled,
            [styles.withExtraLargeSearch]: isSearchOpened && canUserAccessStaging && dataroom.docSearchEnabled,
          }) }
        >
          <NavigationContentContainer>
            <Downloads className={ styles.downloadsItem } />
            <FieIndexItem
              className={ styles.fileIndexItem }
            />
            { isUserDefaultAdmin && (
              <RedactionItem
                className={ styles.redactionItem }
              />
            ) }
            <QuickFilters />
            { canUserAccessStaging && stagingFolderTree && (
              <SideBarFolderTree
                folderTree={ stagingFolderTree }
                isFetching={ isStagingFetching }
              />
            ) }
            { canUserAccessPrimary && primaryFolderTree && (
              <SideBarFolderTree
                folderTree={ primaryFolderTree }
                isFetching={ isPrimaryFetching }
              />
            ) }
          </NavigationContentContainer>
        </div>
      </div>
    </>
  );
};

export default Navigation;
