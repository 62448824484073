import cn from 'classnames';
import React from 'react';
import { defaultIcon, iconMapping } from '@/dataroom/ui/common/FilesystemIcon/constants';

interface IProps {
  extension: string,
  className?: string,
}

const FileIcon = (props: IProps) => {
  let key = props.extension;
  const {
    icon: Icon,
    className,
  } = iconMapping[key] || defaultIcon;

  return (
    <span className={ cn(className, props.className) }>
      <Icon />
    </span>
  );
};

export default FileIcon;
