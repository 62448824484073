export const questionPriority = {
  low: {
    name: 'low',
    label: 'Low',
  },
  medium: {
    name: 'medium',
    label: 'Medium',
  },
  high: {
    name: 'high',
    label: 'High',
  },
};
