import { isValidName } from '../helpers';
import Upload from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/Upload';
import { WithChildren } from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/WithChildren';

type UploadSerialize = {
  id: string,
  type: 'upload',
};

type FolderSerialize = {
  name: string,
  type: 'folder',
  children: (FolderSerialize | UploadSerialize)[],
};

export const serializeReducer = (
  acc: Array<FolderSerialize | UploadSerialize>,
  child: Folder | Upload,
) => {
  if (child instanceof Folder && isValidName(child.name)) {
    const folderSerialize: FolderSerialize = {
      name: child.name,
      type: 'folder',
      children: child.children.reduce(serializeReducer, []),
    };

    acc.push(folderSerialize);
  } else if (child instanceof Upload && !child.isFailure()) {
    const uploadSerialize: UploadSerialize = {
      id: child.id,
      type: 'upload',
    };
    acc.push(uploadSerialize);
  }
  return acc;
};

export default class Folder {
  name: string;

  parent: WithChildren;

  children: Array<Folder | Upload> = [];

  constructor(name: string, parent: WithChildren) {
    this.name = name;
    this.parent = parent;
  }

  serialize(): {
    name: string,
    type: 'folder',
    children: Array<ReturnType<typeof Upload.prototype.serialize> | ReturnType<typeof Folder.prototype.serialize>>,
  } {
    return {
      name: this.name,
      type: 'folder',
      children: this.children.reduce(serializeReducer, []),
    };
  }
}
