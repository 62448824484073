import React from 'react';
import Radio, { RadioGroup } from '@dealroadshow/uikit/core/components/Radio';
import { QuestionAlertsType, questionAlertTypes } from './alertsType';
import styles from './questionAlertsTypeSwitcher.scss';

interface IProps {
  value: QuestionAlertsType,
  onChange: (value: QuestionAlertsType) => void,
  isDisabled?: boolean,
}

const QuestionAlertsTypeSwitcher = ({
  value,
  onChange,
  isDisabled,
}: IProps) => (
  <RadioGroup
    name="questionAlertsType"
    fieldClassName={ styles.radioGroupWrp }
    className={ styles.radioGroupContainer }
    selectedValue={ value }
    onChange={ onChange }
    dataTest="questionAlertsTypeRadioGroup"
    disabled={ isDisabled }
    isNarrow
  >
    {
      questionAlertTypes.map((item) => (
        <Radio
          key={ item.name }
          value={ item.name }
          label={ item.label }
          className={ styles.radioItemWrp }
          dataTest={ item.label }
        />
      ))
    }
  </RadioGroup>
);

export default QuestionAlertsTypeSwitcher;
