import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { IQuestionListItem } from '@/dataroom/domain/vo/permissionGroups/questions/QuestionListItem';
import { IQuestionLimitsRemaining } from '@/dataroom/domain/questions/vo/QuestionLimitsRemaining';

interface IResponse {
  collection: IQuestionListItem[],
}

interface IPayload {
  dataroomId: number,
  filter: string,
  page: number,
  perPage: number,
  sortBy: string,
  sortOrder: string,
}

interface IEditPayload {
  dataroomId: number,
  permissionGroups: IQuestionListItem[],
}

@Dependencies(JsonRpcDispatcherFactory)
class QuestionsLimitsRepository {
  constructor(private readonly rpc: typeof JsonRpcDispatcherFactory) {}

  getQuestionPermissionGroups = async (payload: IPayload): Promise<IResponse> => {
    const request = new Request('dataroom.permission_groups.qna.list', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  editQuestionPermissionGroups = async (payload: IEditPayload): Promise<{}> => {
    const request = new Request('dataroom.permission_groups.qna.update', payload);

    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult();
  };

  getQuestionsLimitsRemaining = async (payload: { dataroomId: number }): Promise<IQuestionLimitsRemaining> => {
    const request = new Request('dataroom.permission_groups.qna.remaining_limit', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default QuestionsLimitsRepository;
