import React, { useState } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Clearfix from '@dealroadshow/uikit/core/components/Clearfix';
import Checkbox from '@dealroadshow/uikit/core/components/Checkbox';
import { responsiveModalStyles } from '@dealroadshow/uikit/core/components/Modal';
import ChangePermissions from '@/dataroom/ui/common/DataroomExplorer/Modals/ChangePermissions';

import styles from './changePermissionsSection.scss';

interface IProps {
  parentFolderId: number,
  onChange: (values) => void,
  invalid: boolean,
  closeModal: () => void,
  tip?: React.ReactNode,
  isCheckboxVisible?: boolean,
  isFetching: boolean,
  pristine: boolean,
  permissionGroups: object,
  isFolder?: boolean,
  name: string,
  footerRefCallback: () => void,
}

const ChangePermissionsSection = (
  {
    parentFolderId,
    onChange,
    invalid,
    closeModal,
    tip,
    isCheckboxVisible = false,
    isFetching,
    pristine,
    permissionGroups,
    isFolder,
    name,
    footerRefCallback,
  }: IProps,
) => {
  const [isUnderstand, setIsUnderstand] = useState(false);
  const [customPermissionGroups, setCustomPermissionGroups] = useState({});

  return (
    <div className={ styles.sectionWrp }>
      <ChangePermissions
        folderId={ parentFolderId }
        onInit={ setCustomPermissionGroups }
        onChange={ onChange }
        isFolder={ isFolder }
        permissionsName="customEditPermissions"
        permissionsTableTitle="Permission Group Access"
      />
      {
        tip && (
          <div className={ styles.tip }>
            { tip }
          </div>
        )
      }

      {
        isCheckboxVisible && (
          <Checkbox
            dataTest="understand"
            className={ styles.agree }
            onChange={ (e) => setIsUnderstand(e.target.checked) }
            disabled={ isFetching }
            checked={ isUnderstand }
            label="I understand that by making any changes, I will overwrite existing permissions on any
sub-files and sub-folders with the permissions selected in the table above."
          />
        )
      }

      <div
        className={ cn(styles.buttonsWrp, responsiveModalStyles.modalFooter) }
        ref={ footerRefCallback }
      >
        <Button
          type="submit"
          variant={ ButtonVariantType.action }
          disabled={
            invalid ||
            isEmpty(customPermissionGroups) ||
            isFetching ||
            (!isUnderstand && isCheckboxVisible) ||
            (pristine && isEqual(customPermissionGroups, permissionGroups))
          }
          className={ styles.submitButton }
          title="Save"
          dataTest={ `${ name }SubmitButton` }
        />
        <Button
          variant={ ButtonVariantType.text }
          onClick={ closeModal }
          className={ styles.cancelButton }
          title="Cancel"
          dataTest={ `${ name }CancelButton` }
        />
        <Clearfix />
      </div>
    </div>
  );
};

export default ChangePermissionsSection;
