import config from '@/Framework/config';
import EVERCALL_URL from '@/evercall/Router/urlConstants';
import condorUrl from '@/condor/infrastructure/condorUrl';
import drsUrl from '@/dealroadshow/infrastructure/url/drsUrl';
import usersUrl from '@/users/infrastructure/usersUrl';
/*
Yep, I know :(
But please look on this funny cat while trying to work with this.
         /\_/\
    ____/ o o \
  /~____  =ø= /
 (______)__m_m)
 */

/*
* Dynamic logout targets mapping. ORDER MATTERS!!!
* */
const logoutTargets: Array<{
  pattern: RegExp,
  getTarget: (isAutoLogout: boolean) => string,
}> = [
  { // Condor
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.condorSubdomain }\..*\/.*`),
    getTarget: () => usersUrl.getLoginUrl(condorUrl.getPropagateUrl()),
  },
  {
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.usersSubdomain }\..*\/profile.*`),
    getTarget: () => usersUrl.getLoginUrl(),
  },
  { // Manager viewer
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.drsSubdomain }\..*\/manager\/.*`),
    getTarget: () => usersUrl.getLoginUrl(drsUrl.getPropagateUrl()),
  },
  { // Retail roadshow
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.drsSubdomain }\..*\/(viewer\/|e\/).*\/retail`),
    getTarget: () => `${ config.protocol }//${ config.drsSubdomain }.${ config.globalDomain }/retail`,
  },
  { // Cross deal analytics
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/analytics.*`),
    getTarget: () => usersUrl.getLoginUrl(),
  },
  { // Cross deal analytics
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/accounts.*`),
    getTarget: () => usersUrl.getLoginUrl(),
  },
  { // Roadshow
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/roadshow.*`),
    getTarget: (isAutoLogout) => {
      if (isAutoLogout) {
        return usersUrl.getLoginUrl(drsUrl.getPropagateUrl());
      }

      return `${ config.protocol }//${ config.drsSubdomain }.${ config.globalDomain }/`;
    },
  },
  { // Investor viewer
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.drsSubdomain }\..*\/(viewer\/|e\/).*`),
    getTarget: () => `${ config.protocol }//${ config.drsSubdomain }.${ config.globalDomain }/`,
  },
  { // DealVDR
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/dealvdr.*`),
    getTarget: (isAutoLogout) => {
      if (isAutoLogout) {
        return usersUrl.getLoginUrl();
      }

      return `${ config.protocol }//${ config.tenantDealvdrDomain }`;
    },
  },
  { // 17g5
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/17g5.*`),
    getTarget: (isAutoLogout) => {
      if (isAutoLogout) {
        return usersUrl.getLoginUrl();
      }

      return `${ config.protocol }//${ config.tenant17g5Domain }/`;
    },
  },
  { // Investorset
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/investorset.*`),
    getTarget: (isAutoLogout) => {
      if (isAutoLogout) {
        return usersUrl.getLoginUrl();
      }

      return `${ config.protocol }//${ config.tenantInvestorSetDomain }/`;
    },
  },
  { // Verisend
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/verisend.*`),
    getTarget: (isAutoLogout) => {
      if (isAutoLogout) {
        return usersUrl.getLoginUrl();
      }

      return `${ config.protocol }//${ config.tenantVeriSendDomain }/`;
    },
  },
  { // Researchroom
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/researchroom.*`),
    getTarget: (isAutoLogout) => {
      if (isAutoLogout) {
        return usersUrl.getLoginUrl();
      }

      return `${ config.protocol }//${ config.tenantResearchRoomDomain }/`;
    },
  },
  { // Deal Manager Portal
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.dmPortalSubdomain }\..*\/.*`),
    getTarget: () => usersUrl.getLoginUrl(),
  },
  { // Evercall Chat
    // eslint-disable-next-line no-useless-escape
    pattern: new RegExp(`(http.{0,1}:\/\/){0,1}${ config.evercallDomain }\..*\/.*`),
    getTarget: () => `${ config.protocol }//${ config.evercallDomain }${ EVERCALL_URL.DASHBOARD }`,
  },
];

export default logoutTargets;
