import React, { SyntheticEvent, useEffect } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import NextLink from './Link';
import { UrlObject } from 'url';
import styles from './nextLink.scss';

interface IProps {
  id?: string,
  title?: string,
  className?: string,
  name?: string,
  isActive?: boolean,
  isExactActive?: boolean,
  activeClassName?: string,
  to: string | UrlObject,
  shallow?: boolean,
  children?: any,
  onClick?: (e: any) => void,
  onMouseEnter?: (e: SyntheticEvent) => void,
  onBecomeActive?: () => void,
  disabled?: boolean,
  dataTest?: string,
}

const TabLink = React.forwardRef(({
  id,
  name,
  to,
  title,
  className,
  isActive,
  isExactActive,
  activeClassName,
  children,
  shallow = true,
  onBecomeActive,
  disabled,
  onClick,
  dataTest,
  ...props
}: IProps, ref: any) => {
  const { asPath } = useRouter();
  const toPathname = typeof to === 'string' ? to : to.pathname;
  const isLinkActive = isActive || (isExactActive ? asPath === toPathname : asPath.includes(toPathname));
  const ariaCurrent = isLinkActive ? 'page' : undefined;

  useEffect(() => {
    if (isLinkActive && onBecomeActive) {
      onBecomeActive();
    }
  }, [isLinkActive]);

  return (
    <NextLink
      to={ to }
      passHref
      shallow={ shallow }
      id={ id }
      title={ title }
      ariaCurrent={ ariaCurrent }
      dataTest={ dataTest }
      className={ cn(className, {
        [activeClassName]: isLinkActive,
        [styles.isDisabled]: disabled,
      }) }
      ref={ ref }
      onClick={ onClick }
      { ...props }
    >
      { children }
    </NextLink>
  );
});

export default TabLink;
