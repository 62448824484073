import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateRequiredField } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredField';

const searchField = { fieldName: 'searchText', fieldCaption: 'Search' };

export default (values, isFormOpened) => {
  if (!isFormOpened) return {};

  return groupValidators(
    validateRequiredField({ ...searchField, value: values.searchText?.trim() }),
    validateMinLength({ ...searchField, value: values.searchText?.trim(), length: 3 }),
    validateMaxLength({ ...searchField, value: values.searchText?.trim(), length: 128 }),
  );
};
