import React from 'react';
import cn from 'classnames';
import { IQuestionLimitsRemaining } from '@/dataroom/domain/questions/vo/QuestionLimitsRemaining';
import styles from './questionLimitsMessage.scss';

interface IProps {
  limits: IQuestionLimitsRemaining,
  isLimitsReached?: boolean,
}

const getLimitMessage = (limits: IQuestionLimitsRemaining) => {
  const {
    lowPriorityRemain,
    mediumPriorityRemain,
    highPriorityRemain,
    maxQuantityRemain,
  } = limits;

  if (!lowPriorityRemain && !mediumPriorityRemain && !highPriorityRemain) {
    return (
      <>
        <strong> { maxQuantityRemain } question{ maxQuantityRemain > 1 ? 's' : '' }</strong>  remaining
      </>
    );
  }

  if (maxQuantityRemain > 0) {
    return (
      <>
        <strong> { lowPriorityRemain } low</strong>, <strong>{ mediumPriorityRemain } medium</strong>,
        and <strong>{ highPriorityRemain } high priority</strong> remaining OR
        <strong> { maxQuantityRemain } question</strong> remaining
      </>
    );
  }

  return (
    <>
      <strong> { lowPriorityRemain } low</strong>, <strong>{ mediumPriorityRemain } medium</strong>,
      and <strong>{ highPriorityRemain } high priority</strong> questions remaining
    </>
  );
};

const QuestionLimitsMessage = ({
  limits,
  isLimitsReached,
}: IProps) => (
  <>
    { !limits.isNoLimit && (
    <div className={ cn(styles.limitsWrapper, { [styles.disabled]: isLimitsReached }) }>
      <div className={ styles.limitsItem }># of questions limited by Q&A Manager:
        { getLimitMessage(limits) }
      </div>
    </div>
      ) }
  </>
);

export default QuestionLimitsMessage;
