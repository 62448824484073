import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import FolderRepository from '@/dataroom/infrastructure/repository/FolderRepository';
import FileRepository from '@/dataroom/infrastructure/repository/FileRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import * as managePermissions from '@/dataroom/domain/managePermissions';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { IFolderPermissionGroup } from '@/dataroom/domain/vo/filesystem/FolderPermissionGroup';
import { IFilePermissionGroup } from '@/dataroom/domain/vo/filesystem/FilePermissionGroup';

const useCustomEditPermissions = () => {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState(false);
  const [collection, setCollection] = useState<((IFolderPermissionGroup | IFilePermissionGroup) & {
    isDisabled?: boolean,
  })[]>([]);

  const { dataroom } = useDataroomContext();
  const { currentUser } = useCurrentUserContext();
  const isAdmin = managePermissions.canUserManageOwnSettings(currentUser);
  const ownPermissionIds = currentUser?.permissionGroups.map((permission) => permission.id);

  const getPermissions = async (id: number, isFolder: boolean) => {
    setIsFetching(true);

    try {
      const payload = { dataroomId: dataroom.id };

      const response = await (() => {
        if (isFolder) {
          return container.get<FolderRepository>(FolderRepository).getCustomEditPermissions({
            ...payload,
            folderId: id,
          });
        }
        return container.get<FileRepository>(FileRepository).getCustomEditPermissions({
          ...payload,
          fileId: id,
        });
      })();

      const collection = isAdmin ?
        response :
        response.map((permission: IFolderPermissionGroup | IFilePermissionGroup) => ({
          ...permission,
          isDisabled: ownPermissionIds.includes(permission.permissionGroupId), // Disable self PGs
        }));

      setCollection(collection);
      return collection;
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
    return [];
  };

  return {
    isFetching,
    collection,
    getPermissions,
  };
};

export default useCustomEditPermissions;
