import * as filesystemPermissions from '@/dataroom/domain/filesystemPermissions';
import { isFolder } from '@/dataroom/domain/filesystem';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';

export const activeItemsInitial: {
  foldersCanMove: Array<IFilesystemListItem | IFolderTree>,
  filesCanMove: Array<IFilesystemListItem | IFolderTree>,
  foldersCanNotMove: Array<IFilesystemListItem | IFolderTree>,
  filesCanNotMove: Array<IFilesystemListItem | IFolderTree>,
} = {
  foldersCanMove: [],
  filesCanMove: [],
  foldersCanNotMove: [],
  filesCanNotMove: [],
};

export const getActiveItems = (
  filesystemItems: Array<IFilesystemListItem | IFolderTree>,
  folderTree: IFolderTree[],
) => {
  const sortFunction = (a, b) => (a.fileIndexCurrent < b.fileIndexCurrent ? 1 : -1);

  return filesystemItems
    .sort(sortFunction)
    .reduce(
      (
        result,
        item,
      ) => {
        const propPrefix = isFolder(item) ? 'folders' : 'files';
        if (filesystemPermissions.canCopyAndMove(item, folderTree, [])) {
          item.canMove = true;
          result[`${ propPrefix }CanMove`] = [item, ...result[`${ propPrefix }CanMove`]];
        } else {
          result[`${ propPrefix }CanNotMove`] = [item, ...result[`${ propPrefix }CanNotMove`]];
        }
        return result;
      }, {
        ...activeItemsInitial,
      });
};

export const getInfoText = (
  foldersCanMoveNumber: number,
  filesCanMoveNumber: number,
) => {
  if (!(foldersCanMoveNumber + filesCanMoveNumber)) {
    return 'selected items cannot be moved';
  }
  const foldersText = foldersCanMoveNumber ?
    `${ foldersCanMoveNumber } folder${ foldersCanMoveNumber > 1 ? 's' : '' }` : '';
  const filesText = filesCanMoveNumber ?
    `${ filesCanMoveNumber } file${ filesCanMoveNumber > 1 ? 's' : '' }` : '';
  return `${ foldersText }${ foldersText && filesText ? ',' : '' } ${ filesText }`;
};
