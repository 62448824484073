import React from 'react';
import cn from 'classnames';
import { useFileUploaderContext } from '@/dataroom/application/FileUploader/FileUploaderContext';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import styles from './fileUploaderItemActions.scss';
import IconRefresh from '@dealroadshow/uikit/core/components/Icon/IconRefresh';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';

interface IProps {
  children: React.ReactNode,
  file: any,
}

const FileUploaderItemActions = ({
  file,
  children,
}: IProps) => {
  const {
    deleteFile,
    retryFile,
  } = useFileUploaderContext();

  return (
    <div className={ styles.toolbarWrp }>
      <div className={ styles.actionsColumnData }>
        { children }
      </div>
      <div className={ cn(styles.actions) }>
        { file.upload.isFailure() && (
          <div
            className={ styles.toolbarAction }
            onClick={ () => retryFile(file) }
            data-test="retryIconButton"
          >
            <Tooltip content="Retry">
              <IconRefresh />
            </Tooltip>
          </div>
        ) }
        <div
          className={ styles.toolbarAction }
          onClick={ () => deleteFile(file) }
          data-test="removeIconButton"
        >
          <Tooltip content="Remove">
            <IconTrash />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default FileUploaderItemActions;
