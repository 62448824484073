import { IQuestionsRealtimeNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/QuestionsEmailAlerts';

export const submittersRealTimeOptions: {
  name: keyof IQuestionsRealtimeNotificationSettingsPayload,
  label: string,
  dataTest: string,
}[] = [
  {
    name: 'questionSubmitted',
    label: 'New Question Submitted',
    dataTest: 'questionSubmittedCheckbox',
  },
  {
    name: 'replySubmitted',
    label: 'New Response Submitted',
    dataTest: 'replySubmittedCheckbox',
  },
];

export const managersRealTimeOptions: {
  name: keyof IQuestionsRealtimeNotificationSettingsPayload,
  label: string,
  dataTest: string,
}[] = [
  ...submittersRealTimeOptions,
  {
    name: 'noteSubmitted',
    label: 'New Note Submitted',
    dataTest: 'noteSubmittedCheckbox',
  },
  {
    name: 'questionAssigned',
    label: 'New Question you are assigned to',
    dataTest: 'questionAssignedCheckbox',
  },
];
