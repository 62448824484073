import React from 'react';
import cn from 'classnames';

import baseStyles from '../base.scss';
import styles from './support.scss';
import supportPattern from './assets/supportPattern.svg';

interface IProps {
  title?: string,
  description?: string | React.JSX.Element,
  pattern?: string,
  patternClassName?: string,
  headingClassName?: string,
  titleClassName?: string,
  descriptionClassName?: string,
}

const defaultDescription = (
  <>
    We offer unlimited, 24x7x365 U.S. based phone, email and live chat
    support to you and any user in your data room.
  </>
);

const Support = ({
  title = 'Your data room means everything to us',
  description = defaultDescription,
  ...props
}: IProps) => (
  <>
    <div className={ cn(styles.supportPattern, props.patternClassName) }>
      <img src={ props.pattern || supportPattern } />
    </div>
    <div className={ styles.supportContent }>
      <div className={ baseStyles.mdContainer }>
        <div className={ cn(styles.supportHeading, props.headingClassName) }>
          24 <span>/</span> 7 <span>/</span> 365
        </div>
        <div className={ cn(styles.supportTitle, props.titleClassName) }>
          { title }
        </div>
        <p className={ cn(styles.supportDescription, props.descriptionClassName) }>
          { description }
        </p>
      </div>
    </div>
  </>
);

export default Support;
