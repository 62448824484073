import React from 'react';
import { useCheckBoxArrayContext } from './CheckboxArrayContext';
import { useShiftContext } from './ShiftContext';

const Checkbox = (props) => {
  // @ts-ignore
  const { isShiftHeld } = useShiftContext();
  const { CheckboxArray, values, setValues } = useCheckBoxArrayContext();

  const getNewSelectedItems = (items, selectedItems) => {
    const currentSelectedIndex = items.indexOf(selectedItems[selectedItems.length - 1]);
    const lastSelectedIndex = items.indexOf(selectedItems[selectedItems.length - 2]);

    return items
      .slice(
        Math.min(lastSelectedIndex, currentSelectedIndex),
        Math.max(lastSelectedIndex, currentSelectedIndex) + 1,
      ).filter(({ isDisabled }) => !isDisabled);
  };

  const onChange = (values) => {
    const { selectedItems, allItems } = values;

    if (!isShiftHeld || selectedItems.length <= 1) {
      setValues(values);
      return;
    }

    const newValues = getNewSelectedItems(allItems, selectedItems);

    setValues({ allItems, selectedItems: [...Array.from(new Set([...selectedItems, ...newValues]))] });
  };
  return (
    <CheckboxArray
      { ...props }
      allValues={ values }
      change={ onChange }
    />
  );
};

export default Checkbox;
