import { FileWithPath } from 'file-selector';
import { WithChildren } from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/WithChildren';
import FileWithUploadAndPath
  from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/FileWithUploadAndPath';
import UploadError from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/UploadError';
import { IUploadProcessing } from '@dealroadshow/file-uploader';

const additionalCombineChunksTimeout = 5 * 60 * 1000; // 5m in ms;

export enum UploadStatus {
  FAILURE = 'FAILURE',
  UPLOADING = 'UPLOADING',
  JOINING = 'JOINING',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

export type UploadId = string | null;

export default class Upload {
  id: UploadId = null;

  file: FileWithUploadAndPath;

  parent: WithChildren;

  status: UploadStatus;

  percentage: number = 0;

  uploadProcessing: IUploadProcessing;

  combineChunksTimeout: ReturnType<typeof setTimeout>;

  combineChunksSessionProlongInterval: ReturnType<typeof setInterval>;

  errors: UploadError[] = [];

  constructor(file: FileWithPath, parent: WithChildren) {
    this.file = file as FileWithUploadAndPath;
    this.parent = parent;

    Object.defineProperty(file, 'upload', { value: this });
  }

  setCombineChunksTimeout(timerHandler: () => void, intervalHandler: () => void, timeout: number, interval: number) {
    this.combineChunksTimeout = setTimeout(timerHandler, timeout + additionalCombineChunksTimeout);
    this.combineChunksSessionProlongInterval = setInterval(intervalHandler, interval);
  }

  clearCombineChunksTimeout() {
    clearTimeout(this.combineChunksTimeout);
    clearInterval(this.combineChunksSessionProlongInterval);
  }

  serialize() {
    return {
      id: this.id,
      type: 'upload',
    };
  }

  addError(error: UploadError) {
    this.status = Upload.FAILURE;
    this.errors.push(error);
  }

  isSuccess() {
    return this.status === Upload.SUCCESS;
  }

  isPending() {
    return this.status === Upload.PENDING;
  }

  isUploading() {
    return this.status === Upload.UPLOADING;
  }

  isJoining() {
    return this.status === Upload.JOINING;
  }

  isFailure() {
    return this.status === Upload.FAILURE;
  }

  isProcessing() {
    return this.isPending() || this.isUploading() || this.isJoining();
  }

  isProcessed() {
    return this.isFailure() || this.isSuccess();
  }

  getStatusCompareNumber() {
    if (this.isFailure()) {
      return 0;
    }
    if (this.isUploading()) {
      return 1;
    }
    if (this.isJoining()) {
      return 2;
    }
    if (this.isPending()) {
      return 3;
    }
    if (this.isSuccess()) {
      return 4;
    }
    return 5;
  }

  static get FAILURE() {
    return UploadStatus.FAILURE;
  }

  static get UPLOADING() {
    return UploadStatus.UPLOADING;
  }

  static get JOINING() {
    return UploadStatus.JOINING;
  }

  static get PENDING() {
    return UploadStatus.PENDING;
  }

  static get SUCCESS() {
    return UploadStatus.SUCCESS;
  }
}
