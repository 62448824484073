import getDataroomBasedUrl from './getDataroomBasedUrl';

const stagingPrefix = 'staging';

const getFolderUrl = (dataroomName: string, folderId: number, isStaging: boolean = false) => getDataroomBasedUrl(
  dataroomName,
  isStaging ? `${ stagingPrefix }/${ folderId }` : `${ folderId }`,
);

export default getFolderUrl;
