import React from 'react';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import styles from './changePermissionsButton.scss';
import IconLock from '@dealroadshow/uikit/core/components/Icon/IconLock';

interface IProps {
  onShowPermissionsSection: () => void,
  disabled: boolean,
}

const ChangePermissionsButton = ({
  onShowPermissionsSection,
  disabled,
}: IProps) => (
  <Button
    variant={ ButtonVariantType.text }
    className={ styles.changePermissionsButton }
    onClick={ onShowPermissionsSection }
    disabled={ disabled }
    dataTest="changePermissionsButton"
  >
    <IconLock
      className={ styles.changePermissionsIcon }
    />
    <span className={ styles.changePermissionsButtonText }>Change Permissions</span>
  </Button>
);

export default ChangePermissionsButton;
