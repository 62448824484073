import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { parseDateString } from '@/Framework/DateTime/parseDateString';
import { IFileUploadNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/FileUploadEmailAlerts';
import { IStagingFileUploadNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/StagingFileUploadEmailAlerts';
import { IDigestNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/DigestEmailAlerts';
import { IAccessRequestNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/AccessRequestEmailAlerts';
import { IQuestionsNotificationSettingsPayload } from '@/dataroom/domain/vo/emailAlerts/QuestionsEmailAlerts';

const getShortTime = (time: string): string => (
  formatInTimeZone(parseDateString(time, null, 'H:mm:ss'), null, 'ha')
);

export const getNotificationSummary = (
  values: IFileUploadNotificationSettingsPayload |
    IStagingFileUploadNotificationSettingsPayload |
    IAccessRequestNotificationSettingsPayload,
  abbr: string,
) => {
  if (!values) return 'None';

  const result = [];

  if (values.isRealTime) {
    result.push('Real-time');
  }

  if (values.isDaily) {
    result.push(`Daily at ${ getShortTime(values.dailyTime) } ${ abbr }`);
  }

  if (values.isWeekly) {
    result.push(`Weekly on ${ values.weeklyDay } at ${ getShortTime(values.weeklyTime) } ${ abbr }`);
  }

  return result.length ? result.join('; ') : 'None';
};

export const getDigestNotificationSummary = (
  values: IDigestNotificationSettingsPayload,
  abbr: string,
) => {
  if (!values) return 'None';

  const result = [];

  if (values.isDigestDaily) {
    result.push(`Daily at ${ getShortTime(values.digestDailyTime) } ${ abbr }`);
  }

  if (values.isDigestWeekly) {
    result.push(`Weekly on ${ values.digestWeeklyDay } at ${ getShortTime(values.digestWeeklyTime) } ${ abbr }`);
  }

  return result.length ? result.join('; ') : 'None';
};

export const getQuestionNotificationSummary = (
  values: IQuestionsNotificationSettingsPayload,
  abbr: string,
) => {
  if (!values) return 'None';

  const result = [];

  if (values.isQuestionRealTime) {
    result.push('Real-time');
  }

  if (values.isQuestionDaily && values.questionDailyTime?.length) {
    result.push(`Daily at ${ getShortTime(values.questionDailyTime) } ${ abbr }`);
  }

  if (values.isQuestionWeekly) {
    result.push(`Weekly on ${ values.questionWeeklyDay } at ${ getShortTime(values.questionWeeklyTime) } ${ abbr }`);
  }

  return result.length ? result.join('; ') : 'None';
};
