import React, { createContext, useContext, useState } from 'react';
import Dispatcher from '@dealroadshow/json-rpc-dispatcher';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import FileRepository from '@/dataroom/infrastructure/repository/FileRepository';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDIContext } from '@/Framework/DI/DIContext';
import { IFileInfo } from '@/dataroom/domain/vo/filesystem/FileInfo';

const useFileInfo = () => {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();
  const [isFetching, setIsFetching] = useState(false);
  const [fileInfo, setFileInfo] = useState<IFileInfo>(null);

  const getFileInfo = async (fileId: number) => {
    setIsFetching(true);

    try {
      const fileRepository = container.get<FileRepository>(FileRepository);
      const response = await fileRepository.getInfo({
        fileId,
        dataroomId: dataroom.id,
      });

      setFileInfo(response);
    } catch (error) {
      !Dispatcher.isAbortError(error) && container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  const resetFileInfo = () => setFileInfo(null);

  return {
    isFetching,
    fileInfo,
    getFileInfo,
    resetFileInfo,
  };
};

type TFileInfoContext = ReturnType<typeof useFileInfo>;

export const FileInfoContext = createContext<TFileInfoContext>(null);

export function useFileInfoContext() {
  const context = useContext(FileInfoContext);
  if (!context) {
    throw new Error('useFileInfoContext must be used within a FileInfoContext');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function FileInfoContextProvider({ children } : IProps) {
  return (
    <FileInfoContext.Provider value={ useFileInfo() }>
      { children }
    </FileInfoContext.Provider>
  );
}

export default FileInfoContextProvider;
