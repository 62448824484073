import FileWithUploadAndPath from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/FileWithUploadAndPath';
import { IUploadStrategy } from '@/dataroom/application/FileUploader/uploadStrategy/IUploadStrategy';
import UploadError from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/UploadError';
import {
  isValidExtension,
  isValidName,
  isValidPathWithoutName,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/helpers';

const validateFile = async (file: FileWithUploadAndPath, uploadStrategy: IUploadStrategy, allowedFileTypes) => {
  let error: UploadError;
  if (!isValidPathWithoutName(file.path)) {
    // The delay is necessary in order to simulate a request to the server when re-uploading the file
    await uploadStrategy.delay();
    error = new UploadError('Invalid folder name');
  }
  if (!isValidName(file.name)) {
    // The delay is necessary in order to simulate a request to the server when re-uploading the file
    await uploadStrategy.delay();
    error = new UploadError('Invalid file name');
  }

  if (!isValidExtension(allowedFileTypes, file.name)) {
    // The delay is necessary in order to simulate a request to the server when re-uploading the file
    await uploadStrategy.delay();
    error = new UploadError('This file extension is prohibited');
  }
  if (!isValidPathWithoutName(file.path)) {
    // The delay is necessary in order to simulate a request to the server when re-uploading the file
    await uploadStrategy.delay();
    error = new UploadError('Invalid file path');
  }
  return error;
};

export default validateFile;
