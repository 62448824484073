import React, { useCallback, useState } from 'react';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import CreateFolderSection from './CreateFolderSection';
import ChangePermissionsSection from './ChangePermissionsSection';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';
import { IChangePermissionsType } from '@/dataroom/domain/vo/filesystem/ChangePermissionsType';
import { TFinalFormRenderProp } from '@/Framework/UI/Organisms/FinalForm/interfaces';

interface IProps {
  initialValues: {},
  parentFolder: IFolderTree,
  isPermissionsSectionVisible: boolean,
  userHasSystemManageAccess: boolean,
  onShowPermissionsSection: () => void,
  closeModal: () => void,
  footerRefCallback: () => void,
}

const CreateFolderForm = ({
  initialValues,
  parentFolder,
  isPermissionsSectionVisible,
  userHasSystemManageAccess,
  onShowPermissionsSection,
  closeModal,
  footerRefCallback,
}: IProps) => {
  const { createFolder: { createFolders } } = useFilesystemContext();
  const [permissions, setPermissions] = useState<IChangePermissionsType>({});
  const { allItems, selectedItems, disabledIds, defaultCheckboxGroupName, ...permissionGroups } = permissions;

  const handleFormSubmit = (formData) => createFolders(parentFolder, formData, permissionGroups).then(closeModal);

  const renderForm: TFinalFormRenderProp = useCallback(({
    valid,
  }, {
    Field,
    FieldArray,
  }) => (
    <>
      <CreateFolderSection
        isValid={ valid }
        closeModal={ closeModal }
        isPermissionsSectionVisible={ isPermissionsSectionVisible }
        userHasSystemManageAccess={ userHasSystemManageAccess }
        onShowPermissionsSection={ onShowPermissionsSection }
        fieldComponent={ Field }
        fieldArrayComponent={ FieldArray }
        footerRefCallback={ footerRefCallback }
        parentFolder={ parentFolder }
      />
      { userHasSystemManageAccess && isPermissionsSectionVisible && (
        <ChangePermissionsSection
          isValid={ valid }
          closeModal={ closeModal }
          parentFolderId={ parentFolder.id }
          onChange={ setPermissions }
          footerRefCallback={ footerRefCallback }
        />
      ) }
    </>
  ), [isPermissionsSectionVisible, footerRefCallback]);

  return (
    <FinalForm
      name="createFolderForm"
      dataTest="createFolderForm"
      initialValues={ initialValues }
      onSubmit={ handleFormSubmit }
      render={ renderForm }
      keepDirtyOnReinitialize
    />
  );
};

export default CreateFolderForm;
