import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import FolderRepository from '@/dataroom/infrastructure/repository/FolderRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { decorateNode } from '@/dataroom/domain/filesystem';

const usePrimaryFolderTree = () => {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();
  const { currentUser, canUserAccessPrimary } = useCurrentUserContext();

  const [isPrimaryFetching, setIsPrimaryFetching] = useState<boolean>(false);
  const [primaryFolderTree, setPrimaryFolderTree] = useState<IFolderTree>(null);

  useEffect(() => {
    if (!dataroom || !currentUser) {
      return;
    }

    canUserAccessPrimary && getPrimaryFolderTree();
  }, [dataroom?.id, currentUser?.id]);

  const getPrimaryFolderTree = async () => {
    setIsPrimaryFetching(true);

    try {
      const folderRepository = container.get<FolderRepository>(FolderRepository);
      const response = await folderRepository.getTree({
        dataroomId: dataroom.id,
      });
      const primaryFolderTree = decorateNode(response, null);

      setPrimaryFolderTree(primaryFolderTree);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsPrimaryFetching(false);
    }
  };

  return {
    getPrimaryFolderTree,
    isPrimaryFetching,
    primaryFolderTree,
  };
};

type PrimaryFolderTreeContextType = ReturnType<typeof usePrimaryFolderTree>;

export const PrimaryFolderTreeContext = createContext<PrimaryFolderTreeContextType>(null);

export const usePrimaryFolderTreeContext = () => {
  const context = useContext(PrimaryFolderTreeContext);
  if (!context) {
    throw new Error('usePrimaryFolderTreeContext must be used within a PrimaryFolderTreeContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const PrimaryFolderTreeContextProvider = ({ children }: IProps) => (
  <PrimaryFolderTreeContext.Provider value={ usePrimaryFolderTree() }>
    { children }
  </PrimaryFolderTreeContext.Provider>
);

export default PrimaryFolderTreeContextProvider;
