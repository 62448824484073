import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import { Area } from '@/dataroom/domain/vo/Area';
import { Action } from '@/dataroom/domain/vo/filesystem/Action';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';

const defaultAlert = `By default, file and folder permissions will carry over to the selected destination
  folder. Any permission group with access to these files and folders will continue to have access after this action
  is performed. To override the default, please select 'Change Permissions' below.`;

const copyStagingToStagingAlert = (tenant: DataroomTenant) => `By default, file and folder permissions will carry over
  to the selected destination folder. Any permission group with access to these files and folders will continue to have
  access after this action is performed. To override the default, please select 'Change Permissions' below. Any pinning
  on Staging will override pinning in the primary ${ DataroomTenantConfig.fromCode(tenant).options.tenantLabel } upon
  copying.`;

const copyPrimaryToStagingAlert = (tenant: DataroomTenant) => `By default, files and folders copied from the
  primary ${ DataroomTenantConfig.fromCode(tenant).options.tenantLabel } to the Staging Area will inherit the
  permissions of the destination folder. After this operation, only Admins and Users with explicit access to the
  Staging Area folders will be able to access these files and folders.`;

const copyStagingToPrimaryAlert = (tenant: DataroomTenant) => `By default, files and folders copied from the Staging
  Area to the primary ${ DataroomTenantConfig.fromCode(tenant).options.tenantLabel } will inherit the permissions of
  the destination folder. However, if you want to override this behavior and set different permissions than those of
  the destination folder, you can select 'Change Permissions' below. Any pinning on Staging will override pinning in
  the primary ${ DataroomTenantConfig.fromCode(tenant).options.tenantLabel } upon copying.`;

const movePrimaryToPrimaryAlert = `By default, file and folder permissions will carry over to the selected destination
  folder. Any permission group with access to these files and folders will continue to have access after this action
  is performed. To override the default, please select 'Change Permissions' below.`;

const movePrimaryToStagingAlert = (tenant: DataroomTenant) => `By default, files and folders moved from the
  primary ${ DataroomTenantConfig.fromCode(tenant).options.tenantLabel } to the Staging Area will inherit the
  permissions of the destination folder. After this operation, only Admins and Users with explicit access to the
  Staging Area folder will be able to access these files and folders.`;

const moveStagingToPrimaryAlert = (tenant: DataroomTenant) => `By default, files and folders moved from the Staging
  Area to the primary ${ DataroomTenantConfig.fromCode(tenant).options.tenantLabel } will inherit the permissions of
  the destination folder. However, if you want to override this behavior and set different permissions than those of
  the destination folder, you can select 'Change Permissions' below. Any pinning on Staging will override pinning in
  the primary ${ DataroomTenantConfig.fromCode(tenant).options.tenantLabel } upon moving.`;

const moveStagingToStagingAlert = (tenant: DataroomTenant) => `By default, file and folder permissions will carry over
  to the selected destination folder. Any permission group with access to these files and folders will continue to have
  access after this action is performed. To override the default, please select 'Change Permissions' below. Any pinning
  on Staging will override pinning in the primary ${ DataroomTenantConfig.fromCode(tenant).options.tenantLabel } upon
  moving.`;

export const getAlert = (
  tenant: DataroomTenant,
  actionName: Action,
  sourceArea: Area,
  activeArea: Area,
) => {
  const mapping = {
    [Action.Copy]: {
      [Area.Primary]: {
        [Area.Primary]: defaultAlert,
        [Area.Staging]: copyPrimaryToStagingAlert(tenant),
      },
      [Area.Staging]: {
        [Area.Primary]: copyStagingToPrimaryAlert(tenant),
        [Area.Staging]: copyStagingToStagingAlert(tenant),
      },
    },
    [Action.Move]: {
      [Area.Primary]: {
        [Area.Primary]: movePrimaryToPrimaryAlert,
        [Area.Staging]: movePrimaryToStagingAlert(tenant),
      },
      [Area.Staging]: {
        [Area.Primary]: moveStagingToPrimaryAlert(tenant),
        [Area.Staging]: moveStagingToStagingAlert(tenant),
      },
    },
  };

  return mapping[actionName][sourceArea][activeArea];
};
