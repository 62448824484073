import Base from './Base';

export default class RetryOne extends Base {
  getProgress = () => 0;

  delay = () => Base.delay();

  prepareForUpload = () => {};

  getFileProgressTotal = () => 0;

  getFileProgressTotalChunks = () => 0;

  getFileProgressInit = () => 0;

  getFileProgressJoinDone = () => 0;

  getFileProgressJoinRequest = () => 0;

  getFileProgressChunk = () => 0;

  getFileProgressRemaining = () => 0;
}
