import { Method } from 'constitute';
import { UploadDispatcher } from '@dealroadshow/file-uploader';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import UsersUploadAdapter
  from '@/dataroom/infrastructure/api/FileUploadDispatcher/uploadAdapters/UsersUploadAdapter';
import UsersUploadStrategy
  from '@/dataroom/infrastructure/api/FileUploadDispatcher/uploadAdapters/uploadStrategy/UsersUploadStrategy';
import { DataroomTenantContext, IDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';

import Container from '@/Framework/DI/Container';

class FactoryBuilder {
  container: Container;

  constructor(container) {
    this.container = container;
  }

  getFactory() {
    return (datarooomName: string) => {
      const { tenant } = this.container.get<IDataroomTenantContext>(DataroomTenantContext);

      const usersUploadAdapter = new UsersUploadAdapter(
        { url: dataroomUrl(tenant).getUsersUploadUrl(datarooomName) },
      );
      return new UploadDispatcher(new UsersUploadStrategy(usersUploadAdapter));
    };
  }
}

export default new Method(
  (container: Container) => {
    const factoryBuilder = new FactoryBuilder(container);
    return factoryBuilder.getFactory();
  },
  [Container],
) as ReturnType<InstanceType<typeof FactoryBuilder>['getFactory']>;
