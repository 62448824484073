import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';
import useRouter from '@/Framework/hooks/useNextRouter';
import getDataroomBasedUrl from '@/dataroom/infrastructure/getDataroomBasedUrl';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import {
  useAccessRequestsCountContext,
} from '@/dataroom/application/accessRequests/listing/AccessRequestsCountContext';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { AccessRequestStatus } from '@/dataroom/ui/components/Settings/ManageRequests/constants';
import { Role } from '@/dataroom/ui/components/Settings/ManagePermissionGroups/types';
import Dataroom from '@/dataroom/domain/Dataroom';
import { Area } from '@/dataroom/domain/vo/Area';
import { canManageSettingsLite } from '@/dataroom/domain/managePermissions';
import IconUsers from '@dealroadshow/uikit/core/components/Icon/IconUsers';
import IconTrash from '@dealroadshow/uikit/core/components/Icon/IconTrash';
import IconSettings from '@dealroadshow/uikit/core/components/Icon/IconSettings';
import IconDescription from '@dealroadshow/uikit/core/components/Icon/IconDescription';
import IconPieChart from '@dealroadshow/uikit/core/components/Icon/IconPieChart';
import IconLock from '@dealroadshow/uikit/core/components/Icon/IconLock';
import IconFileLock from '@dealroadshow/uikit/core/components/Icon/IconFileLock';
import IconUserLock from '@dealroadshow/uikit/core/components/Icon/IconUserLock';

export default () => {
  const { dataroom } = useDataroomContext();
  const { tenant } = useDataroomTenantContext();
  const {
    canUserAccessPrimary,
    canUserAccessStaging,
    currentUser,
  } = useCurrentUserContext();
  const {
    accessRequestsCount,
    isAccessRequestsCountFetching,
  } = useAccessRequestsCountContext();
  const { asPath: pathname } = useRouter();

  const {
    name: dataroomName,
    id: dataroomId,
  } = dataroom;
  const settingsPath = getDataroomBasedUrl(dataroomName, 'settings');
  const isUserAdminLite = canManageSettingsLite(currentUser);

  const {
    isAnalyticsEnabled,
    isManageWhitelistEnabled,
    isManageAccessRequestsEnabled,
    tenantSettingsPrefix,
    tenantUrlPrefix,
  } = DataroomTenantConfig.fromCode(tenant).options;

  return {
    manageRequests: {
      key: 'manage-requests',
      title: 'Manage Requests',
      path: `${ settingsPath }/manage-access-requests/${ AccessRequestStatus.Pending }`,
      isActive: pathname.startsWith(`${ settingsPath }/manage-access-requests`),
      icon: IconUserLock,
      isVisible: !!isManageAccessRequestsEnabled && !Dataroom.isDataroomOpenAccess(dataroom),
      isDisabled: isUserAdminLite,
      isCountLabelFetching: isAccessRequestsCountFetching[AccessRequestStatus.Pending],
      count: accessRequestsCount[AccessRequestStatus.Pending],
      isExternal: false,
      isAdminLite: isUserAdminLite,
    },
    manageWhitelist: {
      key: 'manage-whitelist',
      title: 'Manage Whitelist',
      path: `${ settingsPath }/manage-whitelist`,
      isActive: pathname.startsWith(`${ settingsPath }/manage-whitelist`),
      icon: IconFileLock,
      isVisible: !!isManageWhitelistEnabled && !Dataroom.isDataroomOpenAccess(dataroom),
      isDisabled: isUserAdminLite,
      isCountLabelFetching: false,
      count: null,
      isExternal: false,
      isAdminLite: isUserAdminLite,
    },
    manageUsers: {
      key: 'manage-users',
      title: 'Manage Users',
      path: `${ settingsPath }/users-permissions`,
      isActive: pathname.startsWith(`${ settingsPath }/users-permissions`),
      icon: IconUsers,
      isVisible: true,
      isDisabled: false,
      isCountLabelFetching: false,
      count: null,
      isExternal: false,
      isAdminLite: isUserAdminLite,
    },
    managePermissionGroups: {
      key: 'manage-permission-groups',
      title: 'Manage Permission Groups',
      path: `${ settingsPath }/permission-groups/${ Role.Manage }`,
      isActive: pathname.startsWith(`${ settingsPath }/permission-groups`),
      icon: IconLock,
      isVisible: true,
      isDisabled: isUserAdminLite,
      isCountLabelFetching: false,
      count: null,
      isExternal: false,
      isAdminLite: isUserAdminLite,
    },
    auditTrail: {
      key: 'audit-trail',
      title: 'Audit Trail',
      path: `${ settingsPath }/audit-trail`,
      isActive: pathname.startsWith(`${ settingsPath }/audit-trail`),
      icon: IconDescription,
      isVisible: true,
      isDisabled: isUserAdminLite,
      isCountLabelFetching: false,
      count: null,
      isExternal: false,
      isAdminLite: isUserAdminLite,
    },
    analytics: {
      key: 'analytics',
      title: 'Analytics',
      path: dmPortalUrl.getUrl(
        `/${ tenant }/${ tenantUrlPrefix }/${ dataroomId }/analytics`,
      ),
      isActive: false,
      icon: IconPieChart,
      isVisible: !!isAnalyticsEnabled,
      isDisabled: !canUserAccessPrimary,
      isCountLabelFetching: false,
      count: null,
      isExternal: true,
      isAdminLite: isUserAdminLite,
    },
    dataroomSettings: {
      key: tenantSettingsPrefix,
      title: 'Settings',
      path: `${ settingsPath }/${ tenantSettingsPrefix }`,
      isActive: pathname.startsWith(`${ settingsPath }/${ tenantSettingsPrefix }`),
      icon: IconSettings,
      isVisible: true,
      isDisabled: isUserAdminLite,
      isCountLabelFetching: false,
      count: null,
      isExternal: false,
      isAdminLite: isUserAdminLite,
    },
    trash: {
      key: 'trash',
      title: 'Trash',
      path: `${ settingsPath }/trash${ canUserAccessPrimary && canUserAccessStaging ? `/${ Area.Primary }` : '' }`,
      isActive: pathname.startsWith(`${ settingsPath }/trash`),
      icon: IconTrash,
      isVisible: true,
      isDisabled: isUserAdminLite,
      isCountLabelFetching: false,
      count: null,
      isExternal: false,
      isAdminLite: isUserAdminLite,
    },
  };
};
