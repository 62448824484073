import React, { useEffect } from 'react';
import cn from 'classnames';
import CopyToClipboard from '@/Framework/UI/Molecules/CopyToClipboard';
import linkStyles from '@dealroadshow/uikit/core/styles/links.scss';
import IconCopy from '@dealroadshow/uikit/core/components/Icon/IconCopy';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Popover from '@dealroadshow/uikit/core/components/Popover';
import DialInItem from './DialInItem';
import { dialInTypes } from './config';
import styles from './dialInPopover.scss';

interface IProps {
  children: React.ReactElement,
  collection: {
    countryCode: string,
    countryName: string,
    phoneNumber: string,
    phoneCode?: string,
    description?: string,
  }[],
  wrapperClassName?: string,
  contentClassName?: string,
  getDialInPhones?: (dialInType: any) => void,
  isFetching?: boolean,
  dialInType?: string,
  usePortal?: boolean,
  defaultScrolledToTop?: boolean,
  renderOnlyVisible?: boolean,
}

const DialInPopover = ({
  children,
  collection,
  wrapperClassName,
  contentClassName,
  getDialInPhones,
  isFetching,
  dialInType,
  usePortal = false,
  defaultScrolledToTop = true,
  renderOnlyVisible,
}: IProps) => {
  useEffect(() => {
    if (getDialInPhones) {
      getDialInPhones(dialInType);
    }
  }, []);

  const scrollToTop = ({ contentRef }) => {
    if (defaultScrolledToTop && contentRef?.current) {
      contentRef.current.scrollTop = 0;
    }
  };

    const dialInsList = collection.map((item) => (
      <DialInItem
        key={ item.countryCode + item.phoneNumber }
        countryCode={ item.countryCode }
        countryName={ item.countryName }
        phoneCode={ item.phoneCode }
        phoneNumber={ item.phoneNumber }
        description={ item.description }
      />
    ));

    const dialInsToCopy = collection.map((item) => (`${ item.countryName }${ item.description ? ` (${ item.description })` : '' }:${ item.phoneCode ? ` ${ item.phoneCode }` : '' } ${ item.phoneNumber }`)).join('\n');

    const content = isFetching ? (
      <Spinner centered />
    ) : (
      <>
        <div className={ styles.header }>
          Dial-In Codes work with all int’l numbers below
          <div className={ cn(linkStyles.link, styles.copyIconWrp) }>
            <CopyToClipboard
              content={ dialInsToCopy }
              copyText="Copy All"
            >
              <IconCopy className={ styles.copyIcon } />
            </CopyToClipboard>
          </div>
        </div>
        { dialInsList }
      </>
    );

    return (
      <Popover
        content={ content }
        className={ cn(styles.popover, {
          [styles.isDialInTypeAudio]: dialInType === dialInTypes.AUDIO_LISTENING,
        }) }
        contentClassName={ cn(styles.popoverContent, contentClassName) }
        wrapperClassName={ wrapperClassName }
        usePortal={ usePortal }
        minPadding={ 0 }
        afterClose={ scrollToTop }
        triggerEvent="hover"
        renderOnlyVisible={ renderOnlyVisible }
      >
        { children }
      </Popover>
    );
};

export default DialInPopover;
