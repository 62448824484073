import Upload, { UploadStatus } from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/Upload';

export function convertUploadStatusesToSting(types: UploadStatus) {
  switch (types) {
    case Upload.FAILURE:
      return 'Upload Failed';
    case Upload.SUCCESS:
      return 'Upload Processed';
    default:
      return 'Upload Pending';
  }
}
