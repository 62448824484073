import useRouter from '@/Framework/hooks/useNextRouter';
import NavigationItem from '@/dataroom/ui/components/Dataroom/Sidebar/Navigation/NavigationItem';
import getDataroomBasedUrl from '@/dataroom/infrastructure/getDataroomBasedUrl';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { Area } from '@/dataroom/domain/vo/Area';
import IconRedaction from '@dealroadshow/uikit/core/components/Icon/IconRedaction';

interface IProps {
  className: string,
}

const RedactionItem = ({ className }: IProps) => {
  const { dataroom } = useDataroomContext();
  const {
    canUserAccessPrimary,
    canUserAccessStaging,
  } = useCurrentUserContext();
  const { asPath: pathname } = useRouter();

  return (
    <NavigationItem
      path={
        getDataroomBasedUrl(
          dataroom.name,
          `redaction/redaction-area${ canUserAccessPrimary && canUserAccessStaging ? `/${ Area.Primary }` : '' }`,
        )
      }
      isActive={ pathname.startsWith(getDataroomBasedUrl(dataroom.name, 'redaction')) }
      icon={ IconRedaction }
      title="Redaction"
      className={ className }
    />
  );
};

export default RedactionItem;
