import isDefined from '@/Framework/dataHelpers/isDefined';
import { IDataroom } from '@/dataroom/domain/vo/Dataroom';
import { Area } from '@/dataroom/domain/vo/Area';
import { FolderType, FileType, UrlType } from '@/dataroom/domain/vo/types/FilesystemItemType';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { IFilesystemItem } from '@/dataroom/domain/vo/filesystem/FilesystemItem';
import { ICopyMoveFilesystemItem } from '@/dataroom/domain/vo/filesystem/CopyMoveFilesystemItem';
import { IFilesystemConflictResolvingItem } from '@/dataroom/domain/vo/filesystem/FilesystemConflictResolvingItem';

export const filesystemItemType: {
  FOLDER: FolderType,
  FILE: FileType,
  URL: UrlType,
} = {
  FOLDER: 'folder',
  FILE: 'file',
  URL: 'url',
};

export const isFolder = (item: IFilesystemItem): boolean => (
  item.type !== filesystemItemType.FILE && item.type !== filesystemItemType.URL
);

export const isFile = (item: IFilesystemItem): boolean => item.type === filesystemItemType.FILE;

export const isUrl = (item: IFilesystemItem): boolean => item.type === filesystemItemType.URL;

export const isRoot = (item: IFilesystemItem): boolean => (item.parentFolderId === null);

const getNodeDataById = (
  tree: IFolderTree[],
  id: number,
  path: IFolderTree[] = [],
): {
  item: IFolderTree,
  path: IFolderTree[],
  rootDistance: number,
} => {
  for (let i = 0; i < tree.length; i++) {
    const item = tree[i];

    if (item?.id === id) {
      return { item, path, rootDistance: i };
    }

    if (item?.children?.length) {
      const itemById = getNodeDataById(item.children, id, path);
      if (isDefined(itemById.item)) {
        path.push(item);
        return {
          ...itemById,
          rootDistance: Number(i + itemById.rootDistance.toString()),
        };
      }
    }
  }
  return { item: null, path, rootDistance: 0 };
};

export const getNodeItemById = (tree: IFolderTree, id: number): IFolderTree => getNodeDataById([tree], id).item;

export const getNodePathById = (tree: IFolderTree, id: number): IFolderTree[] => getNodeDataById([tree], id).path;

export const getRootClosestPathByIds = (tree: IFolderTree, ids: number[]): IFolderTree[] => {
  const nodePaths = ids.map((id) => getNodeDataById([tree], id));
  const nodePathsByDistance = nodePaths.sort((a, b) => a.rootDistance - b.rootDistance);
  const rootClosestPath = nodePathsByDistance[0];
  const { item, path } = rootClosestPath;

  return item ? [item].concat(path) : path;
};

/**
 * Return index prefix array.
 * For root folder it is always be an empty array - [].
 * For root file it always be - ['0.0'].
 * For other cases it is an array of indexes splitted by dot excluding current index.
 */
export const getParentIndexArray = (fileIndexFull: string): string[] => {
  const indexes = fileIndexFull.replace('S', '').split('.');
  const tail = indexes.pop();

  switch (true) {
    case tail === '0': // root folder
      return [];
    case indexes[0] === '0': // root file
      return ['0.0'];
    default:
      return [...indexes, ...tail.split('-')].slice(0, -1);
  }
};

/**
 * @return {boolean}
 */
export const isFilesystemPreparingForArchive = () => false; // TODO implement logic

export const filterSkippedItems = (
  items: IFilesystemConflictResolvingItem[],
): IFilesystemConflictResolvingItem[] => (
  items.filter(({ resolving }) => resolving !== 'skip')
);

export const getNotificationPrefix = (items: IFilesystemItem[]): string => {
  const { length } = items;

  if (length === 0) {
    return '';
  }

  if (length === 1) {
    return `"${ items[0].name }" has`;
  }

  const folderCount = items.filter(({ type }) => type === filesystemItemType.FOLDER).length;
  const fileCount = length - folderCount;
  const parts = [];

  if (folderCount) {
    parts.push(folderCount + (folderCount === 1 ? ' folder' : ' folders'));
  }

  if (fileCount) {
    parts.push(fileCount + (fileCount === 1 ? ' file' : ' files'));
  }

  return `${ parts.join(' and ') } have`;
};

export const prepareCopyMoveItems = (
  filesystemItems: IFilesystemConflictResolvingItem[],
  destinationFolder: IFolderTree,
): {
  folders: ICopyMoveFilesystemItem[],
  files: ICopyMoveFilesystemItem[],
} => {
  const prepareItem = (fileSystemItem: IFilesystemConflictResolvingItem): ICopyMoveFilesystemItem => {
    fileSystemItem = { ...fileSystemItem };
    return {
      id: fileSystemItem.id,
      destination: fileSystemItem.destination || {
        id: destinationFolder.id,
      },
      resolving: fileSystemItem.resolving || null,
    };
  };

  return splitItems<ICopyMoveFilesystemItem>(filesystemItems, prepareItem);
};

/**
 * Splits filesystem items into files and folders arrays.
 */
export const splitItems = <T>(
  filesystemItems: IFilesystemConflictResolvingItem[],
  prepareItemCallback?: (fileSystemItem: IFilesystemConflictResolvingItem) => T,
): {
  folders: T[],
  files: T[],
} => {
  const prepareItem = prepareItemCallback || ((item) => item);
  const prepared = {
    folders: [],
    files: [],
  };

  return filesystemItems.reduce((prepared, filesystemItem) => {
    if (isFolder(filesystemItem)) {
      prepared.folders.push(prepareItem(filesystemItem));
    } else {
      prepared.files.push(prepareItem(filesystemItem));
    }
    return prepared;
  }, prepared);
};

export const decorateNode = (folderTree: IFolderTree, parent: IFolderTree, isStaging: boolean = false): IFolderTree => {
  if (parent) {
    const { children, ...parentFolder } = parent;

    folderTree.parent = {
      ...parentFolder,
      childrenCount: children.length,
    };
  } else {
    folderTree.parent = null;
  }

  folderTree.type = filesystemItemType.FOLDER;
  folderTree.isStaging = isStaging;

  folderTree.children.forEach((node) => {
    decorateNode(node, folderTree, isStaging);
  });

  return folderTree;
};

export const isFileIndexingEnabled = (dataroom: IDataroom, area: Area = Area.Primary) => (
  area === Area.Primary ? dataroom.fileIndexing : dataroom.stagingFileIndexing
);
