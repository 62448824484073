import { AccessRequestType } from '@/dataroom/domain/vo/accessRequests/AccessRequestType';

export enum AccessRequestStatus {
  Pending = 'pending',
  Approved = 'approved',
  Denied = 'denied',
}

export const APPROVE_BUTTON = 'approveButton';
export const DENY_BUTTON = 'denyButton';
export const ADD_DOMAIN_BUTTON = 'addDomainButton';
export const REVERT_BUTTON = 'revertButton';

export const buttons = {
  [AccessRequestStatus.Pending]: [
    APPROVE_BUTTON,
    DENY_BUTTON,
    ADD_DOMAIN_BUTTON,
  ],
  [AccessRequestStatus.Approved]: [
    APPROVE_BUTTON,
    ADD_DOMAIN_BUTTON,
  ],
  [AccessRequestStatus.Denied]: [
    APPROVE_BUTTON,
    REVERT_BUTTON,
  ],
};

export const tabs: AccessRequestType[] = [
  AccessRequestStatus.Pending,
  AccessRequestStatus.Approved,
  AccessRequestStatus.Denied,
];
