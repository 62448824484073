import React from 'react';
import { useVirtualDataTableContext } from '../VirtualDataTableContext';
import DataTable from '@dealroadshow/uikit/core/components/Table/DataTable';

const InnerComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(({
  children,
  ...otherProps
}, ref) => {
  const { dataTableProps } = useVirtualDataTableContext();

  return (
    <div { ...otherProps } ref={ ref }>
      <DataTable
        { ...dataTableProps }
        style={ {
          position: 'relative',
          width: '100%',
        } }
        rows={ children }
      />
    </div>
  );
});

export default InnerComponent;
