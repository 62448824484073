import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDialInPhones } from '@/dealroadshow/application/actions/dialIn/actions';
import DialInPopover from './DialInPopover';

function mapStateToProps(state) {
  return {
    collection: state.roadshow.dialIn.collection,
    isFetching: state.roadshow.dialIn.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getDialInPhones,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DialInPopover);
