export default class Percentage {
  constructor(public total = 0, public part = 0, public scale = 100) {}

  calculatePercentage = () => {
    if (this.total === 0) {
      return 0;
    }
    return Number(((this.part * this.scale) / this.total).toFixed(5));
  };
}
