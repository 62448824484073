import createAction from '@/Framework/State/Redux/createAction';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import { NotificationManager } from '@/Framework/Notification';
import * as actionTypes from './actionTypes';

export const getDialInPhones = (dialInType) => async (dispatch, getState) => {
  dispatch(createAction(actionTypes.REQUEST_DIAL_IN_PHONES));
  try {
    const roadshowRepository = getState().container.get(RoadshowRepository);
    const dialInPhones = await roadshowRepository.getDialInPhones({ dialInType });
    dispatch(createAction(actionTypes.REQUEST_DIAL_IN_PHONES_SUCCESS, dialInPhones));
  } catch (error) {
    NotificationManager.error(getErrorMessage(error));
    dispatch(createAction(actionTypes.REQUEST_DIAL_IN_PHONES_ERROR));
  }
};
