import { getEventCoordinates } from '@dnd-kit/utilities';
import { Modifier } from '@dnd-kit/core';

export const pluralize = (count: number, noun: string):string => `${ count } ${ noun }${ count !== 1 ? 's' : '' }`;

export const snapCenterToCursor: Modifier = ({
  activatorEvent,
  draggingNodeRect,
  transform,
}) => {
  if (draggingNodeRect && activatorEvent) {
    const activatorCoordinates = getEventCoordinates(activatorEvent);

    if (!activatorCoordinates) {
      return transform;
    }

    const offsetX = activatorCoordinates.x - draggingNodeRect.left;
    const offsetY = activatorCoordinates.y - draggingNodeRect.top - draggingNodeRect.height;

    return {
      ...transform,
      x: transform.x + offsetX,
      y: transform.y + offsetY,
    };
  }

  return transform;
};
