export const addHttpToUrl = (url: string): string => {
  if (!url) {
    return '';
  }
  return (/^((https?|ftp):\/\/)/.test(url)) ? url : `http://${ url }`;
};

export const cutProtocol = (url: string): string => url?.replace(/https?:\/\//i, '');

export const cutProtocolAndWWW = (url: string): string => url?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');

export const replaceProtocolWithWWW = (url: string): string => `www.${ cutProtocolAndWWW(url) }`;
