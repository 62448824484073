import { useState } from 'react';

import CancelChangesModal from './CancelChangesModal';

export default function useCancelChangesModal() {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  function showCanceledModal() {
    setIsVisible(true);
  }

  function hideCanceledModal() {
    setIsVisible(false);
  }

  return {
    CancelChangesModal,
    isVisible,
    showCanceledModal,
    hideCanceledModal,
  };
}
