import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';
import { systemFolders } from '@/dataroom/domain/filesystemPermissions';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { ResponsiveModal as Modal } from '@dealroadshow/uikit/core/components/Modal';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import styles from './disableModal.scss';

const modalTitles = {
  [systemFolders.audioUpload]: 'Disable Audio Uploading',
  [systemFolders.emailUpload]: 'Disable Email Uploading',
};

interface IProps {
  items: IFilesystemListItem[],
  closeModal: () => void,
}

const DisableModal = ({ items, closeModal }: IProps) => {
  const { tenant } = useDataroomTenantContext();
  const { tenantLabelSm } = DataroomTenantConfig.fromCode(tenant).options;
  const { disable: { isFetching, disable } } = useFilesystemContext();

  const handleDisable = () => disable(items).then(closeModal);

  const footer = (
    <>
      <Button
        variant={ ButtonVariantType.action }
        title="Disable"
        onClick={ handleDisable }
        disabled={ isFetching }
        dataTest="disableModalCloseButton"
      />
      <Button
        title="Cancel"
        variant={ ButtonVariantType.text }
        onClick={ closeModal }
        dataTest="disableModalCancelButton"
      />
    </>
  );

  if (isEmpty(items)) {
    return null;
  }

  return (
    <Modal
      title={ items.length === 1 ? modalTitles[items[0].name] : `Disable ${ items.length } Selected Items` }
      isVisible
      onCloseClicked={ closeModal }
      footer={ footer }
      dataTest="disableModal"
    >
      <div className={ styles.contentWrp }>
        <div className={ styles.messageWrp }>
          Please confirm you would like to disable the selected
          item{ items.length > 1 ? 's' : '' } to
          the { tenantLabelSm }.
          Selecting "<strong>Disable</strong>" below will
          hide {
            items.length === 1 ? `the "${ items[0].name }" folder` : `${ items.length } Selected Items`
          } from all
          Users in the { tenantLabelSm }.
        </div>
        <Spinner
          isVisible={ isFetching }
          overlay
        />
      </div>
    </Modal>
  );
};

export default DisableModal;
