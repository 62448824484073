import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import { Area } from '@/dataroom/domain/vo/Area';

interface IDownloadRequestProps {
  uuid: string,
  hash: string,
  folders: { id: number }[],
  files: { id: number }[],
  areas: Area[],
  quickFilterName: string,
  filters: { [key: string]: string | number },
  truncationMode: string,
  stagingTruncationMode: string,
  onFinish: typeof noop,
  onDownload: typeof noop,
  isTruncationModalVisible: boolean,
  isEmailModalVisible: boolean,
  isPreloadModalVisible: boolean,
  notificationTimer: ReturnType<typeof setTimeout>,
}

export default class DownloadRequest {
  public uuid: IDownloadRequestProps['uuid'] = null;

  public hash: IDownloadRequestProps['hash'] = null;

  public folders: IDownloadRequestProps['folders'] = [];

  public files: IDownloadRequestProps['files'] = [];

  public areas: IDownloadRequestProps['areas'] = [];

  public quickFilterName: IDownloadRequestProps['quickFilterName'] = '';

  public filters: IDownloadRequestProps['filters'] = {};

  public truncationMode: IDownloadRequestProps['truncationMode'] = 'Undefined';

  public stagingTruncationMode: IDownloadRequestProps['stagingTruncationMode'] = 'Undefined';

  public onFinish: IDownloadRequestProps['onFinish'] = noop;

  public onDownload: IDownloadRequestProps['onDownload'] = noop;

  public isTruncationModalVisible: IDownloadRequestProps['isTruncationModalVisible'] = false;

  public isEmailModalVisible: IDownloadRequestProps['isEmailModalVisible'] = false;

  public notificationTimer: IDownloadRequestProps['notificationTimer'] = null;

  constructor(props: Partial<IDownloadRequestProps>) {
    Object.keys(props).forEach((key) => { this[key] = props[key] ?? this[key]; });
  }

  get isSingleFileRequest() { return this.files.length === 1 && !this.folders.length; }

  get isDownloadAllRequested() { return isEmpty(this.folders) && isEmpty(this.files); }
}
