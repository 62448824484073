import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';

@Dependencies(JsonRpcDispatcherFactory)
class UploaderHealthCheckRepository {
  constructor(private rpc: typeof JsonRpcDispatcherFactory) {
  }

  sendTroublesNotification = async (payload: { dataroomId: number }): Promise<void> => {
    const request = new Request('upload.trouble_notify', payload);
    await this.rpc().call<RpcSuccess>(request);
  };
}

export default UploaderHealthCheckRepository;
