import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';

@Dependencies(JsonRpcDispatcherFactory)
class StagingFolderRepository {
  constructor(protected rpc: typeof JsonRpcDispatcherFactory) {}

  getTree = async (
    payload: {
      dataroomId: number,
    },
  ): Promise<IFolderTree> => {
    const request = new Request('dataroom.filesystem.tree.staging_folder_tree', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };
}

export default StagingFolderRepository;
