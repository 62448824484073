import styles from './permissionGroups.scss';
import { permissionType } from '@/dataroom/domain/filesystemPermissions';
import IconSettings from '@dealroadshow/uikit/core/components/Icon/IconSettings';
import IconDownload from '@dealroadshow/uikit/core/components/Icon/IconDownload';
import IconDisplay from '@dealroadshow/uikit/core/components/Icon/IconDisplay';
import IconLock from '@dealroadshow/uikit/core/components/Icon/IconLock';

export const permissionGroups = {
  manage: {
    name: permissionType.manage,
    label: 'Manage',
    icon: IconSettings,
  },
  viewAndDownload: {
    name: permissionType.viewAndDownload,
    label: 'Download',
    icon: IconDownload,
  },
  viewOnly: {
    name: permissionType.viewOnly,
    label: 'View only',
    icon: IconDisplay,
  },
  noAccess: {
    name: permissionType.noAccess,
    label: 'No access',
    icon: IconLock,
  },
  listOnly: {
    name: permissionType.listOnly,
    label: 'List only',
    icon: null,
  },
};

export const permissionGroupsClassNames = {
  [permissionGroups.manage.name]: styles.managePermission,
  [permissionGroups.viewAndDownload.name]: styles.viewAndDownloadPermission,
  [permissionGroups.viewOnly.name]: styles.viewOnlyPermission,
  [permissionGroups.noAccess.name]: styles.noAccessPermission,
};
