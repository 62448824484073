export const RESEARCHROOM_FEATURE = 'researchroomEnabled';
export const RESTRICT_ACCESS_FEATURE = 'restrictAccessEnabled';

export const featureToggle = {
  [RESEARCHROOM_FEATURE]: {
    cookieName: RESEARCHROOM_FEATURE,
  },
  [RESTRICT_ACCESS_FEATURE]: {
    cookieName: RESTRICT_ACCESS_FEATURE,
  },
};
