import { combineFieldsWithValues, groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateNumber } from '@/Framework/UI/Organisms/FinalForm/validators/validateNumber';
import { validateRequiredField } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredField';
import { validateNumberMaxMinAmount } from '@/Framework/UI/Organisms/FinalForm/validators/validateNumberMaxMinAmount';

const fileIndexField = { fieldName: 'fileIndexCurrent', fieldCaption: 'Number' };

export default (maxAmount, values) => {
  const { fileIndexCurrent } = combineFieldsWithValues([fileIndexField], values);

  return groupValidators(
    validateRequiredField({
      ...fileIndexCurrent,
    }),
    validateNumber({
      ...fileIndexCurrent,
    }),
    validateNumberMaxMinAmount({
      minAmount: 1,
      maxAmount,
      ...fileIndexCurrent,
      value: +fileIndexCurrent.value,
    }),
  );
};
