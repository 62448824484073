import { QuestionsPermission } from '@/dataroom/domain/questions/permissions';

export const canPermissionGroupAccessQna = (permissionGroup) => ([
    QuestionsPermission.FullAccessManager,
    QuestionsPermission.ViewOnlyManager,
    QuestionsPermission.Submitter,
  ].includes(permissionGroup.qnaPermission)
);

export const canPermissionGroupManageQna = (permissionGroup) => ([
    QuestionsPermission.FullAccessManager,
    QuestionsPermission.ViewOnlyManager,
  ].includes(permissionGroup.qnaPermission)
);

export const isFullAccessQuestionManager = (user) => user.permissionGroups.some((permissionGroup) => (
  permissionGroup.qna_permission === QuestionsPermission.FullAccessManager
));

export const isViewOnlyQuestionManager = (user) => user.permissionGroups.some((permissionGroup) => (
  permissionGroup.qna_permission === QuestionsPermission.ViewOnlyManager
));

export const isQuestionSubmitter = (user) => user.permissionGroups.some((permissionGroup) => (
  permissionGroup.qna_permission === QuestionsPermission.Submitter
));

export const canLeaveQuestion = (user) => !user.qnaIsLimitReached && user.permissionGroups.some((permissionGroup) => (
  !!permissionGroup.qna_can_create_question
));

export const canSubmitQuestion = (user) => user.permissionGroups.some((permissionGroup) => ([
  QuestionsPermission.FullAccessManager,
  QuestionsPermission.ViewOnlyManager,
  QuestionsPermission.Submitter,
].includes(permissionGroup.qna_permission) &&
  !!permissionGroup.qna_can_create_question
));

export const hasQuestionAccess = (user) => user.permissionGroups.some((permissionGroup) => ([
  QuestionsPermission.FullAccessManager,
  QuestionsPermission.ViewOnlyManager,
  QuestionsPermission.Submitter,
].includes(permissionGroup.qna_permission)));

export const isQuestionManager = (user) => user.permissionGroups.some((permissionGroup) => ([
  QuestionsPermission.FullAccessManager,
  QuestionsPermission.ViewOnlyManager,
].includes(permissionGroup.qna_permission)));
