import { ADD_CHILDREN_PROGRESS_COEFFICIENT, chunkSize } from '@/dataroom/application/FileUploader/constants';
import Upload from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/Upload';
import {
  IIncreaseUploadingTotalDifficulty,
  ISetAddChildrenDifficulty,
  IUploadStrategy,
} from '@/dataroom/application/FileUploader/uploadStrategy/IUploadStrategy';
import FileWithUploadAndPath from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/FileWithUploadAndPath';

export default abstract class Base implements IUploadStrategy {
  static TIME_DELAY = 300; // ms

  abstract delay(ms?: number): Promise<any>;

  static delay = (ms = Base.TIME_DELAY) => new Promise((resolve) => setTimeout(resolve, ms));

  prepareForUpload = (
    filesToUpload: FileWithUploadAndPath[],
    increaseUploadingTotalDifficulty: IIncreaseUploadingTotalDifficulty,
    setAddChildrenDifficulty: ISetAddChildrenDifficulty,
  ) => {
    let filesUploadActionsCount = 0;

    filesToUpload.forEach((file) => {
      const fileActionsCount = this.getFileProgressTotal(file);

      increaseUploadingTotalDifficulty(fileActionsCount);
      filesUploadActionsCount += fileActionsCount;
      file.upload.status = Upload.PENDING;
    });

    const addChildrenDifficulty = filesUploadActionsCount * ADD_CHILDREN_PROGRESS_COEFFICIENT;
    setAddChildrenDifficulty(addChildrenDifficulty);
    increaseUploadingTotalDifficulty(addChildrenDifficulty);
  };

  getFileProgressTotal = (file: File) => this.getFileProgressInit()
    + this.getFileProgressTotalChunks(file)
    + this.getFileProgressJoinRequest()
    + this.getFileProgressJoinDone();

  getFileProgressTotalChunks = (file: File) => Math.ceil(file.size / chunkSize);

  getFileProgressInit = () => 1;

  getFileProgressJoinRequest = () => 0;

  getFileProgressJoinDone = () => 1;

  getFileProgressChunk = () => 1;

  getFileProgressRemaining = (totalChunks, successfulChunks) => totalChunks
    - successfulChunks
    + this.getFileProgressJoinRequest()
    + this.getFileProgressJoinDone();
}
