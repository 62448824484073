import { Value, Method, Class, Transient } from 'constitute';
import JsonRpcDispatcher, { interceptors } from '@dealroadshow/json-rpc-dispatcher';
import { SockJsAdapter } from '@dealroadshow/socket-frontend-sdk';
import { isDataroom } from '@/dataroom/domain/config';
import DataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import addSessionData from '@/users/application/Session/interceptors/rpc/addSessionData';
import checkSession from '@/users/application/Session/interceptors/rpc/checkSession';
import parseWrappedError from '@/Framework/api/Rpc/parseWrappedError';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import Container from '@/Framework/DI/Container';
import Connection from '@/Framework/DI/Providers/Socket/Connection';

const dataroomSocketDispatcherFactory = (tenantCode: DataroomTenant): SockJsAdapter => {
  if (!tenantCode) {
    throw 'Tenant is required to perform dataroom api requests';
  }

  if (!isDataroom(tenantCode)) {
    throw `Only dataroom tenants allowed. ${ tenantCode } provided`;
  }

  const SocketAdapterProvider = new Class(SockJsAdapter, Transient.with([
    new Value(DataroomUrl(tenantCode).getDirectRpcUrl()),
    Connection,
  ]));

  return new Method((socketAdapter, container) => (
    (new JsonRpcDispatcher(socketAdapter))
      .interceptRequest(addSessionData(container))
      .interceptResponse(checkSession(container))
      .interceptResponse(parseWrappedError())
      .interceptResponse(interceptors.response.transformErrorToException)
  ), [SocketAdapterProvider, Container]);
};

export interface ISocketDispatcherFactory {
  (tenant: DataroomTenant): JsonRpcDispatcher,
}

export default new Value(dataroomSocketDispatcherFactory);
