export enum QuestionAlertsType {
  Your,
  All,
}

export const questionAlertTypes = [
  {
    name: QuestionAlertsType.All,
    label: 'All Questions',
  },
  {
    name: QuestionAlertsType.Your,
    label: 'Your Questions Only',
  },
];
