import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { AppProps } from 'next/app';
import Head from 'next/head';
import 'reflect-metadata';
import GlobalStyles, { styles } from '@/dataroom/ui/common/GlobalStyles';
import ApplicationFallback from '@/Framework/ErrorHandling/ErrorBoundary/ApplicationFallback';
import Bootstrap from '@/Framework/Bootstrap';
import ContentWrp from '@/Framework/UI/Templates/ContentWrp';
import ErrorBoundary from '@/Framework/ErrorHandling/ErrorBoundary';
import { createContainer, PageRenderingState } from '@/Framework/DI';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import DataroomLayout from '@/dataroom/ui/components/Dataroom/DataroomLayout';
import { SidebarTab } from '@/dataroom/ui/components/Dataroom/Sidebar/constants';
import Container from '@/Framework/DI/Container';
import GlobalProps from '@/Framework/Router/Next/GlobalProps';
import isServer from '@/Framework/Router/Next/isServer';
import '@/Framework/browser/polyfill';
import { IGlobalProps } from '@/Framework/Router/Next/withGlobalProps';
import config from '@/dataroom/application/config/config';
import contentWrpStyles from '@/Framework/UI/Templates/ContentWrp/contentWrp.scss';

const configureApp = (Component) => {
  // On Server Side we need to recreate container for each request
  // On Client Side we need to remember container in the closure.
  const container = isServer() ? null : createContainer(PageRenderingState.ClientSideRendering);

  return (props: AppProps) => (
    <Component
      container={ container || createContainer(PageRenderingState.ServerSideRendering) }
      { ...props }
    />
  );
};

const internalDataroomRoute = '/dataroom/[dataroomName]';

const internalDataroomRouteMapping = {
  '/dataroom/[dataroomName]/settings': SidebarTab.Settings,
  '/dataroom/[dataroomName]/questions': SidebarTab.Questions,
};

const App = ({ Component, pageProps, router, container }: AppProps<IGlobalProps> & {
  pageProps: {
    hostname: string,
  },
  container: Container,
}) => {
  const [isMounted, setIsMounted] = useState(false);

  const { code: tenant } = DataroomTenantConfig.fromHostname(pageProps?.hostname);
  const { pathname, query: { dataroomName = '' } } = router;

  const sidebarTabRouteKey = Object.keys(internalDataroomRouteMapping).find(
    (path) => pathname.startsWith(path),
  );
  const sidebarTabKey = internalDataroomRouteMapping[sidebarTabRouteKey] || SidebarTab.Dataroom;

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const isOverflowFixClassName
    = tenant === config.tenant.tenantResearchRoom.code && !pathname.startsWith(internalDataroomRoute);

  return (
    <>
      {
        (isMounted || !pathname.startsWith(internalDataroomRoute)) && (
          <GlobalStyles>
            <Head>
              <title />
              <meta charSet="utf-8" />
              <meta name="referrer" content="no-referrer-when-downgrade" />
              <meta
                httpEquiv="Cache-Control"
                content="no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0"
              />
              <meta httpEquiv="Pragma" content="no-store" />
              <meta httpEquiv="Expires" content="0" />
              <meta
                content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
                name="viewport"
              />
              <meta name="HandheldFriendly" content="true" />
            </Head>
            { /* @ts-ignore */ }
            <ErrorBoundary fallback={ ApplicationFallback }>
              <Bootstrap
                container={ container }
                session={ pageProps?.session }
                cookies={ pageProps?.cookies }
              >
                <GlobalProps { ...pageProps }>
                  <ContentWrp
                    className={ cn(styles.body, { [contentWrpStyles.wrpOverflowFix]: isOverflowFixClassName }) }
                  >
                    { pathname.startsWith(internalDataroomRoute) ? (
                      <DataroomLayout
                        tenant={ tenant }
                        dataroomName={ dataroomName.toString() }
                        sidebarTabKey={ sidebarTabKey }
                      >
                        <Component { ...pageProps } />
                      </DataroomLayout>
                    ) : (
                      <Component { ...pageProps } />
                    ) }
                  </ContentWrp>
                </GlobalProps>
              </Bootstrap>
            </ErrorBoundary>
          </GlobalStyles>
        )
      }
    </>
  );
};

export default configureApp(App);
