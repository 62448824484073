import { IDataroomListItem } from '@/dataroom/domain/vo/collection/DataroomListItem';
import { IDataroom } from '@/dataroom/domain/vo/Dataroom';
import { DataroomAccessType } from '@/dataroom/domain/vo/DataroomAccessType';

/**
 * @param {Object} dataroom
 * @return {Boolean}
 */
export default class Dataroom {
  static isDataroomDisabled = (dataroom: IDataroomListItem) => {
    const { isDeactivated } = dataroom;
    const isAccessRequestPending = dataroom.accessRequest?.isPending;
    const isAccessRequestDenied = dataroom.accessRequest?.isDenied;
    return isAccessRequestPending || isAccessRequestDenied || isDeactivated;
  };

  static isDataroomOpenAccess = (dataroom: IDataroom) => dataroom.publicity === DataroomAccessType.OpenAccess;
}
