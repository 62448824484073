import React from 'react';
import { useFileUploaderContext } from '@/dataroom/application/FileUploader/FileUploaderContext';
import UploaderProgressBar
  from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderProgress/UploaderProgressBar';

interface IProps {
  isErrorTab: boolean,
}

function UploaderProgress({ isErrorTab }: IProps) {
  const {
    uploadPercentage,
    processingPercentage,
    isUploadErrors,
    isUploading,
    isAddingChildren,
    isUploadComplete,
    allFiles,
  } = useFileUploaderContext();

  const isUploadedWithErrors = isUploadErrors();
  const uploadPercentageCalculated = ~~uploadPercentage.calculatePercentage();
  const processingPercentageCalculated = ~~processingPercentage.calculatePercentage();

  if (isUploadComplete() && isUploadedWithErrors) {
    return (
      <UploaderProgressBar
        status="Upload Processing Error"
        processingPercentage={ processingPercentageCalculated }
        uploadPercentage={ 100 }
        isError
      />
    );
  }

  if (isUploadedWithErrors && isErrorTab) {
    return (
      <UploaderProgressBar
        status="Upload Processing Error"
        processingPercentage={ processingPercentageCalculated }
        uploadPercentage={ uploadPercentageCalculated }
        isError
      />
    );
  }

  if (isUploadComplete() && !isAddingChildren) {
    return (
      <UploaderProgressBar
        status="Upload Processing Complete"
        processingPercentage={ processingPercentageCalculated }
        uploadPercentage={ uploadPercentageCalculated }
      />
    );
  }

  if (isUploading || isAddingChildren) {
    return (
      <UploaderProgressBar
        status={ `Uploading... (${ uploadPercentageCalculated }%)` }
        processingPercentage={ processingPercentageCalculated }
        uploadPercentage={ uploadPercentageCalculated }
      />
    );
  }
  if (processingPercentageCalculated === 100 && allFiles.length) {
    return (
      <UploaderProgressBar
        status="Ready to upload (100%). Press Upload to continue."
        processingPercentage={ processingPercentageCalculated }
        uploadPercentage={ uploadPercentageCalculated }
      />
    );
  }
  if (processingPercentageCalculated > 0 && processingPercentageCalculated < 100) {
    return (
      <UploaderProgressBar
        status={ `Processing for Upload... (${ processingPercentageCalculated }%)` }
        processingPercentage={ processingPercentageCalculated }
        uploadPercentage={ uploadPercentageCalculated }
      />
    );
  }

  return null;
}

export default UploaderProgress;
