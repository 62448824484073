import cn from 'classnames';
import React from 'react';
import styles from './uploaderProgressBar.scss';

interface IProps {
  children: React.ReactNode,
  isError?: boolean,
}

const Status = ({ children, isError }: IProps) => (
  <div
    className={ cn(styles.status, {
      [styles.error]: isError,
    }) }
  >
    { children }
  </div>
);

export default Status;
