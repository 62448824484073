import { RefObject } from 'react';
import { TVirtualBody } from '@/Framework/UI/Molecules/Tables/VirtualDataTable/interfaces';

export const overrideRangeToRender = (
  listRef: RefObject<TVirtualBody>,
  overscan: number,
) => {
  const innerGetRangeToRender = listRef.current._getRangeToRender.bind(listRef.current);

  listRef.current._getRangeToRender = () => {
    const { itemCount } = listRef.current.props;
    const [,, startIndex, stopIndex] = innerGetRangeToRender();
    const overscanCount = Math.max(1, overscan);

    return [
      Math.max(0, startIndex - overscanCount),
      Math.max(0, Math.min(itemCount - 1, stopIndex + overscanCount)),
      startIndex,
      stopIndex,
    ];
  };
};

export const getItemStyle = (listRef: RefObject<TVirtualBody>, index: number) => {
  return listRef.current?._getItemStyle(index);
};
