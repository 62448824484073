import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import StagingFolderRepository from '@/dataroom/infrastructure/repository/StagingFolderRepository';
import { useDIContext } from '@/Framework/DI/DIContext';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { decorateNode } from '@/dataroom/domain/filesystem';

const useStagingFolderTree = () => {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();
  const { currentUser, canUserAccessStaging } = useCurrentUserContext();

  const [isStagingInitialized, setIsStagingInitialized] = useState<boolean>(false);
  const [isStagingFetching, setIsStagingFetching] = useState<boolean>(false);
  const [stagingFolderTree, setStagingFolderTree] = useState<IFolderTree>(null);

  useEffect(() => {
    if (!dataroom || !currentUser) {
      return;
    }

    if (canUserAccessStaging) {
      getStagingFolderTree();
    } else {
      setStagingFolderTree(null);
      setIsStagingInitialized(true);
    }
  }, [canUserAccessStaging, dataroom?.id, currentUser?.id]);

  const getStagingFolderTree = async (): Promise<void> => {
    if (!canUserAccessStaging) {
      return;
    }

    setIsStagingFetching(true);

    try {
      const stagingFolderRepository = container.get<StagingFolderRepository>(StagingFolderRepository);

      const response = await stagingFolderRepository.getTree({
        dataroomId: dataroom.id,
      });

      const stagingFolderTree = decorateNode(response, null, true);

      setStagingFolderTree(stagingFolderTree);
      setIsStagingInitialized(true);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsStagingFetching(false);
    }
  };

  return {
    getStagingFolderTree,
    isStagingFetching,
    stagingFolderTree,
    isStagingInitialized,
  };
};

type StagingFolderTreeContextType = ReturnType<typeof useStagingFolderTree>;

export const StagingFolderTreeContext = createContext<StagingFolderTreeContextType>(null);

export const useStagingFolderTreeContext = () => {
  const context = useContext(StagingFolderTreeContext);
  if (!context) {
    throw new Error('useStagingFolderTreeContext must be used within a StagingFolderTreeContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const StagingFolderTreeContextProvider = ({ children }: IProps) => (
  <StagingFolderTreeContext.Provider value={ useStagingFolderTree() }>
    { children }
  </StagingFolderTreeContext.Provider>
);

export default StagingFolderTreeContextProvider;
