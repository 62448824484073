import React, { useState } from 'react';
import NavigationItem from '../NavigationItem';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { quickFilterItems } from './constants';
import screenVariables from '@dealroadshow/uikit/core/styles/screen/screen.scss';
import styles from './quickFilters.scss';
import { device } from '@/Framework/browser/device';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import useRouter from '@/Framework/hooks/useNextRouter';
import { isDateModifiedDisabled } from '@/dataroom/domain/tableColumnsPermissions';
import IconExpand from '@dealroadshow/uikit/core/components/Icon/IconExpand';
import IconCollapse from '@dealroadshow/uikit/core/components/Icon/IconCollapse';

const QuickFilters = () => {
  const [isOpen, setIsOpen] = useState<boolean>(!device()
    .isPhoneSized(parseInt(screenVariables.screenS)));

  const { dataroom } = useDataroomContext();
  const {
    canUserAccessPrimary,
    canUserAccessStaging,
    currentUser,
  } = useCurrentUserContext();
  const { asPath: pathname } = useRouter();

  const toggleFilters = () => {
    setIsOpen(!isOpen);
  };

  const IconComponent = isOpen ? IconCollapse : IconExpand;

  return (
    <div
      className={ styles.quickFiltersWrp }
      data-test="sidebarQuickFilters"
    >
      <div
        onClick={ toggleFilters }
        className={ styles.quickFiltersHeader }
      >
        <IconComponent
          className={ styles.toggleIcon }
          data-test="quickFiltersHeadButton"
        />
        <span className={ styles.titleItem }>Quick Filters</span>
      </div>
      { isOpen ? (
        <div className={ styles.quickFiltersChildren }>
          { quickFilterItems(
            dataroom.name,
            pathname,
            canUserAccessPrimary,
            canUserAccessStaging,
            !isDateModifiedDisabled(dataroom, currentUser),
          )
            .map(({
              key,
              ...rest
            }) => (
              <NavigationItem
                key={ key }
                { ...rest }
                className={ styles.quickFiltersItem }
                activeClassName={ styles.quickFiltersActiveItem }
                iconClassName={ styles.quickFiltersItemIcon }
                textClassName={ styles.quickFiltersItemText }
              />
            )) }
        </div>
      ) : null }
    </div>
  );
};

export default QuickFilters;
