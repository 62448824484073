import React, { useState } from 'react';
import isArray from 'lodash/isArray';
import DeleteModalContainer from '@/dataroom/ui/common/DataroomExplorer/Modals/DeleteModal';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';

interface IProps {
  items: IFilesystemListItem[],
  title?: string,
  className?: string,
  closeModal: () => void,
  children: (any) => void,
}

const DeleteModal = ({ items, title, className, closeModal, children }: IProps) => (
  <DeleteModalContainer
    title={ title }
    items={ items }
    className={ className }
    closeModal={ closeModal }
  >
    { /* @ts-ignore */ }
    { (footerRefCallback) => children({
      items,
      closeModal,
      footerRefCallback,
    }) }
  </DeleteModalContainer>
);

export default function useDeleteModal() {
  const [deleteItems, setDeleteItems] = useState([]);

  const showDeleteModal = (items) => {
    setDeleteItems(isArray(items) ? items : [items]);
  };

  const hideDeleteModal = () => {
    setDeleteItems([]);
  };

  return {
    DeleteModal,
    deleteItems,
    showDeleteModal,
    hideDeleteModal,
  };
}
