import React, { createContext, useContext } from 'react';
import noop from 'lodash/noop';
import useSession from './hooks/useSession';
import { SESSION_PROPAGATE_FORM_NAME } from './constants';
import useProlong from './hooks/useProlong';
import usePropagation from '@/users/application/Session/SessionContext/hooks/usePropagation';

interface IProps {
  children: any,
}

type SessionContextType = ReturnType<typeof useSession> & {
  propagate: ReturnType<typeof usePropagation>['propagate'],
  prolongSessionWithInterval: ReturnType<typeof useProlong>['prolongSession'],
};

export const SessionContext = createContext<SessionContextType>(null);

export function useSessionContext() {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSessionContext must be used within the SessionContext');
  }
  return context;
}

function SessionContextProvider({
  children,
}: IProps) {
  const {
    initialized,
    session,
    getSession,
    sessionFetching,
    currentUser,
    getCurrentUser,
    currentUserFetching,
    currentHost,
    accessPermissions,
    publicAccess,
    managerAccess,
    investorAccess,
    switchToInvestorAccess,
    switchToPublicAccess,
    switchToManagerAccess,
    switchToLegacyZeroToleranceAccess,
    isZeroToleranceAccess,
    checkSession,
    satisfiesAccessPermissions,
    satisfiesCurrentAccessPermissions,
    prolongSession,
    logout,
    invalidateSession,
    isTwoFactorAuthenticationRequired,
    setIsTwoFactorAuthenticationRequired,
    setSsidCookie,
    getViewSsid,
    bindViewSsid,
    clearViewSsid,
    getViewerId,
    getFingerprint,
    getUserAgent,
  } = useSession();

  const { prolongSession: prolongSessionWithInterval } = useProlong();

  const { propagate, shouldPropagate, callbackUrl, ssid } = usePropagation();

  let contextValue = {
    initialized,
    session,
    getSession,
    sessionFetching,
    currentUser,
    getCurrentUser,
    currentUserFetching,
    currentHost,
    accessPermissions,
    publicAccess,
    managerAccess,
    investorAccess,
    satisfiesAccessPermissions,
    satisfiesCurrentAccessPermissions,
    switchToInvestorAccess,
    switchToPublicAccess,
    switchToManagerAccess,
    switchToLegacyZeroToleranceAccess,
    isZeroToleranceAccess,
    checkSession,
    prolongSession,
    logout,
    invalidateSession,
    getViewSsid,
    bindViewSsid,
    clearViewSsid,
    setSsidCookie,
    getViewerId,
    getFingerprint,
    getUserAgent,
    isTwoFactorAuthenticationRequired,
    setIsTwoFactorAuthenticationRequired,
    propagate,
    prolongSessionWithInterval,
  };

  return (
    <SessionContext.Provider value={ contextValue }>
      { children }
      { shouldPropagate && (
        <form
          id={ SESSION_PROPAGATE_FORM_NAME }
          method="post"
          action={ callbackUrl }
          style={ { visibility: 'hidden' } }
        >
          <input type="text" name="ssid" value={ ssid } onChange={ noop } />
          <input type="submit" />
        </form>
      ) }
    </SessionContext.Provider>
  );
}

export default SessionContextProvider;
