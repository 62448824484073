import { Dependencies } from 'constitute';
import ErrorCodeHelper, { TErrorKeys } from '@finsight/error-codes';
import Dispatcher, { RpcError } from '@dealroadshow/json-rpc-dispatcher';
import Container from '@/Framework/DI/Container';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import Logger from '@/Framework/browser/log/Logger';
import messages from '@/Framework/Message/messages';
import { ConsentContext, ConsentContextType } from '@/dataroom/application/ConsentContext';
import { AccessRestrictedContext, AccessRestrictedContextType } from '@/dataroom/application/AccessRestrictedContext';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';
import config from '@/dataroom/application/config/config';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';

const mutedErrors = [
  'DATAROOM_TWO_FACTOR_VIOLATION',
  'DATAROOM_CONSENT_NOT_ACCEPTED',
  'DATAROOM_ACCESS_RESTRICTED',
  'DATAROOM_FILESYSTEM_ELEMENT_ACCESS_RESTRICTED',
];

@Dependencies(Container)
class DataroomErrorHandler {
  private container: Container;

  constructor(container: Container) {
    this.container = container;
  }

  /**
   * Handles dataroom error.
   */
  handleError(error, defaultNotification = null, tenant = null) {
    if (Dispatcher.isBrowserAbortError(error)) {
      return;
    }

    if (!(error instanceof RpcError)) {
      Logger.error(error, 'Dataroom response ErrorHandler scope. Not RpcError error.');
      return;
    }

    if (error.getCode() === ErrorCodeHelper.getCodeByName('DATAROOM_ACCESS_RESTRICTED')) {
      const dataroomDisplayName = error.getData()?.dataroomDisplayName;
      const dataroomTenant = tenant || DataroomTenantConfig.fromHostname(window.location.hostname).code;
      window.location.href = dataroomUrl(dataroomTenant).getAccessRestrictedUrl(dataroomDisplayName);
    }

    if (error.getCode() === ErrorCodeHelper.getCodeByName('DATAROOM_FILESYSTEM_ELEMENT_ACCESS_RESTRICTED')) {
      const { showAccessRestrictedModal } = this.container.get<AccessRestrictedContextType>(AccessRestrictedContext);
      showAccessRestrictedModal();
    }

    // todo: check this when BE be ready
    // todo: go to direct links with disabled consent
    if (error.getCode() === ErrorCodeHelper.getCodeByName('DATAROOM_CONSENT_NOT_ACCEPTED')) {
      const veriSendSetsUrl = dmPortalUrl.getUrl(`/${ config.tenant.tenantVeriSend.code }/my_sets`);
      const researchroomSetsUrl = dmPortalUrl.getUrl(`/${ config.tenant.tenantResearchRoom.code }/my_researches`);

      if (DataroomTenantConfig.fromHostname(window.location.hostname).code === config.tenant.tenantVeriSend.code) {
        window.location.href = veriSendSetsUrl;
        return;
      }

      if (DataroomTenantConfig.fromHostname(window.location.hostname).code === config.tenant.tenantResearchRoom.code) {
        window.location.href = researchroomSetsUrl;
        return;
      }

      const { setIsConsentAccepted } = this.container.get<ConsentContextType>(ConsentContext);
      setIsConsentAccepted(false);
    }

    if (error.getCode() === ErrorCodeHelper.getCodeByName('DATAROOM_USER_NOT_ADDED_TO_DATAROOM')) {
      if (
        tenant === config.tenant.tenantVeriSend.code || tenant === config.tenant.tenantInvestorSet.code
        || tenant === config.tenant.tenantResearchRoom.code
      ) {
        const setsName = tenant === config.tenant.tenantResearchRoom.code ? 'explore_researches' : 'explore_sets';
        const name = encodeURIComponent(error.getData()?.dataroomDisplayName);
        const exploreSetsUrl = dmPortalUrl.getUrl(`/${ tenant }/${ setsName }?search=${ name }`);
        window.location.href = exploreSetsUrl;
        return;
      }
    }

    const shouldErrorNotificationBeMuted = !!mutedErrors.find(
      (mutedError: TErrorKeys) => error?.getCode() === ErrorCodeHelper.getCodeByName(mutedError),
    );

    if (shouldErrorNotificationBeMuted) {
      return;
    }
    const notification = defaultNotification || getErrorMessage(error);
    NotificationManager.error(notification);

    if (notification === messages.GENERAL_ERROR) {
      Logger.error(error, 'Dataroom response ErrorHandler scope.');
    }
  }
}
export default DataroomErrorHandler;
