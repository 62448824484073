import React, { createContext, useContext } from 'react';
import useCopy from '@/dataroom/application/filesystem/filesystemActions/useCopy';
import useMove from '@/dataroom/application/filesystem/filesystemActions/useMove';
import useCreateFolder from '@/dataroom/application/filesystem/filesystemActions/useCreateFolder';
import useCreateLink from '@/dataroom/application/filesystem/filesystemActions/useCreateLink';
import useFileIndexChange from '@/dataroom/application/filesystem/filesystemActions/useFileIndexChange';
import useIndexLimits from '@/dataroom/application/filesystem/filesystemActions/useIndexLimits';
import useRemove from '@/dataroom/application/filesystem/filesystemActions/useRemove';
import useEdit from '@/dataroom/application/filesystem/filesystemActions/useEdit';
import useDisable from '@/dataroom/application/filesystem/filesystemActions/useDisable';
import useCustomPermissions from '@/dataroom/application/filesystem/filesystemActions/useCustomPermissions';
import useCustomEditPermissions from '@/dataroom/application/filesystem/filesystemActions/useCustomEditPermissions';
import useAllEditPermissions from '@/dataroom/application/filesystem/filesystemActions/useAllEditPermissions';
import useBulkRename from '@/dataroom/application/filesystem/filesystemActions/useBulkRename';

const useFilesystem = () => ({
  copy: useCopy(),
  move: useMove(),
  remove: useRemove(),
  edit: useEdit(),
  createFolder: useCreateFolder(),
  createLink: useCreateLink(),
  fileIndexChange: useFileIndexChange(),
  indexLimits: useIndexLimits(),
  disable: useDisable(),
  customPermissions: useCustomPermissions(),
  customEditPermissions: useCustomEditPermissions(),
  allEditPermissions: useAllEditPermissions(),
  bulkRename: useBulkRename(),
});

type FilesystemContextType = ReturnType<typeof useFilesystem>;

export const FilesystemContext = createContext<FilesystemContextType>(null);

export const useFilesystemContext = () => {
  const context = useContext(FilesystemContext);
  if (!context) {
    throw new Error('useFilesystemContext must be used within a FilesystemContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactElement,
}

const FilesystemContextProvider = ({ children }: IProps) => (
  <FilesystemContext.Provider value={ useFilesystem() }>
    { children }
  </FilesystemContext.Provider>
);

export default FilesystemContextProvider;
