import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import TenantConfig from '@/Framework/Tenant/TenantConfig';
import ConsentRepository from '../infrastructure/repository/ConsentRepository';
import DataroomErrorHandler from './ErrorHandler/DataroomErrorHandler';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';

const useConsent = (tenant, contactId) => {
  const { container } = useDIContext();
  const [isConsentAccepted, setIsConsentAccepted] = useState<boolean>(undefined);
  const [isAcceptConsentFetching, setIsAcceptConsentFetching] = useState(false);
  const [isConsentStatusFetching, setIsConsentStatusFetching] = useState(false);

  const consentRepository = container.get<ConsentRepository>(ConsentRepository);

  useEffect(() => {
    const setStatus = async () => {
      try {
        setIsConsentStatusFetching(true);
        let response;

        if (contactId) {
          response = await consentRepository.isConsentAcceptedById(tenant, contactId);
        } else {
          response = await consentRepository.isConsentAccepted(tenant);
        }

        setIsConsentAccepted(response.accepted);
      } catch (error) {
        container.get(DataroomErrorHandler).handleError(error);
      } finally {
        setIsConsentStatusFetching(false);
      }
    };

    if (TenantConfig.fromCode(tenant).isConsentForEDeliveryRequired) {
      setStatus();
    }
  }, []);

  const acceptConsent = async () => {
    setIsAcceptConsentFetching(true);

    try {
      await consentRepository.acceptConsent(tenant);

      setIsConsentAccepted(true);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsAcceptConsentFetching(false);
    }
  };

  return {
    acceptConsent,
    isAcceptConsentFetching,
    isConsentStatusFetching,
    isConsentAccepted,
    setIsConsentAccepted,
  };
};

export type ConsentContextType = ReturnType<typeof useConsent>;

export const ConsentContext = createContext<ConsentContextType>(undefined);

export const useConsentContext = () => {
  const context = useContext(ConsentContext);
  if (!context) {
    throw new Error('useConsentContext must be used within a ConsentContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
  tenant: DataroomTenant, // We can't use tenant context here, because can't use nested context
  contactId?: string,
}

const ConsentContextProvider = ({ children, tenant, contactId }: IProps) => {
  const { container } = useDIContext();
  const contextValue = useConsent(tenant, contactId);
  container.bindContextValue(ConsentContext, contextValue);

  return (
    <ConsentContext.Provider value={ contextValue }>
      { children }
    </ConsentContext.Provider>
  );
};

export default ConsentContextProvider;
