import React, { useEffect } from 'react';
import { useSidebarTabContext } from '@/dataroom/application/SidebarTabContext';
import { SidebarTab } from '@/dataroom/ui/components/Dataroom/Sidebar/constants';

interface IProps {
  children: React.ReactElement,
  tabKey: SidebarTab,
}

const SidebarTabSwitcher = ({ children, tabKey }: IProps) => {
  const { changeSidebarTab } = useSidebarTabContext();

  useEffect(() => {
    changeSidebarTab(tabKey);
  }, [tabKey]);

  return children;
};

export default SidebarTabSwitcher;
