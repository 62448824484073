import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateRequiredFields } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredFields';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateFilesystemItemName } from '@/Framework/UI/Organisms/FinalForm/validators/validateFilesystemItemName';

const fieldsList = [
  { fieldName: 'fileName', fieldCaption: 'File name' },
];

export default (values) => groupValidators(
  validateRequiredFields(
    fieldsList,
    {
      fileName: values.fileName?.trim(),
    },
  ),
  validateMaxLength({
    value: values.fileName,
    length: 235,
    fieldName: 'fileName',
    fieldCaption: 'File name',
  }),
  validateFilesystemItemName({
    value: values.fileName,
    fieldName: 'fileName',
    fieldCaption: 'File name',
  }),
);
