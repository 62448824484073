import React, { Fragment } from 'react';
import getOverflowedArray from '@/dataroom/infrastructure/getOverflowedArray';
import styles from './path.scss';
import FileWithUploadAndPath from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/FileWithUploadAndPath';

interface IProps {
  cellProps: {
    row: FileWithUploadAndPath,
  },
}

const Path = ({ cellProps }: IProps) => {
  const { path } = cellProps.row;
  const pathArray = path.split('/').filter((el) => el);
  const filePathArray = pathArray.slice(0, pathArray.length - 1);

  return (
    <span className={ styles.pathColumn }>
      { getOverflowedArray(filePathArray).map((item, index, arr) => {
        const key = `${ index }${ item }`;
        return (
          <Fragment key={ key }>
            <span className={ styles.pathItem }>{ item }</span>
            { index !== arr.length - 1 && <span className={ styles.pathItemDelimiter }>/</span> }
          </Fragment>
        );
      }) }
    </span>
  );
};

export default Path;
