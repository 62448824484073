import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';
import { conflictTypes } from '@/dataroom/ui/common/DataroomExplorer/Modals/ConflictModal/constants';
import CopyMoveModal from '@/dataroom/ui/common/DataroomExplorer/Modals/CopyMoveModal/CopyMoveModal';
import { Action } from '@/dataroom/domain/vo/filesystem/Action';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';

interface IProps {
  items: IFolderTree[],
  closeModal: () => void,
}

const CopyModal = ({ items, closeModal }: IProps) => {
  const { copy } = useFilesystemContext();

  if (isEmpty(items)) {
    return null;
  }

  return (
    <CopyMoveModal
      { ...copy }
      filesystemItems={ items }
      title={
        items.length === 1
          ? `Copy: "${ items[0].name }"`
          : `Copy ${ items.length } Selected Items`
      }
      closeCopyMoveModal={ () => {
        closeModal();
        copy.reset();
      } }
      actionName={ Action.Copy }
      conflictType={ conflictTypes.copy }
    />
  );
};

export default CopyModal;
