import React, { useEffect, useRef, useCallback } from 'react';
import cn from 'classnames';
import { useVirtualDataTableContext } from '../VirtualDataTableContext';
import styles from './outerComponent.scss';

interface IProps {
  onScroll: ({ currentTarget }: { currentTarget: Partial<HTMLDivElement> }) => void,
  style: React.CSSProperties,
  children: React.ReactNode,
}

const headerOffset = 100;

const OuterComponent = ({
  onScroll,
  style: { height, ...style },
  children,
}: IProps) => {
  const { dataTableProps: {
    layoutContentId,
    outerElementClassName,
  } } = useVirtualDataTableContext();

  const containerRef = useRef(null);
  const scrollerRef = useRef(null);
  const scroller = document.getElementById(layoutContentId);

  const handleScroll = useCallback(() => {
    const {
      clientWidth,
      clientHeight,
      scrollLeft,
      scrollTop,
      scrollHeight,
      scrollWidth,
    } = scrollerRef.current;

    const containerTopPosition = containerRef.current?.getBoundingClientRect().top;
    const theadComponent = containerRef.current.querySelector('thead');

    if (theadComponent) {
      theadComponent.style.visibility = (containerTopPosition + headerOffset < 0) ? 'hidden' : 'visible';
    }

    onScroll({
      currentTarget: {
        clientHeight,
        clientWidth,
        scrollLeft,
        scrollTop: scrollTop - (containerRef.current ? containerTopPosition + scrollTop : 0),
        scrollHeight,
        scrollWidth,
      },
    });
  }, []);

  useEffect(() => {
    scrollerRef.current = scroller;

    scroller && scroller.addEventListener('scroll', handleScroll);

    return () => {
      scroller && scroller.removeEventListener('scroll', handleScroll);
    };
  }, [scroller]);

  return (
    <div
      className={ cn(styles.outerElement, outerElementClassName) }
      style={ style }
      ref={ containerRef }
    >
      { children }
    </div>
  );
};

export default OuterComponent;
