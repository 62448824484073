import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import FilesystemRepository from '@/dataroom/infrastructure/repository/FilesystemRepository';

export default function useAllowedFileTypes() {
  const { container } = useDIContext();
  const [allowedFileTypes, setAllowedFileTypes] = useState([]);
  const [isAllowedFileTypesFetching, setIsAllowedFileTypesFetching] = useState(false);

  const getAllowedFileTypes = async (dataroomId) => {
    setIsAllowedFileTypesFetching(true);

    const filesystemRepository = container.get<FilesystemRepository>(FilesystemRepository);
    const result = await filesystemRepository.getAllowedExtensions({ dataroomId });
    const allowedExtensions = Object.values(result.collection).map(({ extension }) => extension);
    setAllowedFileTypes(allowedExtensions);
    setIsAllowedFileTypesFetching(false);
  };

  return {
    allowedFileTypes,
    getAllowedFileTypes,
    isAllowedFileTypesFetching,
  };
}
