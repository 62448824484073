import { Class, Transient, Method } from 'constitute';
import Client from '@dealroadshow/socket-frontend-sdk';
import SocketDispatcherProvider, {
  ISocketDispatcherFactory,
} from '@/dataroom/application/DI/Rpc/SocketDispatcher';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import { DataroomTenantContext, IDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import Container from '@/Framework/DI/Container';

// TODO: need a typing for socket-frontend-sdk. Client is just a function in built code.

class FactoryBuilder {
  container: Container;

  constructor(container) {
    this.container = container;
  }

  getFactory() {
    return (tenantCode: DataroomTenant = null) => {
      const tenant = tenantCode || this.container.get<IDataroomTenantContext>(DataroomTenantContext).tenant;

      const socketDispatcherProvider = this.container.get<ISocketDispatcherFactory>(SocketDispatcherProvider);

      return this.container.get(new Class(Client, Transient.with([socketDispatcherProvider(tenant)])) as Client);
    };
  }
}

export default new Method(
  (container: Container) => {
    const factoryBuilder = new FactoryBuilder(container);
    return factoryBuilder.getFactory();
  },
  [Container],
) as ReturnType<InstanceType<typeof FactoryBuilder>['getFactory']>;
