import React from 'react';
import { components } from 'react-select';
import Select from '@/Framework/UI/Molecules/Form/Select/legacy';
import { groupedStylesConfig } from './groupedSelectConfig';

import styles from './groupedSelect.scss';

const handleHeaderClick = ({ selectProps, data }) => {
  const selected = selectProps.value
    ? [
      ...selectProps.value,
      ...data.options.filter((option) => !selectProps
        .value.find(
          (v) => selectProps.getOptionValue(option) === selectProps.getOptionValue(v),
        ),
      ),
    ]
    : data.options;

  selectProps.onChange(selected);
};

const CustomGroupHeading = (props) => (
  <div
    onClick={ () => handleHeaderClick(props) }
  >
    <components.GroupHeading { ...props } className={ styles.groupHeader } />
  </div>
);

const CustomGroup = (props) => <components.Group { ...props } className={ styles.group } />;

/**
 * @deprecated This component is DEPRECATED. Use ui/shared/components/Form/Select
 */
const GroupedSelect = (props) => (
  <Select
    isMulti
    hideSelectedOptions={ false }
    optionClassName={ styles.option }
    selectedOptionClassName={ styles.selectedOption }
    multiValueClassName={ styles.multiValueWrp }
    { ...props }
    components={ {
        ...props.components,
        GroupHeading: CustomGroupHeading,
        Group: CustomGroup,
      } }
    selectStyles={ groupedStylesConfig }
  />
);

export default GroupedSelect;
