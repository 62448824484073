import { useState } from 'react';
import CancelUploadModal from './CancelUploadModal';

export default function useCancelUploadModal() {
  const [isVisibleCancelUploadModal, setIsVisibleCancelUploadModal] = useState(false);

  function showCanceledModal() {
    setIsVisibleCancelUploadModal(true);
  }

  function hideCanceledModal() {
    setIsVisibleCancelUploadModal(false);
  }

  return {
    isVisibleCancelUploadModal,
    CancelUploadModal,
    showCanceledModal,
    hideCanceledModal,
  };
}
