export const defaultCheckboxGroupName = 'selectedItems';
export const allItems = 'allItems';
export const disabledIds = 'disabledIds';

export type Value = string | number | {
  isDisabled: boolean,
};

export interface IValues {
  allItems: Value[],
  selectedItems: Value[],
}
