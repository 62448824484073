import React, { createContext, useContext, useState } from 'react';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';

const useInfoPanel = () => {
  const [isInfoPanelVisible, setIsInfoPanelVisible] = useState(false);
  const [infoItem, setInfoItem] = useState<IFilesystemListItem | IFolderTree>(null);

  const showInfoPanel = () => {
    setIsInfoPanelVisible(true);
  };

  const setSelectedItem = (infoItem) => {
    setInfoItem(infoItem);
  };

  const hideInfoPanel = () => {
    setIsInfoPanelVisible(false);
  };

  return {
    isInfoPanelVisible,
    infoItem,
    showInfoPanel,
    hideInfoPanel,
    setSelectedItem,
  };
};

export const InfoContext = createContext<ReturnType<typeof useInfoPanel>>(null);

export const useInfoPanelContext = () => {
  const context = useContext(InfoContext);
  if (!context) {
    throw new Error('useInfoPanelContext must be used within a InfoPanelContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const InfoPanelContextProvider = ({ children }: IProps) => (
  <InfoContext.Provider value={ useInfoPanel() }>
    { children }
  </InfoContext.Provider>
);

export default InfoPanelContextProvider;
