import React, { useState, useEffect } from 'react';
import { useDownloadContext } from '@/dataroom/application/DownloadContext';
import TruncationModal from './TruncationModal';
import EmailModal from './EmailModal';
import PreloadModal from './PreloadModal';
import DownloadRequest from '@/dataroom/application/models/DownloadRequest';
import { useDownloadArchiveStatusContext } from '@/dataroom/application/DownloadArchiveStatusContext';

const DownloadModals = () => {
  const [currentDownload, setCurrentDownload] = useState<DownloadRequest>(null);
  const { incrementArchiveCount } = useDownloadArchiveStatusContext();

  const {
    runningDownloadRequests,
    addDownloadRequest,
    removeDownloadRequest,
    download,
    isPreloadModalVisible,
    closePreloadModal,
  } = useDownloadContext();

  useEffect(() => {
    setCurrentDownload(runningDownloadRequests?.[0]);
  }, [runningDownloadRequests]);

  const handleCloseModal = () => {
    removeDownloadRequest(currentDownload);
    setCurrentDownload(null);
  };

  const handleTruncationModalSubmit = (truncationMode, stagingTruncationMode) => {
    handleCloseModal();
    currentDownload.truncationMode = truncationMode;
    currentDownload.stagingTruncationMode = stagingTruncationMode;
    currentDownload.isTruncationModalVisible = false;
    addDownloadRequest(currentDownload);
    incrementArchiveCount();
    download(currentDownload);
  };

  if (currentDownload?.isTruncationModalVisible) {
    return (
      <TruncationModal
        areas={ currentDownload.areas }
        closeModal={ handleCloseModal }
        onSubmit={ handleTruncationModalSubmit }
      />
    );
  }

  if (currentDownload?.isEmailModalVisible) {
    return <EmailModal closeModal={ handleCloseModal } />;
  }

  if (isPreloadModalVisible) {
    return <PreloadModal closeModal={ closePreloadModal } />;
  }

  return null;
};

export default DownloadModals;
