import React from 'react';
import cn from 'classnames';
import { CountLabel as CountLabelBase } from '@dealroadshow/uikit/core/components/Badge/CountLabel';
import isDefined from '@/Framework/dataHelpers/isDefined';
import styles from './countLabel.scss';

interface IProps {
  className?: string,
  totalCount: number,
  canShowCount?: boolean,
  maxTotalCount?: number,
  backgroundColor?: string,
  showCountWithZeroVolume?: boolean,
}

const CountLabel = (
  {
    className,
    totalCount,
    canShowCount,
    maxTotalCount = 99,
    backgroundColor = '#cc4d4d',
    showCountWithZeroVolume = false,
  }: IProps,
) => (
  <CountLabelBase
    className={ cn(styles.countLabel, className) }
    totalCount={ totalCount }
    maxTotalCount={ maxTotalCount }
    showCountWithZeroVolume={ showCountWithZeroVolume }
    canShowCount={ isDefined(canShowCount) ? canShowCount : !!totalCount }
    backgroundColor={ backgroundColor }
  />
);

export default CountLabel;
