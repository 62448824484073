import React, { useState, createContext, useContext, useEffect } from 'react';

const useShift = () => {
  const [isShiftHeld, setShiftHeld] = useState(false);

  const downKeyHandler = (e) => {
    if (e.key === 'Shift') {
      setShiftHeld(true);
      document.onselectstart = () => false;
    }
  };

  const upKeyHandler = (e) => {
    if (e.key === 'Shift') {
      setShiftHeld(false);
      document.onselectstart = () => true;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downKeyHandler);
    window.addEventListener('keyup', upKeyHandler);
    return () => {
      window.removeEventListener('keydown', downKeyHandler);
      window.removeEventListener('keyup', upKeyHandler);
    };
  }, []);

  return {
    isShiftHeld,
  };
};

type TShiftContext = ReturnType<typeof useShift>;

export const ShiftContext = createContext<TShiftContext>(null);

export function useShiftContext() {
  const context = useContext(ShiftContext);
  if (!context) {
    throw new Error('useShiftContext must be used within a ShiftContextProvider');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function ShiftContextProvider({ children } : IProps) {
  return (
    <ShiftContext.Provider value={ useShift() }>
      { children }
    </ShiftContext.Provider>
  );
}

export default ShiftContextProvider;
